import { parse } from "csv-parse/browser/esm/sync";
import * as XLSX from "xlsx";
import { ServerError, ValidationError } from "./responseResults";

export async function parseFromFile(file: File) {
  // if (file.name.endsWith(".csv")) {
  //     const csvTextResult = await parseFromBrowserFile(file);
  //     if (!csvTextResult.success) {
  //         throw new ValidationError(`Failed to read ${file.name}`, { name: file.name });
  //     }

  //     return parse(csvTextResult.data, {
  //         columns: true,
  //         skip_empty_lines: true,
  //         relax_column_count: true,
  //         trim: true
  //     });
  // }

  // TODO read from excel file
  const workbook = XLSX.read(await file.arrayBuffer());
  return workbook;
}

function parseFromBrowserFile(
  file: File,
): Promise<{ success: boolean; data: string; error: any }> {
  return new Promise((res) => {
    var reader = new FileReader();
    reader.readAsText(file, "UTF-8");
    reader.onload = function (event) {
      res({
        success: true,
        data: (event.target?.result as string) || "",
        error: null,
      });
    };
    reader.onerror = function (event) {
      res({ success: false, data: "", error: event });
    };
  });
}

export async function UploadPresignedFile(url: string, newFile: File) {
    return await fetch(url, {
      method: "PUT",
      body: newFile,
      headers: {
        "Content-Type": newFile.type,
      },
    })
      .then((res) => {
        if ([202, 200, 201].includes(res.status))
          return { success: true, data: { status: res.status } };
        return { success: false, data: { status: res.status } };
      })
      .catch((error: any) => {
        return { success: false, data: { error } };
      });
}

export async function UploadPresignedBlob(url: string, blob: Blob, fileType: string) {
    return await fetch(url, {
      method: "PUT",
      body: blob,
      headers: {
        "Content-Type": fileType,
      },
    })
      .then((res) => {
        if ([202, 200, 201].includes(res.status)) {
          return { success: true, data: { status: res.status } };
        }
        return { success: false, data: { status: res.status } };
      })
      .catch((error: any) => {
        return { success: false, data: { error } };
      });
}

export function downloadFromS3(presignedUrl: string, fileName: string) {
  return fetch(presignedUrl)
    .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.blob();
    })
    .then(blob => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      return { success: true }
    })
    .catch((error: any) => {
      return { success: false };
    });
}
