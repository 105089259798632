import * as React from "react";
import moment from "moment-timezone";
import { CalendarDays } from "lucide-react";

import { cn } from "../../lib/utils";
import { Button } from "./button";
import { Calendar, DateRange, SelectRangeEventHandler } from "./calendar";
import { Popover, PopoverContent, PopoverTrigger } from "./popover";

export type { DateRange };

export function DatePicker({
  value: date,
  onChange: setDate,
  placeholder,
  tabIndex,
  buttonClassName,
}: {
  value: Date | undefined;
  onChange: (date: Date | undefined) => void;
  placeholder?: String;
  tabIndex?: number;
  buttonClassName?: string;
}) {
  const fromDate = moment().subtract(5, "years").startOf("day").toDate();
  const toDate = moment().add(5, "years").endOf("day").toDate();

  const fromYear = moment().subtract(5, "years").startOf("day").year();
  const toYear = moment().add(5, "years").endOf("day").year();

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          tabIndex={tabIndex}
          variant={"outline"}
          className={cn(
            "w-[200px] justify-start text-left font-normal",
            !date && "text-muted-foreground",
            buttonClassName,
          )}
        >
          <CalendarDays className="mr-2 h-4 w-4" />
          {date ? (
            moment(date).format("MMM Do, YYYY")
          ) : (
            <span>{placeholder || "Pick a date"}</span>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0" align="start">
        <Calendar
          mode="single"
          captionLayout="dropdown"
          fromDate={fromDate}
          toDate={toDate}
          fromYear={fromYear}
          toYear={toYear}
          selected={date}
          onSelect={setDate}
          defaultMonth={date}
          initialFocus
        />
      </PopoverContent>
    </Popover>
  );
}

export function DateRangePicker({
  start,
  end,
  onChange: setDate,
  placeholder,
  fromYear,
  toYear,
  className,
  fromDate,
  toDate,
}: {
  start: Date | undefined;
  end: Date | undefined;
  fromDate?: Date;
  toDate?: Date;
  onChange: SelectRangeEventHandler;
  placeholder?: String;
  fromYear: number;
  toYear: number;
  className?: string;
}) {
  const displayString = generateRangeDisplay(start, end);
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant={"outline"}
          className={cn(
            "w-full justify-start text-left font-normal",
            !displayString && "text-muted-foreground",
            className,
          )}
        >
          <CalendarDays className="mr-2 h-4 w-4" />
          <span>{displayString || placeholder || "Pick a date"}</span>
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0" align="start">
        <Calendar
          mode="range"
          selected={{ from: start, to: end }}
          onSelect={setDate}
          initialFocus
          fromDate={fromDate}
          toDate={toDate}
          captionLayout="dropdown"
          fromYear={fromYear}
          toYear={toYear}
        />
      </PopoverContent>
    </Popover>
  );
}

function generateRangeDisplay(start?: Date, end?: Date) {
  if (!start && !end) {
    return undefined;
  }

  if (!end) {
    return moment(start).format("MMM Do, YYYY - ");
  }

  return `${moment(start).format("MMM Do, YYYY")} - ${moment(end).format("MMM Do, YYYY")}`;
}
