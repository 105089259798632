import moment from "moment-timezone";
import { v4 as uuidV4 } from "uuid";
import { useRef, useState, useEffect } from "react";
import {
  CheckIcon,
  PencilIcon,
  AlertCircleIcon,
  CheckSquare as CheckSquareIcon,
  Plus as PlusIcon,
  Send as SendIcon,
  Trash as TrashIcon,
  FlaskConical,
} from "lucide-react";
import { useToast } from "../../components/ui/use-toast";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../components/ui/popover";
import { SelectInput } from "../../components/ui/SelectInput";
import { Input, InputProps } from "../../components/ui/input";
import { Combobox } from "../../components/ui/combobox";
import { Skeleton } from "../../components/ui/skeleton";
import { Button } from "../../components/ui/button";
import { Textarea } from "../../components/ui/textarea";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../components/ui/tooltip";
import {
  AnalystRecommendation,
  AnalystAlert,
  AnalystRecommendationType,
  AnalysisAction,
  AnalysisCommodityOptions,
  AnalysisSignalOptions,
  AnalystRecommendationCropTypeOptions,
  AlertCommodities,
} from "../../lib/models/analystRecommendation";
import {
  useUpsertRecommendation,
  useLastDayAnalysis,
  usePublishRecommendations,
  useDeleteRecommendation,
  useAnalystRecommendations,
  ProjectFullName,
} from "../../lib/api/analystRecommendations";
import { MultiResourceAutoSaver } from "../../lib/autosave";
import { SelectOptionItem } from "../../lib/models/ui";
import { cn } from "../../lib/utils";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogClose,
} from "../../components/ui/dialog";

moment.tz.setDefault("America/Chicago");

const handleFocus = (event: any) => event.target.select();

function generateCommodityListOptions(a: AnalystAlert[], b: AnalystAlert[]) {
  const currentYear = moment().year();
  const nextYear = moment().year() + 1;

  const currentList = AlertCommodities.map((item) => {
    if (item.type === "continuous_charts") {
      return [item.outputName];
    }
    return [
      `${item.outputName}${currentYear}`,
      `${item.outputName}${nextYear}`,
    ];
  }).flat();

  return Array.from(
    new Set([
      ...currentList,
      ...a.map((item) => item.commodity),
      ...b.map((item) => item.commodity),
    ]),
  ).map((a) => ({ value: a, label: a }));
}

function parseFloatNumber(onChange: (item: number) => void) {
  return function parseFloatNumberHelper(event: any) {
    try {
      return onChange(parseFloat(event.target.value));
    } catch (e) {
      return onChange(0);
    }
  };
}

function generateRandomAlert(): any {
  return {
    id: uuidV4(),
    type: AnalystRecommendationType.Alert,
    action: AnalysisAction.Buy,
    active: false,
    published: false,
    date: moment.tz("America/Chicago").startOf("day").toDate(),
    commodity: `Corn${moment().year()}`,
    amount: 20,
    details: "",
    price: 0.0,
  };
}

export function RecommendationAlert() {
  return (
    <>
      <div className="border-b border-b-2 flex flex-row justify-between items-center">
        <h1 className="scroll-m-20 text-2xl font-bold tracking-tight lg:text-3xl">
          HedgeBeacon Alerts
        </h1>
        <span className="flex flex-row items-center">
          {moment.tz("America/Chicago").format("dddd M/D/YY")}
        </span>
      </div>
      <BaseView />
    </>
  );
}

function LoadingPage() {
  return (
    <div className="flex flex-col space-y-2 mt-8">
      <Skeleton className="h-4 w-[250px]" />
      <Skeleton className="h-4 w-[200px]" />
    </div>
  );
}

function calculateFilters(date: Date) {
  const from = moment
    .tz(date, "America/Chicago")
    .startOf("year")
    .subtract(1, "year")
    .toDate();
  const to = moment.tz(date, "America/Chicago").endOf("year").toDate();
  const recommendationType = AnalystRecommendationType.Alert;
  return { from, to, recommendationType, limit: 25 };
}

function BaseView() {
  const { data: result, isLoading } = useAnalystRecommendations(
    calculateFilters(new Date()),
  );

  // @ts-ignore
  const base: any[] = (result?.success ? result?.data : []) || [];
  // @ts-ignore
  const analystAlerts: AnalystAlert[] = base?.map((data: AnalystAlert) => ({
    ...data,
    amount: parseFloat(data.amount as any),
    price: parseFloat(data.price as any),
  }));

  return (
    <div className="flex items-start flex-row h-full gap-4">
      <div className="flex flex-col items-start justify-start">
        {isLoading && <LoadingPage />}
        {!isLoading && <RecentAlerts alerts={analystAlerts || []} />}
      </div>
    </div>
  );
}

function RecentAlerts({ alerts }: { alerts: AnalystAlert[] }) {
  const [commodityOptionsList, setCommodityOptionsList] = useState<
    SelectOptionItem<string>[]
  >(generateCommodityListOptions([], alerts));
  const [currentAlerts, setCurrentAlerts] = useState<AnalystAlert[]>(
    alerts || [],
  );
  const upsertRecommendationAlert = useUpsertRecommendation();
  const [autoSaver, _setAutoSaver] = useState<
    MultiResourceAutoSaver<AnalystAlert, AnalystRecommendation>
  >(
    new MultiResourceAutoSaver<AnalystAlert, AnalystRecommendation>(
      [],
      upsertRecommendationAlert,
    ),
  );

  const updateRow =
    (id: string) =>
    ({ key, value }: { key: string; value: any }) => {
      let returnValue = undefined;
      setCurrentAlerts(
        currentAlerts.map((row, index) => {
          if (id !== row.id) return row;

          if (key === "date") {
            const simpleDate = moment(value).format("YYYY/MM/DD");
            value = moment
              .tz(simpleDate, "YYYY/MM/DD", "America/Chicago")
              .toDate();
          }

          const updatedRecommendation = { ...row, [key]: value };
          returnValue = updatedRecommendation;
          autoSaver.enqueueData(updatedRecommendation);

          return updatedRecommendation;
        }),
      );

      return returnValue;
    };

  const dashboardColumns = [
    {
      id: "date",
      header: "Date",
      cell: ({ row }: { row: { original: AnalystAlert } }) =>
        row.original.published
          ? moment.tz(row.original.date, "America/Chicago").format("M/D/YYYY")
          : "--",
    },
    {
      accessorKey: "action",
      header: "Action",
      cell: ({ row }: { row: { original: AnalystAlert } }) => {
        return (
          <SelectInput
            disabled={row.original.published}
            onValueChange={(value) =>
              updateRow(row.original.id)({ key: "action", value })
            }
            className="px-0 w-[4.5rem] bg-transparent border-none focus:ring-0"
            value={row.original.action}
            options={AnalysisSignalOptions}
          />
        );
      },
    },
    {
      accessorKey: "price",
      header: "Price",
      cell: ({ row }: { row: { original: AnalystAlert } }) => (
        <PriceInput alert={row.original} updateRow={updateRow} />
      ),
    },
    {
      accessorKey: "amount",
      header: "Amount",
      cell: ({ row }: { row: { original: AnalystAlert } }) => (
        <AmountInput alert={row.original} updateRow={updateRow} />
      ),
    },
    {
      accessorKey: "commodity",
      header: "Commodity",
      cell: ({ row }: { row: { original: AnalystAlert } }) => (
        <Combobox
          disabled={row.original.published}
          onValueChange={(value) => {
            return updateRow(row.original.id)({
              key: "commodity",
              value:
                commodityOptionsList.find(
                  (item) =>
                    item.value.toLocaleLowerCase() ===
                    value.toLocaleLowerCase(),
                )?.value || "",
            });
          }}
          className="px-0 w-[10rem] bg-transparent border-none focus:ring-0"
          value={row.original.commodity}
          options={commodityOptionsList}
        />
      ),
    },
    {
      accessorKey: "details",
      header: "Details",
      cell: ({ row }: { row: { original: AnalystAlert } }) => (
        <AlertDetails
          disabled={row.original.published}
          details={row.original.details || ""}
          onChange={(value: string) => {
            return updateRow(row.original.id)({ key: "details", value });
          }}
        />
      ),
    },
    {
      accessorKey: "published",
      header: "Published",
      cell: ({ row }: { row: { original: AnalystAlert } }) => (
        <AlertPublishedAction
          alert={row.original}
          onChange={(value: boolean) => {
            return updateRow(row.original.id)({ key: "published", value });
          }}
          requiresConfirmation
          projectName={ProjectFullName.HedgeBeacon}
        />
      ),
    },
    {
      accessorKey: "is_deleted",
      header: "Delete",
      cell: ({ row }: { row: { original: AnalystAlert } }) => (
        <DeleteAlertAction
          alert={row.original}
          onChange={() => {
            setCurrentAlerts(
              currentAlerts.filter((item) => item.id !== row.original.id),
            );
          }}
        />
      ),
    },
  ];

  const onCreateNewAlert = () => {
    const newAlert = generateRandomAlert();
    setCurrentAlerts([newAlert, ...currentAlerts]);
    autoSaver.enqueueData(newAlert);
  };

  return (
    <div className="flex flex-col m-4 min-w-[56rem]">
      <div className="bg-neutral-200 flex flex-row justify-between items-center px-4 py-2">
        <span className="font-semibold">Recent Alerts</span>
        <span className="flex flex-row">
          <Button
            variant="default"
            size="icon"
            className="flex justify-center items-center h-8 w-8 bg-red-700 hover:bg-red-700/90"
            onClick={onCreateNewAlert}
          >
            <PlusIcon className="h-4 w-4 p-0" />
          </Button>
        </span>
      </div>
      <div className="text-sm border min-h-[30rem] max-h-[40rem] lg:max-h-[42]  overflow-auto">
        <div className="[&_span]:border-b grid grid-cols-[1fr_1fr_1fr_1fr_2fr_3fr_1fr_1fr] justify-center items-center text-center py-1 px-2">
          {dashboardColumns.map((col) => (
            <span key={col.header} className="font-semibold">
              {col.header as any}
            </span>
          ))}
        </div>
        <div className="flex flex-col [&_div:last-child]:border-0 px-1 pb-1">
          {currentAlerts.map((alert) => (
            <div
              key={`${alert.id}`}
              className="grid grid-cols-[1fr_1fr_1fr_1fr_2fr_3fr_1fr_1fr] border-b transition-colors hover:bg-muted/50"
            >
              {dashboardColumns.map((col) => (
                <span
                  key={`${col.header}:${alert.id}`}
                  className="flex justify-center items-center "
                >
                  {col?.cell({ row: { original: alert } })}
                </span>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

function DeleteAlertAction({
  alert,
  onChange,
}: {
  alert: AnalystAlert;
  onChange: (status: boolean) => void;
}) {
  const { toast } = useToast();
  const [isDeleted, setIsDeleted] = useState<boolean>(alert.is_deleted);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const deleteAlert = useDeleteRecommendation();

  const onDelete = async () => {
    if (isLoading) return;
    setIsLoading(true);

    const result = await deleteAlert(alert.id);

    setIsLoading(false);

    if (!!result.success) {
      onChange(true);
      setIsDeleted(true);
      toast({
        title: "Deleted alert",
        description: "",
      });
    } else {
      toast({
        title: "Failed to delete",
        description: "Please try again",
      });
    }
  };

  if (isDeleted) {
    return <CheckIcon size={16} />;
  }

  return (
    <Button
      variant="ghost"
      className="h-8 w-8 p-0"
      onClick={onDelete}
      disabled={isLoading}
    >
      <span className="sr-only">Delet Alert</span>
      <TrashIcon className="h-4 w-4" />
    </Button>
  );
}

function AlertPublishedAction({
  alert,
  onChange,
  requiresConfirmation,
  projectName,
}: {
  alert: AnalystAlert;
  onChange: (status: boolean) => void;
  requiresConfirmation?: boolean;
  projectName: ProjectFullName;
}) {
  const { toast } = useToast();
  const [isPublished, setIsPublished] = useState<boolean>(alert.published);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const publishAnalysis = usePublishRecommendations();

  const isTest = projectName.includes("test");

  const onPublish = async () => {
    if (!alert.details) {
      toast({
        title: "Failed",
        description: "May not send empty alert.",
      });
      return;
    }

    if (isLoading) return;
    setIsLoading(true);

    const publishResult = await publishAnalysis({
      recommendationIds: [alert.id],
      published: true,
      projectName,
    });
    setIsLoading(false);

    if (!!publishResult.success) {
      setIsPublished(true);
      onChange(true);
      toast({
        title: "Published alert",
        description: "",
      });
    } else {
      toast({
        title: "Failed to publish",
        description: "Please try again",
      });
    }
  };

  if (isPublished) {
    return <CheckIcon size={16} />;
  }

  if (requiresConfirmation) {
    return (
      <Dialog>
        <DialogTrigger>
          <span className="sr-only">confirm</span>
          {isTest ? (
            <FlaskConical className="h-4 w-4" />
          ) : (
            <SendIcon className="h-4 w-4" />
          )}
        </DialogTrigger>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Send</DialogTitle>
            <DialogDescription>
              {isTest
                ? "Send test SMS to preview what you will see"
                : "Are you sure you want to send an Alert to everyone?"}
            </DialogDescription>
          </DialogHeader>
          <span className="flex flex-row justify-end gap-4">
            <DialogClose>
              <Button variant="ghost" className="">
                Cancel
              </Button>
            </DialogClose>
            <DialogClose>
              <Button className="" onClick={onPublish} disabled={isLoading}>
                {isTest ? "Send Preview" : "Send"}
              </Button>
            </DialogClose>
          </span>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Button
      variant="ghost"
      className="h-8 w-8 p-0"
      onClick={onPublish}
      disabled={isLoading}
    >
      <span className="sr-only">Publish Alert</span>
      {isTest ? (
        <FlaskConical className="h-4 w-4" />
      ) : (
        <SendIcon className="h-4 w-4" />
      )}
    </Button>
  );
}

function AlertDetails({
  details,
  onChange,
  disabled,
}: {
  disabled?: boolean;
  details: string;
  onChange: (data: string) => void;
}) {
  const [stateDetails, updateDetails] = useState<string>(details || "");

  const onUpdateDetails = (newDetails: string) => {
    updateDetails(newDetails);
    onChange(newDetails);
  };

  return (
    <span className="flex">
      <Textarea
        disabled={disabled}
        className="text-sm w-[14rem] ring-0 border-none focus:ring-0 focus-visible:ring-0"
        onChange={(event) => onUpdateDetails(event.target.value)}
        rows={3}
        value={stateDetails}
      />
    </span>
  );
}

function AmountInput({
  alert,
  updateRow,
}: {
  alert: AnalystAlert;
  updateRow: any;
}) {
  const [amount, setAmount] = useState(alert.amount ? `${alert.amount}` : "0");

  const onChange = (event: any) => {
    setAmount(event.target.value);
    parseFloatNumber((value) => {
      if (!value || isNaN(value) || typeof value !== "number") {
        return;
      }
      return updateRow(alert.id)({ key: "amount", value });
    })(event);
  };

  return (
    <span
      className="flex flex-row items-center"
      key={`Alert:${alert.id}:amount`}
    >
      <Input
        disabled={alert.published}
        value={amount}
        type="number"
        pattern="/^\d{1,2}(?:\.\d{1,2})?$|^\.\d{1,2}$/"
        required
        min={0}
        max={100}
        onChange={onChange}
        className="px-0 w-[3rem] bg-transparent border-none focus-visible:ring-0 focus-visible:border-none focus-visible:ring-offset-0 focus:ring-0 [-moz-appearance:_textfield] [&::-webkit-inner-spin-button]:m-0 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:m-0 [&::-webkit-outer-spin-button]:appearance-none"
      />
      <span className={cn(alert.published ? "opacity-50" : "")}>%</span>
    </span>
  );
}

function PriceInput({
  alert,
  updateRow,
}: {
  alert: AnalystAlert;
  updateRow: any;
}) {
  const [price, setPrice] = useState(alert.price ? `${alert.price}` : "0.00");

  const onPriceChange = (event: any) => {
    setPrice(event.target.value);
    parseFloatNumber((value) => {
      if (!value || isNaN(value) || typeof value !== "number") {
        return;
      }

      return updateRow(alert.id)({ key: "price", value });
    })(event);
  };

  return (
    <span
      className="flex flex-row items-center"
      key={`Alert:${alert.id}:price`}
    >
      <span className={cn(alert.published ? "opacity-50" : "")}>$</span>
      <Input
        disabled={alert.published}
        value={price}
        type="number"
        required
        min="0"
        step="0.01"
        placeholder="0.00"
        onChange={onPriceChange}
        className="px-0 w-[3rem] bg-transparent border-none focus-visible:ring-0 focus-visible:border-none focus-visible:ring-offset-0 focus:ring-0 [-moz-appearance:_textfield] [&::-webkit-inner-spin-button]:m-0 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:m-0 [&::-webkit-outer-spin-button]:appearance-none"
      />
    </span>
  );
}
