import { useQuery, useQueryClient, QueryKey } from "@tanstack/react-query";
import Decimal from "decimal.js";
import { BaseDbObject } from "../models/db";
import { Customer } from "./reports";
import { z } from "zod";
import moment, { Moment } from "moment-timezone";
import {
  fetchApiDelete,
  fetchApiGet,
  fetchApiPost,
  fetchApiPut,
} from "./utils";
import {
  PossibleReturnType,
} from "../responseResults";
import { genFlatOptionList, CommonDestinationTimezone } from "../utils";
import { formatDateWithoutTimezone } from "./date";
import { DbUser } from "../models/index";

export enum ContractSourceType {
  GeneralInput = "general_input",
  SpeechInput = "speech_input",
}

export enum MzVendor {
  LighthouseLogistics = "Lighthouse Logistics",
}

const sort_options = ["asc", "desc"] as const;
export const ContractSortValidation = z.object({
  created_at: z.enum(sort_options).optional(),
  contract_no: z.enum(sort_options).optional(),
  contract_date: z.enum(sort_options).optional(),
  source_type: z.enum(sort_options).optional(),
});

export const ContractFilterValidation = z.object({
  on_created_at: z.coerce.date().optional(),
  contract_no: z.string().optional(),
  contract_date: z.string().date().optional(),
  shipment_start_date: z.string().date().optional(),
  shipment_end_date: z.string().date().optional(),
  contract_type: z.string().optional(),
  commodity: z.string().optional(),
  option_month: z.string().optional(),
  source_type: z.string().optional(),

  location_id: z.string().uuid().optional(),
  customer_id: z.string().uuid().optional(),
  trader_id: z.string().uuid().optional(),
});

export type ContractSortOptions = z.infer<typeof ContractSortValidation>;
export type ContractFilterOptions = z.infer<typeof ContractFilterValidation>;

export const ContractSortFiltersValidations = z.object({
  filters: ContractFilterValidation.optional(),
  sorting: ContractSortValidation.optional(),
})

export type ContractSortFiltersOptions = z.infer<typeof ContractSortFiltersValidations>;

export interface StagedGeneralContractData {
  user_id: string;
  data: {
    location: string;
    market_zone: string;
    commodity: string;
    basis: string;
    futures: string;
    contract_type: string;
    quantity: string;
    quantity_uom: string;
    customer: string;
    option_month: string;
    crop_year?: number;
    ["p/s/o"]: string;
    delivery_start: string;
    delivery_end: string;
  }
}

export enum DeliveryTerms {
  Del = "DEL",
  Fob = "FOB",
}

export enum PurchaseSaleType {
    Purchase = "P",
    Sale = "S",
}

export enum LocationType {
    LhcDakota = "0STCOMA",
    LhcNE = "0STCOMS",
    LhcOhio = "0LHOHIO",
    LhcWis = "0LHCWIS",
    LhcMin = "0LHCMIN",
}

export enum CostCategory {
    Freight = "Freight",
}

export enum CalculateOn {
    Unload = "Unload",
}

export enum ContractType {
}

export enum Uom {
    Bushel = "BU",
    Cwt = "CWT",
    Lbs = "LB",
    MetricTon = "MT",
    Tonne = "TON",
}

export enum Currency {
    Canadian = "CAN$",
    UsDollar = "US$",
}

export enum RateType {
    PerUom = "Per UOM",
    PerVehicle = "Per Vehicle",
}

export enum MzAdjVehicle {
    Truck = "Truck",
    Rail = "Rail",
}

export enum AddDedAdjType {
    FreightSpreadFee = "Freight spread fee",
    OtcFee = "OTC Fee",
    BeaconPricingFee = "Beacon Pricing Fee",
}

function isFuturesRequired({ contractType }: { contractType: string }) {
    if (contractType.startsWith("HTA")) { return true; }
    else if (contractType.startsWith("Flat")) { return true; }
    else if (contractType === "Flat-CAN") { return true; }
    return false;
}

function isBasisRequired({ contractType }: { contractType: string }) {
    if (contractType.startsWith("Basis")) { return true; }
    else if (contractType.startsWith("Flat")) { return true; }
    else if (contractType === "Flat-CAN") { return true; }
    return false;
}

const dakotaContractTypes = [
    "Basis",
    "Basis-CAN",
    "BEACONPRIC",
    "DP",
    "Flat",
    "Flat-CAN",
    "HTA",
    "Spot",
];

const neContractTypes= [
    "Basis-NE",
    "BEACONPNE",
    "DP-NE",
    "Flat-NE",
    "HTA-NE",
    "Spot-NE",
];

const ohioContractTypes= [
    "Basis- OH",
    "Flat-OH",
    "HTA-OH",
    "Spot-OH",
];

const wiContractTypes= [
    "Basis- WI",
    "DP-WI",
    "Flat-WI",
    "HTA-WI",
    "Spot-WI",
];

const miContractTypes= [
    "Basis MN",
    "DP-MN",
    "Flat-MN",
    "HTA-MN",
    "Spot-MN",
];

const allContractTypes = Array.from(new Set([
    ...dakotaContractTypes,
    ...neContractTypes,
    ...ohioContractTypes,
    ...wiContractTypes,
    ...miContractTypes,
]));

export function generateContractTypesWith({ locationLabel }: { locationLabel?: string }) {
    switch (locationLabel) {
        case "LHC Dakota":
            return dakotaContractTypes;
        case "LHC NE":
            return neContractTypes;
        case "LHC OHIO":
            return ohioContractTypes;
        case "LHC WIS":
            return wiContractTypes;
        case "LHC MIN":
            return miContractTypes;
        default:
            return allContractTypes;
    }
}

export interface GeneralContract extends BaseDbObject {
  location_id: string;
  purchase_sale_type: string;

  contract_type: string;
  contract_no?: string;
  contract_date?: Date;

  customer_id: string;
  commodity: string;

  reference?: string;
  quantity: Decimal;

  uom: string;
  price_uom?: string;
  currency?: string;

  futures: Decimal;
  basis: Decimal;

  option_month: string;
  crop_year?: number;

  shipment_start_date?: Date;
  shipment_end_date?: Date;

  source_type?: string;

  market_zone_id?: string;
  mz_adj_id?: string;
  add_ded_adj_id?: string;

  Location?: Location;
  Customer?: Customer;
  MarketZone?: MarketZone;
  MzAdj?: MzAdj;
  AddDedAdj?: AddDedAdj;
}

export interface GeneralContractAudit extends BaseDbObject {
    meta_data: GeneralContractAuditMetaData;

    general_contract_id?: string;
    archived_contract_id?: string;
}

export interface GeneralContractAuditMetaData {
    "new"?: GeneralContract;
    "existing"?: GeneralContract;
}

export interface ArchivedGeneralContract extends BaseDbObject {
    meta_data: ArchivedGeneralContractMetaData;
}

interface ArchivedGeneralContractMetaData {
    contract: GeneralContract;
}

export interface Location extends BaseDbObject {
    external_id: string;

    short_name: string;
    long_name: string;
}

export interface MarketZone extends BaseDbObject {
    market_zone: string;
    dtn_market_zone: string;

    active: boolean;
}

export interface MzAdj extends BaseDbObject {
  cost_category: string;
  accrue: boolean;
  budget: boolean;

  rate_type?: string;
  vehicle?: string;
  amount?: Decimal;
  uom?: string;
  currency?: string;
  vendor?: string;

  standard?: Decimal;
  mz_adj?: Decimal;

  calculate_on?: string;

  include_for_iv_costing: boolean;
  fx_rate?: Decimal;
}

export interface AddDedAdj extends BaseDbObject {
    adjustment: string;
    rate_type:  string;
    amount: Decimal;
    note?: string;
    include_for_iv_costing: boolean;
}

export function useListLocations() {
  const queryParams = new URLSearchParams();

  return useQuery({
    queryKey: ["locations", queryParams.toString()],
    queryFn: () =>
      fetchApiGet<Location[]>("location", queryParams),
    retry: 1,
  });
}

export function useCreateLocation() {
  const queryClient = useQueryClient();

  return async (params: any) => {
    const queryParams = new URLSearchParams();

    const result = await fetchApiPost<Location>(
      `location`,
      params,
      queryParams,
    );
    queryClient.invalidateQueries({ queryKey: ["locations"] });
    return result;
  };
}

export function useUpdateLocation() {
  const queryClient = useQueryClient();

  return async (params: any) => {
    const queryParams = new URLSearchParams();

    const result = await fetchApiPut<Location>(
      `location/${params.id}`,
      params,
      queryParams,
    );
    queryClient.invalidateQueries({ queryKey: ["locations"] });
    return result;
  };
}

export function useListMarketZones() {
  const queryParams = new URLSearchParams();

  return useQuery({
    queryKey: ["marketZones", queryParams.toString()],
    queryFn: () =>
      fetchApiGet<MarketZone[]>("marketZone", queryParams),
    retry: 1,
  });
}

export function useCreateMarketZone() {
  const queryClient = useQueryClient();

  return async (params: any) => {
    const queryParams = new URLSearchParams();

    const result = await fetchApiPost<Location>(
      `marketZone`,
      params,
      queryParams,
    );
    queryClient.invalidateQueries({ queryKey: ["marketZones"] });
    return result;
  };
}

export function useUpdateMarketZone() {
  const queryClient = useQueryClient();

  return async (params: any) => {
    const queryParams = new URLSearchParams();

    const result = await fetchApiPut<Location>(
      `marketZone/${params.id}`,
      params,
      queryParams,
    );
    queryClient.invalidateQueries({ queryKey: ["marketZones"] });
    return result;
  };
}

export function useListGeneralContracts(data?: ContractSortFiltersOptions) {
  const queryParams = new URLSearchParams();
  if (data?.sorting) {
    queryParams.append('sorting', JSON.stringify(data.sorting))
  }
  if (data?.filters) {
    queryParams.append('filters', JSON.stringify(data.filters))
  }

  return useQuery({
    queryKey: ["generalContracts", JSON.stringify(data || {})],
    queryFn: () =>
      fetchApiGet<GeneralContract[]>("contract", queryParams),
    retry: 1,
  });
}

export function useCreateGeneralContract() {
  const queryClient = useQueryClient();

  return async (params: any) => {
    const queryParams = new URLSearchParams();

    const result = await fetchApiPost<GeneralContract>(
      `contract`,
      params,
      queryParams,
    );
    queryClient.invalidateQueries({ queryKey: ["generalContracts"] });
    return result;
  };
}

export function useUpdateGeneralContract() {
  const queryClient = useQueryClient();

  return async (params: any) => {
    const queryParams = new URLSearchParams();

    const result = await fetchApiPut<GeneralContract>(
      `contract/${params.id}`,
      params,
      queryParams,
    );
    queryClient.invalidateQueries({ queryKey: ["generalContracts"] });
    return result;
  };
}

export function useArchiveGeneralContract() {
  const queryClient = useQueryClient();

  return async (params: any) => {
    const queryParams = new URLSearchParams();

    const result = await fetchApiPut<GeneralContract>(
      `contract/${params.id}/archive`,
      params,
      queryParams,
    );
    queryClient.invalidateQueries({ queryKey: ["generalContracts"] });
    return result;
  };
}

export const costCategoryOptions = [
  { value: CostCategory.Freight, label: "Freight" }
];

export const mzVendorOptions = genFlatOptionList(Object.values(MzVendor));

export const rateTypeOptions = [
  { value: RateType.PerUom, label: "Per UOM" },
  { value: RateType.PerVehicle, label: "Per Vehicle" },
];

export const calculateOnOptions = [
  { value: CalculateOn.Unload, label: "Unload" },
];

export const purchaseSaleOptions = [
    { value: PurchaseSaleType.Purchase, label: "Purchase" },
    { value: PurchaseSaleType.Sale, label: "Sale" },
];

export const uomOptions = [
    { value: Uom.Bushel, label: "Bushel" },
    { value: Uom.Cwt, label: "Cwt" },
    { value: Uom.Lbs, label: "LB" },
    { value: Uom.MetricTon, label: "MT" },
    { value: Uom.Tonne, label: "Tonne" },
];

export const currencyOptions = [
    { value: Currency.Canadian, label: "CAN" },
    { value: Currency.UsDollar, label: "USD" },
];

export const addDedADjOptions = [
    { value: AddDedAdjType.FreightSpreadFee, label: "Freight spread fee" },
    { value: AddDedAdjType.OtcFee, label: "OTC Fee" },
    { value: AddDedAdjType.BeaconPricingFee, label: "Beacon Pricing Fee" },
];

export const deliveryTermsOptions = [
    { value: DeliveryTerms.Del, label: DeliveryTerms.Del },
    { value: DeliveryTerms.Fob, label: DeliveryTerms.Fob },
];

export function useDownloadOpenRecentContractData() {
  const queryClient = useQueryClient();

  return async (params: { created_date: string }) => {
    const queryParams = new URLSearchParams();
    queryParams.append("created_date", moment
        .tz(params.created_date, CommonDestinationTimezone)
        .format("YYYY/MM/DD"));

    const result = await fetchApiPost<{
      import_recent_link: string;
    }>(
      `contract/download/recent`,
      {},
      queryParams,
    );
    return result;
  };
}

export function useDownloadOpenImportContractData() {
  const queryClient = useQueryClient();

  return async (params: { created_date: string }) => {
    const queryParams = new URLSearchParams();
    queryParams.append("created_date", moment
        .tz(params.created_date, CommonDestinationTimezone)
        .format("YYYY/MM/DD"));

    const result = await fetchApiPost<{
      import_new_contracts_link: string;
    }>(
      `contract/download/import`,
      {},
      queryParams,
    );
    return result;
  };
}

export function useCreateStagedUpload() {
  const queryClient = useQueryClient();

  return async (body: any) => {
    const queryParams = new URLSearchParams();

    const result = await fetchApiPost<any>(
      `genericContract/speech/upload/presign`,
      body,
      queryParams,
    );

    return result;
  };
}

export function useListGeneralContractSpeecData() {
  const queryParams = new URLSearchParams();

  return useQuery({
    queryKey: ["speechContracts"],
    queryFn: () =>
      fetchApiGet<StagedGeneralContractData>("genericContract/speech", queryParams),
    retry: 1,
    refetchInterval: 3 * 1000
  });
}


export function useCreateGeneralContractFrom() {
  const queryClient = useQueryClient();

  return async (params: any) => {
    const queryParams = new URLSearchParams();

    const result = await fetchApiPost<GeneralContract>(
      `genericContract/from`,
      params,
      queryParams,
    );
    queryClient.invalidateQueries({ queryKey: ["speechContracts"] });
    queryClient.invalidateQueries({ queryKey: ["generalContracts"] });

    return result;
  };
}

export function useDeleteStagedGeneralContract() {
  const queryClient = useQueryClient();

  return async (params: any) => {
    const queryParams = new URLSearchParams();

    const result = await fetchApiDelete<boolean>(
      `genericContract/speech/${params.id}`,
      queryParams,
    );

    queryClient.invalidateQueries({ queryKey: ["generalContracts"] });
    queryClient.invalidateQueries({ queryKey: ["speechContracts"] });

    return result;
  };
}

export function useListSpeechJobs() {
  const queryParams = new URLSearchParams();

  return useQuery({
    queryKey: ["generalContractSpeechJobs", queryParams.toString()],
    queryFn: () =>
      fetchApiGet<Location[]>("genericContract/speech/jobs", queryParams),
    retry: 1,
    refetchInterval: 15 * 1000
  });
}
