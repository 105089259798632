import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  fetchApiDelete,
  fetchApiGet,
  fetchApiPost,
  fetchApiPut,
} from "./utils";

const ENTITY_KEY = "providers";

export function useListProviders({
  isUploadable,
  providerType,
}: {
  providerType?: string;
  isUploadable?: boolean;
}) {
  const queryParams = new URLSearchParams();
  if (providerType) {
    queryParams.append("providerType", providerType);
  }
  if (isUploadable) {
    queryParams.append("isUploadable", "true");
  }

  return useQuery({
    queryKey: [ENTITY_KEY],
    queryFn: () => fetchApiGet("providers", queryParams),
    retry: 1,
  });
}
