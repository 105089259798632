import { useQuery, useQueryClient, QueryKey } from "@tanstack/react-query";
import hashObject from "object-hash";
import { useState, useEffect } from 'react';
import moment, { Moment } from "moment-timezone";
import ExcelJS from "exceljs";
import { z } from "zod";
import Decimal from "decimal.js";
import * as Excel from "../excel";
import { S3ObjectItem } from "../models/trading";
import { BaseDbObject } from "../models/db";
import { DbUser } from "../models/index";
import {
  fetchApiDelete,
  fetchApiGet,
  fetchApiPost,
  fetchApiPut,
} from "./utils";
import {
  PossibleReturnType,
} from "../responseResults";
import { CommonDestinationTimezone } from "../utils";
import { Customer, Trader } from "./reports";
import { Commodities, CommodityOptions } from "./commodity";
import { formatDateWithoutTimezone } from "./date";
import {
  findMatchOption,
  OptionMonthOptions, OlderOptionMonthOptions, formulateOptionMonths, optionLetterToMonthNumber, futureMonths, generateMonthOptionsFor, generateCurrentAndFutureMonthOptions, monthNumberToOptionLetter, generateAccountLabel
} from "./common"

export function useSendNotifications() {
  const queryClient = useQueryClient();

  return async (params: any) => {
    const result = await fetchApiPost<string>(
      `client/notification/issue`,
      params,
      new URLSearchParams(),
    );
    return result;
  };
}

export function useSendTestNotifications() {
  const queryClient = useQueryClient();

  return async (params: any) => {
    const result = await fetchApiPost<string>(
      `client/notification/issue/test`,
      params,
      new URLSearchParams(),
    );
    return result;
  };
}

export enum NotificationType {
    Image = "image",
    SaveTheDate = "save_the_date",
}

export enum NotificationSubType {
}

export const NotificationTypeOptions = [
  { value: NotificationType.Image, label: "Image" },
  // { value: NotificationType.SaveTheDate, label: "Save The Date" },
];

export const NotificationSubTypeOptions = [
];
