import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./select";
import { SelectOptionItem as OptionItem } from "../../lib/models/ui";

export function SelectInput<T extends string>({
  value,
  placeHolder,
  options,
  onValueChange,
  className,
  disabled,
}: {
  className?: string;
  value?: string;
  placeHolder?: string;
  options: OptionItem<T>[];
  onValueChange: (value: string) => void;
  disabled?: boolean;
}) {
  return (
    <Select value={value} onValueChange={onValueChange}>
      <SelectTrigger className={className} disabled={disabled}>
        <SelectValue placeholder="" />
      </SelectTrigger>
      <SelectContent className="max-h-40 max-w-sm">
        {options.map((option) => (
          <SelectItem
            key={option.value}
            value={option.value}
            className="cursor-pointer"
          >
            {option.label}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
}
