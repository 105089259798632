import { SelectOptionItem } from "./ui";
import { BaseApiObject } from "./common";

export enum ProjectRouteName {
  HedgeBeacon = "hedgebeacon",
  MarketThoughts = "market_thoughts",
  CodetiTest = "codeti_test",
  LighthouseTest = "lighthouse_test",
}

// Corn, Minnie Wheat (i.e. Spring Wheat), Canola, and Soybeans
export enum AnalysisCommodity {
  MinnieWheat = "spring_wheat",
  Soybean = "soybean",
  Canola = "canola",
  Corn = "corn",
}

export enum AlertCommodity {}

export enum AnalysisAction {
  Buy = "buy",
  Sell = "sell",
  Neutral = "neutral",
}

export enum AnalysisCropType {
  NC = "nc",
  OC = "oc",
}

export enum AnalystRecommendationType {
  Analysis = "analysis",
  Alert = "alert",
  MarketThoughts = "market_thoughts",
  Commentary = "commentary",
}

export interface AnalystCommentary extends BaseApiObject {
  type: AnalystRecommendationType.Commentary;

  details: string;
}

export interface AnalystAnalysis extends BaseApiObject {
  type: AnalystRecommendationType.Analysis;

  action: string;
  published: boolean;
  date: Date;
  commodity: string;
  details: string;
  crop_type: AnalysisCropType;
}

export interface AnalystAlert extends BaseApiObject {
  type: AnalystRecommendationType.Alert;

  date: Date;
  action: string;
  amount: number;
  commodity: string;

  details?: string;

  published: boolean;
  is_deleted: boolean;
  price: number;
}

export type AnalystRecommendation = AnalystAnalysis | AnalystAlert;

export const AnalysisCommodityOptions: SelectOptionItem<AnalysisCommodity>[] = [
  {
    value: AnalysisCommodity.MinnieWheat,
    label: "Wheat",
  },
  {
    value: AnalysisCommodity.Canola,
    label: "Canola",
  },
  {
    value: AnalysisCommodity.Soybean,
    label: "Soybeans",
  },
  {
    value: AnalysisCommodity.Corn,
    label: "Corn",
  },
];

export const AnalysisSignalOptions: SelectOptionItem<AnalysisAction>[] = [
  {
    value: AnalysisAction.Buy,
    label: "Buy",
  },
  {
    value: AnalysisAction.Neutral,
    label: "Neutral",
  },
  {
    value: AnalysisAction.Sell,
    label: "Sell",
  },
];

export const AnalystRecommendationCropTypeOptions: SelectOptionItem<AnalysisCropType>[] =
  [
    {
      value: AnalysisCropType.NC,
      label: "NC",
    },
    {
      value: AnalysisCropType.OC,
      label: "OC",
    },
  ];

export const AlertCommodities = [
  {
    id: "corn",
    label: "Corn",
    ticker: "C",
    type: "continuous_charts",
    outputName: "CornCont",
  },
  {
    id: "chicago_wheat",
    label: "Chicago Wheat",
    ticker: "W",
    type: "continuous_charts",
    outputName: "ChiWheatCont",
  },
  {
    id: "kc_wheat",
    label: "Kansas City Wheat",
    ticker: "KW",
    type: "continuous_charts",
    outputName: "KCWheatCont",
  },
  {
    id: "msp_wheat",
    label: "Minneapolis Wheat",
    ticker: "MW",
    type: "continuous_charts",
    outputName: "MinnieCont",
  },
  {
    id: "soybeans",
    label: "Soybeans",
    ticker: "S",
    type: "continuous_charts",
    outputName: "SoybeansCont",
  },
  {
    id: "soybean_meal",
    label: "Soybean Meal",
    ticker: "SM",
    type: "continuous_charts",
    outputName: "SoybeansMeal",
  },
  {
    id: "soybean_oil",
    label: "Soybean Oil",
    ticker: "BO",
    type: "continuous_charts",
    outputName: "SoybeansOilCont",
  },
  {
    id: "canola",
    label: "Canola",
    ticker: "RS",
    type: "continuous_charts",
    outputName: "CanolaCont",
  },
  {
    id: "live_cattle",
    label: "Live Cattle",
    ticker: "LE",
    type: "continuous_charts",
    outputName: "LiveCattle",
  },
  {
    id: "feeder_cattle",
    label: "Feeder Cattle",
    ticker: "GF",
    type: "continuous_charts",
    outputName: "FeederCattle",
  },
  {
    id: "lean_hogs",
    label: "Lean Hogs",
    ticker: "HE",
    type: "continuous_charts",
    outputName: "LeanHogs",
  },

  {
    id: "oats",
    label: "Oats",
    ticker: "O",
    type: "continuous_charts",
    outputName: "Oats",
  },
  {
    id: "rice",
    label: "Rice",
    ticker: "RR",
    type: "continuous_charts",
    outputName: "Rice",
  },
  {
    id: "cocoa",
    label: "Cocoa",
    ticker: "CC",
    type: "continuous_charts",
    outputName: "Cocoa",
  },
  {
    id: "coffee",
    label: "Coffee",
    ticker: "KC",
    type: "continuous_charts",
    outputName: "Coffee",
  },
  {
    id: "cotton",
    label: "Cotton",
    ticker: "CT",
    type: "continuous_charts",
    outputName: "Cotton",
  },
  {
    id: "orange_juice",
    label: "Orange Juice",
    ticker: "OJ",
    type: "continuous_charts",
    outputName: "OrangeJuice",
  },
  {
    id: "sugar",
    label: "Sugar",
    ticker: "SB",
    type: "continuous_charts",
    outputName: "Sugar",
  },
  {
    id: "lumber",
    label: "Lumber",
    ticker: "LBR",
    type: "continuous_charts",
    outputName: "Lumber",
  },
  {
    id: "crude_oil",
    label: "Crude Oil",
    ticker: "QCL",
    type: "continuous_charts",
    outputName: "CrudeOil",
  },
  {
    id: "natural_gas",
    label: "Natural Gas",
    ticker: "QNG",
    type: "continuous_charts",
    outputName: "NaturalGas",
  },
  {
    id: "heating_oil",
    label: "Heating Oil",
    ticker: "QHO",
    type: "continuous_charts",
    outputName: "HeatingOil",
  },
  {
    id: "us_dollar",
    label: "US Dollar",
    ticker: "DX",
    type: "continuous_charts",
    outputName: "USDollar",
  },
  {
    id: "canadian_dollar",
    label: "Canadian Dollar",
    ticker: "CD",
    type: "continuous_charts",
    outputName: "CanadianDollar",
  },
  {
    id: "sp_500",
    label: "S&P 500",
    ticker: "ES",
    type: "continuous_charts",
    outputName: "SP500",
  },

  {
    id: "corn_new_crop",
    label: "Corn",
    ticker: "CZ",
    type: "new_crop_charts",
    outputName: "Corn",
  },
  {
    id: "beans_new_crop",
    label: "Soybeans",
    ticker: "SX",
    type: "new_crop_charts",
    outputName: "Soybeans",
  },
  {
    id: "msp_wheat_new_crop",
    label: "Minneapolis Wheat",
    ticker: "MWU",
    type: "new_crop_charts",
    outputName: "Minnie",
  },
  {
    id: "canola_new_crop",
    label: "Canola",
    ticker: "RSX",
    type: "new_crop_charts",
    outputName: "Canola",
  },
  {
    id: "chicago_wheat_new_crop",
    label: "Chicago Wheat",
    ticker: "WN",
    type: "new_crop_charts",
    outputName: "ChiWheat",
  },
  {
    id: "kc_wheat_new_crop",
    label: "Kansas City Wheat",
    ticker: "KWN",
    type: "new_crop_charts",
    outputName: "KCWheat",
  },
];

export interface CalSummaryItem {
  type: AnalystRecommendationType;
  date: string; // YYYY-MM-DD
  published: boolean;
  active: boolean;
}

export interface AnalystCalendarSummary {
  calendarSummary: CalSummaryItem[];
  today: AnalystAnalysis[];
}
