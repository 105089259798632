import { create } from "zustand";
import { useState } from "react";
import moment from "moment-timezone";
import { GenerateLoadingOrError, genFlatOptionList, genIdOptionList, CommonDestinationTimezone } from "../../lib/utils";
import {
    InputRequiredStatus,
    InputFormType,
    InputType,
    ImportType,
    ExportType,
    InputFilter,
    InputRequiredStatusType,
} from "../../lib/form";
import {
    useListMarketZones,
    MarketZone,
    useCreateMarketZone,
    useUpdateMarketZone,
} from "../../lib/api/generalContract";
import { useListCustomers } from "../../lib/api/customer";
import { Customer } from "../../lib/api/reports";
import * as FormHelper from "../../lib/form";
import * as FormLayout from "../../lib/form/layout";
import {
    PrimitiveGridLayout,
    PrimitiveManagedTable,
    PrimitiveDialogForm,
} from "../../lib/form/layout";
import { Button } from "../../components/ui/button";
import { useToast } from "../../components/ui/use-toast";

const BaseFormSchema = {
    id: { hidden: true, input_type: InputType.Uuid, input_type_validation: InputFormType.Uuid, required_status: InputRequiredStatus.Optional },

    market_zone: { label: "Market Zone", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Required },
    dtn_market_zone: { label: "DTN Market Zone", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Required },

    active: { hidden: true, label: "Active", input_type: InputType.Boolean, input_type_validation: InputFormType.Boolean, required_status: InputRequiredStatus.Optional },

    customer_id: { label: "Related Customer", options_reference_id: "customerOptions", input_type: InputType.Uuid, input_type_validation: InputFormType.Uuid, required_status: InputRequiredStatus.Optional, apiDisplayFormat: ({ original }: any) => { return original.Customer?.long_name || "" } },
};

const createState = FormHelper.generateBaseState({
    schemas: [BaseFormSchema],
    baseSchema: BaseFormSchema,
});

const updateState = FormHelper.generateBaseState({
    schemas: [BaseFormSchema],
    baseSchema: BaseFormSchema,
});

function CreateDynamicForm({
    onCloseForm,
    marketZones,
    customers,
}: {
    onCloseForm: any;
    marketZones: MarketZone[]
    customers: Customer[];
}) {
    const inputState: any = createState.useInputsState((state: any) => state);
    const submissionState: any = createState.useSubmissionState((state: any) => state);
    const createApiMarketZone = useCreateMarketZone();
    const { toast } = useToast();

    return FormLayout.RenderFullPrimitiveGridLayout({
        id: "CreateMarketZone",
        activeSchema: BaseFormSchema,
        totalFields: inputState.totalFields,
        formValues: inputState.formValues,
        updateFormValue: inputState.updateFormValue,
        formRelationships: inputState.formRelationships,
        updateFormRelationship: inputState.updateFormRelationship,
        submissionState,
        externalReferences: {
            customerOptions: genIdOptionList(customers, "long_name"),
        },
        onSubmitTo: (item: any) => createApiMarketZone(item),
        onCloseForm,
        handleSubmitResponse: (result) => {
            if (!result.success) {
                toast({
                    title: "Failed to create Market Zone",
                    description: "",
                });
            } else {
                toast({
                    title: "Created",
                    description: "",
                });
                onCloseForm(false);
                inputState.clearForm();
            }
        }
    });
}

function UpdateDynamicForm({
    onCloseForm,
    marketZones,
    customers,
}: {
    onCloseForm: any;
    marketZones: MarketZone[]
  customers: Customer[];
}) {
    const inputState: any = updateState.useInputsState((state: any) => state);
    const submissionState: any = updateState.useSubmissionState((state: any) => state);
    const updateApiMarketZone = useUpdateMarketZone();
    const { toast } = useToast();

    return FormLayout.RenderFullPrimitiveGridLayout({
        id: "UpdateMarketZone",
        activeSchema: BaseFormSchema,
        totalFields: inputState.totalFields,
        formValues: inputState.formValues,
        updateFormValue: inputState.updateFormValue,
        formRelationships: inputState.formRelationships,
        updateFormRelationship: inputState.updateFormRelationship,
        submissionState,
        externalReferences: {
            customerOptions: genIdOptionList(customers, "long_name"),
        },
        onCloseForm,
        onSubmitTo: (item: any) => updateApiMarketZone(item),
        handleSubmitResponse: (result) => {
            if (!result.success) {
                toast({
                    title: "Failed to update Market Zone",
                    description: "",
                });
            } else {
                toast({
                    title: "Updated",
                    description: "",
                });
                onCloseForm(false);
                inputState.clearForm();
            }
        }
    });
}

function CreateFormModal({ marketZones, customers }: { marketZones: MarketZone[]; customers: Customer[]; }) {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <PrimitiveDialogForm isOpen={isOpen} onDialogChange={setIsOpen} buttonLabel={"Create Market Zone"} dialogTitle={"Create Market Zone"} form={<CreateDynamicForm onCloseForm={setIsOpen} marketZones={marketZones} customers={customers} /> }/>
    );
}

function UpdateFormModal({ isOpen, onDialogChange, marketZones, customers }: { isOpen: boolean, onDialogChange: any, marketZones: MarketZone[]; customers: Customer[]; }) {
    return (
        <PrimitiveDialogForm isOpen={isOpen} onDialogChange={onDialogChange} dialogTitle={"Update Market Zone"} form={<UpdateDynamicForm onCloseForm={onDialogChange} marketZones={marketZones} customers={customers} />}/>
    );
}

export function MarketZoneDashboard() {
    const [isUpdateOpen, setIsUpdateOpen] = useState(false);
    const marketZonesRes = useListMarketZones();
    const customersResult = useListCustomers();
    const loadingOrErrorUi = GenerateLoadingOrError([marketZonesRes])
    const updatePopulateWith: any = updateState.useInputsState((state: any) => state.populateWith);
    const updateClearForm: any = updateState.useInputsState((state: any) => state.clearForm);

    if (loadingOrErrorUi) {
         return (
             <section className="flex min-h-full flex-1 flex-col justify-start px-6 py-6 lg:px-8">
                <h1 className="scroll-m-20 text-2xl font-extrabold tracking-tight lg:text-3xl">
                    Manage Market Zones
                </h1>
                {loadingOrErrorUi}
            </section>
        );
    }

    const marketZones = marketZonesRes.data?.data || [];
    const customers = customersResult.data?.data || [];

    return (
        <section className="flex min-h-full flex-1 flex-col justify-start px-6 py-6 lg:px-8">
            <h1 className="scroll-m-20 text-2xl font-extrabold tracking-tight lg:text-3xl">
                Manage Market Zones
            </h1>
            <div className="flex justify-end mb-4">
                <CreateFormModal marketZones={marketZones} customers={customers} />
                <UpdateFormModal
                    marketZones={marketZones}
                    isOpen={isUpdateOpen}
                    customers={customers}
                    onDialogChange={
                      (value: any) => {
                          console.warn("on dialog change", value)
                        if (!value) {
                            setIsUpdateOpen(false);
                            updateClearForm();
                        }
                      }
                    }
                />
            </div>
            <PrimitiveManagedTable schema={BaseFormSchema} values={marketZones} onRowClick={(item) => {
                setIsUpdateOpen(true);
                updatePopulateWith(item);
            }} />
        </section>
    );
}
