import { useStytchMember } from "@stytch/react/b2b";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useState } from "react";
import {
  RotateCcwIcon,
  DownloadCloud,
  Trash2,
  Plus,
  MoreHorizontal,
} from "lucide-react";
import moment from "moment-timezone";
import { Link } from "react-router-dom";
import { Textarea } from "../../components/ui/textarea";
import { useListProviders } from "../../lib/api/providers";
import {
  useDownloadTicketBatch,
  useTicketProviderSummary,
  useDownloadUndownloadedTickets,
} from "../../lib/api/tickets";
import {
  TicketRunSummary,
  TicketsSummary,
  ProviderTicketCount,
  Provider,
} from "../../lib/models/index";
import { ProviderType } from "../../lib/models/provider";
import {
  TicketProviderSummary,
  TicketProviderRecentRunPartial,
  TicketProviderBatchDownloadPartial,
} from "../../lib/models/tickets";
import { Button } from "../../components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../components/ui/dropdown-menu";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../components/ui/tabs";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/ui/table";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../components/ui/accordion";
import { Badge } from "../../components/ui/badge";
import { Checkbox } from "../../components/ui/checkbox";
import { SelectInput } from "../../components/ui/SelectInput";
import { Skeleton } from "../../components/ui/skeleton";
import { useToast } from "../../components/ui/use-toast";
import { DataTable, DataTableProps } from "../../components/ui/dataTable";
import { downloadCsvTextAsFile } from "../../lib/browser/download";
import { cn } from "../../lib/utils";

const date = new Date();


export function TradingTicketDashboard({
  includeRecentDownloads,
  label,
  filterTo,
}: {
  includeRecentDownloads?: boolean;
  label?: string
  filterTo?: string[]
}) {
  const summaryRes = useTicketProviderSummary(ProviderType.Trading);

  if ([summaryRes].some((result) => result.isLoading)) {
    return (
      <div className="flex flex-col space-y-2">
        <Skeleton className="h-4 w-[250px]" />
        <Skeleton className="h-4 w-[200px]" />
      </div>
    );
  }

  if ([summaryRes].some((result) => result.error || !result?.data?.success)) {
    return (
      <div className="flex flex-col space-y-2">
        Failed to fetch ticket info. Please refresh and try again
      </div>
    );
  }

  return (<TicketDashboardView filterTo={filterTo} label={label == null ? "Trading Tickets Dashboard" : label} summaries={summaryRes?.data?.data || []} includeRecentDownloads={includeRecentDownloads == null ? true : includeRecentDownloads} />);
}

function LastSummaryTicketRun({
  runs,
}: {
  runs: TicketProviderRecentRunPartial[];
}) {
  if (!runs.length) {
    return <span>Never Run</span>;
  }

  const run = runs[0];
  return <span>{moment(run.fetched).format("M/D/YY HH:mm a")}</span>;
}

function LastSummaryBatchDownloaded({
  batches,
}: {
  batches: TicketProviderBatchDownloadPartial[];
}) {
  const { member } = useStytchMember();

  if (!batches.length) {
    return <span>Never Downloaded</span>;
  }
  const isAdmin = member?.trusted_metadata["role"] === "admin" || false;

  const batch = batches[0];
  return (
    <span>
      {isAdmin && <>{batch.user.name} on </>}
      {moment(batch.downloaded_date).format("M/D/YY HH:mm a")}
    </span>
  );
}

export function TicketDashboardView({
  summaries,
  includeRecentDownloads,
  label,
  filterTo,
}: {
  filterTo?: string[];
  label?: string;
  summaries: TicketProviderSummary[];
  includeRecentDownloads: boolean;
}) {
  const dashboardColumns: ColumnDef<any>[] = [
    {
      id: "provider",
      header: "Provider",
      cell: ({ row }) => <span className="">{row.original.provider.name}</span>,
      enableSorting: false,
      enableHiding: false,
    },
    {
      accessorKey: "lastUpdated",
      header: "Last Updated",
      cell: ({ row }) => (
        <LastSummaryTicketRun runs={row.original.recentRuns} />
      ),
      enableSorting: false,
      enableHiding: false,
    },
    {
      accessorKey: "netNewCount",
      header: "Net New",
      cell: ({ row }) => {
        return <span className="">{row.original.netNewCount}</span>;
      },
      enableSorting: false,
      enableHiding: false,
    },
    {
      accessorKey: "updatedCount",
      header: "Updated",
      cell: ({ row }) => {
        return <span className="">{row.original.updatedCount}</span>;
      },
      enableSorting: false,
      enableHiding: false,
    },
    {
      accessorKey: "downloaded",
      header: "Last Downloaded",
      cell: ({ row }) => (
        <LastSummaryBatchDownloaded
          batches={row.original.recentBatchDownloads}
        />
      ),
      enableSorting: false,
      enableHiding: false,
    },
    {
      id: "newActions",
      header: "Download New",
      cell: ({ row }) => (
        <div className="flex flex-row">
          <DownloadProviderTickets
            provider={row.original.provider}
            downloadType="new"
          />
        </div>
      ),
    },
    {
      id: "updatedActions",
      header: "Download Updated",
      cell: ({ row }) => (
        <div className="flex flex-row">
          <DownloadProviderTickets
            provider={row.original.provider}
            downloadType="updated"
          />
        </div>
      ),
    },
  ];



  return (
    <>
      <div className={cn(" flex flex-col mb-2", includeRecentDownloads ? "container" : "")}>
        <h1 className="scroll-m-20 text-2xl font-extrabold tracking-tight lg:text-3xl">
          {label}
        </h1>
      </div>
      <div className="flex items-start flex-col h-full">
        <div className={cn("flex flex-col ", includeRecentDownloads ? "container" : "")}>
          <DataTable columns={dashboardColumns} data={summaries.filter(summary => {
    if (!filterTo?.length) return true;
    return filterTo.includes(summary.provider.name);
  })} />
          { includeRecentDownloads && (
              <RecentDownloadsExpanded summaries={summaries} />
          ) }
        </div>
      </div>
    </>
  );
}

function RecentDownloadsExpanded({
  summaries,
}: {
  summaries: TicketProviderSummary[];
}) {
  if (
    !summaries.length ||
    !summaries.some(
      (summary: TicketProviderSummary) => !!summary.recentBatchDownloads.length,
    )
  ) {
    return null;
  }

  return (
    <>
      <h2 className="mt-4 scroll-m-20 text-lg font-bold tracking-tight lg:text-2xl">
        Recent Downloads
      </h2>
      <Accordion type="single" collapsible className="w-full">
        {summaries
          .filter((summary) => {
            return !!summary.recentBatchDownloads.length;
          })
          .map((summary) => (
            <ProviderDownloadsSummary summary={summary} />
          ))}
      </Accordion>
    </>
  );
}

function ProviderDownloadsSummary({
  summary,
}: {
  summary: TicketProviderSummary;
}) {
  const dashboardColumns: ColumnDef<any>[] = [
    {
      accessorKey: "downloaded",
      header: "Downloaded",
      cell: ({ row }) => (
        <LastSummaryBatchDownloaded batches={[row.original]} />
      ),
      enableSorting: false,
      enableHiding: false,
    },
    {
      accessorKey: "newNew",
      header: "Ticket Count",
      cell: ({ row }) => {
        return <span className="">{row.original.ticketCount}</span>;
      },
      enableSorting: false,
      enableHiding: false,
    },
    {
      id: "actions",
      header: "Download",
      cell: ({ row }) => (
        <span className="flex flex-row">
          <DownloadExistingBatchTickets
            provider={summary.provider as any}
            batchId={row.original.id}
          />
        </span>
      ),
    },
  ];

  return (
    <AccordionItem
      key={`ProviderDownloadSummary:${summary.provider.id}`}
      value={summary.provider.name}
    >
      <AccordionTrigger>{summary.provider.name}</AccordionTrigger>
      <AccordionContent>
        <DataTable
          columns={dashboardColumns}
          data={summary.recentBatchDownloads}
        />
      </AccordionContent>
    </AccordionItem>
  );
}

function DownloadExistingBatchTickets({
  provider,
  batchId,
}: {
  provider: Provider;
  batchId: string;
}) {
  const { toast } = useToast();
  const [isDownloading, setIsDownloading] = useState(false);
  const downloadProviderTickets = useDownloadTicketBatch(
    provider.id,
    batchId,
    ProviderType.Trading,
  );

  const onDownload = async (providerId: string) => {
    if (isDownloading) return;
    setIsDownloading(true);

    const result = await downloadProviderTickets(provider.id);
    setIsDownloading(false);

    if (!result.success) {
      toast({
        title: "Failed to download tickets",
        description: "",
      });
    } else {
      const fetchDate = moment(result.data.downloaded_date).format("MM/DD/YY");
      toast({
        title: "Downloaded",
        description: "",
      });
      downloadCsvTextAsFile(
        `${provider.name}_${fetchDate}.csv`,
        result.data.ticketsToDownload,
      );
    }
  };

  return (
    <Button
      variant="ghost"
      className="h-8 w-8 p-0"
      onClick={() => onDownload(provider.id)}
      disabled={isDownloading}
    >
      <span className="sr-only">Download</span>
      <DownloadCloud className="h-4 w-4" />
    </Button>
  );
}

function DownloadProviderTickets({
  provider,
  downloadType,
}: {
  provider: Provider;
  downloadType: "new" | "updated";
}) {
  const { toast } = useToast();
  const [isDownloading, setIsDownloading] = useState(false);
  const downloadProviderTickets = useDownloadUndownloadedTickets(
    ProviderType.Trading,
    downloadType,
  );

  const onDownload = async (providerId: string, filename: string) => {
    if (isDownloading) return;
    setIsDownloading(true);

    const result = await downloadProviderTickets([providerId]);
    setIsDownloading(false);
    if (!result.success) {
      toast({
        title: "Failed to download tickets",
        description: "",
      });
    } else {
      toast({
        title: "Downloaded",
        description: "",
      });
      downloadCsvTextAsFile(filename, result.data.ticketsToDownload);
    }
  };

  const fetchDate = moment().format("MM/DD/YY");

  return (
    <Button
      variant="ghost"
      className="h-8 w-8 p-0"
      onClick={() =>
        onDownload(
          provider.id,
          `${provider.name}_${downloadType}_${fetchDate}.csv`,
        )
      }
      disabled={isDownloading}
    >
      <span className="sr-only">Download</span>
      <DownloadCloud className="h-4 w-4" />
    </Button>
  );
}
