import { create } from "zustand";
import { useState } from "react";
import moment from "moment-timezone";
import { GenerateLoadingOrError, genFlatOptionList, genIdOptionList, CommonDestinationTimezone } from "../../lib/utils";
import {
    InputRequiredStatus,
    InputFormType,
    InputType,
    ImportType,
    ExportType,
    InputFilter,
    InputRequiredStatusType,
} from "../../lib/form";
import {
    useListLocations,
    Location,
    useCreateLocation,
    useUpdateLocation,
} from "../../lib/api/generalContract";
import * as FormHelper from "../../lib/form";
import * as FormLayout from "../../lib/form/layout";
import {
    PrimitiveGridLayout,
    PrimitiveManagedTable,
    PrimitiveDialogForm,
} from "../../lib/form/layout";
import { Button } from "../../components/ui/button";
import { useToast } from "../../components/ui/use-toast";

const BaseFormSchema = {
    id: { hidden: true, input_type: InputType.Uuid, input_type_validation: InputFormType.Uuid, required_status: InputRequiredStatus.Optional },

    external_id: { label: "External ID", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Required },
    short_name: { label: "Short Name", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Required },
    long_name: { label: "Long Name", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Required },
};

const createState = FormHelper.generateBaseState({
    schemas: [BaseFormSchema],
    baseSchema: BaseFormSchema,
});

const updateState = FormHelper.generateBaseState({
    schemas: [BaseFormSchema],
    baseSchema: BaseFormSchema,
});

function CreateDynamicForm({ onCloseForm, locations }: { onCloseForm: any; locations: Location[] }) {
    const inputState: any = createState.useInputsState((state: any) => state);
    const submissionState: any = createState.useSubmissionState((state: any) => state);
    const createApiLocation = useCreateLocation();
    const { toast } = useToast();

    return FormLayout.RenderFullPrimitiveGridLayout({
        id: "CreateLocation",
        activeSchema: BaseFormSchema,
        totalFields: inputState.totalFields,
        formValues: inputState.formValues,
        updateFormValue: inputState.updateFormValue,
        formRelationships: inputState.formRelationships,
        updateFormRelationship: inputState.updateFormRelationship,
        submissionState,
        externalReferences: {},
        onSubmitTo: (item: any) => createApiLocation(item),
        handleSubmitResponse: (result) => {
            if (!result.success) {
                toast({
                    title: "Failed to create Location",
                    description: "",
                });
            } else {
                toast({
                    title: "Created",
                    description: "",
                });
                onCloseForm(false);
                inputState.clearForm();
            }
        }
    });
}

function UpdateDynamicForm({ onCloseForm, locations }: { onCloseForm: any; locations: Location[] }) {
    const inputState: any = updateState.useInputsState((state: any) => state);
    const submissionState: any = updateState.useSubmissionState((state: any) => state);
    const updateApiLocation = useUpdateLocation();
    const { toast } = useToast();

    return FormLayout.RenderFullPrimitiveGridLayout({
        id: "UpdateLocation",
        activeSchema: BaseFormSchema,
        totalFields: inputState.totalFields,
        formValues: inputState.formValues,
        updateFormValue: inputState.updateFormValue,
        formRelationships: inputState.formRelationships,
        updateFormRelationship: inputState.updateFormRelationship,
        submissionState,
        externalReferences: {},
        onSubmitTo: (item: any) => updateApiLocation(item),
        handleSubmitResponse: (result) => {
            if (!result.success) {
                toast({
                    title: "Failed to update Location",
                    description: "",
                });
            } else {
                toast({
                    title: "Updated",
                    description: "",
                });
                onCloseForm(false);
                inputState.clearForm();
            }
        }
    });
}

function CreateFormModal({ locations }: { locations: Location[] }) {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <PrimitiveDialogForm isOpen={isOpen} onDialogChange={setIsOpen} buttonLabel={"Create Location"} dialogTitle={"Create Location"} form={<CreateDynamicForm onCloseForm={setIsOpen} locations={locations} />}/>
    );
}

function UpdateFormModal({ isOpen, onDialogChange, locations }: { isOpen: boolean, onDialogChange: any, locations: Location[] }) {
    return (
        <PrimitiveDialogForm isOpen={isOpen} onDialogChange={onDialogChange} dialogTitle={"Update Location"} form={<UpdateDynamicForm onCloseForm={onDialogChange}  locations={locations} />}/>
    );
}

export function LocationDashboard() {
    const [isUpdateOpen, setIsUpdateOpen] = useState(false);
    const locationRes = useListLocations();
    const loadingOrErrorUi = GenerateLoadingOrError([locationRes])
    const updatePopulateWith: any = updateState.useInputsState((state: any) => state.populateWith);
    const updateClearForm: any = updateState.useInputsState((state: any) => state.clearForm);

    if (loadingOrErrorUi) {
         return (
             <section className="flex min-h-full flex-1 flex-col justify-start px-6 py-6 lg:px-8">
                <h1 className="scroll-m-20 text-2xl font-extrabold tracking-tight lg:text-3xl">
                    Manage Locations
                </h1>
                {loadingOrErrorUi}
            </section>
        );
    }

    const locations = locationRes.data?.data || [];

    return (
        <section className="flex min-h-full flex-1 flex-col justify-start px-6 py-6 lg:px-8">
            <h1 className="scroll-m-20 text-2xl font-extrabold tracking-tight lg:text-3xl">
                Manage Locations
            </h1>
            <div className="flex justify-end mb-4">
                <CreateFormModal locations={locations} />
                <UpdateFormModal
                    locations={locations}
                    isOpen={isUpdateOpen}
                    onDialogChange={
                      (value: any) => {
                        if (!value) {
                            setIsUpdateOpen(false);
                            updateClearForm();
                        }
                      }
                    }
                />
            </div>
            <PrimitiveManagedTable schema={BaseFormSchema} values={locations} onRowClick={(item) => {
                setIsUpdateOpen(true);
                updatePopulateWith(item);
            }} />
        </section>
    );
}
