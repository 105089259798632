import ExcelJS from "exceljs";
import moment from "moment-timezone";
import { CommonDestinationTimezone } from "../api/trading";
import { downloadBinaryData } from "../browser/download";
import { parseFromBrowserFile, CellFormat } from "../excel";
import {
  createArrayOfSize,
  createEmptyRow,
  createHeaderRows,
  createOutputLine,
  createPurchaseOutputLine,
  createSalesOutputLine,
  PURCHASE_LENGTH,
  SALES_LENGTH,
} from "./frieghtReportFunctions";
import {
  Row,
  addMissingCommoditiesToSheetMap,
  padArrayWithNulls,
  parseFromString,
  sheetMap,
  unique,
} from "./frieghtReportUtils";

interface OutputPartialRow {
  sum: number;
  rows: string[][];
  balancesIndex?: number;
  wroteBalances: boolean;
}

export async function processFromFiles({
  purchaseReportFile,
  salesReportFile,
}: {
  purchaseReportFile?: File;
  salesReportFile?: File;
}) {
  const allPurchases: Row[] = purchaseReportFile
    ? parseFromString((await parseFromBrowserFile(purchaseReportFile)).data)
    : [];
  const allSales: Row[] = salesReportFile
    ? parseFromString((await parseFromBrowserFile(salesReportFile)).data)
    : [];

  return processFreightCSVs(allPurchases, allSales);
}

function processFreightCSVs(allPurchases: Row[], allSales: Row[]) {
  const allCommodities = unique(
    ...allPurchases.map((x) => x["Commodity"]),
    ...allSales.map((x) => x["Commodity"]),
  );
  const allMarketZones = unique(
    ...allPurchases.map((x) => x["Market Zone"]),
    ...allSales.map((x) => x["Market Zone"]),
  );

  addMissingCommoditiesToSheetMap(allCommodities);

  const testWorkbook = new ExcelJS.Workbook();

  for (let sheetName of Object.keys(sheetMap)) {
    const sheetSpec = sheetMap[sheetName];

    let purchasesInCommodity = allPurchases.filter((x) =>
      sheetSpec.commodities.includes(x["Commodity"]),
    );
    let salesInCommodity = allSales.filter((x) =>
      sheetSpec.commodities.includes(x["Commodity"]),
    );

    let relevantMarketZones = allMarketZones.filter(
      (marketZone: string) =>
        [
          ...purchasesInCommodity.map((x) => x["Market Zone"]),
          ...salesInCommodity.map((x) => x["Market Zone"]),
        ].includes(marketZone) && // marketZone has entries in this commodity
        (!sheetSpec.excludeMarketZones ||
          !sheetSpec.excludeMarketZones.includes(marketZone)) && // marketZone is not excluded
        (!sheetSpec.includeMarketZones ||
          sheetSpec.includeMarketZones.includes(marketZone)), // marketZone is whitelisted (if applicable)
    );

    const CombinedCornMarketZones = ["SE MB", "NE ND", "NW ND"];
    if (sheetName === "Corn") {
      relevantMarketZones = relevantMarketZones.filter(
        (item) => !CombinedCornMarketZones.includes(item),
      );
    } else if (sheetName === "Canadian Corn") {
      relevantMarketZones = relevantMarketZones.filter(
        (item) => !!CombinedCornMarketZones.includes(item),
      );
    }

    const headers: string[][] = createHeaderRows();

    const salesRowGroups: OutputPartialRow[] = [];
    const purchasesRowGroups: OutputPartialRow[] = [];

    const sheet = testWorkbook.addWorksheet(sheetName);
    sheet.addRow(headers[0]);
    sheet.addRow(headers[1]);

    for (let marketZone of relevantMarketZones) {
      let purchases: (Row | null)[] = purchasesInCommodity
        .filter((x) => x["Market Zone"] === marketZone)
        .filter((x) => !shouldFilterOutRow(x));
      let sales: (Row | null)[] = salesInCommodity
        .filter((x) => x["Market Zone"] === marketZone)
        .filter((x) => !shouldFilterOutRow(x));

      // Make both lists the same length
      const desiredArrayLength = Math.max(purchases.length, sales.length);
      padArrayWithNulls(purchases, desiredArrayLength);
      padArrayWithNulls(sales, desiredArrayLength);

      // Account for rows not matching up in length or having no null rows
      // and needing to insert a new row
      let salesGroup: OutputPartialRow = {
        balancesIndex: undefined,
        sum: 0,
        rows: [],
        wroteBalances: false,
      };
      let purchasesGroup: OutputPartialRow = {
        balancesIndex: undefined,
        sum: 0,
        rows: [],
        wroteBalances: false,
      };

      for (let i = 0; i < sales.length; i++) {
        const {
          row,
          sum: nextSum,
          wroteBalances: didWriteBalances,
        } = parseRow({
          getPreviousRow: () => sales[i - 1]!,
          row: sales[i]!,
          isFirstRow: i === 0,
          lastSum: salesGroup.sum,
          rowLength: SALES_LENGTH,
          wroteBalances: salesGroup.wroteBalances,
          balanceIndex: 6,
          noteIndex: 8,
          uomIndex: 4,
          createRow: createSalesOutputLine,
        });

        if (!salesGroup.wroteBalances && didWriteBalances) {
          salesGroup.balancesIndex = i;
        }

        salesGroup.wroteBalances = didWriteBalances;
        salesGroup.sum += nextSum;
        salesGroup.rows.push(row);
      }

      for (let i = 0; i < purchases.length; i++) {
        const {
          row,
          sum: nextSum,
          wroteBalances: didWriteBalances,
        } = parseRow({
          getPreviousRow: () => purchases[i - 1]!,
          row: purchases[i]!,
          isFirstRow: i === 0,
          lastSum: purchasesGroup.sum,
          rowLength: PURCHASE_LENGTH,
          wroteBalances: purchasesGroup.wroteBalances,
          balanceIndex: 7,
          noteIndex: 10,
          uomIndex: 5,
          createRow: createPurchaseOutputLine,
        });

        if (!purchasesGroup.wroteBalances && didWriteBalances) {
          purchasesGroup.balancesIndex = i;
        }

        purchasesGroup.wroteBalances = didWriteBalances;
        purchasesGroup.sum += nextSum;
        purchasesGroup.rows.push(row);
      }

      sortBy(salesGroup.rows, 1, 3);
      sortBy(purchasesGroup.rows, 1, 4);

      salesRowGroups.push(salesGroup);
      purchasesRowGroups.push(purchasesGroup);
    }

    for (let i = 0; i < salesRowGroups.length; i++) {
      const salesGroup = salesRowGroups[i];
      const purchasesGroup = purchasesRowGroups[i];

      const maxLength = Math.max(
        salesGroup.rows.length,
        purchasesGroup.rows.length,
      );

      let rowStart = sheet.actualRowCount + (maxLength > 0 ? 1 : 0);

      for (let j = 0; j < maxLength; j++) {
        const salesRow =
          j < salesGroup.rows.length
            ? [...salesGroup.rows[j]]
            : createArrayOfSize(SALES_LENGTH);
        const purchasesRow =
          j < purchasesGroup.rows.length
            ? [...purchasesGroup.rows[j]]
            : createArrayOfSize(PURCHASE_LENGTH);

        if (salesGroup.balancesIndex === j && salesGroup.sum > 0) {
          const balance = computeOutputBalance(salesGroup.sum, salesRow[j][4]);
          if (balance > 0) {
            salesRow[6] = `${balance}`;
          }
        }

        if (purchasesGroup.balancesIndex === j && purchasesGroup.sum > 0) {
          const balance = computeOutputBalance(
            purchasesGroup.sum,
            purchasesRow[j][5],
          );
          if (balance > 0) {
            purchasesRow[7] = `${balance}`;
          }
        }

        const row = [...salesRow, "", ...purchasesRow];

        pushSheetRow(sheet, row);
        let lastRow = sheet.lastRow;

        setCellAsNumber(lastRow!, "G");
        setCellAsNumber(lastRow!, "R");
      }

      let rowEnd = sheet.actualRowCount;

      if (!salesGroup.wroteBalances || !purchasesGroup.wroteBalances) {
        const salesPart = createArrayOfSize(SALES_LENGTH);
        const purchasesPart = createArrayOfSize(PURCHASE_LENGTH);

        const row = [...salesPart, "", ...purchasesPart];

        pushSheetRow(sheet, row);

        if (!salesGroup.wroteBalances && salesGroup.sum > 0) {
          salesGroup.balancesIndex = maxLength;
          sheet.getRow(rowEnd + 1).getCell(7).value = {
            formula: `SUM(G${rowStart}:G${rowEnd})`,
            result: computeOutputBalance(salesGroup.sum, salesPart[4]),
          };
          boldCell(sheet.getRow(rowEnd + 1), "G");
        }

        if (!purchasesGroup.wroteBalances && purchasesGroup.sum > 0) {
          purchasesGroup.balancesIndex = maxLength;
          sheet.getRow(rowEnd + 1).getCell(18).value = {
            formula: `SUM(R${rowStart}:R${rowEnd})`,
            result: computeOutputBalance(purchasesGroup.sum, purchasesPart[5]),
          };
          boldCell(sheet.getRow(rowEnd + 1), "R");
        }
      }

      pushSheetRow(sheet, createEmptyRow());
    }

    if (sheet.actualRowCount === 2) {
      testWorkbook.removeWorksheet(sheet.name);
    }

    // let salesBalCol = sheet.getColumn(7);
    // let purchasesBalCol = sheet.getColumn(18);

    sheet.getColumn(7).numFmt = CellFormat.NUMBER;
    sheet.getColumn(18).numFmt = CellFormat.NUMBER;

    sheet.getColumn(7).width = 20;
    sheet.getColumn(18).width = 20;
  }

  return testWorkbook;
}

function pushSheetRow(sheet: ExcelJS.Worksheet, row: string[]) {
  sheet.addRow(row);
  let addedRow = sheet.getRow(sheet.actualRowCount - 1);
  let previousRow =
    sheet.actualRowCount - 2 > 0
      ? sheet.getRow(sheet.actualRowCount - 2)
      : undefined;

  if (
    !!previousRow &&
    !!previousRow?.getCell("G").text &&
    !addedRow.getCell("G").text
  ) {
    boldCell(addedRow, "G");
  }

  if (
    !!previousRow &&
    !!previousRow?.getCell("R").text &&
    !addedRow.getCell("R").text
  ) {
    boldCell(addedRow, "R");
  }
}

function boldCell(row: ExcelJS.Row, column: string) {
  const cell = row.getCell(column);
  if (cell.font) {
    cell.style = JSON.parse(JSON.stringify(cell.style));
    cell.font.bold = true;
  } else {
    cell.style.font = {
      ...JSON.parse(JSON.stringify(cell.style)),
      bold: true,
    };
  }
}

function assembleNote(item?: Row | null): string {
  if (!item) return "";

  let contractRef = item?.["Cont  Ref"]?.trim() || "";
  let releaseRef = item?.["Release Reference"]?.trim() || "";
  return [contractRef, releaseRef].filter((item) => !!item).join(" ");
}

function computeOutputBalance(sum: number, uom: string) {
  if (sum === 0.0) {
    return 0.0;
  }

  if (uom === "MT") {
    return (sum * 2204.62262) / 56;
  }

  // return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(sum).replace("$", "");
  return sum;
}

function shouldFilterOutRow(row?: Row): boolean {
  if (!row) return false;

  if (
    row["Market Zone"] &&
    ["HTA", "OTC", "BeaconPricing"].includes(row?.["Market Zone"].trim()!)
  ) {
    return true;
  }

  if (row.Balance && row.Balance === "0") {
    return true;
  }

  return false;
}

function sortBy(item: any[], nameIndex: number, dateIndex: number) {
  item.sort((a, b) => {
    if (!a[0] && !b[0]) return 0;
    if (!!a[0] && !b[0]) return -1;
    if (!a[0] && !!b[0]) return 1;

    return (
      sortByName(a[nameIndex], b[nameIndex]) ||
      sortByDate(a[dateIndex], b[dateIndex])
    );
  });
}

function sortByName(a: string, b: string) {
  return a.localeCompare(b);
}

function sortByDate(a: string, b: string) {
  const dateA = moment.tz(a, "MM/DD/YYYY", CommonDestinationTimezone);
  const dateB = moment.tz(b, "MM/DD/YYYY", CommonDestinationTimezone);

  if (dateA.isSame(dateB)) return 0;
  return dateA.isAfter(dateB) ? 1 : -1;
}

function parseRow({
  createRow,
  uomIndex,
  noteIndex,
  balanceIndex,
  row,
  lastSum,
  wroteBalances: lastWroteBalances,
  rowLength,
  getPreviousRow,
  isFirstRow,
}: {
  isFirstRow: boolean;
  row: Row;
  lastSum: number;
  wroteBalances: boolean;
  rowLength: number;
  getPreviousRow: () => Row;
  balanceIndex: number;
  noteIndex: number;
  createRow: (row: Row | null) => string[];
  uomIndex: number;
}) {
  let wroteBalances = lastWroteBalances;
  let skipCurrentRow = false;
  let sum = 0;

  if (
    !!row?.["Market Zone"] &&
    ["HTA", "OTC"].includes(row?.["Market Zone"].trim()!)
  ) {
    skipCurrentRow = true;
  }

  if (!!row?.["Balance"]?.trim() && !skipCurrentRow) {
    sum += parseFloat(row?.["Balance"]?.replace(/,/gim, "")! || "0");
  }

  if (row?.Balance === "0") {
    skipCurrentRow = true;
  }

  let ouputRow = createRow(row);

  if (
    !wroteBalances &&
    isFirstRow &&
    !row &&
    !!getPreviousRow()?.["Balance"]?.trim()
  ) {
    wroteBalances = true;
    ouputRow = createArrayOfSize(rowLength);

    const balance = computeOutputBalance(sum, ouputRow[uomIndex]);
    if (balance > 0) {
      ouputRow[balanceIndex] = `${balance}`;
    }
  }

  if (wroteBalances || !skipCurrentRow) {
    ouputRow[noteIndex] = assembleNote(row);
    if (!wroteBalances) {
      const balance = computeOutputBalance(
        parseFloat(row?.["Balance"]?.replace(/,/gim, "")! || "0"),
        ouputRow[uomIndex],
      );
      if (balance > 0) {
        ouputRow[balanceIndex] = `${balance}`;
      }
    }

    return {
      wroteBalances,
      sum,
      row: ouputRow,
    };
  }

  return {
    wroteBalances,
    sum,
    row: createArrayOfSize(rowLength),
  };
}

function setCellAsNumber(row: ExcelJS.Row, cell: string) {
  const cellText = row.getCell(cell).text?.trim() || "";

  if (/[\d,\.\$].*/gim.test(cellText)) {
    row!.getCell(cell).value = parseFloat(cellText.replaceAll(/,/gim, "")!);
  }
}
