import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import moment from "moment-timezone";

import { parseExcelDateToTimezone } from "./excel"
import { Skeleton } from "../components/ui/skeleton";

export const CommonDestinationTimezone = "America/Chicago";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const handleFocus = (event: any) => event.target.select();

export function genIdOptionList(item: any[], labelCol: string): { value: string; label: string }[] {
    return item.map(item => ({ value: item.id, label: item[labelCol] }));
}

export function genFlatOptionList(item: any[]): { value: string; label: string }[] {
    return item.map(item => ({ value: item, label: item }));
}

export function GenerateLoadingOrError(list: any[]) {

    if (
        list.some((result) => result.isLoading)
    ) {
        return (
            <div className="flex flex-col space-y-2">
                <Skeleton className="h-4 w-[250px]" />
                <Skeleton className="h-4 w-[200px]" />
            </div>
        );
  }

  if (
      list.some(
          (result) => result.error || !result?.data?.success,
      )
  ) {
    return (
      <div className="flex flex-col space-y-2">
        Failed to communicate with servers. Please refresh and try again
      </div>
    );
  }
}

export function createDateLabel(field?: string | Date, parseFormat?: string) {
  if (parseFormat && typeof field === "string") {
     return field
         ? moment
           .tz(field, parseFormat, CommonDestinationTimezone)
           .format("MM/DD/YYYY") : "";
  }

    return field
         ? moment
           .tz(field, CommonDestinationTimezone)
           .format("MM/DD/YYYY") : "";
}

export function createUtcDateLabel(field?: string | Date) {
  if (!field) return "";
  if (typeof field === "string") {
    return parseExcelDateToTimezone(new Date(field), CommonDestinationTimezone).format("MM/DD/YYYY");
  }

  return parseExcelDateToTimezone(field, CommonDestinationTimezone).format("MM/DD/YYYY");
}
