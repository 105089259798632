import React, { useEffect } from "react";
import { useStytchB2BClient } from "@stytch/react/b2b";

export const RefreshSession = () => {
  const stytch = useStytchB2BClient();

  useEffect(() => {
    const refresh = () => {
      if (stytch.session.getSync()) {
        stytch.session.authenticate({
          session_duration_minutes: 60,
        });
      }
    };

    // Refresh session every 50 minutes
    let interval = setInterval(refresh, 3000000);
    return () => clearInterval(interval);
  }, [stytch]);

  return <></>;
};
