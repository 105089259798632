import { create } from "zustand";
import { Member } from "@stytch/vanilla-js/b2b";
import moment from "moment-timezone";
import { useStytchMember, useStytchMemberSession } from "@stytch/react/b2b";
import { useEffect, useState } from "react";
import { useToast } from "../../../components/ui/use-toast";
import { Input } from "../../../components/ui/input";
import { Label } from "../../../components/ui/label";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../components/ui/form";
import { Skeleton } from "../../../components/ui/skeleton";
import { Loader } from "../../../components/ui/loader";
import { SelectInput } from "../../../components/ui/SelectInput";
import { Button } from "../../../components/ui/button";
import { Separator } from "../../../components/ui/separator";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableFooter,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../components/ui/table";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../../components/ui/card";
import {
  useGetAtsCurrentState,
  useSubmitAtsCommand,
  useAtsOptions,
  AtsCommand,
  AtsCommandActionType,
  AtsPositionType,
  AtsCurrentPostion,
  AtsPosition,
  AtsPositionOco,
  AtsAppEnvironmentType,
  AtsInstrument,
  AtsSession,
  SimplePriceBar,
  CommonDestinationTimezone,
  AppOrderType,
  AtsOrderEventType,
  AtsOrderPurchaseType,
} from "../../../lib/api/trading";
import { cn } from "../../../lib/utils";
import {
  PossiblePackage,
  doesUserHavePackages,
} from "../../../lib/models/auth";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../../components/ui/accordion";

interface BearState {
  currentPositionsState: {
    isInitialFetch: boolean;
    isFetching: boolean;
    data: AtsPosition[];
  };
  refreshPosition: () => Promise<boolean>;
  commandAppEnvironment: string;
  commandSessionId?: string;
  commandInstrumentId: string;
  commandConfigId?: string;
  selectedCommandType: AtsCommandActionType;
  backoffs: {
    buyBackoff: number;
    sellBackoff: number;
    combinedBackoff: number;
  };
  blockHour: {
    note: string;
    absolute_start: string;
    absolute_end: string;
  };
  enterPosition: {
    purchase_type: AtsOrderPurchaseType;
    original_price: number;
  };
  updateAppEnvironment: (environmentType: AtsAppEnvironmentType) => void;
  updateCommandType: (commandType: AtsCommandActionType) => void;
  updateBuyBackoff: (backoff: number) => void;
  updateSellBackoff: (backoff: number) => void;
  updateBackoffs: (backoff: number) => void;
  updateBlockHour: (key: keyof BearState["blockHour"], value: string) => void;
  updateInstrument: (value: string) => void;
  updateEnterPosition: (
    key: keyof BearState["enterPosition"],
    value: any,
  ) => void;
}

const RequiresInstrument = [
  AtsCommandActionType.ExitPosition,
  AtsCommandActionType.EnterPosition,
  AtsCommandActionType.IssueBackoff,
  AtsCommandActionType.IssueBuyBackoff,
  AtsCommandActionType.IssueSellBackoff,
  AtsCommandActionType.TopUpCurrentPosition,
  AtsCommandActionType.StartupLoadConfig,
  AtsCommandActionType.LoadSession,
  AtsCommandActionType.RestorePositionSnapshot,
];

const useBearStore = create<BearState>((set) => ({
  currentPositionsState: {
    isInitialFetch: true,
    isFetching: true,
    data: [],
  },
  commandAppEnvironment: AtsAppEnvironmentType.Simulation,
  commandSessionId: undefined,
  commandInstrumentId: "",
  commandConfigId: undefined,
  selectedCommandType: AtsCommandActionType.ExitPosition,
  backoffs: {
    buyBackoff: 0,
    sellBackoff: 0,
    combinedBackoff: 0,
  },
  blockHour: {
    note: "",
    absolute_start: moment
      .tz(CommonDestinationTimezone)
      .startOf("day")
      .hour(17)
      .format("YYYY-MM-DDTHH:mm:ss"),
    absolute_end: moment
      .tz(CommonDestinationTimezone)
      .startOf("day")
      .add(1, "day")
      .hour(17)
      .format("YYYY-MM-DDTHH:mm:ss"),
  },
  enterPosition: {
    purchase_type: AtsOrderPurchaseType.Buy,
    original_price: 4000.0,
  },
  refreshPosition: async () => true,
  updateAppEnvironment: (environmentType: AtsAppEnvironmentType) =>
    set((state) => ({ commandAppEnvironment: environmentType })),
  updateCommandType: (commandType: AtsCommandActionType) =>
    set((state) => ({ selectedCommandType: commandType })),
  updateBuyBackoff: (backoff: number) =>
    set((state) => ({ backoffs: { ...state.backoffs, buyBackoff: backoff } })),
  updateSellBackoff: (backoff: number) =>
    set((state) => ({ backoffs: { ...state.backoffs, sellBackoff: backoff } })),
  updateBackoffs: (backoff: number) =>
    set((state) => ({
      backoffs: { ...state.backoffs, combinedBackoff: backoff },
    })),
  updateBlockHour: (key: keyof BearState["blockHour"], value: string) =>
    set((state) => ({ blockHour: { ...state.blockHour, [key]: value } })),
  updateInstrument: (value: string) =>
    set((state) => ({ commandInstrumentId: value })),
  updateEnterPosition: (key: keyof BearState["enterPosition"], value: any) =>
    set((state) => ({
      enterPosition: { ...state.enterPosition, [key]: value },
    })),
}));

const atsAppEnvironments = [
  {
    value: AtsAppEnvironmentType.Live,
    label: "Prod",
  },
  {
    value: AtsAppEnvironmentType.Simulation,
    label: "Staging",
  },
];

function generatePurchaseTypes() {
  return [
    {
      value: AtsOrderPurchaseType.Buy,
      label: "Buy",
    },
    {
      value: AtsOrderPurchaseType.Sell,
      label: "Sell",
    },
  ];
}

function generateCommandTypes(member?: Member | null) {
  if (!member) return [];
  const atsCommandTypes = [
    {
      value: AtsCommandActionType.ExitPosition,
      label: "Exit Position",
    },
    {
      value: AtsCommandActionType.EnterPosition,
      label: "Enter Position",
    },
    {
      value: AtsCommandActionType.TopUpCurrentPosition,
      label: "Top Up Current Position",
    },
    {
      value: AtsCommandActionType.IssueBackoff,
      label: "Issue Backoff",
    },
    {
      value: AtsCommandActionType.IssueBuyBackoff,
      label: "Issue Buy Backoff",
    },
    {
      value: AtsCommandActionType.IssueSellBackoff,
      label: "Issue Sell Backoff",
    },
  ];

  if (
    doesUserHavePackages({
      requiredPackages: [PossiblePackage.Developer],
      member,
    })
  ) {
    atsCommandTypes.push(
      ...[
        {
          value: AtsCommandActionType.AddBlockHour,
          label: "New Holiday Hour",
        },
        {
          value: AtsCommandActionType.RestorePositionSnapshot,
          label: "Restore Position",
        },
        {
          value: AtsCommandActionType.StartupLoadConfig,
          label: "New Session",
        },
        {
          value: AtsCommandActionType.LoadSession,
          label: "Load Session",
        },
      ],
    );
  }
  return atsCommandTypes;
}

export const AtsLivePage = () => {
  return (
    <section className="flex min-h-full flex-1 flex-col justify-start px-6 py-6 gap-4 lg:px-8 sm:grid sm:grid-cols-[5fr_2fr]">
      <AtsCurrentPositionSection />
      <AtsCommandSection />
    </section>
  );
};

export default AtsLivePage;

function convertToMetaData(config: BearState) {
  switch (config.selectedCommandType) {
    case AtsCommandActionType.AddBlockHour:
      return config.blockHour;
    case AtsCommandActionType.IssueBackoff:
      return { backoff_amount: config.backoffs.combinedBackoff };
    case AtsCommandActionType.IssueBuyBackoff:
      return { backoff_amount: config.backoffs.buyBackoff };
    case AtsCommandActionType.IssueSellBackoff:
      return { backoff_amount: config.backoffs.sellBackoff };
    case AtsCommandActionType.EnterPosition:
      return config.enterPosition;
    default:
      return {};
  }
}

function convertToApiBody(config: BearState, atsOptions: any) {
  const session_id = atsOptions.data?.data?.sessions?.find(
    (session: any) => session.app_environment === config.commandAppEnvironment,
  )?.id;
  // TODO be able to choose a config
  // For now we assume the base config wont change other then hours.
  // NOTE: Live / Sim need different configs as when updating them it updates the config in the DB but only forwards to one of the ATS queues
  const config_id =
    config.commandAppEnvironment === AtsAppEnvironmentType.Live
      ? "eda9759d-3241-49e7-8b68-7d8c2181641e"
      : "5a7b3995-7ff6-4436-8af0-599389114855";

  return {
    app_environment: config.commandAppEnvironment,
    action_type: config.selectedCommandType,
    session_id,
    instrument_id: config.commandInstrumentId,
    config_id,
    meta_data: convertToMetaData(config),
  };
}

function isMissingRequired(config: BearState) {
  if (!config?.selectedCommandType) {
    return true;
  }

  switch (config.selectedCommandType) {
    case AtsCommandActionType.LoadSession:
    case AtsCommandActionType.StartupLoadConfig:
      if (!config.commandAppEnvironment || !config.commandInstrumentId) {
        console.warn("Failed startup validation ", {
          commandAppEnvironment: config.commandAppEnvironment,
          commandInstrumentId: config.commandInstrumentId,
        });
        return true;
      }
      break;
    case AtsCommandActionType.EnterPosition:
      if (!config.enterPosition.original_price) {
        console.warn("Failed to find original price", {
          original_price: config.enterPosition.original_price,
        });
        return true;
      }

      if (!config.enterPosition.purchase_type) {
        console.warn("Failed to find purchase type", {
          purchase_type: config.enterPosition.purchase_type,
        });
        return true;
      }

      break;
    case AtsCommandActionType.IssueBackoff:
      if (!config.backoffs.combinedBackoff || !config.commandInstrumentId) {
        console.warn("Failed IssueBackoff validation ", {
          combinedBackoff: config.backoffs.combinedBackoff,
          commandInstrumentId: config.commandInstrumentId,
        });
        return true;
      }
      break;
    case AtsCommandActionType.IssueBuyBackoff:
      if (!config.backoffs.buyBackoff || !config.commandInstrumentId) {
        console.warn("Failed IssueBuyBackoff validation ", {
          buyBackoff: config.backoffs.buyBackoff,
          commandInstrumentId: config.commandInstrumentId,
        });
        return true;
      }
      break;
    case AtsCommandActionType.IssueSellBackoff:
      if (!config.backoffs.sellBackoff || !config.commandInstrumentId) {
        console.warn("Failed IssueSellBackoff validation ", {
          sellBackoff: config.backoffs.sellBackoff,
          commandInstrumentId: config.commandInstrumentId,
        });
        return true;
      }
      break;
    case AtsCommandActionType.AddBlockHour:
      if (
        !config.blockHour?.absolute_start ||
        !moment(config.blockHour.absolute_start).isValid()
      ) {
        console.warn("Failed AddBlockHour validation ", {
          absolute_start: config.blockHour?.absolute_start,
        });
        return true;
      }
      if (
        !config.blockHour?.absolute_end ||
        !moment(config.blockHour.absolute_end).isValid()
      ) {
        console.warn("Failed AddBlockHour validation ", {
          absolute_end: config.blockHour?.absolute_end,
        });
        return true;
      }
      break;
  }

  return false;
}

function AtsCommandSection() {
  const { member } = useStytchMember();
  const { toast } = useToast();
  const [isSaving, setIsSaving] = useState(false);
  const atsOptions = useAtsOptions();
  const wholeConfig = useBearStore();
  const submitApiCommand = useSubmitAtsCommand();

  useEffect(() => {
    const instruments = atsOptions.data?.data?.instruments;
    if (instruments?.length > 0) {
      wholeConfig.updateInstrument(instruments[0].id);
    }
  }, [atsOptions.data?.data?.instruments?.length > 0]);

  async function onSubmit(
    values: BearState,
    atsOptions: { isLoading: boolean; data: any },
  ) {
    if (!!atsOptions.isLoading) {
      console.warn("ATS Options are loading");
      return true;
    }
    if (isMissingRequired(values)) {
      return true;
    }
    if (isSaving) return true;
    setIsSaving(true);

    const apiBody = convertToApiBody(values, atsOptions);
    const response = await submitApiCommand(apiBody as any);

    if (!response.success) {
      toast({
        title: "Failed to submit command",
        description: "",
      });
    } else {
      toast({
        title: "Success",
        description: "",
      });
    }

    setIsSaving(false);
  }

  if (member && !canUserSubmitCommands(member!)) {
    return <div />;
  }

  if (atsOptions.isLoading) {
    return (
      <div className="flex flex-col space-y-2">
        <Skeleton className="h-4 w-[250px]" />
        <Skeleton className="h-4 w-[200px]" />
      </div>
    );
  }

  if (!atsOptions.data?.success) {
    return (
      <div className="flex flex-col space-y-2">
        <Skeleton className="h-4 w-[250px]" />
        <Skeleton className="h-4 w-[200px]" />
      </div>
    );
  }

  return (
    <div>
      <p className="scroll-m-20 text-xl font-extrabold tracking-tight lg:text-2xl">
        Command
      </p>
      <form
        onSubmit={(e) => {
          if (e?.preventDefault) e.preventDefault();
          return onSubmit(wholeConfig, atsOptions);
        }}
      >
        <div className="flex flex-col sm:grid sm:grid-cols-1 gap-4">
          <FormSelectInput
            name="selectedAtsCommand"
            label="Command"
            value={wholeConfig.selectedCommandType}
            onValueChange={(item) => {
              return wholeConfig.updateCommandType(item as any);
            }}
            options={generateCommandTypes(member)}
          />
          <FormSelectInput
            name="commandAppEnvironment"
            label="Environment"
            value={wholeConfig.commandAppEnvironment}
            onValueChange={(item) => {
              return wholeConfig.updateAppEnvironment(item as any);
            }}
            options={atsAppEnvironments}
          />

          {wholeConfig.selectedCommandType ===
            AtsCommandActionType.IssueBackoff && (
            <Backoff
              name="Combined Backoff"
              storeKey="combinedBackoff"
              updateKey="updateBackoffs"
            />
          )}
          {wholeConfig.selectedCommandType ===
            AtsCommandActionType.IssueSellBackoff && (
            <Backoff
              name="Sell Backoff"
              storeKey="sellBackoff"
              updateKey="updateSellBackoff"
            />
          )}
          {wholeConfig.selectedCommandType ===
            AtsCommandActionType.IssueBuyBackoff && (
            <Backoff
              name="Buy Backoff"
              storeKey="buyBackoff"
              updateKey="updateBuyBackoff"
            />
          )}
          {wholeConfig.selectedCommandType ===
            AtsCommandActionType.EnterPosition && <EnterPositionForm />}
          {wholeConfig.selectedCommandType ===
            AtsCommandActionType.AddBlockHour && (
            <>
              <BlockInput name="Note" storeKey="note" />
              <BlockInput name="Start" storeKey="absolute_start" />
              <BlockInput name="End" storeKey="absolute_end" />
            </>
          )}

          <Button type="submit" className="my-4" disabled={isSaving}>
            {isSaving ? <Loader /> : "Submit"}
          </Button>
        </div>
      </form>
    </div>
  );
}

function EnterPositionForm() {
  const position = useBearStore((state) => state.enterPosition);
  const updateConfig = useBearStore((state) => state.updateEnterPosition);

  return (
    <>
      <FormSelectInput
        name="enterPurchaseType"
        label="Purchase Type"
        required
        value={position.purchase_type}
        onValueChange={(item) => {
          return updateConfig("purchase_type", item);
        }}
        options={generatePurchaseTypes()}
      />
      <FormFloatInput
        label="Price"
        name="enterPurchasePrice"
        min="1"
        step="0.25"
        required
        value={position.original_price}
        onChange={(item) => updateConfig("original_price", item)}
      />
    </>
  );
}

function BlockInput({
  name,
  storeKey,
}: {
  name: string;
  storeKey: "note" | "absolute_start" | "absolute_end";
}) {
  const backoffValue = useBearStore((state) => state.blockHour[storeKey]);
  const updateConfig = useBearStore((state) => state.updateBlockHour);

  const id = `blockHour${storeKey}`;
  return (
    <div className="flex flex-col space-y-2">
      {name && <label htmlFor={id}>{name}</label>}
      <Input
        name={name}
        required
        value={backoffValue}
        onChange={(event) => updateConfig(storeKey, event.target.value)}
      />
    </div>
  );
}

function Backoff({
  name,
  storeKey,
  updateKey,
}: {
  name: string;
  storeKey: "combinedBackoff" | "sellBackoff" | "buyBackoff";
  updateKey: "updateBuyBackoff" | "updateSellBackoff" | "updateBackoffs";
}) {
  const backoffValue = useBearStore((state) => state.backoffs[storeKey]);
  const updateConfig = useBearStore((state) => state[updateKey]);

  const id = `backoffs${storeKey}`;
  return (
    <div className="flex flex-col space-y-2">
      {name && <label htmlFor={id}>{name}</label>}
      <FormIntInput
        name={id}
        min="0"
        step="1"
        required
        value={backoffValue}
        onChange={(item) => updateConfig(item)}
      />
    </div>
  );
}

function FormSelectInput({
  value,
  onValueChange,
  options,
  name,
  required,
  label,
}: {
  value: string;
  onValueChange: (item: string) => void;
  options: { value: string; label: string }[];
  name: string;
  required?: boolean;
  label?: string;
}) {
  return (
    <div className="flex flex-col space-y-2">
      {label && <label htmlFor={name}>{label}</label>}
      <SelectInput
        value={value}
        onValueChange={onValueChange}
        options={options}
      />
    </div>
  );
}

function FormFloatInput({
  value,
  onChange,
  name,
  min,
  max,
  required,
  step,
  label,
}: {
  value: number;
  onChange: (item: number) => void;
  name: string;
  min?: string;
  max?: string;
  required?: boolean;
  step: string;
  label?: string;
}) {
  return (
    <div className="flex flex-col space-y-2">
      {label && <label htmlFor={name}>{label}</label>}
      <Input
        value={value}
        id={name}
        type="number"
        required
        min={min}
        max={max}
        step={step}
        onChange={parseFloatNumber(onChange)}
        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
      />
    </div>
  );
}

function FormIntInput({
  value,
  onChange,
  name,
  min,
  max,
  required,
  step,
  label,
}: {
  value: number;
  onChange: (item: number) => void;
  name: string;
  min?: string;
  max?: string;
  required?: boolean;
  step: string;
  label?: string;
}) {
  return (
    <div className="flex flex-col space-y-2">
      {label && <label htmlFor={name}>{label}</label>}
      <Input
        value={value}
        id={name}
        type="number"
        required
        min={min}
        max={max}
        step={step}
        onChange={parseIntEvent(onChange)}
        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
      />
    </div>
  );
}

function canUserViewCurrentPosition(member: Member) {
  return doesUserHavePackages({
    requiredPackages: [PossiblePackage.AtsRead],
    member,
  });
}

function canUserSubmitCommands(member: Member) {
  return doesUserHavePackages({
    requiredPackages: [PossiblePackage.AtsAction],
    member,
  });
}

function AtsCurrentPositionSection() {
  const { member } = useStytchMember();
  const refreshPosition = useBearStore((state) => state.refreshPosition);
  const currentPositionsState = useBearStore(
    (state) => state.currentPositionsState,
  );
  const liveCurrentState = useGetAtsCurrentState();

  useEffect(() => {
    if (member && canUserViewCurrentPosition(member!)) {
      const interval = setInterval(() => {
        refreshPosition();
      }, 15 * 1000);
      return () => clearInterval(interval);
    }
  }, [member]);

  if (member && !canUserViewCurrentPosition(member!)) {
    return <div />;
  }

  if (liveCurrentState.isLoading) {
    return (
      <div className="flex flex-col space-y-2">
        <Skeleton className="h-4 w-[250px]" />
        <Skeleton className="h-4 w-[200px]" />
      </div>
    );
  }

  if (!liveCurrentState.data?.success) {
    return (
      <div className="flex flex-col space-y-2">
        <Skeleton className="h-4 w-[250px]" />
        <Skeleton className="h-4 w-[200px]" />
      </div>
    );
  }

  const data = liveCurrentState.data?.data;

  return (
    <div>
      <p className="scroll-m-20 text-xl font-extrabold tracking-tight lg:text-2xl">
        Current Sessions
      </p>
      {data?.length == 0 && <p className="">No Active Sessions</p>}
      {data?.length > 0 && (
        <div className="flex flex-col gap-4">
          <Accordion
            type="single"
            collapsible
            className="w-full"
            defaultValue={data?.[0]?.id}
          >
            {data?.map((session: AtsSession) => (
              <AccordionItem key={session.id} value={session.id}>
                <AccordionTrigger>
                  {generateEnvName(session.app_environment)}
                </AccordionTrigger>
                <AccordionContent>
                  <AtsSessionPreview session={session} />
                </AccordionContent>
              </AccordionItem>
            ))}
          </Accordion>
        </div>
      )}
    </div>
  );
}

function parseIntEvent(onChange: (item: number) => void) {
  return function parseIntEventHelper(event: any) {
    try {
      return onChange(parseInt(event.target.value));
    } catch (e) {
      return onChange(0);
    }
  };
}

function parseFloatNumber(onChange: (item: number) => void) {
  return function parseFloatEventHelper(event: any) {
    try {
      return onChange(parseFloat(event.target.value));
    } catch (e) {
      return onChange(0.0);
    }
  };
}

function generateEnvName(app_environment: string) {
  switch (app_environment) {
    case AtsAppEnvironmentType.Live:
      return "Live";
    case AtsAppEnvironmentType.Simulation:
      return "Simulation";
    default:
      return "Unknown";
  }
}

function transformPriceBar(instrument?: AtsInstrument) {
  const last_price_bar: SimplePriceBar = instrument?.last_price_bar!;
  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>Instrument</TableHead>
          <TableHead>Time</TableHead>
          <TableHead>High</TableHead>
          <TableHead>Low</TableHead>
          <TableHead>Open</TableHead>
          <TableHead>Close</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        <TableRow>
          <TableCell>{instrument?.contract_alias}</TableCell>
          <TableCell>
            {moment
              .tz(last_price_bar.timestamp, CommonDestinationTimezone)
              .format("MM/DD/YY HH:mm a")}
          </TableCell>
          <TableCell>{last_price_bar.high}</TableCell>
          <TableCell>{last_price_bar.low}</TableCell>
          <TableCell>{last_price_bar.open}</TableCell>
          <TableCell>{last_price_bar.close}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
}

function AtsSessionPreview({ session }: { session: AtsSession }) {
  return (
    <div className="flex flex-col gap-2">
      <Card className="">
        <CardContent>
          <div className="grid w-full items-center gap-4 mt-4">
            <div className="flex flex-col sm:grid sm:grid-cols-5 gap-2 justify-center">
              <div className="flex flex-col space-y-1.5">
                <Label htmlFor={`${session.id}startDate`}>Start Date</Label>
                <p id={`${session.id}startDate`}>
                  {session.GlobalState?.algo_start_date
                    ? moment
                        .tz(
                          session.GlobalState?.algo_start_date,
                          CommonDestinationTimezone,
                        )
                        .format("MMM DD, YY HH:mm a")
                    : ""}
                </p>
              </div>
              <div className="flex flex-col space-y-1.5">
                <Label htmlFor={`${session.id}marketOpen`}>Market</Label>
                <p id={`${session.id}marketOpen`}>
                  {session.GlobalState?.is_market_open ? "Open" : "Closed"}
                </p>
              </div>

              <div className="flex flex-col space-y-1.5">
                <Label htmlFor={`${session.id}allTimeRealizedPL`}>
                  All Time Realized P/L
                </Label>
                <p id={`${session.id}allTimeRealizedPL`}>
                  {displayFloat(session.all_time_realized!)}
                </p>
              </div>
              <div className="flex flex-col space-y-1.5">
                <Label htmlFor={`${session.id}realizedPl`}>
                  Session Realized P/L
                </Label>
                <p id={`${session.id}realizedPL`}>
                  {displayFloat(session.GlobalState?.realized_algo_pl)}
                </p>
              </div>
              {session.GlobalState?.CurrentInstrument?.contract_alias && (
                <div className="flex flex-col space-y-1.5">
                  <Label htmlFor={`${session.id}contract`}>Contract</Label>
                  <p id={`${session.id}contract`}>
                    {session.GlobalState?.CurrentInstrument?.contract_alias}
                  </p>
                </div>
              )}
            </div>

            {session.GlobalState?.CurrentInstrument?.last_price_bar && (
              <div className="flex flex-col space-y-1.5">
                {transformPriceBar(session.GlobalState?.CurrentInstrument)}
              </div>
            )}
            <p className="text-lg font-bold tracking-tight">Positions</p>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Position</TableHead>
                  <TableHead>Size</TableHead>
                  <TableHead>Buy/Sell Backoff</TableHead>
                  <TableHead>P/L</TableHead>
                  <TableHead>Average Price</TableHead>
                  <TableHead>Start Date</TableHead>
                  <TableHead>Convergence Period</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {session.Positions?.map((position: any) => (
                  <TableRow key={position.id}>
                    <TableCell>{position.position_type}</TableCell>
                    <TableCell>{position.num_open_positions}</TableCell>
                    <TableCell>
                      {position.buy_backoff}/{position.sell_backoff}
                    </TableCell>
                    <TableCell>
                      {displayFloat(position.position_profit_loss)}
                    </TableCell>
                    <TableCell>
                      {displayFloat(position.position_average_price)}
                    </TableCell>
                    <TableCell>
                      {position.position_start_date
                        ? moment
                            .tz(
                              position.position_start_date,
                              CommonDestinationTimezone,
                            )
                            .format("MMM DD, YY HH:mm a")
                        : "--"}
                    </TableCell>
                    <TableCell>{position.live_convergence_period}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <p className="text-lg font-bold tracking-tight">OCOs</p>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Buy/Sell</TableHead>
                  <TableHead>Rem./Order Qty</TableHead>
                  <TableHead>Price</TableHead>
                  <TableHead>Order Type</TableHead>
                  <TableHead>Oco Type</TableHead>
                  <TableHead>Time In Force</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {session.Positions?.map(
                  (position: any) => position.AtsPositionOcos,
                )
                  ?.flat()
                  ?.map((positionOco: any) => (
                    <TableRow key={positionOco.id}>
                      <TableCell>{positionOco.buy_sell}</TableCell>
                      <TableCell>
                        {positionOco.remaining_quantity} of{" "}
                        {positionOco.order_quantity}
                      </TableCell>
                      <TableCell>${positionOco.order_price}</TableCell>
                      <TableCell>{positionOco.order_type}</TableCell>
                      <TableCell>{positionOco.oco_type}</TableCell>
                      <TableCell>{positionOco.time_in_force}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            <p className="text-lg font-bold tracking-tight">Holiday Hours</p>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Note</TableHead>
                  <TableHead>Start</TableHead>
                  <TableHead>End</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {session.AtsConfig?.block_hours
                  ?.filter((blockHour) => {
                    return moment
                      .tz(blockHour.AbsoluteEnd, CommonDestinationTimezone)
                      .isSameOrAfter(moment.tz(CommonDestinationTimezone));
                  })
                  ?.map((blockHour) => (
                    <TableRow key={blockHour.Note || blockHour.note}>
                      <TableCell>{blockHour.Note || blockHour.note}</TableCell>
                      <TableCell>
                        {moment
                          .tz(
                            blockHour.absolute_start || blockHour.AbsoluteStart,
                            CommonDestinationTimezone,
                          )
                          .format("MMM Do YYYY, hh:mm a")}
                      </TableCell>
                      <TableCell>
                        {moment
                          .tz(
                            blockHour.absolute_end || blockHour.AbsoluteEnd,
                            CommonDestinationTimezone,
                          )
                          .format("MMM Do YYYY, hh:mm a")}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            <p className="text-lg font-bold tracking-tight">Recent Events</p>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Type</TableHead>
                  <TableHead>Status</TableHead>
                  <TableHead>Qty</TableHead>
                  <TableHead>Price</TableHead>
                  <TableHead>Date</TableHead>
                  <TableHead>Message</TableHead>
                  <TableHead>TT ID</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {session.recent_events?.map((event) => (
                  <TableRow
                    key={event.id}
                    className={cn(
                      event.event_type === AtsOrderEventType.OrderDeleted
                        ? "bg-red-300/50"
                        : "",
                      event.event_type === AtsOrderEventType.OrderFilled &&
                        event.app_order_type === AppOrderType.EnterPosition
                        ? "bg-green-300/50"
                        : "",
                      "hover:bg-yellow-200/50",
                    )}
                  >
                    <TableCell>
                      {generateAppTypeLabel(event.app_order_type)}
                    </TableCell>
                    <TableCell>
                      {generateEventTypeLabel(event.event_type)}
                    </TableCell>
                    <TableCell>
                      {event.event_type === AtsOrderEventType.OrderFilled
                        ? event.fill_quantity
                        : event.canceled_quantity}
                    </TableCell>
                    <TableCell>
                      {event.event_type === AtsOrderEventType.OrderFilled
                        ? event.executed_price
                        : event.order_price}
                    </TableCell>
                    <TableCell>
                      {moment
                        .tz(
                          event.exchange_transaction_time,
                          CommonDestinationTimezone,
                        )
                        .format("MMM Do YY, hh:mm:ss a")}
                    </TableCell>
                    <TableCell>{event.message || ""}</TableCell>
                    <TableCell>{event.tt_id}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}

function displayFloat(item: any) {
  if (typeof item === "number") return item.toFixed(2);
  if (!item) return "--";
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(parseFloat(item));
}

function generateAppTypeLabel(app_order_type: AppOrderType) {
  switch (app_order_type) {
    case AppOrderType.EnterPosition:
      return "Enter";
    case AppOrderType.ClosePosition:
      return "Exit";
    case AppOrderType.Oco:
      return "OCO";
    case AppOrderType.TopUP:
      return "TopUp";
  }
}

function generateEventTypeLabel(event_type: AtsOrderEventType) {
  switch (event_type) {
    case AtsOrderEventType.OrderFilled:
      return "Filled";
    case AtsOrderEventType.OrderDeleted:
      return "Failed";
  }
}
