import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  fetchApiDelete,
  fetchApiGet,
  fetchApiPost,
  fetchApiPut,
} from "./utils";
import {
  TicketRun,
  TicketsSummary,
  TicketRunSummary,
  Empty,
} from "../models/index";
import { TicketJob, TicketProviderSummary } from "../models/tickets";
import { ProviderType } from "../models/provider";

const ENTITY_KEY = "tickets";

export function useTicketUploadGeneratePresignPutUrl() {
  const queryClient = useQueryClient();

  return async ({
    providerId,
    originalFileName,
    mimeType,
  }: {
    providerId: string;
    originalFileName: string;
    mimeType: string;
  }) => {
    const result = await fetchApiPost<{ url: string }>(
      `tickets/providers/${providerId}/upload/presign`,
      {
        originalFileName,
        mimeType,
      },
      new URLSearchParams(),
    );
    return result;
  };
}

export function useDownloadTicketBatch(
  providerId: string,
  batchId: string,
  providerType: ProviderType,
) {
  const queryClient = useQueryClient();
  const queryParams = new URLSearchParams();
  if (providerType) {
    queryParams.append("providerType", providerType);
  }

  return async (providerId: string) => {
    const result = await fetchApiPost<{
      ticketsToDownload: string;
      downloaded_date: string;
    }>(
      `tickets/providers/${providerId}/download/batch/${batchId}`,
      {},
      queryParams,
    );
    queryClient.invalidateQueries({
      queryKey: ["providerTicketSummary", providerType],
    });
    return result;
  };
}

export function useDownloadUndownloadedTickets(
  providerType: ProviderType,
  downloadType: "new" | "updated",
) {
  const queryClient = useQueryClient();
  const queryParams = new URLSearchParams();
  if (providerType) {
    queryParams.append("providerType", providerType);
  }
  queryParams.append("downloadType", downloadType);

  return async (providerIds: string[]) => {
    const result = await fetchApiPost<{ ticketsToDownload: string }>(
      `tickets/providers/download`,
      { providerIds },
      queryParams,
    );
    queryClient.invalidateQueries({
      queryKey: ["providerTicketSummary", providerType],
    });
    return result;
  };
}

export function useDownloadUndownloadedUpdatedTickets(
) {
  const queryClient = useQueryClient();
  const queryParams = new URLSearchParams();

  return async (providerIds: string[]) => {
    const result = await fetchApiPost<{ ticketsToDownload: string }>(
      `tickets/providers/download/update`,
      { providerIds },
      queryParams,
    );
    queryClient.invalidateQueries({
      queryKey: ["providerTicketSummary", "updated"],
    });
    return result;
  };
}

export function useResetDownloadedCountsTickets() {
  const queryClient = useQueryClient();

  return async (providerId: string) => {
    return await fetchApiPost<Empty>(
      `tickets/${providerId}/resetDownloadCount`,
      {},
    );
  };
}

export function useTicketSummary() {
  return useQuery({
    queryKey: [ENTITY_KEY],
    queryFn: () => fetchApiGet<TicketsSummary>("tickets/summary"),
    retry: 1,
    cacheTime: 0,
  });
}

export function useListProviderTicketRuns(providerId: string) {
  return useQuery({
    queryKey: ["ticketRuns"],
    queryFn: () => {
      if (!providerId)
        throw new Error("Missing providerId in useListProviderTicketRuns");
      return fetchApiGet<TicketRun[]>(`tickets/${providerId}/ticketRuns`);
    },
    retry: 1,
    cacheTime: 0,
  });
}

export function useSummaryTicketRuns(providerType?: string) {
  const queryParams = new URLSearchParams();
  if (providerType) {
    queryParams.append("providerType", providerType);
  }

  return useQuery({
    queryKey: ["ticketRunsSummary"],
    queryFn: () => {
      return fetchApiGet<TicketRunSummary>(`tickets/ticketRuns`, queryParams);
    },
    retry: 1,
    cacheTime: 0,
  });
}

export function useTicketProviderSummary(providerType: ProviderType) {
  const queryParams = new URLSearchParams();
  if (providerType) {
    queryParams.append("providerType", providerType);
  }

  return useQuery({
    queryKey: ["providerTicketSummary", providerType],
    queryFn: () => {
      return fetchApiGet<TicketProviderSummary[]>(
        `tickets/providers/summary`,
        queryParams,
      );
    },
    retry: 1,
    cacheTime: 0,
  });
}

export function fetchTicketJobsPage(page: number) {
  const queryParams = new URLSearchParams();
  queryParams.append("page", `${page}`)

  return fetchApiGet<{
    data: TicketJob[]
    total: number;
    totalPages: number;
    pageSize: number;
    currentPage: number;
  }>(`tickets/jobs/paginated`, queryParams);
}

export function useTicketJobsPaginated({ page, queryFn }: {
  page: number,
  queryFn: any,
}) {
  return useQuery({
    queryKey: ["jobs", `${page}`],
    queryFn,
    retry: 1,
    cacheTime: 0,
    keepPreviousData : true,
  });
}

export function useTicketJobs() {
  const queryParams = new URLSearchParams();
  return useQuery({
    queryKey: ["jobs"],
    queryFn: () => {
      return fetchApiGet<TicketJob[]>(`tickets/jobs`, queryParams);
    },
    retry: 1,
    cacheTime: 0,
  });
}

export function useTicketJob(ticketJobId: string) {
  const queryParams = new URLSearchParams();

  return useQuery({
    queryKey: ["jobs", ticketJobId],
    queryFn: () => {
      return fetchApiGet<TicketJob>(`tickets/jobs/${ticketJobId}`, queryParams);
    },
    retry: 1,
    cacheTime: 0,
  });
}
