import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useState } from "react";
import moment from "moment-timezone";
import { Label } from "../../components/ui/label";
import { Loader } from "../../components/ui/loader";
import { Input } from "../../components/ui/input";
import {
  TicketRunSummary,
  TicketsSummary,
  Provider,
} from "../../lib/models/index";
import { useGetTraderCustomersInfo } from "../../lib/api/reports";
import { BaseInfo, processFromFiles } from "../../lib/transactionReports";
import {
  TicketProviderSummary,
  TicketProviderRecentRunPartial,
  TicketProviderBatchDownloadPartial,
} from "../../lib/models/tickets";
import { Button } from "../../components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../components/ui/dropdown-menu";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../components/ui/tabs";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/ui/table";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../components/ui/accordion";
import { Skeleton } from "../../components/ui/skeleton";
import { useToast } from "../../components/ui/use-toast";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../components/ui/card";
import { cn } from "../../lib/utils";

export function MarketingTransactionalReport() {
  return <ReportView />;
}

function ReportView({}: {}) {
  const { toast } = useToast();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [locationFiles, setLocationFiles] = useState<File[]>([]);
  const [accrualFile, setAccrualFile] = useState<File | undefined>(undefined);
  const [marketingReportFile, setMarketingReportFile] = useState<
    File | undefined
  >(undefined);
  const [baseInfo, setBaseInfo] = useState<
    BaseInfo | undefined
  >(undefined);
  const traderCustomerInfoRes = useGetTraderCustomersInfo();

  const onUploadFile = async () => {
    if (isSubmitting) return;
    if (!locationFiles.length) return;
    if (!accrualFile) return;
    if (!marketingReportFile) return;

    setIsSubmitting(true);

    try {
      const fileMap = locationFiles.reduce(
        (acc, file) => {
          acc[file.name] = file;
          return acc;
        },
        {} as Record<string, File>,
      );
      fileMap[accrualFile.name] = accrualFile;
      fileMap[marketingReportFile.name] = marketingReportFile;

      const baseInfo = await processFromFiles(fileMap, traderCustomerInfoRes.data?.data);
      setBaseInfo(baseInfo);
    } catch (error: any) {
      console.error("Failed to generate marketing fees file", error);
      if (error?.message === "Failed to find Accrual Sheet") {
        toast({
          title: error.message,
          description: "Expected sheet called Mkt Fee Accruals",
        });
      } else {
        toast({
          title: "Failed to create new marketing fees file.",
          description: "",
        });
      }
    }

    setIsSubmitting(false);
  };

  const onLocationFilesChange = (item: any) => {
    setLocationFiles(Object.values(item.target.files));
  };
  const onAccrualFilesChange = (item: any) => {
    setAccrualFile(item.target.files[0]);
  };
  const onMarketingReportFilesChange = (item: any) => {
    setMarketingReportFile(item.target.files[0]);
  };

  if ([traderCustomerInfoRes].some((result) => result.isLoading)) {
    return (
      <div className="flex flex-col space-y-2">
        <Skeleton className="h-4 w-[250px]" />
        <Skeleton className="h-4 w-[200px]" />
      </div>
    );
  }

  if (
    [traderCustomerInfoRes].some(
      (result) => result.error || !result?.data?.success,
    )
  ) {
    return (
      <div className="flex flex-col space-y-2">
        Failed to load Trader / Customer Pairs. Please refresh and try again
      </div>
    );
  }

  return (
    <>
      <div className="container flex flex-col mb-2">
        <h1 className="scroll-m-20 text-2xl font-extrabold tracking-tight lg:text-3xl">
          Marketing Fees
        </h1>
        <p className="text-muted-foreground">
          Upload for a given month all Locations + Accrual and Final MF Listing
          files to generate a report.
        </p>
      </div>
      <div className="flex items-start flex-col h-full mt-4">
        <div className="container flex flex-col gap-4">
          <div className="grid items-center gap-1.5">
            <Label htmlFor="locationReportFiles">Location Reports</Label>
            <Input
              id="locationReportFiles"
              type="file"
              accept=".xlsx, .xls"
              multiple
              onChange={onLocationFilesChange}
            />
          </div>
          <div className="grid items-center gap-1.5">
            <Label htmlFor="accrualFile">Accrual Report</Label>
            <Input
              id="accrualFile"
              type="file"
              accept=".xlsx, .xls"
              onChange={onAccrualFilesChange}
            />
          </div>
          <div className="grid items-center gap-1.5">
            <Label htmlFor="marketingFeesFile">Marketing Fees Report</Label>
            <Input
              id="marketingFeesFile"
              type="file"
              accept=".xlsx, .xls"
              onChange={onMarketingReportFilesChange}
            />
          </div>

          <div className="grid w-full max-w-sm items-center gap-1.5 mt-2">
            <Button
              disabled={
              isSubmitting ||
              !locationFiles.length ||
              !marketingReportFile ||
              !accrualFile
              }
              id="uploadButton"
              onClick={onUploadFile}
            >
              {isSubmitting ? <Loader /> : "Process"}
            </Button>
          </div>
        </div>
        {baseInfo && (
          <div className="container flex flex-col gap-4 mt-8">
            <span className="grid gap-4 md:grid-cols-2 lg:grid-cols-4">
              <NumberCard name="Received Total Files" count={baseInfo.fileCounts.totalFilesCount.toFixed()} />
              <NumberCard name="Found Marketing Fees Files" count={baseInfo.fileCounts.marketingFeesFileCount.toFixed()} />
              <NumberCard name="Found Accrual Files" count={baseInfo.fileCounts.accrualFileCount.toFixed()} />
              <NumberCard name="Found Master Files" count={baseInfo.fileCounts.masterFileCount.toFixed()} />
            </span>

            {(
              baseInfo.missingData.settlementMissingSheet.length > 0 ||
              baseInfo.missingData.accrualMissingSheet
            ) && (
              <TitleList title="Failed Sheets" description={
              [
                baseInfo.missingData.settlementMissingSheet.length > 0 ? "Failed to identify some settlement sheets." : "",
                baseInfo.missingData.accrualMissingSheet ? "Failed to identify accrual file tab." : ""
              ].join(" ")
              } list={[]} />
            )}
            {baseInfo.missingData.settlementMissingCustomers.length > 0 && (
              <TitleList title="Used Sheets" description="" list={baseInfo.utilizedSheetNames} />
            )}
            {baseInfo.missingData.settlementMissingCustomers.length > 0 && (
              <TitleList
                title="Missing Settlement Customers"
                description="Failed to match these customers to one in the DB"
                list={
                Array.from(new Set(baseInfo.missingData.settlementMissingCustomers))
                }
              />
            )}
            {baseInfo.missingData.accrualMissingCustomers.length > 0 && (
              <TitleList
                title="Missing Accrual Customers"
                description="Failed to match these customers to one in the DB"
                list={
                Array.from(new Set(baseInfo.missingData.accrualMissingCustomers))
                }
              />
            )}
            {(baseInfo.missingData.accrualMissingTraderPair.length > 0 || baseInfo.missingData.settlementMissingTrader.length > 0) && (
              <TitleList
                title="Missing Trader"
                description="Fail to find a trader linked to these customers."
                list={
                Array.from(new Set([
                  ...baseInfo.missingData.accrualMissingTraderPair,
                  ...baseInfo.missingData.settlementMissingTrader,
                ]))
                } />
            )}

          </div>
        )}
      </div>
    </>
  );
}

function NumberCard({ name, count }: { name: string; count: string; }) {
  return (
    <Card className="">
      <CardContent>
          <div className="flex flex-col items-center gap-4 space-y-1.5 text-center">
            <div className="font-light text-muted-foreground mt-2">
              {name}
            </div>
            <div className="font-bold text-lg">
              {count}
            </div>
          </div>
      </CardContent>
    </Card>
  );
}

function TitleList({ title, description, list }: {
  title: string;
  description: string;
  list: string[]
}) {
  return (
    <div className="text-center">
      <h3 className="mt-2 text-sm font-semibold text-gray-900">{title}</h3>
      <p className="mt-1 text-sm text-gray-500">{description}</p>
      <ul role="list" className="-mx-2 space-y-1">
        {list.map((item) => (
          <li key={item}>
            <span
              className={cn(
                'text-gray-700 hover:text-gray-500',
                'group flex gap-x-3 rounded-md p-2 pl-3 text-sm font-semibold leading-6'
              )}
            >
              {item}
            </span>
          </li>
        ))}
      </ul>
    </div>
  )
}

