import { create } from "zustand";
import { z } from "zod";
import { useState } from "react";
import {
    CreateTraderValidation,
    AssignTraderCustomerValidation,
    useListTraders,
    useListTradersCustomerPairs,
    useCreateTrader,
    useAssignTrader,
    useUnassignTraderPair,
} from "../../lib/api/trader";
import {
  useListCustomers,
} from "../../lib/api/customer";
import { Trader, Customer, TraderCustomer } from "../../lib/api/reports";
import { Skeleton } from "../../components/ui/skeleton";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/ui/table";
import { Button } from "../../components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogClose,
  DialogFooter,
} from "../../components/ui/dialog";
import { Input } from "../../components/ui/input";
import { Label } from "../../components/ui/label";
import { Combobox } from "../../components/ui/combobox";
import { useToast } from "../../components/ui/use-toast";
import {
    Link as LinkIcon,
    Archive as ArchiveXIcon,
} from "lucide-react";
import { cn } from "../../lib/utils";
import { ValidationError, PossibleReturnType } from "../../lib/responseResults";
import {
  DataTable,
  DataTableProps,
} from "../../components/ui/virtualizedTable";
import { Loader } from "../../components/ui/loader";

interface BearState {
  createTraderData?: typeof CreateTraderValidation._type;
  assignModalData?: typeof AssignTraderCustomerValidation._type;

  setTraderDataModalStatus: (status: boolean) => void;
  setAssignDataModalStatus: (data?: { trader_id: string; } | undefined) => void;
  updateCreateTraderField: (key: string, value: string | undefined | null) => void;
  updateAssignTraderField: (key: string, value: string | undefined | null) => void;
}

const useBearStore = create<BearState>((set) => ({
  createTraderData: undefined,

  setTraderDataModalStatus: (status: boolean) =>
    set((state) => ({
        createTraderData: status ? { initials: "", name: "" } : undefined
    })),
  setAssignDataModalStatus: (data?: { trader_id: string; } | undefined) =>
    set((state) => ({
        assignModalData: data as any
    })),
  updateCreateTraderField: (key: string, value: string | undefined | null) =>
    set((state) => {
        if (!state.createTraderData) return { createTraderData: undefined }
        return ({
      ...state,
      createTraderData: {
        ...state.createTraderData,
        [key]: value as any,
      },
    })
    }),
  updateAssignTraderField: (key: string, value: string | undefined | null) =>
    set((state) => {
        if (!state.assignModalData) return { assignModalData: undefined }
        return ({
      ...state,
      assignModalData: {
        ...state.assignModalData,
        [key]: value as any,
      },
    })
    }),
}));

export function ManageTradersPage() {
  const listTradersRes = useListTraders();
  const listPairsRes = useListTradersCustomerPairs();
  const listCustomersResult = useListCustomers();

    if ([listTradersRes, listCustomersResult, listPairsRes].some((result) => result.isLoading)) {
        return (
            <div className="flex flex-col space-y-2">
                <Skeleton className="h-4 w-[250px]" />
                <Skeleton className="h-4 w-[200px]" />
            </div>
        );
  }

    const traders = listTradersRes?.data?.data || [];
    const customers = listCustomersResult?.data?.data || [];
    const pairs = listPairsRes?.data?.data || [];

  return (
    <section className="flex min-h-full flex-1 flex-col justify-start px-6 py-6 lg:px-8">
      <h1 className="scroll-m-20 text-2xl font-extrabold tracking-tight lg:text-3xl">
        Manage Traders
      </h1>
      <div className="flex flex-row justify-end">
        <CreateTraderModal />
        <AssignTraderModal traders={traders} customers={customers} pairs={pairs} />
      </div>
      <div className="flex flex-col">
          <TradersList
              traders={traders}
              customers={customers}
              pairs={pairs}
          />
      </div>
    </section>
  );

};


async function onSubmitAssignTraderForm(
    {
        onCloseModal,
        assignTrader,
        isSaving,
        setIsSaving,
        toast,
    }: {
        toast: (data: { title: string; description?: string }) => void;
        assignTrader: (formData: any) => Promise<PossibleReturnType<any>>;
    onCloseModal: () => void;
    isSaving: boolean;
    setIsSaving: (status: boolean) => void;
  },
  formData: typeof AssignTraderCustomerValidation._type,
) {
  if (!isAssignTraderFormValid(formData)) {
    return false;
  }

  if (isSaving) return false;

  setIsSaving(true);

  const data = JSON.parse(JSON.stringify(formData));
    const saveResult = await (assignTrader(data as any));

  setIsSaving(false);

    if (!saveResult.success) {
        toast({
            title: "Failed to assign trader",
            description: "",
        });
    } else {
        onCloseModal();
        toast({
            title: "Paired",
            description: "",
        });
    }

    return true;
}

async function onSubmitTraderForm(
    {
        onCloseModal,
        createTrader,
        isSaving,
        setIsSaving,
        toast,
    }: {
        toast: (data: { title: string; description?: string }) => void;
        createTrader: (formData: any) => Promise<PossibleReturnType<any>>;
    onCloseModal: () => void;
    isSaving: boolean;
    setIsSaving: (status: boolean) => void;
  },
  formData: typeof CreateTraderValidation._type,
) {
  if (!isTraderFormValid(formData)) {
    return false;
  }

  if (isSaving) return false;

  setIsSaving(true);

  const data = JSON.parse(JSON.stringify(formData));
    const saveResult = await (createTrader(data as any));

  setIsSaving(false);

    if (!saveResult.success) {
        toast({
            title: "Failed to create trader",
            description: "",
        });
    } else {
        onCloseModal();
        toast({
            title: "Created",
            description: "",
        });
    }

    return true;
}

export function AssignTraderModal({
    traders,
    customers,
    pairs,
}: {
    customers: Customer[];
    traders: Trader[];
    pairs: TraderCustomer[];
}) {
  const { toast } = useToast();
  const [isSaving, setIsSaving] = useState(false);
  const assignTrader = useAssignTrader();

  const isModalOpen = useBearStore((state) => {
    return !!state.assignModalData;
  });

  const {
      setAssignDataModalStatus,
      updateAssignTraderField
  } = useBearStore((state) => {
    return {
        setAssignDataModalStatus: state.setAssignDataModalStatus,
        updateAssignTraderField: state.updateAssignTraderField,
    };
  });


  return (
      <Dialog
          onOpenChange={(status) => {
              if (!status) { setAssignDataModalStatus(undefined); }
          }}
          open={isModalOpen}
          defaultOpen={false}
      >
          <DialogContent className="max-w-screen-2xl md:min-w-sm lg:min-w-md xl:mg-w-lg">
              <DialogHeader>
                  <DialogTitle>
                      Assign Trader
                  </DialogTitle>
              </DialogHeader>
              <AssignTraderForm
                  traders={traders}
                  customers={customers}
                  pairs={pairs}
                  onEditField={updateAssignTraderField}
                  onSubmit={(formData) =>
                      onSubmitAssignTraderForm(
                          {
                              assignTrader,
                              toast,
                              onCloseModal: () => setAssignDataModalStatus(undefined),
                              isSaving,
                              setIsSaving: () => setAssignDataModalStatus(undefined),
                          },
                          formData,
                      )
                  }
              />
              <AssignedCustomersList
                  traders={traders}
                  customers={customers}
                  pairs={pairs}
              />
              <DialogFooter className="sm:justify-start"></DialogFooter>
          </DialogContent>
      </Dialog>
  );
}

export function CreateTraderModal() {
    const { toast } = useToast();
  const [isSaving, setIsSaving] = useState(false);
    const createTrader = useCreateTrader();

  const isModalOpen = useBearStore((state) => {
    return !!state.createTraderData;
  });

  const {
      setTraderDataModalStatus,
      updateCreateTraderField
  } = useBearStore((state) => {
    return {
        setTraderDataModalStatus: state.setTraderDataModalStatus,
        updateCreateTraderField: state.updateCreateTraderField,
    };
  });

  return (
      <Dialog
          onOpenChange={(status) => {
              if (!status) { setTraderDataModalStatus(false); }
          }}
          open={isModalOpen}
          defaultOpen={false}
      >
          <DialogTrigger asChild>
              <Button
                  type="button"
                  className="mr-2"
                  disabled={isModalOpen}
                  onClick={() => setTraderDataModalStatus(true)}
              >
                  New Trader
              </Button>
          </DialogTrigger>
          <DialogContent className="max-w-screen-2xl md:min-w-sm lg:min-w-md xl:mg-w-lg">
              <DialogHeader>
                  <DialogTitle>
                      New Trader
                  </DialogTitle>
              </DialogHeader>
              <TraderForm
                  onEditField={updateCreateTraderField}
                  onSubmit={(formData) =>
                      onSubmitTraderForm(
                          {
                              createTrader,
                              toast,
                              onCloseModal: () => setTraderDataModalStatus(false),
                              isSaving,
                              setIsSaving: () => setTraderDataModalStatus(false),
                          },
                          formData,
                      )
                  }
              />
              <DialogFooter className="sm:justify-start"></DialogFooter>
          </DialogContent>
      </Dialog>
  );
}

export function TradersList({
    traders,
    customers,
    pairs,
}: {
    traders: Trader[];
    customers: Customer[];
    pairs: TraderCustomer[];
}) {
  const {
      setAssignDataModalStatus,
  } = useBearStore((state) => {
    return {
        setAssignDataModalStatus: state.setAssignDataModalStatus,
    };
  });

    return (
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Initials</TableHead>
            <TableHead>Name</TableHead>
            <TableHead>Pair</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
        {traders.map((trader: any) => {
            return (
                <TableRow
                    key={`TraderListRow:${trader.id}`}
                    className=""
                >
                    <TableCell>{trader.initials}</TableCell>
                    <TableCell>{trader.name}</TableCell>
                    <TableCell>
                        <Button
                            variant="ghost"
                            onClick={() => setAssignDataModalStatus({ trader_id: trader.id })}
                        >
                            <LinkIcon className="h-6 w-6" aria-hidden="true" />
                        </Button>
                    </TableCell>
                </TableRow>
          );
        })}
        </TableBody>
      </Table>
    );
}

export function AssignedCustomersList({
    traders,
    customers,
    pairs
}: {
  traders: Trader[];
  customers: Customer[];
    pairs: TraderCustomer[]
}) {
  const fields = useBearStore((state) => state.assignModalData);
  if (!fields?.trader_id) return null;

  const trader = traders.find(trader => trader.id === fields.trader_id);
  if (!trader) return null;

  const pairedIdList = pairs.filter(pair => trader.id === pair.trader_id).map(pair => pair.customer_id);
  const pairedCustomers = customers.filter(customer => pairedIdList.includes(customer.id));

const PairedCustomerColumns: any[] = [
  {
    id: "customer_id",
    header: "ID",
    cell: ({ row }: any) => (
      <span className="">{row.original.client_id}</span>
    ),
    enableSorting: true,
    enableHiding: false,
    onCellClick: false,
  },
  {
    id: "long_name",
    header: "Long Name",
    cell: ({ row }: any) => (
      <span className="">{row.original.long_name}</span>
    ),
    enableSorting: true,
    enableHiding: false,
    onCellClick: false,
  },
  {
    id: "unassign_trader",
    header: "Unassign",
    cell: ({ row }: any) => (
      <UnassignTrader
          trader_id={trader.id}
          customer_id={row.original.id}
      />
    ),
    enableSorting: true,
    enableHiding: false,
    onCellClick: false,
  },
];

    return (
        <>
                <h3 className="text-lg font-bold tracking-tight">
                  Currently Paired
                </h3>
                <DataTable
                  height="250px"
                  className="max-h-[16rem] md:max-h-[24rem] lg:max-h-[30rem] grid overflow-y-auto overflow-x-auto"
                  columns={
                    PairedCustomerColumns
                  }
                  data={pairedCustomers}
                />
        </>
    )
}

export function AssignTraderForm({
    traders,
    customers,
    pairs,
    onEditField,
    onSubmit,
}: {
  traders: Trader[];
  customers: Customer[];
    pairs: TraderCustomer[];
  onEditField: (
    key: string,
    value?: string | undefined | null,
  ) => void;
  onSubmit: (formData: typeof AssignTraderCustomerValidation._type) => Promise<boolean>;
}) {
  const fields = useBearStore((state) => state.assignModalData);
  if (!fields) return null;

  const isSubmittingDisabled = !isAssignTraderFormValid(fields);

  const pairedIdList = pairs.map(pair => pair.customer_id);
  const chooseableCustomers = customers.filter(customer => !pairedIdList.includes(customer.id))

    return (
        <form
            onSubmit={(e) => {
                if (e?.preventDefault) e.preventDefault();
                return onSubmit(fields as any);
            }}
        >
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 items-start gap-4 content-center">
                <div className="grid grid-cols-2 gap-3 col-span-4 content-center">
                    <BearInput
                        tabIndex={0}
                        required
                        disabled
                        name="assign_trader_trader"
                        label="Trader"
                        value={traders.find(trader => trader.id === fields.trader_id)?.name || ""}
                        onChange={(event) => {}}
                    />
                    <BearSelectInput
                        required
                        tabIndex={1}
                        name="assign_customer_id"
                        label="Customer"
                        value={fields.customer_id || ""}
                        onValueChange={(id: string | undefined) => onEditField("customer_id", id)}
                        options={chooseableCustomers.map((customer: Customer) => ({
                            value: customer.id,
                            label: customer.long_name,
                        }))}
                    />
                </div>

                <div className="grid grid-cols-1 place-content-between gap-2 col-span-4">
                    <div className="flex flex-row justify-between">
                        <DialogClose asChild>
                            <Button type="button" variant="secondary" className="col-start-1">
                                Close
                            </Button>
                        </DialogClose>

                        <Button
                            type="submit"
                            size="sm"
                            className="px-3 col-start-4"
                            disabled={isSubmittingDisabled}
                        >
                            {"Create"}
                        </Button>
                    </div>
                </div>
            </div>
        </form>
    );
}

export function TraderForm({
    onEditField,
    onSubmit,
}: {
  onEditField: (
    key: string,
    value?: string | undefined | null,
  ) => void;
  onSubmit: (formData: typeof CreateTraderValidation._type) => Promise<boolean>;
}) {
  const fields = useBearStore((state) => state.createTraderData);
  if (!fields) return null;

  const isSubmittingDisabled = !isTraderFormValid(fields);

  return (
      <form
          onSubmit={(e) => {
              if (e?.preventDefault) e.preventDefault();
              return onSubmit(fields as any);
          }}
      >
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 items-start gap-4 content-center">
              <div className="grid grid-cols-2 gap-3 col-span-4 content-center">
                  <BearInput
                      tabIndex={0}
                      required
                      name="trader_create_initials"
                      label="Initials"
                      value={fields.initials?.toLocaleUpperCase()}
                      onChange={(event) => onEditField("initials", event.target.value?.toLocaleUpperCase())}
                  />
                  <BearInput
                      tabIndex={1}
                      required
                      name="trader_create_name"
                      label="Name"
                      value={fields.name}
                      onChange={(event) => onEditField("name", event.target.value)}
                  />
              </div>

              <div className="grid grid-cols-1 place-content-between gap-2 col-span-4">
                  <div className="flex flex-row justify-between">
                      <DialogClose asChild>
                          <Button type="button" variant="secondary" className="col-start-1">
                              Close
                          </Button>
                      </DialogClose>

                      <Button
                          type="submit"
                          size="sm"
                          className="px-3 col-start-4"
                          disabled={isSubmittingDisabled}
                      >
                          {"Create"}
                      </Button>
                  </div>
              </div>
          </div>
      </form>
  );
}

function isTraderFormValid(formData: any) {
    return !!CreateTraderValidation.safeParse(formData).success;
}

function isAssignTraderFormValid(formData: any) {
    return !!AssignTraderCustomerValidation.safeParse(formData).success;
}

function BearInput({
    disabled,
    placeholder,
  value,
  onChange,
  name,
  required,
  label,
  tabIndex,
  className,
}: {
    disabled?: boolean;
    placeholder?: string;
    value: string;
  onChange: (event: any) => void;
  name: string;
  required?: boolean;
  label?: string;
  tabIndex?: number;
  className?: string;
}) {
  return (
    <div className="grid items-center gap-1.5">
      {label && (
        <Label
          htmlFor={name}
          className="flex flex-row justify-between text-base items-center"
        >
          <span className="font-semibold">{label}</span>
          {required ? (
            <span
              className="text-xs font-light"
              style={{ fontStyle: "italic" }}
            >
              Required
            </span>
          ) : (
            <span></span>
          )}
        </Label>
      )}
      <Input
          disabled={disabled}
        value={value}
        id={name}
        type="text"
        required={required}
        onChange={onChange}
        className={cn(
          "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6",
        )}
        tabIndex={tabIndex}
          placeholder={placeholder}
      />
    </div>
  );
}

function BearSelectInput({
  value,
  onValueChange,
  options,
  name,
  required,
  label,
  tabIndex,
}: {
  value: string;
  onValueChange: (item: string) => void;
  options: { value: string; label: string }[];
  name: string;
  required?: boolean;
  label?: string;
  tabIndex?: number;
}) {
  return (
    <div className="grid items-center gap-1.5">
      {label && (
        <Label
          htmlFor={name}
          className="flex flex-row justify-between text-base items-center"
        >
          <span className="font-semibold">{label}</span>
          {required ? (
            <span
              className="text-xs font-light"
              style={{ fontStyle: "italic" }}
            >
              Required
            </span>
          ) : (
            <span></span>
          )}
        </Label>
      )}

      <Combobox
        tabIndex={tabIndex}
        isModal
        className="w-full"
        value={value}
        onValueChange={onValueChange}
        options={options}
      />
    </div>
  );
}

export function createKeyMap<T extends Record<string, any>>(key: string, items: T[]) {
    return items.reduce((acc, item: any) => {
        acc[item[key]] = true;
        return acc;
    }, {} as any);
}

function UnassignTrader(data: { trader_id: string, customer_id: string }) {
    const { toast } = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const unassignTraderPair = useUnassignTraderPair();

  const onUnassign = async (data: { trader_id: string, customer_id: string }) => {
    if (isLoading) return;
    setIsLoading(true);

    const result = await unassignTraderPair(data);
    setIsLoading(false);

    if (!result.success) {
      toast({
        title: "Failed to unassign",
        description: "",
      });
    } else {
        toast({
            title: "Removed",
            description: "",
        });
    }
  };

  return (
    <Button
      variant="ghost"
      className="h-6 w-6 p-0"
      onClick={() => onUnassign(data)}
      disabled={isLoading}
    >
      <span className="sr-only">Unassign</span>
      {isLoading ? <Loader /> : <ArchiveXIcon className="h-4 w-4" />}
    </Button>
  );
}
