import { parseExcelDateToTimezone, safeParseDecimal } from "../excel";
import { CommonDestinationTimezone } from "../utils";

export const keyMappings = {
  "Location.long_name": "Location",
  "purchase_sale_type": "P/S/O",
  "contract_type": "Cont",
  "shipment_start_date": "Start Date",
  "shipment_end_date": "End Date",
  "commodity": "Commodity",
  "Customer.client_id": "Id",
  "Trader.initials": "Trader",
  "futures": "Futures",
  "basis": "Basis",
  "quantity": "Quantity",
  "option_month": "Month",
  "price_uom": "Pr  UOM",
  "currency": "Cur",
  "contract_date": "Cont  Date",
  "MarketZone.market_zone": "Market Zone",
};

export const baseOptions = {
    keyMappings,
    trimStrings: true,
    aOverride: {
        parseToNumberKeys: ["futures", "basis", "quantity"],
    },
    bOverride: {
        parseToNumberKeys: ["Futures", "Basis", "Quantity"],
        parseToDateKeys: {},
        parseDateToChicagoDateKeys: ["Cont  Date", "End Date", "Start Date"],
        convertFunctionKeys: {
            "Start Date": parseExcelDate,
            "End Date": parseExcelDate,
            "Cont  Date": parseExcelDate,
            "Month": (item: any) => {
                if (!item) return item;
                return item.slice(-2);
            },
            "Cont": (item: any) => item,
            "P/S/O": (item: any) => {
                switch(item) {
                    case "Purch.":
                        return "P";
                    case "Sale.":
                        return "S";
                    case "Offer.":
                        return "O";
                    default:
                        return item;
                }
            },
        }
    }
};

export function parseExcelDate(item: any) {
  if (!item) return item;
  return parseExcelDateToTimezone(item, CommonDestinationTimezone)
}
