import { create } from "zustand";
import { useState } from "react";
import moment from "moment-timezone";
import { GenerateLoadingOrError, genFlatOptionList, genIdOptionList, CommonDestinationTimezone, createDateLabel } from "../../lib/utils";
import {
  CommodityOptions,
  Commodities,
} from "../../lib/api/commodity";
import {
    InputRequiredStatus,
    InputFormType,
    InputType,
    ImportType,
    ExportType,
    InputFilter,
    InputRequiredStatusType,
} from "../../lib/form";
import {
    useSendNotifications,
    useSendTestNotifications,
    NotificationTypeOptions,
    NotificationSubTypeOptions,
    NotificationType,
    NotificationSubType,
} from "../../lib/api/notification";
import { reduceArrayTo, formulateOptionMonths } from "../../lib/api/common";
import * as FormHelper from "../../lib/form";
import * as FormLayout from "../../lib/form/layout";
import {
    PrimitiveGridLayout,
    PrimitiveManagedTable,
    PrimitiveDialogForm,
} from "../../lib/form/layout";
import { Button } from "../../components/ui/button";
import { useToast } from "../../components/ui/use-toast";
import { FormGridHelper } from "../reports/helper";


export const BaseFormSchema = {
    title: { label: "Title", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Required },
    description: { label: "Description", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Optional },

    notification_type: { label: "Type", options_reference_id: "NotificationTypeOptions", input_type: InputType.Enum, enum: NotificationType, input_type_validation: InputFormType.Enum(NotificationType), required_status: InputRequiredStatus.Required },
    notification_sub_type: { label: "Sub Type", options_reference_id: "NotificationSubTypeOptions", input_type: InputType.Enum, enum: NotificationSubType, input_type_validation: InputFormType.Enum(NotificationSubType), required_status: InputRequiredStatus.Optional },

    is_splash: { label: "Is Splash Popup", input_type: InputType.Boolean, input_type_validation: InputFormType.Boolean, required_status: InputRequiredStatus.Required },

    is_test_run: { label: "Send Test Notification", input_type: InputType.Boolean, input_type_validation: InputFormType.Boolean, required_status: InputRequiredStatus.Required },
};

const createState = FormHelper.generateBaseState({
    schemas: [BaseFormSchema],
    baseSchema: BaseFormSchema,
});

export function ClientNotificationsDashboard() {
    const loadingOrErrorUi = GenerateLoadingOrError([]);
    const sendNotifications = useSendNotifications();
    const sendTestNotifications = useSendTestNotifications();

    if (loadingOrErrorUi) {
         return (
             <section className="flex min-h-full flex-1 flex-col justify-start px-6 py-6 lg:px-8">
                <h1 className="scroll-m-20 text-2xl font-extrabold tracking-tight lg:text-3xl">
                    Notifications Dashboard
                </h1>
                {loadingOrErrorUi}
            </section>
        );
    }

    const onSubmitTo = async (data: any) => {
        const fullData: any = {
            ...data,
            meta_data: {
                image_path_document_id: '387869ce-03c7-4062-8072-ac1061c7fb24'
            }
        };

        if (data.is_test_run) {
            return sendTestNotifications(fullData);
        } else {
            return sendNotifications(fullData);
        }
    };

    return (
        <section className="flex min-h-full flex-1 flex-col justify-start px-6 py-6 lg:px-8">
            <h1 className="scroll-m-20 text-2xl font-extrabold tracking-tight lg:text-3xl">
                Notifications Dashboard
            </h1>
            <div className="flex justify-end gap-4 mb-4">
                <FormGridHelper
                    onSuccess={() => {}}
                    state={createState}
                    activeSchema={BaseFormSchema}
                    id={"DownloadNotificationForm"}
                    externalReferences={{
                        NotificationTypeOptions,
                        NotificationSubTypeOptions,
                    }}
                    failMessage="Failed to send notifications"
                    successMessage="Queued"
                    onSubmitTo={onSubmitTo}
                    onCloseForm={() => {}}
                    preventSubmitClose={true}
                />
            </div>
        </section>
    );
}
