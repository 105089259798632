import { CommonDestinationTimezone } from "./utils";
import moment from "moment-timezone";

export function formatDateWithoutTimezone(date: Date): string {
    // Extract the components of the date
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() returns 0-based month
    const day = String(date.getDate()).padStart(2, '0');

    // Format the date as YYYY/MM/DD
    const formattedDate = `${year}/${month}/${day}`;

    return formattedDate;
}

export function formatDateWithoutTimezoneToDate(date: Date): Date {
    // Extract the components of the date
    return moment.tz(formatDateWithoutTimezone(date), "YYYY/MM/DD", CommonDestinationTimezone).toDate()
}
