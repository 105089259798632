import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Trash2 as Trash2Icon, Check as CheckIcon } from "lucide-react";
import { useState } from "react";
import moment from "moment-timezone";
import { Label } from "../../components/ui/label";
import { Loader } from "../../components/ui/loader";
import { Input } from "../../components/ui/input";
import {
  TicketRunSummary,
  TicketsSummary,
  Provider,
} from "../../lib/models/index";
import {
  useListQueuedDocumentsFor,
  useDeleteQueuedDocument,
  DocumentQueue,
  CommonDestinationTimezone,
  QueuedDocumentErrorType,
} from "../../lib/api/document";
import { processFromFiles } from "../../lib/transactionReports";
import {
  TicketProviderSummary,
  TicketProviderRecentRunPartial,
  TicketProviderBatchDownloadPartial,
} from "../../lib/models/tickets";
import { Button } from "../../components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../components/ui/dropdown-menu";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../components/ui/tabs";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/ui/table";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../components/ui/accordion";
import { Skeleton } from "../../components/ui/skeleton";
import { useToast } from "../../components/ui/use-toast";
import { DataTable, DataTableProps } from "../../components/ui/dataTable";

export function QueuedDocumentsReport() {
  const jobsRes = useListQueuedDocumentsFor();

  if ([jobsRes].some((result) => result.isLoading)) {
    return (
      <div className="flex flex-col space-y-2">
        <Skeleton className="h-4 w-[250px]" />
        <Skeleton className="h-4 w-[200px]" />
      </div>
    );
  }

  if ([jobsRes].some((result) => result.error || !result?.data?.success)) {
    return (
      <div className="flex flex-col space-y-2">
        Failed to fetch queued documents. Please refresh and try again
      </div>
    );
  }

  return <ReportView queuedDocuments={jobsRes?.data?.data || []} />;
}

function ReportView({ queuedDocuments }: { queuedDocuments: DocumentQueue[] }) {
  const sessionPeriodsColumns: ColumnDef<any>[] = [
    {
      id: "customer",
      header: "Customer",
      cell: ({ row }) => (
        <span className="">{row.original.Customer?.client_id}</span>
      ),
      enableSorting: false,
      enableHiding: false,
    },
    {
      id: "documentFilename",
      header: "Filename",
      cell: ({ row }) => (
        <span className="">{row.original.Document.filename}</span>
      ),
      enableSorting: false,
      enableHiding: false,
    },
    {
      id: "queueReceievedFile",
      header: "Received",
      cell: ({ row }) => (
        <span className="">
          {row.original.created_at
            ? moment
                .tz(row.original.created_at, CommonDestinationTimezone)
                .format("MMM DD, YY HH:mm a")
            : ""}
        </span>
      ),
      enableSorting: false,
      enableHiding: false,
    },
    {
      id: "validation",
      header: "Validation",
      cell: ({ row }) => (
        <span className="">
          {row.original.validationMessage ? (
            row.original.validationMessage
          ) : (
            <CheckIcon size={16} />
          )}
        </span>
      ),
      enableSorting: false,
      enableHiding: false,
    },
    {
      id: "rejectDocument",
      header: "Remove",
      cell: ({ row }) => (
        <div className="flex flex-row">
          <DeleteQueuedDocument queuedDocumentId={row.original.id} />
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="container flex flex-col mb-2">
        <h1 className="scroll-m-20 text-2xl font-extrabold tracking-tight lg:text-3xl">
          Queued Documents
        </h1>
        <p className="text-muted-foreground">
          Contract signature documents queued to send at midnight.
        </p>
      </div>
      <div className="flex items-start flex-col h-full mt-4">
        <div className="container flex flex-col gap-4">
          <DataTable columns={sessionPeriodsColumns} data={queuedDocuments} />
        </div>
      </div>
    </>
  );
}

function DeleteQueuedDocument({
  queuedDocumentId,
}: {
  queuedDocumentId: string;
}) {
  const { toast } = useToast();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const deleteQueuedDocument = useDeleteQueuedDocument();

  const onDeleteFile = async (queuedDocumentId: string) => {
    if (isSubmitting) return;

    setIsSubmitting(true);

    try {
      deleteQueuedDocument(queuedDocumentId);
      toast({
        title: "Deleted",
        description: "",
      });
    } catch (error: any) {
      toast({
        title: "Failed to delete queued document",
        description: "Please refresh and try again",
      });
    }

    setIsSubmitting(false);
  };

  return (
    <Button
      variant="ghost"
      className="h-8 w-8 p-0"
      onClick={() => onDeleteFile(queuedDocumentId)}
      disabled={isSubmitting}
    >
      <span className="sr-only">Remove</span>
      {isSubmitting ? <Loader /> : <Trash2Icon className="h-4 w-4" />}
    </Button>
  );
}
