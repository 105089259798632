import React from "react";
import ReactDOM from "react-dom/client";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { StytchB2BProvider } from "@stytch/react/b2b";
import { StytchB2BUIClient } from "@stytch/vanilla-js/b2b";

import "./styles/globals.css";
import "./index.css";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount: number, error: any) => {
        return false; // failureCount < 3
      },
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

if (!process.env.REACT_APP_STYTCH_PUBLIC_TOKEN) {
  throw new Error("Missing auth token");
}

const IS_LOCAL = process.env["REACT_APP_IS_LOCAL"] === "true";

const prodCookieOptions = {
  cookieOptions: {
    path: "/",
    availableToSubdomains: true,
    domain: "lighthouse-internal.com",
  },
};

const stytch = new StytchB2BUIClient(
  process.env.REACT_APP_STYTCH_PUBLIC_TOKEN || "",
  IS_LOCAL ? undefined : prodCookieOptions,
);

root.render(
  <React.StrictMode>
    <StytchB2BProvider stytch={stytch}>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </StytchB2BProvider>
    ,
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
