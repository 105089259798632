import { useQuery, useQueryClient, QueryKey } from "@tanstack/react-query";
import Decimal from "decimal.js";
import { BaseDbObject } from "../models/db";
import { Customer } from "./reports";
import { z } from "zod";
import moment, { Moment } from "moment-timezone";
import {
  fetchApiDelete,
  fetchApiGet,
  fetchApiPost,
  fetchApiPut,
} from "./utils";
import {
  PossibleReturnType,
} from "../responseResults";
import { CommonDestinationTimezone } from "../utils";
import { formatDateWithoutTimezone } from "./date";

export interface BaseInfo {
    customers: {
        id: string;
        client_id: string;
        long_name: string;
    }[]
    market_zones: {
        id: string;
        dtn_market_zone: string;
        customer_id: string;
    }[]
}

export interface StagedRowData {
    shipment_start_date: Date;
    shipment_end_date: Date;

    destination_id: string;
    commodity: string;

    market_zone_id: string;

    producer_or_freight_id: string;
    is_enabled: boolean;
}

export interface HistoricalData {
    id: string;
    destination_id: string;
    message: string;
    send_at: string;

    DestinationMessageReceivers: HistoricalMessageReceiver[];
}

export interface HistoricalMessageReceiver extends BaseDbObject {
  destination_message_id: string;
  receiver_id: string;
  commodity: string;
}

export interface StagedRow {
    data: StagedRowData,
    user_id: string;
    id: string;
}

export const FilterValidation = z.object({
    shipment_start: z.string().optional(),
    shipment_end: z.string(),

    destination_id: z.string().optional(),
    commodity: z.string().optional(),
});

export type FilterOptions = z.infer<typeof FilterValidation>;

export function useListBaseData() {
  const queryParams = new URLSearchParams();

  return useQuery({
    queryKey: ["destination", "base_data"],
    queryFn: () =>
      fetchApiGet<BaseInfo[]>("destination/messaging/base", queryParams),
    retry: 1,
  });
}

export function useListHistoricalData(send_date?: string) {
  const queryParams = new URLSearchParams();
  if (send_date) {
    queryParams.append("sent_date", send_date);
  }

  return useQuery({
    queryKey: ["destination", "historical", queryParams.toString()],
    queryFn: () => fetchApiGet<HistoricalData[]>("destination/messaging/history", queryParams),
    enabled: !!send_date,
    retry: 1,
  });
}

export function useListStagedData(filters: typeof FilterValidation._type) {
  const queryParams = new URLSearchParams();
  queryParams.append("filters", JSON.stringify(filters))

  return useQuery({
    queryKey: ["destination", "staged", queryParams.toString()],
    queryFn: () =>
      fetchApiGet<{ data: StagedRow[]; exceptions: any[] }>("destination/messaging/staged", queryParams),
    enabled: !!filters.shipment_end,
    retry: 1,
  });
}

export function useCreateUploadJob() {
  const queryClient = useQueryClient();

  return async (body: any) => {
    const queryParams = new URLSearchParams();

    const result = await fetchApiPost<any>(
      `destination/messaging/upload/presign`,
      body,
      queryParams,
    );

    queryClient.invalidateQueries({ queryKey: ["destination", "staged"] });

    return result;
  };
}

export function useUpdateEnabledStatus() {
  const queryClient = useQueryClient();

  return async (id: string, enabled: boolean) => {
    const queryParams = new URLSearchParams();

    const result = await fetchApiPost<any>(
      `destination/messaging/staged/${id}/status`,
      { id, enabled },
      queryParams,
    );

    queryClient.invalidateQueries({ queryKey: ["destination", "staged"] });

    return result;
  };
}

export function useSendMessage() {
  const queryClient = useQueryClient();

  return async ({ message, destination_id, commodities, scheduledDate }: { message: string; destination_id: string; commodities: string[], scheduledDate?: string }) => {
    const queryParams = new URLSearchParams();

    const result = await fetchApiPost<any>(
      `destination/messaging/staged/${destination_id}/send`,
      { destination_id, message, commodities, send_at: scheduledDate },
      queryParams,
    );

    queryClient.invalidateQueries({ queryKey: ["destination", "staged"] });
    queryClient.invalidateQueries({ queryKey: ["destination", "historical"] });

    return result;
  }
} 
