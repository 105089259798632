import { useQuery, useQueryClient } from "@tanstack/react-query";
import { z as zod } from "zod";
import { fetchApiDelete, fetchApiGet, fetchApiPost, fetchApiPut } from "./utils";
import { Trader, TraderCustomer } from "./reports";

export const CreateTraderValidation = zod.object({
  initials: zod.string().min(2).trim(),
  name: zod.string().min(2).trim(),
});

export const AssignTraderCustomerValidation = zod.object({
  trader_id: zod.string().uuid(),
  customer_id: zod.string().uuid(),
});


export function useListTraders() {
  const queryParams = new URLSearchParams();

  return useQuery({
    queryKey: ["baseTraders"],
    queryFn: () => fetchApiGet<Trader[]>("trader", queryParams),
    retry: 1,
  });
}

export function useListTradersCustomerPairs() {
  const queryParams = new URLSearchParams();

  return useQuery({
    queryKey: ["baseTraderCustomerPairs"],
    queryFn: () => fetchApiGet<TraderCustomer[]>("trader/customer/pairs", queryParams),
    retry: 1,
  });
}


export function useCreateTrader() {
  const queryClient = useQueryClient();
  const queryParams = new URLSearchParams();

  return async (data: typeof CreateTraderValidation._type) => {
    const result = await fetchApiPost<Trader>(
      "trader",
      data,
      queryParams,
    );

    queryClient.invalidateQueries({ queryKey: ["baseTraders"] });
    return result;
  };
}

export function useAssignTrader() {
  const queryClient = useQueryClient();
  const queryParams = new URLSearchParams();

  return async (data: typeof AssignTraderCustomerValidation._type) => {
    const result = await fetchApiPut<boolean>(
      `trader/${data.trader_id}/assign/${data.customer_id}`,
      data,
      queryParams,
    );

    queryClient.invalidateQueries({ queryKey: ["baseTraderCustomerPairs"] });
    return result;
  };
}

export function useUnassignTraderPair() {
  const queryClient = useQueryClient();
  const queryParams = new URLSearchParams();

  return async (data: typeof AssignTraderCustomerValidation._type) => {
    const result = await fetchApiDelete<boolean>(
      `trader/${data.trader_id}/assign/${data.customer_id}`,
      queryParams,
    );

    queryClient.invalidateQueries({ queryKey: ["baseTraderCustomerPairs"] });
    return result;
  };
}
