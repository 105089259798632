import { Provider } from "./provider";
import { BaseDbObject } from "./db";

export enum TicketJobStatus {
  InProgress = "in_progress",
  Queued = "queued",
  ParsedIntermedaries = "parsed_intermedaries",
  ParsedPdf = "parsed_pdf",
  Success = "success",
  Failed = "failed",
}

export enum TicketRunType {
  WeightTicket = "weight_ticket",
  TradeTicket = "trade_ticket",
}

export interface TicketRun {
  id: string;
  created_at: Date;
  updated_at: Date;

  fetched: Date;
  provider_id: string;
  meta_data: object;

  Provider?: Provider;
}

export interface NdMillsTicketRunMetaData {
  attachment: { key: string; bucket: string };
  date: string;
  runType: TicketRunType;
}

export interface HylifeTicketRunMetaData {
  attachment: { key: string; bucket: string };
  runType: TicketRunType;
}

export interface AdmTicketRunMetaData {
  dateRange: { from: string; to: string };
  runType: TicketRunType;
}

export interface ViterraTicketRunMetaData {
  dateRange: { from: string; to: string };
  runType: TicketRunType;
}

export interface RjoTicketRunMetaData {
  dateRange: { fileDate: string };
  file: string;
  runType: TicketRunType;
}

export type CreateTicketRun = Omit<TicketRun, "created_at" | "updated_at">;
export type TicketRunMetadata =
  | AdmTicketRunMetaData
  | ViterraTicketRunMetaData
  | RjoTicketRunMetaData;

export interface TicketProviderRecentRunPartial {
  id: string;
  fetched: Date;
  meta_data: TicketRunMetadata;
}
export interface TicketProviderBatchDownloadPartial {
  id: string;
  downloaded_date: Date;
  user: { id: string; name: string };
  ticketCount: number;
}

export interface TicketProviderSummary {
  provider: Provider;
  undownloadedCount: number;
  netNewCount: number;
  updatedCount: number;
  recentRuns: TicketProviderRecentRunPartial[];
  recentBatchDownloads: TicketProviderBatchDownloadPartial[];
}

export interface TicketJob extends BaseDbObject {
  ticket_run_id: string;

  email_document_id?: string;
  ticket_document_id?: string;

  last_computed_status?: TicketJobStatus;

  start_datetime: Date;
  meta_data?: { source: string };

  TicketRun?: TicketRun;
  TicketJobAcks?: TicketJobAck[];

  TicketDocument?: SignableDocument;
  EmailDocument?: Document;
}

export interface Document {
  id: string;
  created_at: Date;
  updated_at: Date;

  filename: string;
  key: string;
  bucket: string;
}

export interface SignableDocument extends Document {
  signedGetLink?: string;
}

export interface TicketJobAck extends BaseDbObject {
  ticket_job_id: string;

  status_on_ack?: string;
  user_id: string;
}
