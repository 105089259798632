import ExcelJS from "exceljs";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useState } from "react";
import moment from "moment-timezone";
import { Label } from "../../components/ui/label";
import { Loader } from "../../components/ui/loader";
import { Input } from "../../components/ui/input";
import {
  TicketRunSummary,
  TicketsSummary,
  Provider,
} from "../../lib/models/index";
import {
  TicketProviderSummary,
  TicketProviderRecentRunPartial,
  TicketProviderBatchDownloadPartial,
} from "../../lib/models/tickets";
import { Button } from "../../components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../components/ui/dropdown-menu";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../components/ui/tabs";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/ui/table";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../components/ui/accordion";
import { processFromFiles } from "../../lib/freightReport";
import { downloadBinaryData } from "../../lib/browser/download";

export function SalesAndPurchaseReports() {
  return <ReportUploadView />;
}

export function ReportUploadView({}: {}) {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [newPurchaseReportFile, setNewPurchaseReportFile] = useState<
    File | undefined
  >(undefined);
  const [newSalesReportFile, setNewSalesReportFile] = useState<
    File | undefined
  >(undefined);

  const onUploadFile = async () => {
    if (!newPurchaseReportFile && !newSalesReportFile) {
      return;
    }

    if (isSubmitting) return;
    setIsSubmitting(true);

    const wb = await processFromFiles({
      purchaseReportFile: newPurchaseReportFile,
      salesReportFile: newSalesReportFile,
    });
    const date = moment().format("YYYY_MM_DDTHH:mm");
    const wbBuffer = await wb.xlsx.writeBuffer();
    downloadBinaryData(
      wbBuffer,
      `freight_report_${date}.xlsx`,
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    );

    setIsSubmitting(false);
  };

  const onPurchaseFileChange = (...params: any[]) => {
    const item = params[0];
    setNewPurchaseReportFile(item.target.files[0]);
  };
  const onSalesFileChange = (...params: any[]) => {
    const item = params[0];
    setNewSalesReportFile(item.target.files[0]);
  };

  return (
    <>
      <div className="container flex flex-col mb-2">
        <h1 className="scroll-m-20 text-2xl font-extrabold tracking-tight lg:text-3xl">
          Upload Sales and Purchase Reports
        </h1>
      </div>
      <div className="flex items-start flex-col h-full mt-4">
        <div className="container flex flex-col gap-4">
          <div className="grid w-full max-w-sm items-center gap-1.5">
            <Label htmlFor="uploadPurchsaeTicketFile">Purchase Report</Label>
            <Input
              id="uploadPurchsaeTicketFile"
              type="file"
              accept=".csv"
              onChange={onPurchaseFileChange}
            />
          </div>
          <div className="grid w-full max-w-sm items-center gap-1.5">
            <Label htmlFor="uploadSalesTicketFile">Sales Report</Label>
            <Input
              id="uploadSalesTicketFile"
              type="file"
              accept=".csv"
              onChange={onSalesFileChange}
            />
          </div>
          <div className="grid w-full max-w-sm items-center gap-1.5 mt-2">
            <Button
              disabled={
                isSubmitting || (!newPurchaseReportFile && !newSalesReportFile)
              }
              id="uploadButton"
              onClick={onUploadFile}
            >
              {isSubmitting ? <Loader /> : "Process"}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
