import { PossibleReturnType, isRetryable } from "./responseResults";

interface AutoSaveOptions {
  waitXMillsecondsOnRequestFinish: number;
  retryAttempt: number;
}

const DEFAULT_AUTO_SAVE_OPTIONS: AutoSaveOptions = {
  waitXMillsecondsOnRequestFinish: 30,
  retryAttempt: 1,
};

// Optimistic automated data saver
// NOTE: assumes this is saving the same asset over and over (so it is safe to skip to latest update)
// NOTE: This is meant to be used in a view which lacks a save button and saves something automatically.
// It will save one type of data and function as described:
// When a change is pushed, if not currently submitting a request it will make a request to a backend to
// save it.
// If a change is in flight, it will enqueue it. If another change is already enqueued it will be dequeued
// and the newest change will be added.
// Depth of waiting changes: 1
// NOTE: Assumes changes pushed are ordered (always the latest).
// NOTE: If the api request fails, it will silently fail.
//   Unless a retry is specified, then it will notify on last failure
// NOTE: if there is another change waiting it will progress.
//   If not change is waiting, it may* retry up to N times.
// This does not operate on an interval, it operates purely as data is pushed into its array.
// So its effectively limited to how network speed + api processesing + new data enqueued.
// NOTE: api request must not throw an error
// Does not take into account server 429

export class ApiAutoSaver<T, V> {
  data = [] as T[];
  options = DEFAULT_AUTO_SAVE_OPTIONS;
  apiRequest: (arg0: T) => Promise<PossibleReturnType<V>>;
  inFlight = false;

  constructor(
    startingData: T[],
    apiRequest: (arg0: T) => Promise<PossibleReturnType<V>>,
    options?: AutoSaveOptions,
  ) {
    this.data = startingData;
    if (options) this.options = options;
    this.apiRequest = apiRequest;
  }

  public enqueueData(newData: T) {
    switch (this.data.length) {
      case 0:
        this.data.push(newData);
        this.fetchNextItem();
        break;
      case 1:
        this.data.push(newData);
        break;
      case 2:
        this.data.splice(-1, 1, newData);
        break;
    }
  }

  private dequeueFirstItem() {
    this.data.splice(0, 1);
    return true;
  }

  // NOTE: retriedCount represents the number of tries a request has been attempted
  private async invokeAfterFetchOptions<T>({
    retriedCount,
    response,
  }: {
    retriedCount: number;
    response: PossibleReturnType<V>;
  }): Promise<boolean> {
    // Wait X milliseconds after each request
    // If 0 or undefined, will still wait till next event cycle
    await new Promise((res) =>
      setTimeout(res, this.options.waitXMillsecondsOnRequestFinish),
    );

    // It succeeded, lets move on
    if (response?.success) return this.dequeueFirstItem();

    // It didnt succeed but there is another request to process, lets move on
    if (this.data.length > 1) return this.dequeueFirstItem();

    if (!this.options.retryAttempt) return this.dequeueFirstItem();
    if (retriedCount >= this.options.retryAttempt)
      return this.dequeueFirstItem();

    if (!isRetryable(response)) return this.dequeueFirstItem();

    this.inFlight = true;
    const retriedResponse = await this.apiRequest(this.data[0]);
    this.inFlight = false;

    return this.invokeAfterFetchOptions({
      retriedCount: retriedCount + 1,
      response: retriedResponse,
    });
  }

  private async fetchNextItem() {
    if (!this.data.length) return;

    this.inFlight = true;
    const result = await this.apiRequest(this.data[0]);
    this.inFlight = false;

    await this.invokeAfterFetchOptions({ retriedCount: 0, response: result });
    this.fetchNextItem();
  }
}

// TODO We may want to partition this by id later
export class MultiResourceAutoSaver<T, V> {
  data = [] as T[];
  options = DEFAULT_AUTO_SAVE_OPTIONS;
  apiRequest: (arg0: T) => Promise<PossibleReturnType<V>>;
  inFlight = false;

  constructor(
    startingData: T[],
    apiRequest: (arg0: T) => Promise<PossibleReturnType<V>>,
    options?: AutoSaveOptions,
  ) {
    this.data = startingData;
    if (options) this.options = options;
    this.apiRequest = apiRequest;
  }

  public enqueueData(newData: T) {
    switch (this.data.length) {
      case 0:
        this.data.push(newData);
        this.fetchNextItem();
        break;
      case 1:
        this.data.push(newData);
        break;
      default:
        // Typescript somehow thinks findLastIndex isnt real
        const index = (this.data as any).findLastIndex(
          (item: any) => item.id === (newData as any).id,
        );
        if (index >= 1) {
          this.data.splice(index, 1, newData);
        } else {
          this.data.push(newData);
        }
        break;
    }
  }

  private dequeueFirstItem() {
    this.data.splice(0, 1);
    return true;
  }

  // NOTE: retriedCount represents the number of tries a request has been attempted
  private async invokeAfterFetchOptions<T>({
    retriedCount,
    response,
  }: {
    retriedCount: number;
    response: PossibleReturnType<V>;
  }): Promise<boolean> {
    // Wait X milliseconds after each request
    // If 0 or undefined, will still wait till next event cycle
    await new Promise((res) =>
      setTimeout(res, this.options.waitXMillsecondsOnRequestFinish),
    );

    // It succeeded, lets move on
    if (response?.success) return this.dequeueFirstItem();

    if (!this.options.retryAttempt) return this.dequeueFirstItem();
    if (retriedCount >= this.options.retryAttempt)
      return this.dequeueFirstItem();

    if (!isRetryable(response)) return this.dequeueFirstItem();

    this.inFlight = true;
    const retriedResponse = await this.apiRequest(this.data[0]);
    this.inFlight = false;

    return this.invokeAfterFetchOptions({
      retriedCount: retriedCount + 1,
      response: retriedResponse,
    });
  }

  private async fetchNextItem() {
    if (!this.data.length) return;

    this.inFlight = true;
    const result = await this.apiRequest(this.data[0]);
    this.inFlight = false;

    await this.invokeAfterFetchOptions({ retriedCount: 0, response: result });
    this.fetchNextItem();
  }
}
