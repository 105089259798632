import { create } from "zustand";
import { useState } from "react";
import moment from "moment-timezone";
import { GenerateLoadingOrError, genFlatOptionList, genIdOptionList, CommonDestinationTimezone, createDateLabel } from "../../lib/utils";
import {
  CommodityOptions,
  Commodities,
} from "../../lib/api/commodity";
import {
    InputRequiredStatus,
    InputFormType,
    InputType,
    ImportType,
    ExportType,
    InputFilter,
    InputRequiredStatusType,
} from "../../lib/form";
import {
    Uom,
    Currency,
    CostCategory,
    CalculateOn,
    AddDedAdjType,
    RateType,
    ContractType,
    PurchaseSaleType,
    useListGeneralContracts,
    useListLocations,
    useListMarketZones,
    GeneralContract,
    useCreateGeneralContract,
    useUpdateGeneralContract,
    Location, MarketZone,
    purchaseSaleOptions,
    uomOptions,
    currencyOptions,
    generateContractTypesWith,
    calculateOnOptions,
    rateTypeOptions,
    costCategoryOptions,
    addDedADjOptions,
    deliveryTermsOptions,
    DeliveryTerms,
    useDownloadOpenRecentContractData,
    useDownloadOpenImportContractData,
} from "../../lib/api/generalContract";
import { useListTraders } from "../../lib/api/trader";
import { reduceArrayTo, formulateOptionMonths } from "../../lib/api/common";
import { useListCustomers } from "../../lib/api/customer";
import { Trader, Customer } from "../../lib/api/reports";
import * as FormHelper from "../../lib/form";
import * as FormLayout from "../../lib/form/layout";
import {
    PrimitiveGridLayout,
    PrimitiveManagedTable,
    PrimitiveDialogForm,
} from "../../lib/form/layout";
import { Button } from "../../components/ui/button";
import { useToast } from "../../components/ui/use-toast";

export function FormGridHelper({
    hideRelations, onSuccess, state, activeSchema, onSubmitTo, onCloseForm, preventSubmitClose,
    id, externalReferences, failMessage, successMessage,hideSkipButton, preventClearForm
}: {
    hideRelations?: boolean;
    preventSubmitClose?: boolean; id: string; externalReferences: Record<string, any>; failMessage: string; successMessage: string;
    onCloseForm: any; onSubmitTo: any; activeSchema: any; state: any;
    onSuccess?: any; hideSkipButton?: boolean
    preventClearForm?: boolean;
}) {
    const inputState: any = state.useInputsState((state: any) => state);
    const submissionState: any = state.useSubmissionState((state: any) => state);
    const { toast } = useToast();


    return FormLayout.RenderFullPrimitiveGridLayout({
        hideRelations,
        id: id,
        activeSchema,
        totalFields: inputState.totalFields,
        formValues: inputState.formValues,
        updateFormValue: inputState.updateFormValue,
        formRelationships: inputState.formRelationships,
        updateFormRelationship: inputState.updateFormRelationship,
        submissionState,
        externalReferences,
        onCloseForm,
        hideSkipButton,
        onSubmitTo: (item: any) => onSubmitTo(item),
        handleSubmitResponse: (result) => {
            if (!result.success) {
                toast({
                    title: failMessage,
                    description: "",
                });
            } else {
                toast({
                    title: successMessage,
                    description: "",
                });
                if (onSuccess) {
                    onSuccess(result.data);
                }
                if (!preventSubmitClose) {
                    onCloseForm(false);
                }
                if (!preventClearForm) {
                    inputState.clearForm();
                }
            }
        },
        className: "overflow-auto"
    });
}
