import { useQuery, useQueryClient } from "@tanstack/react-query";
import moment from "moment-timezone";
import { S3ObjectItem } from "../models/trading";
import { BaseDbObject } from "../models/db";
import {
  fetchApiDelete,
  fetchApiGet,
  fetchApiPost,
  fetchApiPut,
} from "./utils";

const ENTITY_KEY = "trading";
export const CommonDestinationTimezone = "America/Chicago";

export function useRunBacktest() {
  return async (backtestData: any) => {
    return await fetchApiPost(`trading/backtest`, backtestData);
  };
}

export function useListRecentPeriods() {
  const queryParams = new URLSearchParams();

  return useQuery({
    queryKey: ["listRecentPeriods"],
    queryFn: () =>
      fetchApiGet<S3ObjectItem[]>("trading/ats/periods/recent", queryParams),
    retry: 1,
  });
}

export function useDownloadAtsPeriods() {
  const queryClient = useQueryClient();

  return async (session_id: string, app_type: AtsSessionAppType) => {
    const queryParams = new URLSearchParams();
    queryParams.append("app_type", app_type);

    const result = await fetchApiPost<string[]>(
      `trading/ats/periods/download/${session_id}`,
      {},
      queryParams,
    );
    queryClient.invalidateQueries({ queryKey: ["listRecentPeriods"] });
    return result;
  };
}

export function useListRecentArchives() {
  const queryParams = new URLSearchParams();

  return useQuery({
    queryKey: ["recentAtsArchives"],
    queryFn: () =>
      fetchApiGet<S3ObjectItem[]>("trading/ats/archive/recent", queryParams),
    retry: 1,
  });
}

export function useGetSavedConfigs() {
  const queryParams = new URLSearchParams();

  return useQuery({
    queryKey: ["savedConfigs"],
    queryFn: () =>
      fetchApiGet<AtsSavedConfig[]>("trading/ats/configs/saved", queryParams),
    retry: 1,
  });
}

export function useSaveConfig() {
  const queryClient = useQueryClient();
  const queryParams = new URLSearchParams();

  return async (
    savedConfig: Pick<AtsSavedConfig, "name" | "is_starred" | "full_config">,
  ) => {
    const result = await fetchApiPost<AtsSavedConfig>(
      "trading/ats/configs/saved",
      savedConfig,
      queryParams,
    );
    queryClient.invalidateQueries({ queryKey: ["savedConfigs"] });
    queryClient.invalidateQueries({ queryKey: ["savedConfig"] });
    return result;
  };
}

export function useUpdateConfig() {
  const queryClient = useQueryClient();
  const queryParams = new URLSearchParams();

  return async (
    id: string,
    savedConfig: Partial<Pick<AtsSavedConfig, "name" | "is_starred">>,
  ) => {
    const result = await fetchApiPut<AtsSavedConfig>(
      `trading/ats/configs/saved/${id}`,
      savedConfig,
      queryParams,
    );
    queryClient.invalidateQueries({ queryKey: ["savedConfigs"] });
    queryClient.invalidateQueries({ queryKey: ["updatedConfig"] });
    return result;
  };
}

export function useGetAtsCurrentState() {
  const queryParams = new URLSearchParams();

  return useQuery({
    queryKey: ["atsCurrentState"],
    queryFn: () => fetchApiGet<AtsSession[]>("trading/ats/state", queryParams),
    retry: 1,
  });
}

export function useAtsOptions() {
  const queryParams = new URLSearchParams();

  return useQuery({
    queryKey: ["atsOptions"],
    queryFn: () =>
      fetchApiGet<AtsCurrentPostion[]>("trading/ats/options", queryParams),
    retry: 1,
  });
}

export function useDownloadAtsArchive() {
  const queryClient = useQueryClient();
  const queryParams = new URLSearchParams();

  return async (key: string) => {
    const result = await fetchApiPost<{ url: string }>(
      "trading/ats/archive/download",
      { key },
      queryParams,
    );
    queryClient.invalidateQueries({ queryKey: ["recentAtsArchives"] });
    return result;
  };
}

export function useSubmitAtsCommand() {
  const queryClient = useQueryClient();
  const queryParams = new URLSearchParams();

  return async (command: CreateAtsCommand) => {
    const result = await fetchApiPost<AtsCommand>(
      "trading/ats/command",
      command,
      queryParams,
    );
    queryClient.invalidateQueries({ queryKey: ["tradingAtsCommand"] });
    return result;
  };
}

export interface CreateAtsCommand {
  app_environment: AtsAppEnvironmentType;
  action_type: AtsCommandActionType;
  instrument_id?: string;
  session_id?: string;
  config_id?: string;

  meta_data: { backoff_amount: number } | AtsBlockHour | {};
}

export interface AtsBlockHour {
  absolute_start: string;
  absolute_end: string;
  note: string;
}

export enum AtsCommandActionType {
  ExitPosition = "exit_position",
  EnterPosition = "enter_position",
  TopUpCurrentPosition = "top_up_current_position",
  IssueBackoff = "issue_backoff",
  IssueBuyBackoff = "issue_buy_backoff",
  IssueSellBackoff = "issue_sell_backoff",
  AddBlockHour = "add_block_hour",
  RestorePositionSnapshot = "restore_position_snapshot",
  StartupLoadConfig = "startup_load_config",
  LoadSession = "load_session",
}

export interface SimplePriceBar {
  low: number;
  high: number;
  open: number;
  close: number;
  timestamp: string; // "2024-01-11T13:10:00"
}

export interface AtsSessionLatestGlobalState extends BaseDbObject {
  algo_start_date: string;
  is_market_open: boolean;

  realized_algo_pl: number;
  current_instrument_id: string;

  CurrentInstrument?: AtsInstrument;
}

export enum AtsPositionType {
  Long = "long",
  Short = "short",
  None = "none",
}

export enum AtsAppEnvironmentType {
  Live = "prod",
  Simulation = "staging",
}

export interface AtsCurrentPostion extends AtsPosition {
  position_type: AtsPositionType;
}

export interface AtsSession extends BaseDbObject {
  config_id: string;
  global_id: string;
  app_environment: string;
  all_time_realized?: string;
  recent_events?: AtsOrderEvent[];
  app_type: AtsSessionAppType;

  GlobalState?: AtsSessionLatestGlobalState;
  AtsConfig?: AtsConfig;
  Positions: AtsCurrentPostion[];
}

export interface AtsConfig extends BaseDbObject {
  signal_config: any;
  instrument_config: any;
  backup_time: any;
  working_hours: any;
  block_hours: {
    AbsoluteStart?: string;
    AbsoluteEnd?: string;
    Note?: string;

    absolute_start?: string;
    absolute_end?: string;
    note?: string;
  }[];
}

export interface AtsPosition extends BaseDbObject {
  session_id: string;
  instrument_id: string;
  app_environment: string;

  live_convergence_period: number;
  num_open_positions: number;
  buy_backoff: number;
  sell_backoff: number;
  oco_offset_percentages: number;

  position_profit_loss: number;
  position_average_price: number;
  position_start_date: string;

  AtsPositionOcos?: AtsPositionOco[];
  Instrument?: AtsInstrument;
}

export interface AtsPositionOco extends BaseDbObject {
  position_id: string;

  buy_sell: string;
  order_type: string;
  time_in_force: string;

  oco_type: string;
  order_price: number;

  tt_id: string;
  our_id: string;
  our_opposing_id: string;

  order_quantity: number;
  remaining_quantity: number;

  AtsPosition?: AtsPosition;
}

export interface AtsInstrument extends BaseDbObject {
  contract_alias: string;
  instrument_name: string;

  last_price_bar?: SimplePriceBar;
}

export interface AtsCommand extends BaseDbObject {
  app_environment: AtsAppEnvironmentType;
  action_type: AtsCommandActionType;
  instrument_id?: string;
  session_id?: string;
  config_id?: string;

  block_hour?: AtsBlockHour;
  backoff_amount?: number;
  restore_position_snapshot?: any;
  config?: any;
}

export interface DbUser extends BaseDbObject {
  name?: string;
}

export enum AtsOrderPurchaseType {
  Buy = "Buy",
  Sell = "Sell",
}

export interface AtsOrderEvent extends BaseDbObject {
  session_id: string;
  instrument_id: string;

  event_type: AtsOrderEventType;

  our_id: string;
  tt_id: string;

  oco_type?: OcoStopType;
  app_order_type: AppOrderType;

  message?: string;
  reject_source?: string;

  buy_sell: BuySell;
  order_type: OrderType;
  time_in_force: TimeInForce;

  fill_quantity?: number;
  canceled_quantity?: number;
  order_quantity: number;

  bar_closing_price?: number;
  executed_price?: number;
  order_price?: number;

  event_time?: Date;
  exchange_transaction_time?: Date;
}

export enum TimeInForce {
  GoodTillCancel = "GoodTillCancel",
  ImmediateOrCancel = "ImmediateOrCancel",
}

export enum OrderType {
  Market = "Market",
  Limit = "Limit",
  StopLimit = "StopLimit",
}

export enum AtsOrderEventType {
  OrderProfileSent = "OrderProfileSent",
  OrderProfileFail = "OrderProfileFail",
  OrderRejected = "OrderRejected",
  OrderFilled = "OrderFilled",
  OrderDeleted = "OrderDeleted",
  OrderAdded = "OrderAdded",
  OrderUpdated = "OrderUpdated",
  OrderPendingAction = "OrderPendingAction",
  OrderTimeout = "OrderTimeout",
  OrderStatusUnknown = "OrderStatusUnknown",

  PositionSnapshot = "PositionSnapshot",
}

export enum OcoStopType {
  STOP_LOSS = "STOP_LOSS",
  STOP_GAIN = "STOP_GAIN",
}

export enum BuySell {
  Buy = "Buy",
  Sell = "Sell",
}

export enum AppOrderType {
  TopUP = "TopUp",
  EnterPosition = "EnterPosition",
  ClosePosition = "ClosePosition",
  Oco = "OCO",
}

export interface AtsSavedConfig extends BaseDbObject {
  name?: string;
  full_config: any;
  is_starred: boolean;
  user_id: string;

  User?: DbUser;
}

export enum AtsSessionAppType {
  Live = "live",
  NonLive = "non_live",
}

