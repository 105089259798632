import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  fetchApiDelete,
  fetchApiGet,
  fetchApiPost,
  fetchApiPut,
} from "./utils";
import {
  AnalystCalendarSummary,
  AnalystAnalysis,
  AnalystRecommendationType,
  AnalystRecommendation,
} from "../models/analystRecommendation";

const ENTITY_KEY = "analystRecommendations";

export enum ProjectFullName {
  HedgeBeacon = "hedge_beacon",
  MarketThoughts = "hedge_beacon_market_thoughts",
  CodetiTest = "codeti_test",
  LighthouseTest = "lighthouse_test",
}

export function useUpsertRecommendation() {
  const queryClient = useQueryClient();

  return async (data: any) => {
    if (!data?.id) throw new Error("Failed to upsert. Invalid recommendation.");
    const result = await fetchApiPost<AnalystRecommendation>(
      `analyst/recommendation`,
      data,
    );
    queryClient.invalidateQueries({ queryKey: [ENTITY_KEY], exact: true });
    queryClient.invalidateQueries({ queryKey: [ENTITY_KEY, data.id] });
    return result;
  };
}

export function usePublishMarketThoughts() {
  const queryClient = useQueryClient();

  return async ({
    details,
    projectName,
  }: {
    details: string;
    projectName: ProjectFullName;
  }) => {
    if (!details)
      throw new Error("Failed to publish. Market thoughts was empty.");
    const result = await fetchApiPost<any>(
      `analyst/recommendation/publish/market_thoughts`,
      { details, projectName },
    );
    return result;
  };
}

export function usePublishRecommendations() {
  const queryClient = useQueryClient();

  return async (data: {
    published: boolean;
    recommendationIds: string[];
    projectName: ProjectFullName;
  }) => {
    if (!data?.recommendationIds?.length)
      throw new Error("Failed to publish. Invalid analysis.");
    const result = await fetchApiPut<any>(
      `analyst/recommendation/publish`,
      data,
    );
    queryClient.invalidateQueries({ queryKey: [ENTITY_KEY], exact: true });
    queryClient.invalidateQueries({
      queryKey: [ENTITY_KEY, ...data.recommendationIds],
    });
    return result;
  };
}

export function useDeleteRecommendation() {
  const queryClient = useQueryClient();

  return async (dataId: string) => {
    if (!dataId) return { success: false, data: {}, message: "Missing" };
    const result = await fetchApiDelete(`analyst/recommendation/${dataId}`);
    queryClient.invalidateQueries({ queryKey: [ENTITY_KEY] });
    return result;
  };
}

export function useAnalystRecommendations({
  from,
  to,
  recommendationType,
  limit,
}: {
  from: Date;
  to: Date;
  recommendationType: AnalystRecommendationType;
  limit?: number;
}) {
  const queryParams = new URLSearchParams();

  if (from) {
    queryParams.append("from", from.toISOString());
  }

  if (to) {
    queryParams.append("to", to.toISOString());
  }

  if (recommendationType) {
    queryParams.append("type", recommendationType);
  }

  if (limit) {
    queryParams.append("limit", limit.toString());
  }

  return useQuery({
    refetchOnWindowFocus: false,
    queryKey: [
      ENTITY_KEY,
      "analystRecommendationsList",
      from,
      to,
      recommendationType,
    ],
    queryFn: () =>
      fetchApiGet<AnalystRecommendation[]>(
        "analyst/recommendation",
        queryParams,
      ),
    retry: 1,
    cacheTime: 0,
  });
}

export function useLastDayAnalysis(date: Date) {
  const queryParams = new URLSearchParams();
  queryParams.append("type", AnalystRecommendationType.Analysis);
  queryParams.append("date", date.toISOString());

  return useQuery({
    refetchOnWindowFocus: false,
    queryKey: [ENTITY_KEY, "lastDay", date],
    queryFn: () =>
      fetchApiPost<AnalystAnalysis[]>(
        "analyst/recommendation/lastDay",
        {},
        queryParams,
      ),
    retry: 1,
    cacheTime: 0,
  });
}

export function useAnalystCalendarSummary(clearKey: any) {
  const queryParams = new URLSearchParams();

  return useQuery({
    refetchOnWindowFocus: false,
    queryKey: [ENTITY_KEY, "summary", clearKey],
    queryFn: () =>
      fetchApiGet<AnalystCalendarSummary>(
        "analyst/recommendation/calendar/summary",
        queryParams,
      ),
    retry: 1,
    cacheTime: 0,
  });
}
