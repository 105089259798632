import { create } from "zustand";
import { v4 as uuidV4, validate as isValidUuid } from "uuid";
import { useState } from "react";
import { useAudioRecorder, AudioRecorder } from 'react-audio-voice-recorder';
import moment from "moment-timezone";
import { GenerateLoadingOrError, genFlatOptionList, genIdOptionList, CommonDestinationTimezone, createDateLabel } from "../../lib/utils";
import {
    Archive as ArchiveXIcon,
    Trash2 as Trash2Icon,
} from "lucide-react";
import {
  CommodityOptions,
  Commodities,
} from "../../lib/api/commodity";
import {
    InputRequiredStatus,
    InputFormType,
    InputType,
    ImportType,
    ExportType,
    InputFilter,
    InputRequiredStatusType,
} from "../../lib/form";
import {
    useListSpeechJobs,
    useDeleteStagedGeneralContract,
    useCreateGeneralContractFrom,
    useListGeneralContractSpeecData,
    useCreateStagedUpload,
    Uom,
    Currency,
    CostCategory,
    CalculateOn,
    AddDedAdjType,
    RateType,
    ContractType,
    PurchaseSaleType,
    useListGeneralContracts,
    useListLocations,
    useListMarketZones,
    GeneralContract,
    Location, MarketZone,
    purchaseSaleOptions,
    uomOptions,
    currencyOptions,
    generateContractTypesWith,
    calculateOnOptions,
    rateTypeOptions,
    costCategoryOptions,
    addDedADjOptions,
    deliveryTermsOptions,
    DeliveryTerms,
} from "../../lib/api/generalContract";
import { useListTraders } from "../../lib/api/trader";
import { reduceArrayTo, formulateOptionMonths } from "../../lib/api/common";
import { useListCustomers } from "../../lib/api/customer";
import { Trader, Customer } from "../../lib/api/reports";
import * as FormHelper from "../../lib/form";
import * as FormLayout from "../../lib/form/layout";
import {
    PrimitiveGridLayout,
    PrimitiveManagedTable,
    PrimitiveDialogForm,
} from "../../lib/form/layout";
import { Button } from "../../components/ui/button";
import { useToast } from "../../components/ui/use-toast";
import { FormGridHelper } from "../reports/helper";
import { BaseGeneralFormSchema, ContractAddDedAdjBaseForm, ContractMzAdjBaseForm } from "../../lib/schemas/generalContract";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogClose,
  DialogFooter,
} from "../../components/ui/dialog";
import { Loader } from "../../components/ui/loader";
import { cn } from "../../lib/utils";
import { UploadPresignedBlob } from "../../lib/file";

export const BaseSpeechJobSchema = {
    stage: {
        label: "Status", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Optional,
        apiDisplayFormat: ({ original }: any) => {
            if (original.queue_status === "halt") {
                return "Failed. Please message a Developer";
            }

            switch(original?.stage?.toLocaleLowerCase()) {
                case "wait_presign":
                    return "Starting";
                case "wait_transcribe":
                    return "Transcribing";
                case "wait_ai":
                    return "Using AI";
                case "completed":
                    return "Complete";
                default:
                    return "Unknown";
            }
        }
    },
};

export const BaseStagedFormSchema = {
    location: { label: "Location", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Optional },
    contract_type_display: { label: "Type", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Optional },
    ["p/s/o"]: { label: "P/S", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Optional },

    commodity_full: { label: "Commodity", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Optional },

    quantity: { label: "Quantity", step: "5000", input_type: InputType.Decimal, input_type_validation: InputFormType.Decimal, required_status: InputRequiredStatus.Optional },
    quantity_uom: { label: "Quantity UOM", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Optional },

    option_month_display: { label: "Option Month", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Optional },

    customer: { label: "Customer", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Optional },
    market_zone: { label: "Market Zone", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Optional },

    basis: {
        label: "Basis", step: "0.01", input_type: InputType.Decimal, input_type_validation: InputFormType.Decimal, required_status: InputRequiredStatus.Optional,
    },
    futures_price: {
        label: "Futures", step: "0.1", input_type: InputType.Decimal, input_type_validation: InputFormType.Decimal, required_status: InputRequiredStatus.Optional,
    },

    trader: { label: "Trader", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Optional },

    delivery_start: { label: "Shipment Start", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Optional, apiDisplayFormat: ({ original }: any) => { return createDateLabel(original.delivery_start, "YYYY/MM/DD"); } },
    delivery_end: { label: "Shipment End", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Optional, apiDisplayFormat: ({ original }: any) => { return createDateLabel(original.delivery_end, "YYYY/MM/DD"); } },
};

export const BaseStagedFormSchemaWithOptions = {
    delete_staged_contract_button: { label: "Delete", action_reference_id: "onDeleted", action: "button", derived: true, skipForm: true, apiDisplayFormat: ({ original }: any) => {
        return (
            <Trash2Icon className="h-4 w-4" />
        );
    } },

    ...BaseStagedFormSchema,
};

const createState = FormHelper.generateBaseState({
    schemas: [BaseGeneralFormSchema],
    baseSchema: BaseGeneralFormSchema,
});

export function StagedGeneralContractsDashboard() {
    const { toast } = useToast();
    const [isUpdateOpen, setIsUpdateOpen] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [jobId, setJobId] = useState(undefined);
    const [selectedRow, setSelectedRow] = useState<undefined|any>(undefined);
    const stagedRowsRes = useListGeneralContractSpeecData();
    const onDeleteStagedGeneralContract = useDeleteStagedGeneralContract();

    const locationRes = useListLocations();
    const marketZoneRes = useListMarketZones();
    const customersResult = useListCustomers();
    const listTradersRes = useListTraders();

    const loadingOrErrorUi = GenerateLoadingOrError([stagedRowsRes, locationRes, marketZoneRes, customersResult, listTradersRes]);
    const createPopulateWith: any = createState.useInputsState((state: any) => state.populateWith);
    const createClearForm: any = createState.useInputsState((state: any) => state.clearForm);

    if (loadingOrErrorUi) {
         return (
             <section className="flex min-h-full flex-1 flex-col justify-start px-6 py-6 lg:px-8">
                <h1 className="scroll-m-20 text-2xl font-extrabold tracking-tight lg:text-3xl">
                    Staged General Contracts
                </h1>
                {loadingOrErrorUi}
            </section>
        );
    }

  const onDelete = async ({ id }: { id: string }) => {
    if (isDeleting) return;
    setIsDeleting(true);

    const result = await onDeleteStagedGeneralContract({ id });

    setIsDeleting(false);

    if (!result.success) {
      toast({
        title: "Failed to delete contract",
        description: "",
      });
    } else {
        toast({
            title: "Deleted",
            description: "",
        });
    }
  };

    const stagedRows = (stagedRowsRes.data?.data || []).map((wrapper: any) => ({ ...wrapper.data, user_id: wrapper.user_id, id: wrapper.id, transcription: wrapper.transcription }));
    const locations = locationRes.data?.data || [];
    const marketZones = marketZoneRes.data?.data || [];
    const customers = customersResult.data?.data || [];
    const traders = listTradersRes.data?.data || [];

    return (
        <section className="flex min-h-full flex-1 flex-col justify-start px-6 py-6 lg:px-8">
            <h1 className="scroll-m-20 text-2xl font-extrabold tracking-tight lg:text-3xl">
                Staged General Contracts
            </h1>
            <div className="flex justify-end gap-4 mb-4">
                <CreateFormModal setStagedRow={setSelectedRow} stagedRow={selectedRow} locations={locations} marketZones={marketZones} customers={customers} traders={traders}  />
                <RecordDialog setJobId={setJobId} />
            </div>
            <PrimitiveManagedTable
                externalReferences={{
                    onDeleted: onDelete,
                    onDeletedLoading: isDeleting,
                }}
                schema={BaseStagedFormSchemaWithOptions} values={stagedRows} onRowClick={(item) => {
                    setIsUpdateOpen(true);
                    createPopulateWith(item);
                    setSelectedRow(item);
                }} />
            <ListSpeechJobs />
        </section>
    );
}

function RecordDialog({ setJobId }: { setJobId: any }) {
    const { toast } = useToast();
    const [isOpen, setIsOpen] = useState(false);
    const [audioBlob, setAudioBlob] = useState<undefined | Blob>(undefined);
    const [is_submitting, setIsSubmitting] = useState(false);
    const createStagedUpload = useCreateStagedUpload();
    const recorderControls = useAudioRecorder({
            noiseSuppression: true,
            echoCancellation: true,
        });

    const onCloseForm = () => onDialogChange(false);

    const onDialogChange = (status: boolean) => {
        setIsOpen(status);
    };

    const onSubmit = async ({}) => {
        if (is_submitting) return;
        if (!audioBlob) return;
        setIsSubmitting(true);

        const dateAbbrev = moment.tz(CommonDestinationTimezone).format("YYYYMMDD");
        const createResult = await createStagedUpload({ filename: `recorded_audio_${dateAbbrev}` });

        if (!createResult.success) {
            setIsSubmitting(false);
            return createResult;
        }

        const resultData = createResult.data;
        setJobId(resultData.queuedJobId);

        const uploadRes = await UploadPresignedBlob(resultData.uploadUrl, audioBlob, "audio/mp4");
        if (!uploadRes.success) {
            toast({
                title: "Failed to upload audio",
                description: "",
            });
        }

        setAudioBlob(undefined);
        setIsSubmitting(false);
        onCloseForm();
    };

    const samplePrompt = `I need a purchase <Contract Type> <Location> contract for <Customer>. <Quantity> bushels of <Commodity> at X under the basis, for <Option Month> <Option Year> Delivered to <Market Zone Name> For <Delivery Month/Year Range>.`

    return (
        <div className="flex flex-row justify-between items-center w-full gap-4 mb-4 mt-4">
            {(recorderControls.isRecording || recorderControls.isPaused) ? (
                <span className="flex shrink">Sample Prompt: {samplePrompt}</span>
            ) : (<span/>)}
        <span className="flex shrink-0">
            <AudioRecorder
                recorderControls={recorderControls}
                onRecordingComplete={(data) => {
                    if (!!data) {
                        setAudioBlob(data);
                        onDialogChange(true);
                    }
                }}
                downloadOnSavePress={false}
                downloadFileExtension="webm"
            />
        </span>
            <Dialog
                onOpenChange={(value) => {
                    if (onDialogChange) {
                        onDialogChange(value);
                    }
                }}
                open={isOpen || false}
                defaultOpen={false}
            >
                <DialogContent className={cn("max-w-full sm:max-w-lg lg:max-w-3xl xl:max-w-7xl flex flex-col flex-start")}>
                    <DialogHeader>
                        <DialogTitle>
                            Submit Recording
                        </DialogTitle>
                    </DialogHeader>
                    <form
                        id={`GeneralContractRecordForm`}
                        onSubmit={(e) => {
                            if (e?.preventDefault) e.preventDefault();
                            return onSubmit({});
                        }}
                        className={cn("flex flex-col gap-4")}
                    >
                        <div className="grid grid-cols-1 sm:grid-cols-2 items-start gap-4 content-center">
                            <Button
                                type="button"
                                variant="secondary"
                                className="col-start-1"
                                onClick={() => onCloseForm ? onCloseForm() : undefined}
                            >
                                Cancel
                            </Button>

                            <Button type="submit" size="sm" className="ml-4 px-3 col-start-4" disabled={is_submitting}>
                                {is_submitting ?  <Loader /> : <span>Submit</span>}
                            </Button>
                        </div>
                    </form>
                </DialogContent>
            </Dialog>
        </div>
    )
}

function CreateDynamicForm({ staged_id, onCloseForm, traders, customers, locations, marketZones }: { staged_id: string; onCloseForm: any; locations: Location[]; marketZones: MarketZone[]; customers: Customer[]; traders: Trader[] }) {
    const createApiContract = useCreateGeneralContractFrom();

    return (
        <FormGridHelper
            state={createState}
            activeSchema={BaseGeneralFormSchema}
            id={"StagedCreateGeneralContract"}
            externalReferences={
            {
                locations, marketZones,
                marketZonesOptions: genIdOptionList(marketZones, "market_zone"),
                locationOptions: genIdOptionList(locations, "long_name"),
                customerOptions: genIdOptionList(customers, "long_name"),
                commodityOptions: CommodityOptions,
                purchaseSaleOptions,
                uomOptions,
                currencyOptions,
                costCategoryOptions,
                rateTypeOptions,
                calculateOnOptions,
                addDedADjOptions,
                deliveryTermsOptions,
                traderOptions: genIdOptionList(traders, "name"),
                optionMonthOptions: formulateOptionMonths(),
                contractTypeOptions: genFlatOptionList(generateContractTypesWith({})),
            }
            }
            failMessage="Failed to create contract"
            successMessage="Created"
            onSubmitTo={(contractData: any) => createApiContract({ staged_id, data: contractData })}
            onCloseForm={onCloseForm}
            preventSubmitClose={false}
        />
    );
}

function CreateFormModal({ setStagedRow, stagedRow, customers, traders, locations, marketZones }: { setStagedRow: any; stagedRow: any; locations: Location[]; marketZones: MarketZone[]; customers: Customer[]; traders: Trader[] }) {
    if (!stagedRow) return null;

    const onCloseForm = (status: any) => {
        if (!status) {
            setStagedRow(undefined);
        }
    };

    return (
        <PrimitiveDialogForm className="max-w-[98%] h-[98%]" isOpen={!!stagedRow} onDialogChange={onCloseForm} buttonLabel={"Create Contract"} dialogTitle={"Create Contract"} form={
        (<>
            { stagedRow.transcription && (
                  <>
            <div className="text-lg font-semibold tracking-tight ">
                            Transcription
            </div>
        <p className="text-muted-foreground">
          {stagedRow.transcription}
        </p>
                  </>
              )}
            <div className="text-xl font-bold tracking-tight ">
                            AI Output
            </div>
            <PrimitiveManagedTable
                tableHeight="150px"
                externalReferences={{
                    onDeleted: null,
                    onDeletedLoading: false,
                }}
                schema={BaseStagedFormSchema} values={[stagedRow]} onRowClick={(item) => {}}
            />
            <div className="mt-2" />
            <div className="text-xl font-bold tracking-tight ">
                            Create Form
            </div>
            <CreateDynamicForm onCloseForm={onCloseForm} locations={locations} customers={customers} marketZones={marketZones} traders={traders} staged_id={stagedRow?.id}/>
        </>)}/>
    );
}

function ListSpeechJobs() {
    const speechJobsRes = useListSpeechJobs();

    const loadingOrErrorUi = GenerateLoadingOrError([speechJobsRes]);

    if (loadingOrErrorUi) {
         return (
             <section className="flex min-h-full flex-1 flex-col justify-start px-6 py-6 lg:px-8">
                <h1 className="scroll-m-20 text-2xl font-extrabold tracking-tight lg:text-3xl">
                    Contract Jobs
                </h1>
                {loadingOrErrorUi}
            </section>
        );
    }

    const jobs = (speechJobsRes.data?.data || []);

    return (
        <>
            <div className="mt-4" />
            <h2 className="text-xl font-bold tracking-tight">
                Recorded Contract Jobs
            </h2>
        <PrimitiveManagedTable
            externalReferences={{}}
            schema={BaseSpeechJobSchema} values={jobs} onRowClick={(item) => {}}
        />
        </>
    );
}
