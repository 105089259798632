import { useCallback, Fragment, useState } from "react";
import { useStytchB2BClient, useStytchMember } from "@stytch/react/b2b";
import { useLocation, Link } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import {
  Home as HomeIcon,
  Bell as BellIcon,
  Menu as Bars3Icon,
  Activity as ActivityIcon,
  Wheat as GrainIcon,
  FlaskConical as FlaskConicalIcon,
  LineChart as LineChartIcon,
  X as XMarkIcon,
  Upload as UploadIcon,
  BarChartBig as BarChartBigIcon,
  Zap as ZapIcon,
} from "lucide-react";
import { Avatar, AvatarFallback, AvatarImage } from "./avatar";
import { Button } from "./button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuTrigger,
} from "./dropdown-menu";
import { cn as classNames } from "../../lib/utils";
import {
  PossibleRole,
  PossiblePackage,
  getUserRole,
  doesUserHavePackages,
} from "../../lib/models/auth";
import { RefreshSession } from "../../section/StytchRefreshSession";
import { pagesList } from "../../section/router";

export default function AppPanel({ children }: { children: JSX.Element }) {
  const { member } = useStytchMember();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const location = useLocation();
  const pathname = location.pathname;

  const navigation = pagesList({
    member,
    currentPathname: pathname,
    filterByPackages: true,
  }).filter((item) => !item.excludeFromDashboard);

  const currentPage = navigation.find((nav) => nav.current) || { name: "" };
  if (!member) return null;

  return (
    <>
      <div>
        <RefreshSession />
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-50 lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button
                        type="button"
                        className="-m-2.5 p-2.5"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-red-600 px-6 pb-2">
                    <div className="flex h-2 shrink-0 items-center"></div>
                    <nav className="flex flex-1 flex-col">
                      <ul role="list" className="flex flex-1 flex-col gap-y-7">
                        <li>
                          <ul role="list" className="-mx-2 space-y-1">
                            {navigation.map((item) => (
                              <li key={item.name}>
                                <Link
                                  to={item.href}
                                  className={classNames(
                                    item.current
                                      ? "bg-red-700 text-white"
                                      : "text-slate-200 hover:text-white hover:bg-red-700",
                                    "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold",
                                  )}
                                >
                                  <item.icon
                                    className={classNames(
                                      item.current
                                        ? "text-white"
                                        : "text-slate-200 group-hover:text-white",
                                      "h-6 w-6 shrink-0",
                                    )}
                                    aria-hidden="true"
                                  />
                                  {item.name}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </li>
                        {hasProfile(member) && (
                          <li className="-mx-6 mt-auto">
                            <ProfileWithDropdownMenu />
                          </li>
                        )}
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-red-600 px-6">
            <div className="flex h-2 shrink-0 items-center"></div>
            <nav className="flex flex-1 flex-col">
              <ul role="list" className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul role="list" className="-mx-2 space-y-1">
                    {navigation.map((item) => (
                      <li key={item.name}>
                        <Link
                          to={item.href}
                          className={classNames(
                            item.current
                              ? "bg-red-700 text-white"
                              : "text-slate-200 hover:text-white hover:bg-red-700",
                            "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold",
                          )}
                        >
                          <item.icon
                            className={classNames(
                              item.current
                                ? "text-white"
                                : "text-slate-200 group-hover:text-white",
                              "h-6 w-6 shrink-0",
                            )}
                            aria-hidden="true"
                          />
                          {item.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </li>
                {hasProfile(member) && (
                  <li className="-mx-6 mt-auto">
                    <ProfileWithDropdownMenu />
                  </li>
                )}
              </ul>
            </nav>
          </div>
        </div>

        <div className="sticky top-0 z-40 flex items-center gap-x-6 bg-red-600 px-4 py-4 shadow-sm sm:px-6 lg:hidden">
          <button
            type="button"
            className="-m-2.5 p-2.5 text-slate-200 lg:hidden"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
          <div className="flex-1 text-sm font-semibold leading-6 text-white">
            {currentPage.name}
          </div>
        </div>

        <main className="py-10 lg:pl-72">
          <div className="px-4 sm:px-6 lg:px-8">{children}</div>
        </main>
      </div>
    </>
  );
}

function ProfileWithDropdownMenu() {
  const { member } = useStytchMember();
  const stytchClient = useStytchB2BClient();

  const logout = useCallback(() => {
    stytchClient.session.revoke();
  }, [stytchClient]);

  if (!member) return null;

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <div className="flex flex-row items-center">
          <Button variant="ghost" className="relative h-8 w-8 rounded-full m-2">
            <Avatar className="h-8 w-8">
              <AvatarImage
                src={getProfilePicture(member)}
                alt="profile_picture"
              />
              <AvatarFallback>{member.name[0]}</AvatarFallback>
            </Avatar>
          </Button>
          <p className="text-white text-sm font-medium leading-none">
            {member.name}
          </p>
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56" align="end" forceMount>
        <DropdownMenuLabel className="font-normal">
          <div className="flex flex-col space-y-1">
            <p className="text-sm font-medium leading-none">{member.name}</p>
            <p className="text-xs leading-none text-muted-foreground">
              {member.email_address}
            </p>
          </div>
        </DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuItem onClick={logout}>Log out</DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

// NOTE: not typed because stytch has wrong types
function hasProfile(member: any) {
  return member?.oauth_registrations?.length > 0;
}

function getProfilePicture(member: any) {
  return member.oauth_registrations[0]?.profile_picture_url || "";
}
