import moment from "moment-timezone";
import Decimal from "decimal.js";
import { getPath } from "../prelude";
import { safeParseDecimal } from "../excel";
import { CommonDestinationTimezone } from "../utils";

interface OptionOverrideOptions {
    parseToNumberKeys?: string[];
    parseDateToChicagoDateKeys?: string[];
    parseToDateKeys?: Record<string, string>;
    convertFunctionKeys?: Record<string, any>
}

interface CompareOptions {
    aOverride?: OptionOverrideOptions;
    bOverride?: OptionOverrideOptions;
    keyMappings: Record<string, string>
}

interface CompareResult {
    matchedKeys: string[];
    unmatchedKeys: string[];
}

export function compareToBase({
    a,
    b,
    options
}: {
    a: Record<string, any>,
    b: Record<string, any>,
    options: CompareOptions
}): CompareResult {
    return Object.keys(options.keyMappings).reduce((acc: CompareResult, aKey: string) => {
        const bKey = options.keyMappings[aKey];

        const doesMatch = doesPairMatch({
            a: transformTo({ item: a, optionsOverride: options.aOverride, key: aKey }),
            b: transformTo({ item: b, optionsOverride: options.bOverride, key: bKey }),
            options
        });

        if (doesMatch) {
            acc.matchedKeys.push(aKey);
        } else {
            acc.unmatchedKeys.push(aKey);
        }

        return acc;
    }, {
        aContractNo: a["contract_no"],
        bContractNo: b["Contract No"],
        matchedKeys: [],
        unmatchedKeys: [],
    } as any);
}

// TODO add ability to search for a list and then do a secondary
//    search if there are multiple matches
// NOTE: Doesnt support comparing nested objects right now or arrays
//   Other then the basic getting something from a path
export function compareTo(params: {
    a: Record<string, any>,
    b: Record<string, any>,
    options: CompareOptions
}): boolean {
    return compareToBase(params).unmatchedKeys.length === 0;
}

function transformTo({
    item,
    optionsOverride,
    key
}: {
    item: Record<string, any>;
    optionsOverride?: OptionOverrideOptions;
    key: string;
}) {
    const baseValue = getPath(item, key.split("."));
    let newValue: any = baseValue;

    if (!optionsOverride) return newValue;

    if (typeof newValue === "string") {
        newValue = newValue.trim();
    }

    if (!!optionsOverride.parseToNumberKeys && optionsOverride.parseToNumberKeys.includes(key)) {
        newValue = safeParseDecimal(newValue);
    }

    if (!!optionsOverride.parseToDateKeys && !!optionsOverride.parseToDateKeys[key]) {
        const format = optionsOverride.parseToDateKeys[key];
        newValue = moment.tz(newValue, format, CommonDestinationTimezone).toDate()
    }

    if (!!optionsOverride.parseDateToChicagoDateKeys && !!optionsOverride.parseDateToChicagoDateKeys.includes(key)) {
        newValue = moment.tz(newValue, CommonDestinationTimezone).toDate()
    }

    if (!!optionsOverride.convertFunctionKeys && !!optionsOverride.convertFunctionKeys[key]) {
        newValue = optionsOverride.convertFunctionKeys[key](newValue);
    }

    return newValue;
}

function doesPairMatch({ a, b, options }: { a: any; b: any; options: CompareOptions }) {
    if (a == null && b == null) { return true; }

    const aType = typeof a;
    const bType = typeof b;

    if (aType !== bType) { return false; }

    if (Decimal.isDecimal(a) && Decimal.isDecimal(b)) {
        return a.equals(b);
    }

    if (
        (a instanceof Date && b instanceof Date) ||
        (a instanceof moment && b instanceof moment)
    ) {
        return moment(a).isSame(moment(b));
    }

    // Numbers, Strings, Boolean,
    return a === b;
}
