import { useQuery, useQueryClient, QueryKey } from "@tanstack/react-query";
import Decimal from "decimal.js";
import { BaseDbObject } from "../models/db";
import moment, { Moment } from "moment-timezone";
import {
  fetchApiDelete,
  fetchApiGet,
  fetchApiPost,
  fetchApiPut,
} from "./utils";
import {
  PossibleReturnType,
} from "../responseResults";
import { CommonDestinationTimezone } from "../utils";
import { formatDateWithoutTimezone } from "./date";
import { DbUser } from "../models/index";

export interface JobUploadStatus {
    id: string;
    job_status: string;
}

export interface StagedDormanRow {
    id: string;
    data: {
        "Week": string;
        "Year": string;
        "Price": string;
        "Broker": string;
        "Trader": string;
        "Account": string;
        "Buy/Sell": string;
        "Function": string;
        "Put/Call": string;
        "Quantity": string;
        "Order No.": string;
        "Trade Date": string;
        "Trade Time": string;
        "Trade Type": string;
        "Option Month": string;
        "Strike Price": string;
        "Commodity Symbol": string;
        "external_provider_id": string;
        "Cross Hedge/Risk Commodity": string;
    }
}

export interface StagedDormanTTRow {
    id: string;
    data: {
        "Date": string;
        "Time": string;
        "Exchange": string;
        "Option Month Pair": string;
        "Type": string;
        "Quantity": string;
        "Price": string;
        "Order Type": string;
        "Direct Type": string;
        "Account": string;
        "Text A": string;
        "Trader A": string;
        "Trader B": string;
        "Commodity": string;
        "Option Month": string;
        "Option Month Letter": string;
    }
}

export interface StagedListResult {
    dormanRows: StagedDormanRow[]
    ttRows: StagedDormanTTRow[]
}

export function useListStagedData() {
  const queryParams = new URLSearchParams();

  return useQuery({
    queryKey: ["dormanStagedData"],
    queryFn: () =>
      fetchApiGet<Location[]>("tickets/providers/dorman/preliminary/staged", queryParams),
    retry: 1,
  });
}

export function useDownloadStagedData() {
  const queryClient = useQueryClient();

  return async (params: any) => {
    const queryParams = new URLSearchParams();

    const result = await fetchApiPost<{ downloadPresignUrl: string }>(
      `tickets/providers/dorman/preliminary/staged/download`,
      params,
      queryParams,
    );
    return result;
  };
}

export function usePairStagedData() {
  const queryClient = useQueryClient();

  return async (params: any) => {
    const queryParams = new URLSearchParams();

    const result = await fetchApiPost<any>(
      `tickets/providers/dorman/preliminary/staged/pair`,
      params,
      queryParams,
    );
    queryClient.invalidateQueries({ queryKey: ["dormanStagedData"] });
    return result;
  };
}

export function useMarkStagedData() {
  const queryClient = useQueryClient();

  return async (params: any) => {
    const queryParams = new URLSearchParams();

    const result = await fetchApiPost<any>(
      `tickets/providers/dorman/preliminary/staged/manual`,
      params,
      queryParams,
    );
    queryClient.invalidateQueries({ queryKey: ["dormanStagedData"] });
    return result;
  };
}

export function useCreatePreliminaryUpload() {
  const queryClient = useQueryClient();

  return async (body: any) => {
    const queryParams = new URLSearchParams();

    const result = await fetchApiPost<any>(
      `tickets/providers/dorman/preliminary/job/upload`,
      body,
      queryParams,
    );
    queryClient.invalidateQueries({ queryKey: ["dormanStagedData"] });
    return result;
  };
}


export function useGetCreateJobStatus(id?: string) {
  const queryParams = new URLSearchParams();

  return useQuery({
    queryKey: ["dormanUploadJobStatus", id],
    queryFn: () =>
      fetchApiGet<JobUploadStatus>(`tickets/providers/dorman/preliminary/job/${id}/status`, queryParams),
    retry: 1,
    enabled: !!id,
    refetchInterval: 12 * 1000
  });
}
