import { parse } from "csv-parse/browser/esm/sync";

export function parseFromString(csvText: string) {
  return parse(csvText, {
    columns: true,
    skip_empty_lines: true,
    relax_column_count: true,
    trim: true,
  });
}


export interface PurchaseReleaseRow {
    "Location": string,
    "P/S": string,
    "Cont": string,
    "Contract No": string,
    "Status": string,
    "Pr  Status": string, //: "PR" | "UNP" | "TBD" | "PP" | "HTA",
    "Id": string,
    "Name": string,
    "Commodity": string,
    "Commodity Description": string,
    "Trader": string,
    "Delivery Basis": string,
    "Grade": string,
    "Weights": string,
    "Grades": string,
    "Rel": string,
    "Source Location": string,
    "Add Date": string,
    "Price Date": string,
    "Ct UOM": string,
    "Quantity": string,
    "Loads": string,
    "Futures": string,
    "Basis": string,
    "Opt  Mth": string,
    "Price": string,
    "Pr  UOM": string,
    "MZ Adj": string,
    "MTM Basis": string,
    "MTM Price": string,
    "Cur": string,
    "Ship To": string,
    "UOM": string,
    "Sched  Quantity": string,
    "Balance": string,
    "Vehicle Type": string,
    "Sched  Loads": string,
    "Bal  Loads": string,
    "Start Date": string,
    "End Date": string,
    "Market Zone": string,
    "Product": string,
    "Product Description": string,
    "Buy FX Rate": string,
    "Original Buy FX": string,
    "FX Hedge Value": string,
    "FX Trade Type": string,
    "FX Trans  No": string,
    "FX Trade Date": string,
    "FX Trade Buy": string,
    "FX $ Amount": string,
    "Business Type": string,
    "Contract Ref": string,
    "Add/Ded Rate Type": string,
    "Add/Ded Code 1": string,
    "Add/Ded Adjustment 1": string,
    "Add/Ded Amount 1": string,
    "Add/Ded Code 2": string,
    "Add/Ded Adjustment 2": string,
    "Add/Ded Amount 2": string,
    "Add/Ded Code 3": string,
    "Add/Ded Adjustment 3": string,
    "Add/Ded Amount 3": string,
    "Add/Ded Code 4": string,
    "Add/Ded Adjustment 4": string,
    "Add/Ded Amount 4": string,
    "Add/Ded Code 5": string,
    "Add/Ded Adjustment 5": string,
    "Add/Ded Amount 5": string,
}

export interface SalesReleaseRow {
    "Location": string,
    "P/S": string,
    "Cont": string,
    "Contract No": string,
    "Status": string,
    "Pr  Status": string, //: "PR" | "UNP" | "TBD" | "PP" | "HTA",
    "Id": string,
    "Name": string,
    "Commodity": string,
    "Commodity Description": string,
    "Trader": string,
    "Delivery Basis": string,
    "Grade": string,
    "Weights": string,
    "Grades": string,
    "Rel": string,
    "Source Location": string,
    "Add Date": string,
    "Price Date": string,
    "Ct UOM": string,
    "Quantity": string,
    "Loads": string,
    "Futures": string,
    "Basis": string,
    "Opt  Mth": string,
    "Price": string,
    "Pr  UOM": string,
    "MZ Adj": string,
    "MTM Basis": string,
    "MTM Price": string,
    "Cur": string,
    "Ship To": string,
    "UOM": string,
    "Sched  Quantity": string,
    "Balance": string,
    "Vehicle Type": string,
    "Sched  Loads": string,
    "Bal  Loads": string,
    "Start Date": string,
    "End Date": string,
    "Market Zone": string,
    "Product": string,
    "Product Description": string,
    "Buy FX Rate": string,
    "Original Buy FX": string,
    "FX Hedge Value": string,
    "FX Trade Type": string,
    "FX Trans  No": string,
    "FX Trade Date": string,
    "FX Trade Buy": string,
    "FX $ Amount": string,
    "Business Type": string,
    "Contract Ref": string,
    "Add/Ded Rate Type": string,
    "Add/Ded Code 1": string,
    "Add/Ded Adjustment 1": string,
    "Add/Ded Amount 1": string,
    "Add/Ded Code 2": string,
    "Add/Ded Adjustment 2": string,
    "Add/Ded Amount 2": string,
    "Add/Ded Code 3": string,
    "Add/Ded Adjustment 3": string,
    "Add/Ded Amount 3": string,
    "Add/Ded Code 4": string,
    "Add/Ded Adjustment 4": string,
    "Add/Ded Amount 4": string,
    "Add/Ded Code 5": string,
    "Add/Ded Adjustment 5": string,
    "Add/Ded Amount 5": string,
}

export interface PurchaseAndSalesByContractPositionByMarketZoneRow {
    "Location": string,
    "Location Name": string,
    "Commodity": string,
    "Commodity Name": string,
    "Position UOM": string,
    "Market Zone": string,
    "Total": string,
    "Delinquent": string,
    "Month1": string,
    "Month2": string,
    "Month3": string,
    "Month4": string,
    "Month5": string,
    "Month6": string,
    "Month7": string,
    "Month8": string,
    "Month9": string,
    "Month10": string,
    "Month11": string,
    "Month12": string,
    "Forward": string,
    "As Of Date": string,
}

export interface OpenPositionsFuturesAndOptions {
    "MOC As Of": string,
    "As Of Trade Date": string,
    "Broker": string,
    "Commodity": string,
    "Location": string,
    "Futures Exchange": string,
    "Currency": string,
    "Trade Type": string,
    "Option Month": string,
    "Strike Price": string,
    "Week": string,
    "Buy": string,
    "Sell": string,
    "Position Date": string,
    "Average Buy Price": string,
    "Average Sell Price": string,
    "Book Value": string,
    "Close Price": string,
    "Market Value": string,
    "Gain/Loss": string,
}
