import { useStytchMember } from "@stytch/react/b2b";
import { useState } from "react";
import { Eye as EyeIcon } from "lucide-react";
import { ColumnDef } from "@tanstack/react-table";
import moment from "moment-timezone";
import { Link } from "react-router-dom";
import { fetchTicketJobsPage, useTicketJobsPaginated, useTicketJob } from "../../lib/api/tickets";
import {
  TicketRunSummary,
  TicketsSummary,
  Provider,
} from "../../lib/models/index";
import { TicketJob, TicketJobStatus } from "../../lib/models/tickets";
import { Button } from "../../components/ui/button";
import { Loader } from "../../components/ui/loader";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../components/ui/dropdown-menu";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../components/ui/tabs";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/ui/table";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../components/ui/accordion";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../components/ui/dialog";
import { Badge } from "../../components/ui/badge";
import { Skeleton } from "../../components/ui/skeleton";
import { useToast } from "../../components/ui/use-toast";
import { DataTable } from "../../components/ui/dataTable";

export function TicketJobsDashboard() {
  const [page, setPage] = useState(1);
  const ticketJobsRes: any = useTicketJobsPaginated({
    page,
    queryFn: () => fetchTicketJobsPage(page)
  });

  if ([ticketJobsRes].some((result) => result.isLoading)) {
    return (
      <div className="flex flex-col space-y-2">
        <Skeleton className="h-4 w-[250px]" />
        <Skeleton className="h-4 w-[200px]" />
      </div>
    );
  }

  if (
    [ticketJobsRes].some((result) => result.error || !result?.data?.success)
  ) {
    return (
      <div className="flex flex-col space-y-2">
        Failed to fetch ticket jobs. Please refresh and try again
      </div>
    );
  }

  return (
    <TicketJobsDashboardView info={ticketJobsRes?.data?.data || []} setPage={setPage} isLoading={ticketJobsRes.isFetching} />
  );
}

const dashboardColumns: ColumnDef<any>[] = [
  {
    accessorKey: "provider",
    header: "Provider",
    cell: ({ row }) => {
      return (
        <span className="">
          {row.original.TicketRun?.Provider?.name || "Unknown"}
        </span>
      );
    },
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "startDate",
    header: "Start Time",
    cell: ({ row }) => {
      return (
        <span className="">
          {moment
            .tz(row.original.start_datetime, "America/Chicago")
            .format("MM/DD/YYYY HH:mm a")}
        </span>
      );
    },
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "source",
    header: "Source",
    cell: ({ row }) => {
      return (
        <span className="">{row.original.meta_data?.source || "Unknown"}</span>
      );
    },
    enableSorting: false,
    enableHiding: false,
  },
  {
    id: "fileName",
    header: "File Name",
    cell: ({ row }) => (
      <span className="">{row.original?.TicketDocument?.filename}</span>
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "status",
    header: "Status",
    cell: ({ row }) => (
      <TicketJobStatusBadge status={row.original.last_computed_status} />
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "ticketLink",
    header: "Document",
    cell: ({ row }) => {
      return <ViewDocument ticketJobId={row.original.id} />;
    },
    enableSorting: false,
    enableHiding: false,
  },
];

export function TicketJobsDashboardView({
  info,
  setPage,
  isLoading,
}: {
  isLoading: boolean;
  setPage: any;
  info: {
    data: TicketJob[];
    total: number;
    totalPages: number;
    pageSize: number;
    currentPage: number;
  };
}) {
  const page = info.currentPage;
  return (
    <>
      <div className="container flex flex-col mb-2">
        <h1 className="scroll-m-20 text-2xl font-extrabold tracking-tight lg:text-3xl">
          Ticket Jobs Dashboard
        </h1>
      </div>
      <div className="flex items-start flex-col h-full">
        <div className="container flex flex-col">
          <DataTable columns={dashboardColumns} data={info.data} />
        </div>
      {/* Pagination controls */}
      <div className="container flex items-center justify-between mt-4">
        <Button
          onClick={() => setPage(page - 1)}
          disabled={page <= 1 || isLoading}
        >
          {isLoading ? <Loader /> : <span>Previous</span>}
        </Button>

        <span>
          Page {page} of {info.totalPages}
        </span>

        <Button
          onClick={() => setPage(page + 1)}
          disabled={page >= info.totalPages || isLoading}
        >
          {isLoading ? <Loader /> : <span>Next</span>}
        </Button>
      </div>
      </div>
    </>
  );
}

export function TicketJobStatusBadge({ status }: { status?: TicketJobStatus }) {
  if (!status) {
    return <Badge variant="secondary">"Unknown"</Badge>;
  }

  switch (status) {
    case TicketJobStatus.Queued:
      return <Badge variant="secondary">Queued</Badge>;
    case TicketJobStatus.InProgress:
      return <Badge variant="secondary">Analyzing</Badge>;
    case TicketJobStatus.ParsedIntermedaries:
      return <Badge variant="secondary">Analyzing</Badge>;
    case TicketJobStatus.ParsedPdf:
      return <Badge variant="secondary">Converting</Badge>;
    case TicketJobStatus.Success:
      return <Badge variant="secondary">Complete</Badge>;
    case TicketJobStatus.Failed:
      return <Badge variant="destructive">Failed</Badge>;
    default:
      return <Badge variant="destructive">Unknown</Badge>;
  }
}

function ViewDocument({ ticketJobId }: { ticketJobId: string }) {
  const { toast } = useToast();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleOpenChange = (open: boolean) => {
    // If closing the dialog, force refresh the color scheme
    if (!open) {
      document.documentElement.style.colorScheme = 'light';
      // Optional: Remove after a short delay to allow for any system preferences
      setTimeout(() => {
        document.documentElement.style.removeProperty('color-scheme');
      }, 100);
    }
  };

  return (
    <Dialog
      open={isDialogOpen}
      onOpenChange={() => {
        handleOpenChange(!isDialogOpen);
        setIsDialogOpen(!isDialogOpen)
      }}
    >
      <DialogTrigger>
        <span className="sr-only">View Document</span>
        <EyeIcon className="h-4 w-4" aria-hidden="true" />
      </DialogTrigger>
      {isDialogOpen && <ViewDocumentModal ticketJobId={ticketJobId} />}
    </Dialog>
  );
}

function ViewDocumentModal({ ticketJobId }: { ticketJobId: string }) {
  const ticketJobResult = useTicketJob(ticketJobId);

  if (!ticketJobResult.data && ticketJobResult.isLoading) {
    return (
      <DialogContent>
        <div className="flex flex-col space-y-2">
          <Skeleton className="h-4 w-[100px]" />
          <Skeleton className="h-4 w-[100px]" />
        </div>
      </DialogContent>
    );
  }

  if (
    [ticketJobResult].some((result) => result.error || !result?.data?.success)
  ) {
    return (
      <DialogContent>
        <div className="flex flex-col space-y-2">
          Failed to fetch document. Please refresh and try again
        </div>
      </DialogContent>
    );
  }

  return (
    <DialogContent className="w-[94vw] h-[96vh] max-w-[98vw] FullDialogContent">
      <iframe
        src={ticketJobResult.data?.data.TicketDocument.signedGetLink}
        className="w-full h-full"
        style={{
          colorScheme: 'normal',
          isolation: 'isolate',
        }}
      />
    </DialogContent>
  );
}
