export enum ProviderType {
  Weight = "weight",
  Trading = "trading",
}

export interface Provider {
  id: string;
  created_at: Date;
  updated_at: Date;

  name: string;
  provider_type: ProviderType;
  alias_name?: string;
}
