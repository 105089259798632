import * as XLSX from "xlsx";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useState } from "react";
import moment from "moment-timezone";
import { Label } from "../../components/ui/label";
import { Loader } from "../../components/ui/loader";
import { Input } from "../../components/ui/input";
import {
  TicketRunSummary,
  TicketsSummary,
  Provider,
} from "../../lib/models/index";
import {
  TicketProviderSummary,
  TicketProviderRecentRunPartial,
  TicketProviderBatchDownloadPartial,
} from "../../lib/models/tickets";
import { Button } from "../../components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../components/ui/dropdown-menu";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../components/ui/tabs";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/ui/table";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../components/ui/accordion";
import { processFromFiles, processOldCogsReport } from "../../lib/cogsReport";
import { useToast } from "../../components/ui/use-toast";
import { useListCustomers } from "../../lib/api/customer";
import { Skeleton } from "../../components/ui/skeleton";
import { Separator } from "../../components/ui/separator";
import { downloadBinaryData } from "../../lib/browser/download";

export function CogsReports() {
  return <ReportUploadView />;
}

export function ReportUploadView({}: {}) {
  const { toast } = useToast();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [originalReportFile, setOriginalReportFile] = useState<File | undefined>(undefined);
  const [reportFiles, setNewReportFile] = useState<File[] | undefined>(undefined);
  const [unsettledFiles, setNewUnsettledFile] = useState<File[] | undefined>(undefined);
  const customersListRes = useListCustomers();

  if ([customersListRes].some((result) => result.isLoading)) {
    return (
      <div className="flex flex-col space-y-2">
        <Skeleton className="h-4 w-[250px]" />
        <Skeleton className="h-4 w-[200px]" />
      </div>
    );
  }

  if (
    [customersListRes].some(
      (result) => result.error || !result?.data?.success,
    )
  ) {
    return (
      <div className="flex flex-col space-y-2">
        Failed to load Customer data. Please refresh and try again
      </div>
    );
  }

  const onUploadFile = async () => {
    if (!originalReportFile && !reportFiles && !unsettledFiles) {
      return;
    }

    if (isSubmitting) return;
    setIsSubmitting(true);

    try {
      const date = moment().format("YYYY_MM_DDTHH:mm");
      const { cogsOutputWorkbook, unsettledOutputWorkbook, error } = originalReportFile ? await processOldCogsReport({ reportFile: originalReportFile }) :
      await processFromFiles({
        customers: customersListRes?.data?.data || [],
        reportFiles,
        unsettledFiles
      });

      if (cogsOutputWorkbook) {
        const wbBuffer = await cogsOutputWorkbook.xlsx.writeBuffer();
        downloadBinaryData(
          wbBuffer,
          originalReportFile ? `sales_${date}.xlsx` : `cogs_${date}.xlsx`,
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        );
      }


      if (unsettledOutputWorkbook) {
        const wbBuffer = await unsettledOutputWorkbook.xlsx.writeBuffer();
        downloadBinaryData(
          wbBuffer,
          `unsettled_${date}.xlsx`,
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        );
      }

      if (error) {
        throw error;
      }

      toast({
        title: "Generated",
        description: "",
      });
    } catch (error) {
      console.error("COGS report error", error)
      toast({
        title: "Failed to process",
        description: "",
      });
    }

    setIsSubmitting(false);
  };

  const onSingleFileChange = (setMethod: any) => (...params: any[]) => {
    const item = params[0];
    setMethod(item.target.files[0]);
  };

  const onMultiFileChange = (setMethod: any) => (...params: any[]) => {
    const item = params[0];
    setMethod(Array.from(item.target.files));
  };

  return (
    <>
      <div className="container flex flex-col mb-2">
        <h1 className="scroll-m-20 text-2xl font-extrabold tracking-tight lg:text-3xl">
          Upload Sales and Purchase Reports
        </h1>
      </div>
      <div className="flex items-start flex-col h-full mt-4">
        <div className="container flex flex-col gap-4">
          <div className="grid w-full max-w-sm items-center gap-1.5">
            <Label htmlFor="cogsFile">COGS Report</Label>
            <Input
              id="cogsFile"
              type="file"
              accept=".xlsx"
              onChange={onSingleFileChange(setOriginalReportFile)}
            />
          </div>

          <Separator />
          <h1 className="scroll-m-20 text-xl font-extrabold tracking-tight lg:text-2xl">
            State & Providence
          </h1>
          <div className="grid w-full max-w-sm items-center gap-1.5">
            <Label htmlFor="newCogsFile">COGS Reports</Label>
            <Input
              id="newCogsFile"
              multiple
              type="file"
              accept=".xlsx"
              onChange={onMultiFileChange(setNewReportFile)}
            />
          </div>

          <div className="grid w-full max-w-sm items-center gap-1.5">
            <Label htmlFor="unsettled">Unsettled Files</Label>
            <Input
              id="unsettled"
              multiple
              type="file"
              accept=".xlsx"
              onChange={onMultiFileChange(setNewUnsettledFile)}
            />
          </div>

          <div className="grid w-full max-w-sm items-center gap-1.5 mt-2">
            <Button
              disabled={isSubmitting || (!originalReportFile && !reportFiles && !unsettledFiles)}
              id="uploadButton"
              onClick={onUploadFile}
            >
              {isSubmitting ? <Loader /> : "Process"}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
