import { useQuery, useQueryClient } from "@tanstack/react-query";
import { z as zod } from "zod";
import { fetchApiGet, fetchApiPost, fetchApiPut } from "./utils";
import { Customer } from "./reports";

export const CreateCustomerValidation = zod.object({
  id: zod.string().optional(),

  client_id: zod.string(),
  short_name: zod.string().optional().nullable(),
  long_name: zod.string(),

  is_commercial: zod.boolean(),

  email: zod.string().optional(),
  city: zod.string().optional(),
  state: zod.string().optional(),
  country: zod.string().optional(),
});

export type BaseCustomer = typeof CreateCustomerValidation._type;

export function useListCustomers() {
  const queryParams = new URLSearchParams();

  return useQuery({
    queryKey: ["baseCustomers"],
    queryFn: () => fetchApiGet<Customer[]>("customer", queryParams),
    retry: 1,
  });
}

export function useCreateCustomer() {
  const queryClient = useQueryClient();
  const queryParams = new URLSearchParams();

  return async (data: BaseCustomer) => {
    const result = await fetchApiPost<BaseCustomer>(
      "customer",
      data,
      queryParams,
    );
    queryClient.invalidateQueries({ queryKey: ["baseCustomers"] });
    return result;
  };
}

export function useUpdateCustomer() {
  const queryClient = useQueryClient();
  const queryParams = new URLSearchParams();

  return async (id: string, data: BaseCustomer) => {
    const result = await fetchApiPut<BaseCustomer>(
      `customer/${id}`,
      data,
      queryParams,
    );
    queryClient.invalidateQueries({ queryKey: ["baseCustomers"] });
    return result;
  };
}
