import { create } from "zustand";
import { useState } from "react";
import Decimal from "decimal.js";
import {
    Merge as MergeIcon
} from "lucide-react";
import moment from "moment-timezone";
import { SuccessResult, ServerError, ValidationError } from "../../lib/responseResults";
import { GenerateLoadingOrError, genFlatOptionList, genIdOptionList, CommonDestinationTimezone } from "../../lib/utils";
import {
    InputRequiredStatus,
    InputFormType,
    InputType,
    ImportType,
    ExportType,
    InputFilter,
    InputRequiredStatusType,
} from "../../lib/form";
import {
    useListOtcAgtechData,
    useListGeneralContractAgtechData,
    useCreateAgtechUpload,
    StagedAgtechData,
    usePairStagedData,
    useMarkStagedData,
} from "../../lib/api/agtech";
import { useGetCreateJobStatus } from "../../lib/api/dorman";
import * as FormHelper from "../../lib/form";
import * as FormLayout from "../../lib/form/layout";
import {
    PrimitiveGridLayout,
    PrimitiveManagedTable,
    PrimitiveDialogForm,
} from "../../lib/form/layout";
import { Button } from "../../components/ui/button";
import { useToast } from "../../components/ui/use-toast";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogClose,
  DialogFooter,
} from "../../components/ui/dialog";
import { createUtcDateLabel, cn } from "../../lib/utils";
import { FormGridHelper } from "../reports/helper";
import { safeParseDecimal } from "../../lib/excel";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../components/ui/tabs";
import { Loader } from "../../components/ui/loader";
import { UploadPresignedFile } from "../../lib/file";
import { BaseGeneralFormSchema, ContractAddDedAdjBaseForm, ContractMzAdjBaseForm } from "../../lib/schemas/generalContract";
import * as AgtechCompare from "../../lib/compare/agtech";
import { sortByArrayLength } from "../../lib/prelude";
import { compareToBase } from "../../lib/compare";

const BasePairFormSchema = {
    contract_no: { label: "Contract No", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Required },
};

const BaseAgtechFormSchema = {
    id: { hidden: true, input_type: InputType.Uuid, input_type_validation: InputFormType.Uuid, required_status: InputRequiredStatus.Optional },

    pair_button: { label: "Merge", action_reference_id: "onPairButton", action: "button", derived: true, skipForm: true, apiDisplayFormat: ({ original }: any) => {
        return (
            <MergeIcon className="h-4 w-4" />
        );
    } },

    ["Location"]: { label: "Location", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Optional },
    ["P/S/O"]: { label: "P/S/O", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Optional },
    ["Cont"]: { label: "Cont", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Optional },
    ["Contract No"]: { label: "Contract No", input_type: InputType.Decimal, input_type_validation: InputFormType.Decimal, required_status: InputRequiredStatus.Optional },
    ["Start Date"]: { label: "Start Date", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Optional, apiDisplayFormat: ({ original }: any) => { return createUtcDateLabel(original["Start Date"]); } },
    ["End Date"]: { label: "End Date", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Optional, apiDisplayFormat: ({ original }: any) => { return createUtcDateLabel(original["End Date"]); } },
    ["Commodity"]: { label: "Commodity", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Optional },
    ["Id"]: { label: "Id", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Optional },
    ["Trader"]: { label: "Trader", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Optional },
    ["Futures"]: { label: "Futures", input_type: InputType.Decimal, input_type_validation: InputFormType.Decimal, required_status: InputRequiredStatus.Optional },
    ["Basis"]: { label: "Basis", input_type: InputType.Decimal, input_type_validation: InputFormType.Decimal, required_status: InputRequiredStatus.Optional },
    ["Quantity"]: { label: "Quantity", input_type: InputType.Decimal, input_type_validation: InputFormType.Decimal, required_status: InputRequiredStatus.Optional },
    ["Month"]: { label: "Month", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Optional },
    ["Pr  UOM"]: { label: "Pr  UOM", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Optional },
    ["Cur"]: { label: "Cur", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Optional },
    ["Cont  Date"]: { label: "Cont  Date", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Optional, apiDisplayFormat: ({ original }: any) => { return createUtcDateLabel(original["Cont  Date"]); } },
    ["Market Zone"]: { label: "Market Zone", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Optional },
};

export const CreateJobFormSchema = {
    agtech_file: { label: "Agtech Excel", fileExtensions: [".xlsx", ".xls"], input_type: InputType.File, input_type_validation: InputFormType.File, required_status: InputRequiredStatus.Required },
};

const createJobState = FormHelper.generateBaseState({
    schemas: [CreateJobFormSchema],
    baseSchema: CreateJobFormSchema,
});

const basePairFormState = FormHelper.generateBaseState({
    schemas: [BasePairFormSchema],
    baseSchema: BasePairFormSchema,
});

export function AgtechContractsDashboard() {
    const [jobId, setJobId] = useState<string | undefined>(undefined);
    const [tabsValue, onTabValueChange] = useState<string | undefined>("upload");
    const jobStatusRes = useGetCreateJobStatus(jobId);

    /* const listOtcRes = useListOtcAgtechData(); */
    const listGeneralRes = useListGeneralContractAgtechData();
    const loadingOrErrorUi = GenerateLoadingOrError([listGeneralRes])
    const { toast } = useToast();

    if (loadingOrErrorUi) {
         return (
             <section className="flex min-h-full flex-1 flex-col justify-start px-6 py-6 lg:px-8">
                <h1 className="scroll-m-20 text-2xl font-extrabold tracking-tight lg:text-3xl">
                    Manage Agtech
                </h1>
                {loadingOrErrorUi}
            </section>
        );
    }

    const hasFetchedJob = !!jobStatusRes?.data;
    const jobStatus = jobStatusRes?.data?.data?.job_status;

    return (
        <section className="flex min-h-full flex-1 flex-col justify-start px-6 py-6 lg:px-8">
            <h1 className="scroll-m-20 text-2xl font-extrabold tracking-tight lg:text-3xl">
                Manage Agtech
            </h1>
            <div className="flex items-start flex-col h-full mt-2">
                <Tabs value={tabsValue} onValueChange={onTabValueChange} className="w-full">
                    <TabsList>
                        <TabsTrigger value="upload">Upload</TabsTrigger>
                        <TabsTrigger value="general_contracts">General Contracts</TabsTrigger>
                    </TabsList>
                    <TabsContent value="upload">
                        <div className="mt-8" />
                        <CreateJobForm setJobId={setJobId} />
                        { !!hasFetchedJob && (
                              <JobStatus jobStatus={jobStatus} setJobId={setJobId} onTabValueChange={onTabValueChange}/>
                          )}
                    </TabsContent>
                    <TabsContent value="general_contracts">
                        <ManageGeneralContractsPairs stagedData={listGeneralRes?.data?.data as any}/>
                    </TabsContent>
                </Tabs>
            </div>
        </section>
    );
}

function ManageGeneralContractsPairs({ stagedData }: { stagedData: StagedAgtechData }) {
    const [contractRow, setContractRow] = useState<any | undefined>(undefined);

    return (
        <>
        <div className="flex justify-end mb-4">
            <MarkPairFormModal
                stagedRows={(stagedData?.staged_data || []).map((item: any) => ({ ...item.data, id: item.id }))}
                contractRow={contractRow}
                onDialogChange={
                (value: any) => {
                    if (!value) {
                        setContractRow(undefined);
                    }
                }
                }
            />
        </div>
        <PrimitiveManagedTable schema={BaseGeneralFormSchema} values={stagedData?.contracts || []} onRowClick={(item) => {
            setContractRow(item);
        }} />
        </>
    )
}

function MarkPairFormModal({ contractRow, onDialogChange, stagedRows }: { contractRow: any, onDialogChange: any, stagedRows: any[] }) {
    if (!contractRow) return null;
    return (
        <Dialog
            onOpenChange={onDialogChange}
            open={(!!contractRow) || false}
            defaultOpen={false}
        >
            <DialogContent className={cn("h-[98%] max-w-full sm:max-w-lg lg:max-w-3xl xl:max-w-7xl flex flex-col flex-start")}>
                <DialogHeader>
                    <DialogTitle>
                        Mark or Pair Dorman Row
                    </DialogTitle>
                </DialogHeader>
                <MarkOrPairForm onDialogChange={onDialogChange} contractRow={contractRow} stagedRows={stagedRows} />
            </DialogContent>
        </Dialog>
    );
}


function MarkOrPairForm({ contractRow, stagedRows, onDialogChange }: { contractRow: any; stagedRows: any[]; onDialogChange: any }) {
    const [isMerging, setIsMerge] = useState(false);
    const { toast } = useToast();
    const onPairStaged = usePairStagedData();
    const onMarkStaged = useMarkStagedData();

    if (!contractRow) return null;

    stagedRows.sort((a: any, b: any) => sortByArrayLength("unmatchedKeys")(
        compareToBase({ a: contractRow, b: a, options: AgtechCompare.baseOptions }),
        compareToBase({ a: contractRow, b: b, options: AgtechCompare.baseOptions }),
    ));

    return (
        <div className="max-w-full flex flex-col flex-start">
            <div className="text-xl font-bold tracking-tight ">
                Mark Current
            </div>
            <PrimitiveManagedTable tableHeight="120px" schema={BaseGeneralFormSchema} values={[contractRow]} />
            <div className="mt-2" />
            <FormGridHelper
                hideRelations
                hideSkipButton
                onSuccess={() => {}}
                state={basePairFormState}
                activeSchema={BasePairFormSchema}
                id={"AgtechMarkOrPairForm"}
                externalReferences={{}}
                failMessage="Failed to mark with Contract No"
                successMessage="Marked"
                onSubmitTo={(item: any) => {
                    return onMarkStaged({ contract_id: contractRow.id, contract_no: item.contract_no });
                }}
                onCloseForm={() => { onDialogChange(false); }}
            />

            <div className="text-xl font-bold tracking-tight ">
                Or pair to Agtech rows
            </div>
            <PrimitiveManagedTable
                tableHeight="420px"
                schema={BaseAgtechFormSchema} values={stagedRows}
                externalReferences={{
                    onPairButton: async (item: any) => {
                        if (isMerging) return;

                        setIsMerge(true);
                        const contract_id = contractRow.id;
                        const staged_id = item.id;
                        const result = await onPairStaged({ contract_id, staged_id });

                        if (!result.success) {
                            toast({
                                title: "Failed to merge",
                                description: "",
                            });
                            return;
                        }

                        toast({
                            title: "Merged",
                            description: "",
                        });
                        onDialogChange(false);
                    }
                }}
            />
        </div>
    );
}




function JobStatus({ jobStatus, setJobId, onTabValueChange }: { jobStatus: string; setJobId: any; onTabValueChange: any }) {
    const className = "text-lg font-bold tracking-tight mb-4";

    if (!jobStatus) { return (<div className={className}><Loader /></div>) }
    if (jobStatus === "queued") { return (<div className={className}>Processing</div>) }
    if (jobStatus === "doesnt_exist") { return (<div className={className}>Failed to find files</div>) }
    if (jobStatus === "failed") { return (<div className={className}>Failed to process</div>) }
    if (jobStatus === "halt") { return (<div className={className}>Failed to process</div>) }
    if (jobStatus !== "completed") { return (<div className={className}>Unknown</div>); }

    onTabValueChange("general_contracts");
    setJobId(undefined);
    return (<div className={className}>Completed</div>);
}


function CreateJobForm({ setJobId }: { setJobId: any }) {
    const inputState: any = createJobState.useInputsState((state: any) => state);
    const submissionState: any = createJobState.useSubmissionState((state: any) => state);
    const onCreateJob = useCreateAgtechUpload();
    const { toast } = useToast();

    return FormLayout.RenderFullPrimitiveGridLayout({
        hideSkipButton: true,
        id: "CreateDormanPreliminaryJob",
        activeSchema: CreateJobFormSchema,
        totalFields: inputState.totalFields,
        formValues: inputState.formValues,
        updateFormValue: inputState.updateFormValue,
        formRelationships: inputState.formRelationships,
        updateFormRelationship: inputState.updateFormRelationship,
        submissionState,
        externalReferences: {},
        onSubmitTo: async (item: any) => {
            if (![".xlsx", ".xls"].some((extension: string) => item.agtech_file?.name?.toLocaleLowerCase()?.endsWith(extension))) {
                toast({
                    title: "Invalid Agtech File",
                    description: "Must be an Excel file",
                });
                return new ValidationError("", {});
            }

            const createResult = await onCreateJob({ filename: item.agtech_file.name || "" });

            if (!createResult.success) {
                return createResult;
            }

            const resultData = createResult.data;
            setJobId(resultData.queuedJobId);

            await UploadPresignedFile(resultData.agtechUploadUrl, item.agtech_file);

            return createResult;
        },
        handleSubmitResponse: (result) => {
            if (result.statusCode === 400) {
                return;
            } else if (!result.success) {
                toast({
                    title: "Failed to upload file",
                    description: "",
                });
            } else {
                toast({
                    title: "Queued - Please wait.",
                    description: "",
                });
            }
        }
    });
}
