import { create } from "zustand";
import { useState } from "react";
import Decimal from "decimal.js";
import {
    Merge as MergeIcon
} from "lucide-react";
import moment from "moment-timezone";
import { GenerateLoadingOrError, genFlatOptionList, genIdOptionList, CommonDestinationTimezone } from "../../lib/utils";
import {
    InputRequiredStatus,
    InputFormType,
    InputType,
    ImportType,
    ExportType,
    InputFilter,
    InputRequiredStatusType,
} from "../../lib/form";
import {
    useListStagedData,
    StagedDormanTTRow,
    usePairStagedData,
    useMarkStagedData,
    useDownloadStagedData,
} from "../../lib/api/dorman";
import * as FormHelper from "../../lib/form";
import * as FormLayout from "../../lib/form/layout";
import {
    PrimitiveGridLayout,
    PrimitiveManagedTable,
    PrimitiveDialogForm,
} from "../../lib/form/layout";
import { Button } from "../../components/ui/button";
import { useToast } from "../../components/ui/use-toast";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogClose,
  DialogFooter,
} from "../../components/ui/dialog";
import { cn } from "../../lib/utils";
import { FormGridHelper } from "../reports/helper";
import { safeParseDecimal } from "../../lib/excel";
import { TradingTicketDashboard } from "../tickets/TradingDashboard";

const BaseTxtFormSchema = {
    id: { hidden: true, input_type: InputType.Uuid, input_type_validation: InputFormType.Uuid, required_status: InputRequiredStatus.Optional },

    ["Account"]: { label: "Account", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Required },
    ["Commodity Symbol"]: { label: "Commodity Symbol", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Required },
    ["Option Month"]: { label: "Option Month", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Required, apiDisplayFormat: ({ original }: any) => {
        return `${original["Option Month"]}${original["Year"]}`;
    }},
    ["Buy/Sell"]: { label: "Buy/Sell", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Required },
    ["Quantity"]: { label: "Quantity", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Required },
    ["Price"]: { label: "Price", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Required, apiDisplayFormat: ({ original }: any) => {
        return parseDecimalPlacesNumberWith(original["Price"], 7).toFixed();
    } },
    ["Trade Date"]: { label: "Trade Date", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Required, apiDisplayFormat: ({ original }: any) => {
        const date = original["Trade Date"] || "";
        return `${date.slice(4,6)}/${date.slice(-2)}/${date.slice(0,4)}`;
    } },
    ["Order No."]: { label: "Order No.", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Required },
};

const BasePairFormSchema = {
    note: { label: "Comments", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Required },
};

const BaseExcelFormSchema = {
    id: { hidden: true, input_type: InputType.Uuid, input_type_validation: InputFormType.Uuid, required_status: InputRequiredStatus.Optional },
    pair_button: { label: "Merge", action_reference_id: "onPairButton", action: "button", derived: true, skipForm: true, apiDisplayFormat: ({ original }: any) => {
        return (
            <MergeIcon className="h-4 w-4" />
        );
    } },

    ["Account"]: { label: "Account", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Required },
    ["Commodity"]: { label: "Commodity Symbol", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Required, apiDisplayFormat: ({ original }: any) => {
        return original["Commodity"] || original["Option Month Pair"];
    }},
    ["Option Month"]: { label: "Option Month", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Required },
    ["Type"]: { label: "Buy/Sell", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Required },
    ["Quantity"]: { label: "Quantity", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Required },
    ["Price"]: { label: "Price", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Required, apiDisplayFormat: ({ original }: any) => {
        return parseDecimalPlacesNumberWith(original["Price"], 7).toFixed();
    } },
    ["Date"]: { label: "Trade Date", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Required, apiDisplayFormat: ({ original }: any) => {
        const date = original["Date"] || "";
        return `${date.slice(4,6)}/${date.slice(-2)}/${date.slice(0,4)}`;
    } },
    ["Text A"]: { label: "Text A", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Required },
};

export const DownloadDynamicFormSchema = {
    id: { hidden: true, input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Optional },
};

const downloadDynamicState = FormHelper.generateBaseState({
    schemas: [DownloadDynamicFormSchema],
    baseSchema: DownloadDynamicFormSchema,
    overrideValues: { id: "" }
});

const basePairFormState = FormHelper.generateBaseState({
    schemas: [BasePairFormSchema],
    baseSchema: BasePairFormSchema,
});

function MarkPairFormModal({ stagedDormanRow, onDialogChange, stagedExcelTTRows }: { stagedDormanRow: any, onDialogChange: any, stagedExcelTTRows: StagedDormanTTRow[] }) {
    return (
        <Dialog
            onOpenChange={onDialogChange}
            open={(!!stagedDormanRow) || false}
            defaultOpen={false}
        >
            <DialogContent className={cn("h-[98%] max-w-full sm:max-w-lg lg:max-w-3xl xl:max-w-7xl flex flex-col flex-start")}>
                <DialogHeader>
                    <DialogTitle>
                        Mark or Pair Dorman Row
                    </DialogTitle>
                </DialogHeader>
                <MarkOrPairForm onDialogChange={onDialogChange} stagedDormanRow={stagedDormanRow} stagedExcelTTRows={stagedExcelTTRows} />
            </DialogContent>
        </Dialog>
    );
}

function MarkOrPairForm({ stagedDormanRow, stagedExcelTTRows, onDialogChange }: { stagedDormanRow: any; stagedExcelTTRows: StagedDormanTTRow[]; onDialogChange: any }) {
    const [isMerging, setIsMerge] = useState(false);
    const { toast } = useToast();
    const onPairStaged = usePairStagedData();
    const onMarkStaged = useMarkStagedData();

    if (!stagedDormanRow) return null;

    return (
        <div className="max-w-full flex flex-col flex-start">
            <div className="text-xl font-bold tracking-tight ">
                Mark Current
            </div>
            <PrimitiveManagedTable tableHeight="100px" schema={BaseTxtFormSchema} values={[stagedDormanRow]} />
            <div className="mt-2" />
            <FormGridHelper
                hideSkipButton
                onSuccess={() => {}}
                state={basePairFormState}
                activeSchema={BasePairFormSchema}
                id={"DormanMarkOrPairForm"}
                externalReferences={{}}
                failMessage="Failed to mark with Comment"
                successMessage="Marked"
                onSubmitTo={(item: any) => {
                    return onMarkStaged({ stagedDormanTxtId: stagedDormanRow.id, note: item.note });
                }}
                onCloseForm={() => { onDialogChange(false); }}
            />

            <div className="text-xl font-bold tracking-tight ">
                Or pair to TT rows
            </div>
            <PrimitiveManagedTable
                tableHeight="400px"
                schema={BaseExcelFormSchema} values={stagedExcelTTRows}
                externalReferences={{
                    onPairButton: async (item: any) => {
                        if (isMerging) return;

                        setIsMerge(true);
                        const stagedDormanTxtId = stagedDormanRow.id;
                        const stagedDormanTTId = item.id;
                        const result = await onPairStaged({ stagedDormanTxtId, stagedDormanTTId });

                        if (!result.success) {
                            toast({
                                title: "Failed to merge",
                                description: "",
                            });
                            return;
                        }

                        toast({
                            title: "Merged",
                            description: "",
                        });
                        onDialogChange(false);
                    }
                }}
            />
        </div>
    );
}

function DownloadDynamicForm({ onCloseForm, onSubmitForm, onSuccess }: { preventSubmitClose?: boolean; onCloseForm: any; onSubmitForm: any; onSuccess: any })  {
    return (
        <FormGridHelper
        onSuccess={onSuccess}
        state={downloadDynamicState}
        activeSchema={downloadDynamicState}
            id={"DownloadStagedFormDynamic"}
            externalReferences={{}}
            failMessage="Failed to generate staged data"
            successMessage="Generated"
            onSubmitTo={onSubmitForm}
            onCloseForm={onCloseForm}
            preventSubmitClose={true}
        />
    )
}

function DownloadStagedModal() {
    const [isOpen, setIsOpen] = useState(false);
    const [links, setLinks] = useState<any[]>([]);
    const onGenerateLinks = useDownloadStagedData();

    const onSuccess = (res: any) => {
        if (res.downloadPresignUrl) {
            setLinks([{ label: "Download Staged", link: res.downloadPresignUrl }]);
        } else {
            setLinks([]);
        }
    };

    return (
        <PrimitiveDialogForm
            className=""
            isOpen={isOpen}
            onDialogChange={setIsOpen}
            buttonLabel="Generate"
            dialogTitle={"Download Staged Data"}
            footer={(<span>
                       {links.map((wrapper) => {
                           return (
                               <a
                                   key={`DownloadStagedData:${wrapper.label}`}
                                   href={wrapper.link}
                                   target="_blank"
                                   className="underline underline-offset-1 decoration-sky-500"
                               >
                                       {wrapper.label}
                               </a>
                           )
                       })}
            </span>)}
            form={<DownloadDynamicForm onCloseForm={setIsOpen} onSubmitForm={onGenerateLinks} onSuccess={onSuccess}/>}
        />
    );
}

export function DormanStagedDashboard() {
    const [stagedDormanRow, setStagedDormanRow] = useState<any | undefined>(undefined);
    const listRes = useListStagedData();
    const loadingOrErrorUi = GenerateLoadingOrError([listRes])
    const { toast } = useToast();

    if (loadingOrErrorUi) {
         return (
             <section className="flex min-h-full flex-1 flex-col justify-start px-6 py-6 lg:px-8">
                <h1 className="scroll-m-20 text-2xl font-extrabold tracking-tight lg:text-3xl">
                    Manage Dorman Staged
                </h1>
                {loadingOrErrorUi}
            </section>
        );
    }

    const stagedTxtDormanRows = (listRes.data?.data?.dormanRows || []);
    const stagedExcelTTRows = (listRes.data?.data?.ttRows || []).sort((a: any, b: any) => {
        if (!!a["Commodity"] && !b["Commodity"]) { return -1; }
        else if (!a["Commodity"] && !!b["Commodity"]){ return 1; }
        else if (!a["Commodity"] && !b["Commodity"]) { return 0; }

        return a["Commodity"].localeCompare(b["Commodity"])
    });

    return (
        <section className="flex min-h-full flex-1 flex-col justify-start px-6 py-6 lg:px-8">
            <h1 className="scroll-m-20 text-2xl font-extrabold tracking-tight lg:text-3xl">
                Manage Dorman Staged
            </h1>
            <div className="flex justify-end mb-4">
                <DownloadStagedModal />
                <MarkPairFormModal
                    stagedExcelTTRows={stagedExcelTTRows.map((item: any) => ({ ...item.data, id: item.id }))}
                    stagedDormanRow={stagedDormanRow}
                    onDialogChange={
                    (value: any) => {
                        if (!value) {
                            setStagedDormanRow(undefined);
                        }
                    }
                    }
                />
            </div>
            <PrimitiveManagedTable schema={BaseTxtFormSchema} values={stagedTxtDormanRows.map((item: any) => ({ ...item.data, id: item.id, }))} onRowClick={(item) => {
                setStagedDormanRow(item);
            }} />

            <div className="mt-4" />
            <TradingTicketDashboard includeRecentDownloads={false} label="Mapped Trading Tickets" filterTo={["Dorman Preliminary Order Reports"]}/>
        </section>
    );
}

function parseDecimalPlacesNumberWith(numberString: string, assumedPlaces: number) {
  // Ensure the string has at least 7 characters
  if (numberString.length < assumedPlaces) {
    return safeParseDecimal(0);
  }

  // Insert the decimal point 7 digits from the end
  const decimalPosition = numberString.length - assumedPlaces;
  const formattedNumber = numberString.slice(0, decimalPosition) + '.' + numberString.slice(decimalPosition);

  // Create and return a new Decimal object
  return safeParseDecimal(formattedNumber);
}
