import { create } from "zustand";
import { useState, useEffect } from "react";
import { useStytchMember, useStytchMemberSession } from "@stytch/react/b2b";
import moment from "moment-timezone";
import {
  GenerateLoadingOrError,
  genFlatOptionList,
  genIdOptionList,
  CommonDestinationTimezone,
  createDateLabel,
} from "../../lib/utils";
import {
  Archive as ArchiveXIcon,
  ListFilter as ListFilterIcon,
  ArrowDownWideNarrow as SortIcon,
} from "lucide-react";
import { CommodityOptions, Commodities } from "../../lib/api/commodity";
import {
  InputRequiredStatus,
  InputFormType,
  InputType,
  ImportType,
  ExportType,
  InputFilter,
  InputRequiredStatusType,
} from "../../lib/form";
import {
  Uom,
  Currency,
  CostCategory,
  CalculateOn,
  AddDedAdjType,
  RateType,
  ContractType,
  PurchaseSaleType,
  useListGeneralContracts,
  useListLocations,
  useListMarketZones,
  GeneralContract,
  useCreateGeneralContract,
  useUpdateGeneralContract,
  Location,
  MarketZone,
  purchaseSaleOptions,
  uomOptions,
  currencyOptions,
  generateContractTypesWith,
  calculateOnOptions,
  rateTypeOptions,
  costCategoryOptions,
  addDedADjOptions,
  deliveryTermsOptions,
  DeliveryTerms,
  useDownloadOpenRecentContractData,
  useDownloadOpenImportContractData,
  useArchiveGeneralContract,
  ContractFilterValidation,
  ContractSortValidation,
  ContractSortOptions,
  ContractFilterOptions,
  ContractSortFiltersOptions,
  ContractSourceType,
  mzVendorOptions,
} from "../../lib/api/generalContract";
import { useListTraders } from "../../lib/api/trader";
import { reduceArrayTo, formulateOptionMonths } from "../../lib/api/common";
import { useListCustomers } from "../../lib/api/customer";
import { Trader, Customer } from "../../lib/api/reports";
import * as FormHelper from "../../lib/form";
import * as FormLayout from "../../lib/form/layout";
import {
  PrimitiveGridLayout,
  PrimitiveManagedTable,
  PrimitiveDialogForm,
} from "../../lib/form/layout";
import { Button } from "../../components/ui/button";
import { useToast } from "../../components/ui/use-toast";
import { FormGridHelper } from "../reports/helper";
import {
  BaseGeneralFormSchema,
  ContractAddDedAdjBaseForm,
  ContractMzAdjBaseForm,
  FilterFormSchema,
  SortFormSchema,
  SortingOptions,
} from "../../lib/schemas/generalContract";
import { getUserStytchName } from "../../lib/models/auth";

export const DownloadRecentFormSchema = {
  created_date: {
    label: "Created Date",
    input_type: InputType.Date,
    input_type_validation: InputFormType.Date,
    required_status: InputRequiredStatus.Required,
  },
};

export const BaseFormSchema = {
  archive_contract_button: {
    label: "Archive",
    action_reference_id: "onArchive",
    action: "button",
    derived: true,
    skipForm: true,
    apiDisplayFormat: ({ original }: any) => {
      return <ArchiveXIcon className="h-4 w-4" />;
    },
  },
  ...BaseGeneralFormSchema,
};

const useSortFilterState = create((set) => ({
  sorting: {
    contract_no: "desc",
  } as ContractSortOptions,
  filters: {} as ContractFilterOptions,
  setSortState: (newState: ContractSortOptions) =>
    set((state: any) => ({ sorting: newState })),
  setFilterState: (newState: ContractFilterOptions) =>
    set((state: any) => ({ filters: newState })),
  clearAll: () => set((state: any) => ({ filters: {}, sorting: {} })),
}));

const createState = FormHelper.generateBaseState({
  schemas: [BaseFormSchema],
  baseSchema: BaseFormSchema,
});

const updateState = FormHelper.generateBaseState({
  schemas: [BaseFormSchema],
  baseSchema: BaseFormSchema,
});

const sortOptionsState = FormHelper.generateBaseState({
  schemas: [SortFormSchema],
  baseSchema: SortFormSchema,
  overrideValues: { contract_no: "desc" },
});

const filterOptionsState = FormHelper.generateBaseState({
  schemas: [FilterFormSchema],
  baseSchema: FilterFormSchema,
});

const downloadRecentState = FormHelper.generateBaseState({
  schemas: [DownloadRecentFormSchema],
  baseSchema: DownloadRecentFormSchema,
});

const downloadImportState = FormHelper.generateBaseState({
  schemas: [DownloadRecentFormSchema],
  baseSchema: DownloadRecentFormSchema,
});

function DownloadImportDynamicForm({
  onCloseForm,
  onSubmitForm,
  onSuccess,
}: {
  preventSubmitClose?: boolean;
  onCloseForm: any;
  onSubmitForm: any;
  onSuccess: any;
}) {
  return (
    <FormGridHelper
      onSuccess={onSuccess}
      state={downloadImportState}
      activeSchema={DownloadRecentFormSchema}
      id={"DownloadImportGeneralContract"}
      externalReferences={{}}
      failMessage="Failed to generate import contract"
      successMessage="Generated"
      onSubmitTo={onSubmitForm}
      onCloseForm={onCloseForm}
      preventSubmitClose={true}
    />
  );
}

function DownloadRecentDynamicForm({
  onCloseForm,
  onSubmitForm,
  onSuccess,
}: {
  preventSubmitClose?: boolean;
  onCloseForm: any;
  onSubmitForm: any;
  onSuccess: any;
}) {
  return (
    <FormGridHelper
      onSuccess={onSuccess}
      state={downloadRecentState}
      activeSchema={DownloadRecentFormSchema}
      id={"DownloadRecentGeneralContract"}
      externalReferences={{}}
      failMessage="Failed to generate recent contract"
      successMessage="Generated"
      onSubmitTo={onSubmitForm}
      onCloseForm={onCloseForm}
      preventSubmitClose={true}
    />
  );
}

function SortDynamicForm({ onCloseForm }: { onCloseForm: any }) {
  const setSortState = useSortFilterState((state: any) => state.setSortState);
  return (
    <FormGridHelper
      state={sortOptionsState}
      activeSchema={SortFormSchema}
      id={"SortGeneralContracts"}
      externalReferences={{
        SortingOptions,
      }}
      failMessage="Failed to apply sorting"
      successMessage="Sorting"
      onSubmitTo={(sortOptions: any) => {
        setSortState(sortOptions);
        return { success: true };
      }}
      onCloseForm={onCloseForm}
      preventSubmitClose={false}
      preventClearForm
    />
  );
}

function FilterDynamicForm({
  onCloseForm,
  traders,
  customers,
  locations,
}: {
  onCloseForm: any;
  locations: Location[];
  customers: Customer[];
  traders: Trader[];
}) {
  const setFilterState = useSortFilterState(
    (state: any) => state.setFilterState
  );

  return (
    <FormGridHelper
      state={filterOptionsState}
      activeSchema={FilterFormSchema}
      id={"FilterGeneralContracts"}
      externalReferences={{
        locations,
        locationOptions: genIdOptionList(locations, "long_name"),
        customerOptions: genIdOptionList(customers, "long_name"),
        commodityOptions: CommodityOptions,
        traderOptions: genIdOptionList(traders, "name"),
        optionMonthOptions: formulateOptionMonths(),
        contractTypeOptions: genFlatOptionList(generateContractTypesWith({})),
      }}
      failMessage="Failed to apply filters"
      successMessage="Filtering"
      onSubmitTo={(sortOptions: any) => {
        setFilterState(sortOptions);
        return { success: true };
      }}
      onCloseForm={onCloseForm}
      preventSubmitClose={false}
      preventClearForm
    />
  );
}

function CreateDynamicForm({
  onCloseForm,
  traders,
  customers,
  locations,
  marketZones,
}: {
  onCloseForm: any;
  locations: Location[];
  marketZones: MarketZone[];
  customers: Customer[];
  traders: Trader[];
}) {
  const createApiContract = useCreateGeneralContract();
  const populateWith: any = createState.useInputsState((state: any) => state.populateWith);
  const { member } = useStytchMember();
  const member_name = getUserStytchName(member);

  useEffect(() => {
    const trader_id = traders.find((trader: Trader) => trader.name === member_name)?.id;
    populateWith({
      contract_date: moment.tz(CommonDestinationTimezone).toDate(),
      currency: Currency.UsDollar,
      trader_id,
      uom: Uom.Bushel,
      price_uom: Uom.Bushel,
    })
  }, [])

  return (
    <FormGridHelper
      state={createState}
      activeSchema={BaseFormSchema}
      id={"CreateGeneralContract"}
      externalReferences={{
        locations,
        marketZones,
        marketZonesOptions: genIdOptionList(marketZones, "market_zone"),
        locationOptions: genIdOptionList(locations, "long_name"),
        customerOptions: genIdOptionList(customers, "long_name"),
        commodityOptions: CommodityOptions,
        purchaseSaleOptions,
        uomOptions,
        currencyOptions,
        costCategoryOptions,
        rateTypeOptions,
        calculateOnOptions,
        addDedADjOptions,
        deliveryTermsOptions,
        mzVendorOptions,
        traderOptions: genIdOptionList(traders, "name"),
        optionMonthOptions: formulateOptionMonths(),
        contractTypeOptions: genFlatOptionList(generateContractTypesWith({})),
      }}
      failMessage="Failed to create contract"
      successMessage="Created"
      onSubmitTo={createApiContract}
      onCloseForm={onCloseForm}
      preventSubmitClose={false}
    />
  );
}

function UpdateDynamicForm({
  onCloseForm,
  traders,
  customers,
  locations,
  marketZones,
}: {
  onCloseForm: any;
  locations: Location[];
  marketZones: MarketZone[];
  customers: Customer[];
  traders: Trader[];
}) {
  const updateApiContract = useUpdateGeneralContract();
  return (
    <FormGridHelper
      state={updateState}
      id={"UpdateGeneralContract"}
      activeSchema={BaseFormSchema}
      externalReferences={{
        locations,
        marketZones,
        marketZonesOptions: genIdOptionList(marketZones, "market_zone"),
        locationOptions: genIdOptionList(locations, "long_name"),
        customerOptions: genIdOptionList(customers, "long_name"),
        commodityOptions: CommodityOptions,
        purchaseSaleOptions,
        uomOptions,
        currencyOptions,
        costCategoryOptions,
        rateTypeOptions,
        addDedADjOptions,
        calculateOnOptions,
        deliveryTermsOptions,
        mzVendorOptions,
        traderOptions: genIdOptionList(traders, "name"),
        optionMonthOptions: formulateOptionMonths(),
        contractTypeOptions: genFlatOptionList(generateContractTypesWith({})),
      }}
      failMessage="Failed to update contract"
      successMessage="Updated"
      onSubmitTo={updateApiContract}
      onCloseForm={onCloseForm}
      preventSubmitClose={false}
    />
  );
}

function SortFormModal({}: {}) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <PrimitiveDialogForm
      className="max-w-[98%] h-[98%]"
      isOpen={isOpen}
      onDialogChange={setIsOpen}
      buttonIcon={<SortIcon />}
      dialogTitle={"Sort Options"}
      form={<SortDynamicForm onCloseForm={setIsOpen} />}
    />
  );
}

function FilterFormModal({
  customers,
  traders,
  locations,
}: {
  locations: Location[];
  customers: Customer[];
  traders: Trader[];
}) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <PrimitiveDialogForm
      className="max-w-[98%] h-[98%]"
      isOpen={isOpen}
      onDialogChange={setIsOpen}
      buttonIcon={<ListFilterIcon />}
      dialogTitle={"Filter Options"}
      form={
        <FilterDynamicForm
          onCloseForm={setIsOpen}
          locations={locations}
          customers={customers}
          traders={traders}
        />
      }
    />
  );
}

function CreateFormModal({
  customers,
  traders,
  locations,
  marketZones,
}: {
  locations: Location[];
  marketZones: MarketZone[];
  customers: Customer[];
  traders: Trader[];
}) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <PrimitiveDialogForm
      className="max-w-[98%] h-[98%]"
      isOpen={isOpen}
      onDialogChange={setIsOpen}
      buttonLabel={"Create Contract"}
      dialogTitle={"Create Contract"}
      form={
        <CreateDynamicForm
          onCloseForm={setIsOpen}
          locations={locations}
          customers={customers}
          marketZones={marketZones}
          traders={traders}
        />
      }
    />
  );
}

function UpdateFormModal({
  isOpen,
  onDialogChange,
  traders,
  customers,
  locations,
  marketZones,
}: {
  isOpen: boolean;
  onDialogChange: any;
  locations: Location[];
  marketZones: MarketZone[];
  customers: Customer[];
  traders: Trader[];
}) {
  return (
    <PrimitiveDialogForm
      className="max-w-[98%] h-[98%]"
      isOpen={isOpen}
      onDialogChange={onDialogChange}
      dialogTitle={"Update Contract"}
      form={
        <UpdateDynamicForm
          onCloseForm={onDialogChange}
          locations={locations}
          customers={customers}
          marketZones={marketZones}
          traders={traders}
        />
      }
    />
  );
}

function DownloadImportReportsModal() {
  const [isOpen, setIsOpen] = useState(false);
  const [links, setLinks] = useState<any[]>([]);
  const onGenerateLinks = useDownloadOpenImportContractData();

  const onSuccess = (res: any) => {
    if (res.import_new_contracts_link) {
      setLinks([
        { label: "Download Import", link: res.import_new_contracts_link },
      ]);
    } else {
      setLinks([]);
    }
  };

  return (
    <PrimitiveDialogForm
      className=""
      isOpen={isOpen}
      onDialogChange={setIsOpen}
      buttonLabel="Download Import"
      dialogTitle={"Download Import For"}
      footer={
        <span>
          {links.map((wrapper) => {
            return (
              <a
                key={`DownloadImport:${wrapper.label}`}
                href={wrapper.link}
                target="_blank"
                className="underline underline-offset-1 decoration-sky-500"
              >
                Download Report
              </a>
            );
          })}
        </span>
      }
      form={
        <DownloadImportDynamicForm
          onCloseForm={setIsOpen}
          onSubmitForm={onGenerateLinks}
          onSuccess={onSuccess}
        />
      }
    />
  );
}

function DownloadRecentReportsModal() {
  const [isOpen, setIsOpen] = useState(false);
  const [links, setLinks] = useState<any[]>([]);
  const onGenerateLinks = useDownloadOpenRecentContractData();

  const onSuccess = (res: any) => {
    if (res.import_recent_link) {
      setLinks([{ label: "Download Recent", link: res.import_recent_link }]);
    } else {
      setLinks([]);
    }
  };

  return (
    <PrimitiveDialogForm
      className=""
      isOpen={isOpen}
      onDialogChange={setIsOpen}
      buttonLabel="Download Recent"
      dialogTitle={"Download Contracts For"}
      footer={
        <span>
          {links.map((wrapper) => {
            return (
              <a
                key={`DownloadRecent:${wrapper.label}`}
                href={wrapper.link}
                target="_blank"
                className="underline underline-offset-1 decoration-sky-500"
              >
                Download Report
              </a>
            );
          })}
        </span>
      }
      form={
        <DownloadRecentDynamicForm
          onCloseForm={setIsOpen}
          onSubmitForm={onGenerateLinks}
          onSuccess={onSuccess}
        />
      }
    />
  );
}

export function GeneralContractsDashboard() {
  const { toast } = useToast();
  const [isUpdateOpen, setIsUpdateOpen] = useState(false);
  const [isArchiving, setIsArchiving] = useState(false);
  const sortFilterOptions = useSortFilterState((state: any) => ({
    sorting: state.sorting,
    filters: state.filters,
  }));
  const locationRes = useListLocations();
  const marketZoneRes = useListMarketZones();
  const customersResult = useListCustomers();
  const listTradersRes = useListTraders();
  const archiveGeneralContract = useArchiveGeneralContract();
  const contractsRes = useListGeneralContracts(sortFilterOptions);
  const loadingOrErrorUi = GenerateLoadingOrError([
    locationRes,
    marketZoneRes,
    customersResult,
    contractsRes,
    listTradersRes,
  ]);
  const updatePopulateWith: any = updateState.useInputsState(
    (state: any) => state.populateWith
  );
  const updateClearForm: any = updateState.useInputsState(
    (state: any) => state.clearForm
  );

  if (loadingOrErrorUi) {
    return (
      <section className="flex min-h-full flex-1 flex-col justify-start px-6 py-6 lg:px-8">
        <h1 className="scroll-m-20 text-2xl font-extrabold tracking-tight lg:text-3xl">
          General Contracts
        </h1>
        {loadingOrErrorUi}
      </section>
    );
  }

  const onArchive = async ({ id }: { id: string }) => {
    if (isArchiving) return;
    setIsArchiving(true);

    const result = await archiveGeneralContract({ id });
    setIsArchiving(false);

    if (!result.success) {
      toast({
        title: "Failed to archive contract",
        description: "",
      });
    } else {
      toast({
        title: "Archived",
        description: "",
      });
    }
  };

  const locations = locationRes.data?.data || [];
  const marketZones = marketZoneRes.data?.data || [];
  const customers = customersResult.data?.data || [];
  const contracts = contractsRes.data?.data || [];
  const traders = listTradersRes.data?.data || [];

  return (
    <section className="flex min-h-full flex-1 flex-col justify-start px-6 py-6 lg:px-8">
      <h1 className="scroll-m-20 text-2xl font-extrabold tracking-tight lg:text-3xl">
        General Contracts
      </h1>
      <div className="flex justify-end gap-4 mb-4">
        <DownloadImportReportsModal />
        <DownloadRecentReportsModal />
        <CreateFormModal
          locations={locations}
          marketZones={marketZones}
          customers={customers}
          traders={traders}
        />
        <UpdateFormModal
          traders={traders}
          locations={locations}
          marketZones={marketZones}
          customers={customers}
          isOpen={isUpdateOpen}
          onDialogChange={(value: any) => {
            if (!value) {
              setIsUpdateOpen(false);
              updateClearForm();
            }
          }}
        />
        <SortFormModal />
        <FilterFormModal
          locations={locations}
          customers={customers}
          traders={traders}
        />
        <ClearSortFilterButton />
      </div>
      <PrimitiveManagedTable
        tableHeight="600px"
        externalReferences={{
          onArchive: onArchive,
          onArchiveLoading: isArchiving,
        }}
        schema={BaseFormSchema}
        values={contracts}
        onRowClick={(item) => {
          setIsUpdateOpen(true);
          updatePopulateWith(item);
        }}
      />
    </section>
  );
}

function ClearSortFilterButton() {
  const clearFunc = useSortFilterState((state: any) => state.clearAll);
  const sortStateClear: any = sortOptionsState.useInputsState((state: any) => state.clearForm);
  const filterStateClear: any = filterOptionsState.useInputsState((state: any) => state.clearForm);

  return (
    <Button variant="secondary" onClick={() => {
        clearFunc();
        sortStateClear();
        filterStateClear();
    }} disabled={false}>
      Clear Sort/Filters
    </Button>
  );
}
