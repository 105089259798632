import * as XLSX from "xlsx";
import { v4 as uuidV4, validate as isValidUuid } from "uuid";
import moment from "moment-timezone";
import Decimal from "decimal.js";
import { Customer, Trader, TraderCustomerBulk } from "../api/reports";
import { ValidationError } from "../responseResults";
import { CommonDestinationTimezone } from "../utils";
import { findCustomerFrom, findTraderForCustomer } from "../customer";

export interface BaseInfo {
  fileCounts: {
    totalFilesCount: number;
    marketingFeesFileCount: number;
    accrualFileCount: number;
    masterFileCount: number;
  },
  utilizedSheetNames: string[],
  missingData: {
    settlementMissingSheet: number[],
    accrualMissingSheet: boolean;

    settlementMonthSummaryRows: string[],

    settlementMissingCustomers: string[],
    accrualMissingCustomers: string[],

    settlementMissingTrader: string[],
    accrualMissingTraderPair: string[],
  },
}

function generateMonthOffset(month: string) {
  // jan is month zero in moment
  const monthIndex = moment(month, "MMMM").month();

  // The data columns are groups of 4. Previous month / Current month Marketing Fees, Settlement, Amount
  const offset = monthIndex * 4;
  return [3 + offset, 3 + offset + 1];
}

function parseFromBrowserFile(
  file: File,
): Promise<{ success: boolean; data: ArrayBuffer; error: any }> {
  return new Promise((res) => {
    var reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = function (event) {
      res({
        success: true,
        data: event.target?.result as ArrayBuffer,
        error: null,
      });
    };
    reader.onerror = function (event) {
      res({ success: false, data: undefined as any, error: event });
    };
  });
}

export async function processFromFiles(
  files: Record<string, File>,
  traderInfo: TraderCustomerBulk,
) {
  const baseInfo = await assembleMarketingFeesAccrualChanges(files, traderInfo);
  return baseInfo;
}

function determineMonthFromFileName(fileName: string) {
  if (moment(fileName, "MMMM").isValid()) {
    return moment(fileName, "MMMM").format("MMMM");
  }

  if (moment(fileName, "MMM").isValid()) {
    return moment(fileName, "MMM").format("MMMM");
  }

  return undefined;
}

export function generateMonthFilenameInfo(files: Record<string, File>) {
   const fileNamesMonthInfo = Object.keys(files)
    .filter(
      (item) =>
        !(
          item.toLocaleLowerCase().includes("mflisting") ||
          item.toLocaleLowerCase().includes("fee accrual")
        ),
    )
    .map((fileName: string) => {
      const month = determineMonthFromFileName(fileName);

      if (!month) {
        throw new ValidationError("Failed to determine month", { fileName });
      }

      return { month, fileName };
    });

  const months = Array.from(
    new Set(fileNamesMonthInfo.map((item) => item.month)),
  );

  return { fileNamesMonthInfo, months };
}

function generateFileForMonth({
  baseInfo,
  files,
  traderInfo
}: {
  baseInfo: BaseInfo;
  files: Record<string, File>;
  traderInfo: TraderCustomerBulk;
}) {
  return async function generateFileForMonthHelper({
    month,
    monthFiles,
  }: {
    month: string,
    monthFiles: Record<string, File>
  }) {
    const marketingFeesFiles = await loadMarketingFeesWorkbooks(monthFiles);
    const accrualFile = await loadMarketingFeesAccrualWorkbook(files);
    const masterFile = await loadMasterMarketingFeeWorkbook(files);

    baseInfo.fileCounts.marketingFeesFileCount = marketingFeesFiles.length;
    baseInfo.fileCounts.accrualFileCount = !!accrualFile ? 1 : 0;
    baseInfo.fileCounts.masterFileCount = !!masterFile ? 1 : 0;

      const settlementPerSheet = aggregateSettlementFees(
        baseInfo,
        marketingFeesFiles,
        traderInfo,
      );

      const accrualAggregatePerCustomer = aggregateMarketingFees(
        baseInfo,
        accrualFile,
        traderInfo,
      );

      setMasterFile(
        baseInfo,
        month,
        masterFile,
        settlementPerSheet,
        accrualAggregatePerCustomer,
        traderInfo,
      );
  }
}

export async function assembleMarketingFeesAccrualChanges(
  files: Record<string, File>,
  traderInfo: TraderCustomerBulk,
) {
  const baseInfo = generateDefaultBaseInfo();
  baseInfo.fileCounts.totalFilesCount = Object.keys(files).length;

  const { fileNamesMonthInfo, months } = generateMonthFilenameInfo(files);

  await Promise.all(
    months.map(async (month) => {
      const monthFiles = fileNamesMonthInfo
        .filter((wrapper) => month === wrapper.month)
        .reduce(
          (acc, wrapper) => {
            (acc as any)[wrapper.fileName] = files[wrapper.fileName];
            return acc;
          },
          {} as Record<string, File>,
        );

      return generateFileForMonth({ baseInfo, files, traderInfo })({ month, monthFiles });
    }),
  );

  return baseInfo;
}

export function generateDefaultBaseInfo(): BaseInfo {
  return {
    fileCounts: {
      totalFilesCount: 0,
      marketingFeesFileCount: 0,
      accrualFileCount: 0,
      masterFileCount: 0,
    },
    utilizedSheetNames: [],
    missingData: {
      settlementMonthSummaryRows: [],
      settlementMissingSheet: [],
      accrualMissingSheet: false,
      settlementMissingCustomers: [],
      settlementMissingTrader: [],
      accrualMissingCustomers: [],
      accrualMissingTraderPair: [],
    },
  }
}

interface MarketingFeeRow {
  "Credit Amount": number; // 948.11
  "Debit Amount": number; // 0
  "Account Description": string; // "Merchandising Income"
  "Account No": string; // "0020000-4900-0000"
  Date: number; // 45301
  Description: string; // "1MATFAR MAT Farms"
  "G/L Transaction Number": number; // 105716
  "Journal Source": string; // "GPS"
  "Marketing Fee": number; // 948.11
  "Ref 1": string; // "5000160"
  Rev: string; // ""
  "User Id": string; // "SANDERSON"
  __rowNum__: number; // 4
}

interface AccrualRow {
  "customer/vendor name": string;
  __empty_1?: number;
  payment?: number;
  __rownum__: number;
}

function setMasterFile(
  baseInfo: BaseInfo,
  month: string,
  masterFile: any,
  settlementPerSheet: any,
  accrualFile: any,
  traderInfo: TraderCustomerBulk,
) {
  baseInfo.utilizedSheetNames.push(masterFile.SheetNames[0]);
  const sheet = masterFile.Sheets[masterFile.SheetNames[0]];

  var result = [];
  var row;
  var rowNum;
  var colNum;
  var range = XLSX.utils.decode_range(sheet["!ref"]);
  for (rowNum = range.s.r; rowNum <= range.e.r; rowNum++) {
    row = [];
    for (colNum = range.s.c; colNum <= range.e.c; colNum++) {
      var nextCell = sheet[XLSX.utils.encode_cell({ r: rowNum, c: colNum })];
      if (typeof nextCell === "undefined") {
        row.push(void 0);
      } else row.push(nextCell.w);
    }
    result.push(row);
  }

  const offsetRange = generateMonthOffset(month);
  const [previousMonth, currentMonth] = [
    `${moment(month, "MMMM").subtract(1, "month").format("MMMM")} MF`,
    `${moment(month, "MMMM").format("MMMM")} MF`,
  ];

  const end_index = result.findIndex(
    (row) =>
      row[0]?.toLocaleLowerCase().includes("errors") ||
      row[0]?.toLocaleLowerCase().includes("marketing fees"),
  );

  const mfClientIds: Record<string, string> = {};

  let last_index = 0;
  const new_result = result
    .slice(3, end_index)
    .map((row, index) => {
      last_index = index;

      const monthIndex = moment(month, "MMMM").month();
      const customerDescription: string = row[0];
      const previousMonthString =
        month === "January" ? row[2] : row[2 + monthIndex * 4 - 3];
      const previousMonthAmount =
        parseAccountingStringToFloat(previousMonthString);

      if (isNaN(previousMonthAmount)) {
        throw new ValidationError("Failed to parse previous month", {
          previousMonthString,
        });
      }

      const originalMarketingValue = parseAccountingStringToFloat(
        row[offsetRange[0]],
      );
      const originalSettlementValue = parseAccountingStringToFloat(
        row[offsetRange[1]],
      );

      let marketingFeesAmount = originalMarketingValue;
      let settlementAmount = originalSettlementValue;

      if (!row[0] && row.some((item) => !!item)) {
        return {
          customerDescription,
          previousMonth: previousMonthAmount,
          customer: undefined,
          marketingFeesAmount,
          settlementAmount,
          originalMarketing: originalMarketingValue,
          originalSettlement: originalSettlementValue,
          index,
        };
      } else if (!row[0]) {
        return {
          customerDescription,
          previousMonth: previousMonthAmount,
          customer: undefined,
          marketingFeesAmount,
          settlementAmount,
          originalMarketing: originalMarketingValue,
          originalSettlement: originalSettlementValue,
          index,
        };
      } else if (
        row[0]?.toLocaleLowerCase().includes("errors") ||
        row[0]?.toLocaleLowerCase().includes("marketing fees")
      ) {
        return;
      }

      const customer = findCustomerFrom(
        "Master",
        customerDescription,
        traderInfo.customers,
      );

      mfClientIds[customer?.client_id || customerDescription] =
        customer?.client_id || customerDescription;

      if (!customer) {
        return {
          customerDescription,
          previousMonth: previousMonthAmount,
          customer,
          marketingFeesAmount,
          settlementAmount,
          originalMarketing: originalMarketingValue,
          originalSettlement: originalSettlementValue,
          index,
        };
      }

      if (accrualFile[customer.client_id]) {
        marketingFeesAmount =
          accrualFile[customer.client_id]!.amount.toNumber();
      }

      if (settlementPerSheet[customer.client_id]) {
        settlementAmount =
          settlementPerSheet[customer.client_id]!.amount.toNumber();
      }

      return {
        customerDescription,
        previousMonth: previousMonthAmount,
        customer,
        marketingFeesAmount,
        settlementAmount,
        originalMarketing: originalMarketingValue,
        originalSettlement: originalSettlementValue,
        index,
      };
    })
    .filter((item) => !!item);

  const missingClientIds = Array.from(
    new Set([
      ...findMissingClientIds(mfClientIds, Object.keys(accrualFile)),
      ...findMissingClientIds(mfClientIds, Object.keys(settlementPerSheet)),
    ]),
  );

  if (missingClientIds.length > 0) {
    new_result.push({
      customerDescription: "",
      previousMonth: 0,
      customer: "",
      marketingFeesAmount: 0,
      settlementAmount: 0,
      originalMarketing: 0,
      originalSettlement: 0,
      index: last_index + 0 + 1,
    } as any);

    new_result.push(
      ...missingClientIds.map((client_id, index) => {
        return {
          customerDescription:
            accrualFile[client_id]?.customer?.long_name ||
            settlementPerSheet[client_id]?.customer?.long_name,
          previousMonth: 0,
          customer:
            accrualFile[client_id]?.customer ||
            settlementPerSheet[client_id]?.customer,
          marketingFeesAmount: accrualFile[client_id]
            ? accrualFile[client_id]!.amount.toNumber()
            : 0,
          settlementAmount: settlementPerSheet[client_id]
            ? settlementPerSheet[client_id]!.amount.toNumber()
            : 0,
          originalMarketing: 0,
          originalSettlement: 0,
          index: last_index + index + 2,
        };
      }),
    );
  }

  const csv: any[] = [
    ["Customer Account Name", previousMonth, currentMonth, "Settlement"],
  ];

  new_result.forEach((item: any) => {
    const {
      marketingFeesAmount,
      settlementAmount,
      index,
    }: {
      marketingFeesAmount?: number;
      settlementAmount?: number;
      index: number;
    } = item!;
    const colStart = XLSX.utils.encode_col(offsetRange[0]);
    const colEnd = XLSX.utils.encode_col(offsetRange[1]);
    const rowStart = index + 1;
    const originStart = `${colStart}${rowStart}`;
    const originEnd = `${colEnd}${rowStart}`;

    csv.push([
      item.customerDescription,
      item.previousMonth * -1,
      marketingFeesAmount,
      settlementAmount,
    ]);
  });

  const wb = XLSX.utils.book_new();
  const csvSheet = XLSX.utils.aoa_to_sheet(csv);
  XLSX.utils.book_append_sheet(wb, csvSheet, "Sheet1");
  XLSX.writeFile(wb, `./${month}_marketingfees.xlsx`);
}

function parseAccountingStringToFloat(amount: string) {
  if (!amount) return 0;
  return parseFloat(amount?.replace(/\(|\)|[^(\d|.)]/gim, "") || "0");
}

// https://github.com/SheetJS/sheetjs/issues/128
function setSheetCellAccounting(sheet: any, cellId: string, amount?: number) {
  if (amount === null || amount === undefined) {
    return;
  }

  const formattedNumber = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(amount);
  const numberFormat = formattedNumber.replace(/\d/gim, "0");

  setSheetCell(sheet, cellId, {
    t: "n",
    v: amount,
    z: numberFormat,
    w: formattedNumber,
  });
}

function setSheetCell(
  sheet: any,
  cellId: string,
  options: { t: string; v: number; z: string; w: string },
) {
  sheet[cellId].t = options.t;
  sheet[cellId].v = options.v;
  sheet[cellId].z = options.z;
  sheet[cellId].w = options.w;
}

function guessAccrualSheet({
  workbook,
  baseInfo,
}: {
  workbook: XLSX.WorkBook;
  baseInfo: BaseInfo;
}) {
  const baseSheetName = "Mkt Fee Accruals";

  const sheet_names = [
    "",
    moment.tz(CommonDestinationTimezone).add(1, "months").format("MMM"),
    moment.tz(CommonDestinationTimezone).format("MMM"),
  ]
    .map((month: string) => {
      return `${month} Mkt Fee Accruals`.trim().toLocaleLowerCase();
    });

  const sheetData: AccrualRow[] | null = getSheetNameAndOrder({
    baseInfo,
    workbook,
    sheetGuess: (sheetName: string) => sheet_names.includes(sheetName.trim().toLocaleLowerCase()),
    takeFirstSheetIfOnly: true,
    lowerCaseKeys: true,
    rowSortKey: "customer/vendor name",
  });

  return sheetData;
}

function aggregateMarketingFees(
  baseInfo: BaseInfo,
  accrualFile: XLSX.WorkBook,
  traderInfo: TraderCustomerBulk,
) {
  if (!accrualFile) return [];
  const sheetData: AccrualRow[] | null = guessAccrualSheet({
    workbook: accrualFile,
    baseInfo
  });

  if (sheetData === null) {
    baseInfo.missingData.accrualMissingSheet = true;
    return [];
  }

  return sheetData
    .map((row) => {
      if (!row["customer/vendor name"]){
        return;
      }

      const payment = row["__empty_1"] || row["payment"];
      if (!payment) {
        return;
      }

      let rowCustomerName = row["customer/vendor name"]
        ?.trim()
        ?.replace(/(Total| Total)/gim, "");

      let customer = findCustomerFrom(
        "Accrual",
        rowCustomerName,
        traderInfo.customers,
      )!;

      if (!customer) {
        baseInfo.missingData.accrualMissingCustomers.push(rowCustomerName);

        const client_id = uuidV4();
        const name = rowCustomerName;

        customer = {
          client_id,
          short_name: name,
          long_name: name,
          meta_data: {},
        } as Customer;
        traderInfo.customers.push(customer);
      }

      let trader = findTraderForCustomer(customer, traderInfo)!;
      if (!trader && !isValidUuid(customer.client_id)) {
        baseInfo.missingData.accrualMissingTraderPair.push(customer.client_id);

        return undefined;
      }

      return {
        customer,
        trader,
        customerName: rowCustomerName,
        amount: new Decimal(payment),
        final: payment,
        rowNumber: row["__rownum__"],
      };
    })
    .filter((item) => !!item)
    .reduce(
      (acc, item) => {
        acc[item!.customer.client_id] = item;
        return acc;
      },
      {} as Record<string, any>,
    );
}

function aggregateSettlementFees(
  baseInfo: BaseInfo,
  workbooks: XLSX.WorkBook[],
  traderInfo: TraderCustomerBulk,
) {
  if (!workbooks?.length) return {};

  return workbooks.reduce(
    (acc, workbook: XLSX.WorkBook, index: number) => {
      const sheetData: MarketingFeeRow[] | null = getSheetNameAndOrder<MarketingFeeRow>({
        baseInfo,
        workbook,
        sheetGuess: (sheetName: string) => sheetName.toLocaleLowerCase().includes("marketing fees-"),
        takeFirstSheetIfOnly: true,
        lowerCaseKeys: false,
        rowSortKey: "Description",
      });

      if (sheetData === null) {
        baseInfo.missingData.settlementMissingSheet.push(index);
        return acc;
      }

      const allocationByTrader = sheetData
        .map((row) => {
          if (row.Description.toLocaleLowerCase().includes("marketing fee accrual")) {
            baseInfo.missingData.settlementMonthSummaryRows.push(row.Description);
            return;
          }

          const customer = findCustomerFrom(
            "LocationFees",
            row.Description,
            traderInfo.customers,
          )!;

          if (!customer) {
            baseInfo.missingData.settlementMissingCustomers.push(row.Description);
            return;
          }

          const trader = findTraderForCustomer(customer, traderInfo)!;
          if (!trader) {
            baseInfo.missingData.settlementMissingTrader.push(customer.client_id);
            return;
          }

          return { trader, row, customer };
        })
        .filter((item) => !!item);

      const customerGroupedAllocations = allocationByTrader.reduce(
        (acc, group) => {
          const client_id: string = group!.customer.client_id;
          if (!acc[client_id])
            acc[client_id] = {
              trader: group!.trader,
              customer: group!.customer,
              amount: new Decimal(0),
              final: 0,
              credit: 0,
              debit: 0,
            };

          const amount = new Decimal(group!.row["Credit Amount"]).sub(
            new Decimal(group!.row["Debit Amount"]),
          );
          acc[client_id].amount = acc[client_id].amount.plus(amount);
          acc[client_id].final = acc[client_id].amount.toNumber();
          acc[client_id].credit = new Decimal(
            group!.row["Credit Amount"],
          ).toNumber();
          acc[client_id].debit = new Decimal(
            group!.row["Debit Amount"],
          ).toNumber();

          return acc;
        },
        {} as Record<
          string,
          {
            trader: Trader;
            customer: Customer;
            amount: Decimal;
            final: number;
            credit: any;
            debit: any;
          }
        >,
      );

      return {
        ...acc,
        ...customerGroupedAllocations,
      };
    },
    {} as Record<string, any>,
  );
}


export function loadMarketingFeesWorkbooks(files: Record<string, File>) {
  return Promise.all(
    Object.keys(files)
      .filter((filename: string) => {
        return filename?.toLocaleLowerCase().includes("marketing fees-");
      })
      .map(async (filename: string) => {
        const result = await parseFromBrowserFile(files[filename]);
        return XLSX.read(result.data, { WTF: true });
      }),
  );
}

export async function loadMarketingFeesAccrualWorkbook(
  files: Record<string, File>,
) {
  const results = await Promise.all(
    Object.keys(files)
      .filter((filename: string) => {
        return filename
          ?.toLocaleLowerCase()
          .includes("mkt fee accrual final.xlsx");
      })
      .map(async (filename: string) => {
        const result = await parseFromBrowserFile(files[filename]);
        return XLSX.read(result.data);
      }),
  );
  return results?.[0];
}

export async function loadMasterMarketingFeeWorkbook(
  files: Record<string, File>,
) {
  const results = await Promise.all(
    Object.keys(files)
      .filter((filename: string) => {
        return filename?.toLocaleLowerCase().includes("mflisting");
      })
      .map(async (filename: string) => {
        const result = await parseFromBrowserFile(files[filename]);
        return XLSX.read(result.data);
      }),
  );
  return results?.[0];
}

function findMissingClientIds(
  mfClientIds: Record<string, string>,
  accrualFileClientIds: string[],
) {
  return accrualFileClientIds.filter((accrualClientId) => {
    return !mfClientIds[accrualClientId];
  });
}

function getSheetNameAndOrder<T>({
  baseInfo,
  workbook,
  sheetGuess,
  takeFirstSheetIfOnly,
  rowSortKey,
  lowerCaseKeys,
}: {
  baseInfo: BaseInfo;
  workbook: XLSX.WorkBook,
  sheetGuess: (sheetName: string) => boolean,
  takeFirstSheetIfOnly: boolean,
  lowerCaseKeys: boolean;
  rowSortKey: keyof T
}) {
  let sheetName = workbook.SheetNames.find(sheetGuess)!;
  if (!sheetName && takeFirstSheetIfOnly && workbook.SheetNames.length === 1) {
    sheetName = workbook.SheetNames[0];
  }
  if (!sheetName) return null;

  baseInfo.utilizedSheetNames.push(sheetName);

  let sheet = workbook.Sheets[sheetName];

  let sheetData: T[] = XLSX.utils.sheet_to_json(sheet);
  sheetData = sheetData.map((row: T) => {
    return Object.entries(row as any).reduce(
      (acc: T, [key, value]) => {
        (acc as any)[
          lowerCaseKeys ? key.trim().toLocaleLowerCase() : key.trim()
        ] = value;
        return acc;
      },
      {} as T,
    );
  });

  sheetData.sort((a, b) => {
    if (a[rowSortKey] === b[rowSortKey]) return 0;
    if (a[rowSortKey] < b[rowSortKey]) return -1;
    return 1;
  });

  return sheetData;
}
