import { create } from "zustand";
import { useState } from "react";
import moment from "moment-timezone";
import parsePhoneNumber, { AsYouType as PhoneNumberFormatterAsYouType } from 'libphonenumber-js';
import {
    Archive as ArchiveXIcon
} from "lucide-react";
import { GenerateLoadingOrError, genFlatOptionList, genIdOptionList, CommonDestinationTimezone } from "../../lib/utils";
import {
    InputRequiredStatus,
    InputFormType,
    InputType,
    ImportType,
    ExportType,
    InputFilter,
    InputRequiredStatusType,
} from "../../lib/form";
import {
    useListContacts,
    Contact,
    useCreateContact,
    useUpdateContact,
    useDeleteContact,

    contactTypeOptions,
    staffTypeOptions,
    staffSubTypeOptions,
    ContactType,
    StaffType,
    StaffSubType,
} from "../../lib/api/contacts";
import * as FormHelper from "../../lib/form";
import * as FormLayout from "../../lib/form/layout";
import {
    PrimitiveGridLayout,
    PrimitiveManagedTable,
    PrimitiveDialogForm,
} from "../../lib/form/layout";
import { Button } from "../../components/ui/button";
import { useToast } from "../../components/ui/use-toast";
import { isValidEmail } from "../../lib/prelude";

const BaseFormSchema = {
    id: { hidden: true, input_type: InputType.Uuid, input_type_validation: InputFormType.Uuid, required_status: InputRequiredStatus.Optional },

    archive_contract_button: { label: "Close", action_reference_id: "onArchive", action: "button", derived: true, skipForm: true, apiDisplayFormat: ({ original }: any) => {
        return (
            <ArchiveXIcon className="h-4 w-4" />
        );
    } },

    name: { label: "Name", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Required },
    email: { label: "Email", input_type: InputType.String, status: InputRequiredStatus.Optional,
        input_type_validation: InputFormType.Custom(({ email }: any) => {
            if (!email?.value) { return { isValid: true, errorMessage: "" } }
            const isValid = isValidEmail(email.value);
            return { isValid, errorMessage: isValid ? "" : "Invalid Type for Location" }
        }),
    },
    phone_number: { label: "Phone Number", input_type: InputType.String, required_status: InputRequiredStatus.Optional,
         apiDisplayFormat: ({ original }: any) => {
             if (!original.phone_number?.trim()) return original.phone_number || "";
             return new PhoneNumberFormatterAsYouType('US').input(original.phone_number);
         },
         input_formatter: (({ phone_number }: any, _key: string) => {
             if (!phone_number?.value) return phone_number?.value || "";
             return new PhoneNumberFormatterAsYouType('US').input(phone_number?.value);
         }),
         input_type_validation: InputFormType.Custom(({ phone_number }: any) => {
            if (!phone_number?.value?.trim()) { return { isValid: true, errorMessage: "" } }
            const isValid = isPhoneNumberValid(phone_number?.value);
            return { isValid, errorMessage: isValid ? "" : "Invalid phone number" }
        }),
    },

    contact_type: { hidden: true, label: "Contact Type", options_reference_id: "contactTypeOptions", input_type: InputType.Enum, enum: ContactType, input_type_validation: InputFormType.Enum(ContactType), required_status: InputRequiredStatus.Required },
    staff_type: { label: "Staff Type", options_reference_id: "staffTypeOptions", input_type: InputType.Enum, enum: StaffType, input_type_validation: InputFormType.Enum(StaffType), required_status: InputRequiredStatus.Required },
    staff_sub_type: { label: "Staff Sub Type", options_reference_id: "staffSubTypeOptions", input_type: InputType.Enum, enum: StaffSubType, input_type_validation: InputFormType.Enum(StaffSubType), required_status: InputRequiredStatus.Optional },
};

const createState = FormHelper.generateBaseState({
    schemas: [BaseFormSchema],
    baseSchema: BaseFormSchema,
    overrideValues: { contact_type: ContactType.Lighthouse }
});

const updateState = FormHelper.generateBaseState({
    schemas: [BaseFormSchema],
    baseSchema: BaseFormSchema,
});

function CreateDynamicForm({ onCloseForm }: { onCloseForm: any; }) {
    const inputState: any = createState.useInputsState((state: any) => state);
    const submissionState: any = createState.useSubmissionState((state: any) => state);
    const createApiItem = useCreateContact();
    const { toast } = useToast();

    return FormLayout.RenderFullPrimitiveGridLayout({
        id: "CreateContact",
        activeSchema: BaseFormSchema,
        totalFields: inputState.totalFields,
        formValues: inputState.formValues,
        updateFormValue: inputState.updateFormValue,
        formRelationships: inputState.formRelationships,
        updateFormRelationship: inputState.updateFormRelationship,
        submissionState,
        externalReferences: {
            contactTypeOptions,
            staffTypeOptions,
            staffSubTypeOptions,
        },
        onSubmitTo: (item: any) => {
            return createApiItem(item);
        },
        handleSubmitResponse: (result) => {
            if (!result.success) {
                toast({
                    title: "Failed to create Contact",
                    description: "",
                });
            } else {
                toast({
                    title: "Created",
                    description: "",
                });
                onCloseForm(false);
                inputState.clearForm();
            }
        }
    });
}

function UpdateDynamicForm({ onCloseForm }: { onCloseForm: any; }) {
    const inputState: any = updateState.useInputsState((state: any) => state);
    const submissionState: any = updateState.useSubmissionState((state: any) => state);
    const updateApiItem = useUpdateContact();
    const { toast } = useToast();

    return FormLayout.RenderFullPrimitiveGridLayout({
        id: "UpdateContact",
        activeSchema: BaseFormSchema,
        totalFields: inputState.totalFields,
        formValues: inputState.formValues,
        updateFormValue: inputState.updateFormValue,
        formRelationships: inputState.formRelationships,
        updateFormRelationship: inputState.updateFormRelationship,
        submissionState,
        externalReferences: {
            contactTypeOptions,
            staffTypeOptions,
            staffSubTypeOptions,
        },
        onSubmitTo: (item: any) => updateApiItem(item),
        handleSubmitResponse: (result) => {
            if (!result.success) {
                toast({
                    title: "Failed to update Contact",
                    description: "",
                });
            } else {
                toast({
                    title: "Updated",
                    description: "",
                });
                onCloseForm(false);
                inputState.clearForm();
            }
        }
    });
}

function CreateFormModal() {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <PrimitiveDialogForm isOpen={isOpen} onDialogChange={setIsOpen} buttonLabel={"Create Location"} dialogTitle={"Create Location"} form={<CreateDynamicForm onCloseForm={setIsOpen} />}/>
    );
}

function UpdateFormModal({ isOpen, onDialogChange }: { isOpen: boolean, onDialogChange: any; }) {
    return (
        <PrimitiveDialogForm isOpen={isOpen} onDialogChange={onDialogChange} dialogTitle={"Update Location"} form={<UpdateDynamicForm onCloseForm={onDialogChange} />}/>
    );
}

export function ContactsDashboard() {
    const { toast } = useToast();
    const [isArchiving, setIsArchiving] = useState(false);
    const [isUpdateOpen, setIsUpdateOpen] = useState(false);
    const contactsRes = useListContacts();
    const loadingOrErrorUi = GenerateLoadingOrError([contactsRes])
    const updatePopulateWith: any = updateState.useInputsState((state: any) => state.populateWith);
    const updateClearForm: any = updateState.useInputsState((state: any) => state.clearForm);
    const archiveItem = useDeleteContact();

    if (loadingOrErrorUi) {
         return (
             <section className="flex min-h-full flex-1 flex-col justify-start px-6 py-6 lg:px-8">
                <h1 className="scroll-m-20 text-2xl font-extrabold tracking-tight lg:text-3xl">
                    Manage Contacts
                </h1>
                {loadingOrErrorUi}
            </section>
        );
    }

    const contacts = contactsRes.data?.data || [];

  const onArchive = async ({ id }: { id: string }) => {
    if (isArchiving) return;
    setIsArchiving(true);

    const result = await archiveItem({ id });
    setIsArchiving(false);

    if (!result.success) {
      toast({
        title: "Failed to archive contact",
        description: "",
      });
    } else {
        toast({
            title: "Archived",
            description: "",
        });
    }
  };

    return (
        <section className="flex min-h-full flex-1 flex-col justify-start px-6 py-6 lg:px-8">
            <h1 className="scroll-m-20 text-2xl font-extrabold tracking-tight lg:text-3xl">
                Manage Contacts
            </h1>
            <p className="text-muted-foreground mt-2 mb-2">
                Manage the contacts shown in the mobile app.
            </p>
            <div className="flex justify-end mb-4">
                <CreateFormModal />
                <UpdateFormModal
                    isOpen={isUpdateOpen}
                    onDialogChange={
                      (value: any) => {
                        if (!value) {
                            setIsUpdateOpen(false);
                            updateClearForm();
                        }
                      }
                    }
                />
            </div>
            <PrimitiveManagedTable
                externalReferences={{
                    onArchive: onArchive,
                    onArchiveLoading: isArchiving,
                }}
                schema={BaseFormSchema} values={contacts} onRowClick={(item) => {
                setIsUpdateOpen(true);
                updatePopulateWith(item);
            }} />
        </section>
    );
}


export function isPhoneNumberValid(phoneNumber: string) {
    const parsedNumber = parsePhoneNumber(phoneNumber, 'US');
    if (!parsedNumber) return false;

    return parsedNumber.isPossible() && parsedNumber.isValid();
}
