import moment from "moment-timezone";
import { cn } from "../../lib/utils";
import { Combobox } from "../../components/ui/combobox";
import { DatePicker } from "../../components/ui/typeable_date_picker";
import { Input } from "../../components/ui/input";
import { SelectInput } from "../../components/ui/SelectInput";
import { MultiSelect } from "../../components/ui/selectMulti";
import { Label } from "../../components/ui/label";
import { CommonDestinationTimezone } from "../../lib/utils";
import { Checkbox } from "../../components/ui/checkbox";

const handleFocus = (event: any) => event.target.select();

// TODO create Input Error and Form error

// ---------------------
// Form Inputs
// ---------------------
export function BearDatePicker({
  value,
  onValueChange,
  name,
  required,
  label,
  tabIndex,
  error,
  minDate,
  maxDate,
}: {
  value: Date | undefined;
  onValueChange: (date: Date | undefined) => void;
  name: string;
  required?: boolean;
  label?: string;
  tabIndex?: number;
  error?: string,
  minDate?: Date,
  maxDate?: Date,
}) {
  const hasErrors = !!error;
  return (
    <div className="grid items-center gap-1.5">
      {label && (
        <Label
          htmlFor={name}
          className="flex flex-row justify-between text-base items-center"
        >
          <span className="font-semibold">{label}</span>
          {required ? (
            <span
              className="text-xs font-light"
              style={{ fontStyle: "italic" }}
            >
              Required
            </span>
          ) : (
            <span></span>
          )}
        </Label>
      )}
      <DatePicker
        tabIndex={tabIndex}
        value={value}
        minDate={minDate}
        maxDate={maxDate}
        onChange={(date) => {
          return onValueChange(date);
        }}
        placeholder={"MM/DD/YYYY"}
        className={cn(
          "rounded-md border-0 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6 w-full",
          hasErrors
            ? "ring ring-1 ring-red-500 focus:ring-red-500"
            : "",
        )}
      />
    </div>
  );
}

export function BearSelectInput({
    value,
    onValueChange,
    options,
    name,
    required,
    label,
    tabIndex,
  error,
}: {
  value: string;
  onValueChange: (item: string) => void;
  options: { value: string; label: string }[];
  name: string;
  required?: boolean;
  label?: string;
  tabIndex?: number;
  error?: string,
}) {
  const hasErrors = !!error;

  return (
    <div className="grid items-center gap-1.5">
      {label && (
        <Label
          htmlFor={name}
          className="flex flex-row justify-between text-base items-center"
        >
          <span className="font-semibold">{label}</span>
          {required ? (
            <span
              className="text-xs font-light"
              style={{ fontStyle: "italic" }}
            >
              Required
            </span>
          ) : (
            <span></span>
          )}
        </Label>
      )}

      <Combobox
        tabIndex={tabIndex}
        isModal
        className={cn(
          "w-full",
          hasErrors
          ? "ring ring-1 ring-red-500 focus:ring-red-500"
          : "",
        )}
        value={value}
        onValueChange={onValueChange}
        options={options}
      />
    </div>
  );
}


export function BearFloatInput({
    disabled,
    value,
    onChange,
    name,
    min,
    max,
    required,
    step,
    label,
    tabIndex,
  error,
}: {
  value: string;
  onChange: (item: string | number) => void;
  name: string;
  min?: string;
  max?: string;
  required?: boolean;
  step?: string;
  label?: string;
  disabled?: boolean;
  tabIndex?: number;
  error?: string,
}) {
  const hasErrors = !!error;

  return (
    <div className="grid items-center gap-1.5">
      {label && (
        <Label
          htmlFor={name}
          className="flex flex-row justify-between text-base items-center"
        >
          <span className="font-semibold">{label}</span>
          {required ? (
            <span
              className="text-xs font-light"
              style={{ fontStyle: "italic" }}
            >
              Required
            </span>
          ) : (
            <span></span>
          )}
        </Label>
      )}
      <Input
        tabIndex={tabIndex}
        disabled={disabled}
        value={value ? `${value}` : ""}
        id={name}
        step={step}
        type="text"
        onChange={(event: any) =>
          onChange(event.target.value?.replace(/[^\-0-9\.\$\(\) ]+/gim, ""))
        }
        className={cn(
          "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6",
          hasErrors
          ? "ring ring-1 ring-red-500 focus:ring-red-500"
            : "",
        )}
        onFocus={handleFocus}
      />
    </div>
  );
}

export function BearIntInput({
  disabled,
  placeholder,
  value,
  onChange,
  name,
  min,
  max,
  required,
  step,
  label,
  tabIndex,
  error,
}: {
  disabled?: boolean;
  placeholder?: string;
  value: number;
  onChange: (item: number) => void;
  name: string;
  min?: string;
  max?: string;
  required?: boolean;
  step?: string;
  label?: string;
  tabIndex?: number;
  error?: string,
}) {
  const hasErrors = !!error;

  return (
    <div className="grid items-center gap-1.5">
      {label && (
        <Label
          htmlFor={name}
          className="flex flex-row justify-between text-base items-center"
        >
          <span className="font-semibold">{label}</span>
          {required ? (
            <span
              className="text-xs font-light"
              style={{ fontStyle: "italic" }}
            >
              Required
            </span>
          ) : (
            <span></span>
          )}
        </Label>
      )}
      <Input
        disabled={disabled}
        value={value}
        id={name}
        type="number"
        required={required}
        min={min}
        max={max}
        step={step}
        onChange={parseIntEvent(onChange)}
        className={cn(
          "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6",
          hasErrors
          ? "ring ring-1 ring-red-500 focus:ring-red-500"
          : "",
        )}
        onFocus={handleFocus}
        tabIndex={tabIndex}
        placeholder={placeholder}
      />
    </div>
  );
}

export function BearCheckbox({
    placeholder,
    value,
    onChange,
    name,
    required,
    label,
    tabIndex,
    className,
  error,
}: {
  placeholder?: string;
  value: boolean;
  onChange: (event: any) => void;
  name: string;
  required?: boolean;
  label?: string;
  tabIndex?: number;
  className?: string;
  error?: string,
}) {
  const hasErrors = !!error;
  return (
    <div className="grid items-center gap-1.5">
      {label && (
        <Label
          htmlFor={name}
          className="flex flex-row justify-between text-base items-center"
        >
          <span className="font-semibold">{label}</span>
          {required ? (
            <span
              className="text-xs font-light"
              style={{ fontStyle: "italic" }}
            >
              Required
            </span>
          ) : (
            <span></span>
          )}
        </Label>
      )}
      <Checkbox
        required={required}
        id={name}
        checked={value}
        onCheckedChange={onChange}
        className={cn(
          "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6 h-6 w-6",
          hasErrors
          ? "ring ring-1 ring-red-500 focus:ring-red-500"
            : "",
        )}
        tabIndex={tabIndex}
        placeholder={placeholder}
      />
    </div>
  );
}

export function BearFile({
    placeholder,
  accept,
    value,
    onChange,
    name,
    required,
    label,
    tabIndex,
    className,
  disabled,
  error,
}: {
  accept: string;
  disabled?: boolean;
placeholder?: string;
  value?: string;
  onChange: (event: any) => void;
  name: string;
  required?: boolean;
  label?: string;
  tabIndex?: number;
  className?: string;
  error?: string,
}) {
  const hasErrors = !!error;
  return (
    <div className="grid items-center gap-1.5">
      {label && (
        <Label
          htmlFor={name}
          className="flex flex-row justify-between text-base items-center"
        >
          <span className="font-semibold">{label}</span>
          {required ? (
            <span
              className="text-xs font-light"
              style={{ fontStyle: "italic" }}
            >
              Required
            </span>
          ) : (
            <span></span>
          )}
        </Label>
      )}
      <Input
        accept={accept}
        disabled={disabled}
        id={name}
        value={value}
        type="file"
        required={required}
        onChange={onChange}
        className={cn(
          "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6",
          hasErrors
          ? "ring ring-1 ring-red-500 focus:ring-red-500"
          : "",
        )}
        onFocus={handleFocus}
        tabIndex={tabIndex}
        placeholder={placeholder}
      />
    </div>
  );
}

export function BearInput({
    placeholder,
    value,
    onChange,
    name,
    required,
    label,
    tabIndex,
    className,
  disabled,
  error,
}: {
  disabled?: boolean;
placeholder?: string;
  value: string;
  onChange: (event: any) => void;
  name: string;
  required?: boolean;
  label?: string;
  tabIndex?: number;
  className?: string;
  error?: string,
}) {
  const hasErrors = !!error;
  return (
    <div className="grid items-center gap-1.5">
      {label && (
        <Label
          htmlFor={name}
          className="flex flex-row justify-between text-base items-center"
        >
          <span className="font-semibold">{label}</span>
          {required ? (
            <span
              className="text-xs font-light"
              style={{ fontStyle: "italic" }}
            >
              Required
            </span>
          ) : (
            <span></span>
          )}
        </Label>
      )}
      <Input
        disabled={disabled}
        value={value}
        id={name}
        type="text"
        required={required}
        onChange={onChange}
        className={cn(
          "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6",
          hasErrors
          ? "ring ring-1 ring-red-500 focus:ring-red-500"
          : "",
        )}
        onFocus={handleFocus}
        tabIndex={tabIndex}
        placeholder={placeholder}
      />
    </div>
  );
}

function parseIntEvent(onChange: (item: number) => void) {
  return function parseIntEventHelper(event: any) {
    try {
      return onChange(parseInt(event.target.value));
    } catch (e) {
      return onChange(0);
    }
  };
}

function parseFloatNumber(onChange: (item: string | number) => void) {
  return function parseFloatEventHelper(event: any) {
    try {
      return onChange(parseFloat(event.target.value || ""));
    } catch (e) {
      return onChange(event.target.value || "");
    }
  };
}
