import { StytchB2B } from "@stytch/react/b2b";
import { B2BProducts } from "@stytch/vanilla-js";

export const DefaultStytchLogin = () => {
  const config = {
    products: [B2BProducts.emailMagicLinks],
    authFlowType: "Discovery",
    sessionOptions: {
      sessionDurationMinutes: 60,
    },
    emailMagicLinksOptions: {
      loginRedirectURL: process.env.REACT_APP_LOGIN_REDIRECT_URL,
      signupRedirectURL: process.env.REACT_APP_SIGNUP_REDIRECT_URL,
    },
  } as any;

  const styles = {
    container: {
      backgroundColor: "#FFFFFF",
      borderColor: "#ADBCC5",
      borderRadius: "8px",
      width: "400px",
    },
    colors: {
      primary: "#19303D",
      secondary: "#5C727D",
      success: "#0C5A56",
      error: "#8B1214",
    },
    buttons: {
      primary: {
        backgroundColor: "#19303D",
        textColor: "#FFFFFF",
        borderColor: "#19303D",
        borderRadius: "4px",
      },
      secondary: {
        backgroundColor: "#FFFFFF",
        textColor: "#19303D",
        borderColor: "#19303D",
        borderRadius: "4px",
      },
    },
    fontFamily: "Arial",
    hideHeaderText: false,
    logo: {
      logoImageUrl: "",
    },
  };

  return (
    <div className="flex flex-col justify-center items-center mt-8">
      <StytchB2B config={config} styles={styles} />
    </div>
  );
};

export default DefaultStytchLogin;
