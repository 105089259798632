import { useQuery, useQueryClient } from "@tanstack/react-query";
import { S3ObjectItem } from "../models/trading";
import { Document } from "../models/tickets";
import { Provider, DbUser } from "../models/index";
import { BaseDbObject } from "../models/db";
import {
  fetchApiDelete,
  fetchApiGet,
  fetchApiPost,
  fetchApiPut,
} from "./utils";
import { TicketJob } from "../models/tickets";

const ENTITY_KEY = "queuedDocuments";
export const CommonDestinationTimezone = "America/Chicago";

export function useListQueuedDocumentsFor() {
  const queryParams = new URLSearchParams();

  return useQuery({
    queryKey: ["listQueuedDocuments"],
    queryFn: () => fetchApiGet<DocumentQueue[]>("document/queue", queryParams),
    retry: 1,
  });
}

export function useDeleteQueuedDocument() {
  const queryClient = useQueryClient();
  const queryParams = new URLSearchParams();

  return async (documentId: string) => {
    const result = await fetchApiDelete<{ id: string }>(
      `document/queue/${documentId}`,
      queryParams,
    );
    queryClient.invalidateQueries({ queryKey: ["listQueuedDocuments"] });
    return result;
  };
}

export interface DocumentQueue extends BaseDbObject {
  type: string;

  user_id: string;
  document_id: string;
  provider_id: string;
  ticket_job_id: string;

  Provider?: Provider;
  TicketJob?: TicketJob;
  Document?: Document;
  User?: DbUser;

  validation?: QueuedDocumentErrorType;
}

export interface DocumentQueueAudit extends BaseDbObject {
  result: string;
}

export enum QueuedDocumentErrorType {
  MissingEmail = "Missing client email",
  MissingCustomer = "Missing customer",
  MissingAmendmentNumber = "Missing amendment number",
  MissingSignatureArea = "Missing signature area",
  MissingContractInfo = "Missing contract info",
}
