export enum CellFormat {
  NUMBER = "#,##0.00",
  ACCOUNTING = '_("$"* #,##0.00_);_("$"* (#,##0.00);_("$"* "-"??_);_(@_)',
  NON_CURRENCY_ACCOUNTING = '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)',
  Int = "#,##0",
  Float = "#,##0.00000",
  Float3 = "#,##0.000",
  MonthDate = "MM/DD/YYYY",
  Currency = "($1,234.10)",
  CurrencyRed = `$#,##0.00_);[Red]($#,##0.00)`,
};

const ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

export function numberToExcelColumn(num: number): string {
    if (num < 1) {
        throw new Error('Number must be 1 or greater');
    }

    let result = '';
    while (num > 0) {
        num--;
        result = ALPHABET[num % 26] + result;
        num = Math.floor(num / 26);
    }
    return result;
}

export function excelColumnToNumber(column: string): number {
    if (!/^[A-Z]+$/.test(column)) {
        throw new Error('Column must contain only uppercase letters A-Z');
    }

    let result = 0;
    for (let i = 0; i < column.length; i++) {
        result *= 26;
        result += ALPHABET.indexOf(column[i]) + 1;
    }
    return result;
}
