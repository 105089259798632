import { create } from "zustand";
import hashObject from "object-hash";
import { useState, useEffect } from "react";
import type { SetStateAction, Dispatch, ChangeEvent } from "react";
import Decimal from "decimal.js";
import { useStytchMember } from "@stytch/react/b2b";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import {
  RotateCcwIcon,
  DownloadCloud,
  Trash2,
  Plus,
  MoreHorizontal,
  Link as LinkIcon,
  Archive as ArchiveXIcon,
  ListFilter as ListFilterIcon,
} from "lucide-react";
import moment from "moment-timezone";
import { Link } from "react-router-dom";
import { CommonDestinationTimezone } from "../../lib/utils";
import {
  ContractListOptionParams,
  useListOpenPositions,
  useDownloadOpenPositions,
  useDownloadOpenContracts,
  useListAccounts,
  TradingAccountOpenPosition,
  TradingAccount,
  TradingContract,
  ContractType,
  ContractTypeOptions,
  CommodityOptions,
  useListOpenContracts,
  useListOpenContractFilters,
  useCreateContract,
  useUpdateContract,
  useArchiveContract,
  useUnPairContract,
  usePairContract,
  MinimumPriceValidation,
  UpdateContractValidation,
  CreateContractValidation,
  AccumulatorValidation,
  MinPriceValidation,
  PremiumValidation,
  MinMaxValidation,
  RangeValidation,
  createContractsExcelBuffer,
  contractNumberKeys,
  findMatchOption,
  PositionContractPair,
  useListRecentArchivedPositions,
  TradingAccountOpenPositionAudit,
  useMarkContractManualFullyPaired,
  useMarkPositionManualFullyPaired,
} from "../../lib/api/otc";
import {
    formatDateWithoutTimezone,
    formatDateWithoutTimezoneToDate
} from "../../lib/api/date";
import { useListCustomers } from "../../lib/api/customer";
import { Customer } from "../../lib/api/reports";
import {
  TicketRunSummary,
  TicketsSummary,
  ProviderTicketCount,
  Provider,
} from "../../lib/models/index";
import { ProviderType } from "../../lib/models/provider";
import {
  TicketProviderSummary,
  TicketProviderRecentRunPartial,
  TicketProviderBatchDownloadPartial,
} from "../../lib/models/tickets";
import { Button } from "../../components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../components/ui/dropdown-menu";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../components/ui/tabs";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/ui/table";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../components/ui/accordion";
import { Badge } from "../../components/ui/badge";
import { Checkbox } from "../../components/ui/checkbox";
import { MultiSelect } from "../../components/ui/selectMulti";
import { SelectInput } from "../../components/ui/SelectInput";
import { Input } from "../../components/ui/input";
import { Skeleton } from "../../components/ui/skeleton";
import { useToast } from "../../components/ui/use-toast";
import {
  DataTable,
  DataTableProps,
} from "../../components/ui/virtualizedTable";
import { downloadCsvTextAsFile } from "../../lib/browser/download";
import { Loader } from "../../components/ui/loader";
import { DatePicker } from "../../components/ui/typeable_date_picker";
import { handleFocus } from "../../lib/utils";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogClose,
  DialogFooter,
} from "../../components/ui/dialog";
import { Label } from "../../components/ui/label";
import { ValidationError, PossibleReturnType } from "../../lib/responseResults";
import { Combobox } from "../../components/ui/combobox";
import { cn } from "../../lib/utils";
import { downloadBinaryData } from "../../lib/browser/download";
import { SelectOptionItem } from "../../lib/models/ui";
import { PossiblePackage, doesUserHavePackages } from "../../lib/models/auth";
import useDebounce from "./useDebounce";
import { formatNumber } from "./common";

const baseOpenPositionColumns: any[] = [
  {
    id: "account",
    header: "Account",
    cell: ({ row }: any) => (
      <span className="">{row.original.TradingAccount.account_id}</span>
    ),
    enableSorting: true,
    enableHiding: false,
    onCellClick: true,
  },
  {
    accessorKey: "date",
    header: "Date",
    cell: ({ row }: any) =>
      moment
        .tz(row.original.date, CommonDestinationTimezone)
        .format("MM/DD/YYYY"),
    enableSorting: false,
    enableHiding: false,
    onCellClick: true,
  },
  {
    accessorKey: "long",
    header: "Long",
    cell: ({ row }: any) => row.original.long_qty,
    enableSorting: false,
    enableHiding: false,
    onCellClick: true,
  },
  {
    accessorKey: "short",
    header: "Short",
    cell: ({ row }: any) => row.original.short_qty,
    enableSorting: false,
    enableHiding: false,
    onCellClick: true,
  },
  {
    accessorKey: "put_call",
    header: "Put/Call",
    cell: ({ row }: any) => row.original.put_or_call,
    enableSorting: false,
    enableHiding: false,
    onCellClick: true,
  },
  {
    id: "commodity",
    header: "Commodity",
    cell: ({ row }: any) => row.original.commodity,
    onCellClick: true,
  },
  {
    id: "optionMonth",
    header: "Option Month",
    cell: ({ row }: any) => row.original.option_month || "",
    onCellClick: true,
  },
  {
    id: "strike_price",
    header: "Strike Price",
    cell: ({ row }: any) => row.original.strike_price,
    onCellClick: true,
  },
  {
    id: "price",
    header: "Price",
    cell: ({ row }: any) => row.original.price,
    onCellClick: true,
  },
  {
    id: "premium",
    header: "Option Premium",
    cell: ({ row }: any) => row.original.premium || 0,
    onCellClick: true,
  },
  {
    id: "bushels",
    header: "Bushels",
      cell: ({ row }: any) => formatNumber(row.original.bushel),
    onCellClick: true,
  },
];

const nonActionContractColumns: any[] = [
  {
    accessorKey: "date",
    header: "Date",
    cell: ({ row }: any) => row.original.date_label,
    onCellClick: true,
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "account_no_a",
    header: "Brokerage OTC",
    cell: ({ row }: any) => row.original.options_account.no || "",
    onCellClick: true,
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "account_no_b",
    header: "Brokerage HTA",
    cell: ({ row }: any) => row.original.futures_account?.no || "",
    onCellClick: true,
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "contract_no",
    header: "Contract #",
    cell: ({ row }: any) => row.original.contract_no,
    onCellClick: true,
    enableSorting: false,
    enableHiding: false,
  },
  {
    id: "customer_id",
    header: "Customer",
    cell: ({ row }: any) => row.original.customer?.long_name || "", // customers.find(customer => customer.id === row.original.customer_id)?.long_name ||
    onCellClick: true,
  },
  {
    id: "contract_type",
    header: "Contract Type",
    cell: ({ row }: any) => (
      <span className="">{row.original.contract.type}</span>
    ),
    onCellClick: true,
    enableSorting: true,
    enableHiding: false,
  },
  {
    id: "quantity",
    header: "Quantity",
      cell: ({ row }: any) => formatNumber(row.original.quantity),
    onCellClick: true,
  },
  {
    id: "commodity",
    header: "Commodity",
    cell: ({ row }: any) => row.original.commodity_label,
    onCellClick: true,
  },
  {
    id: "option_month",
    header: "Option Month",
    cell: ({ row }: any) => row.original.option_month,
    onCellClick: true,
  },
  {
    accessorKey: "start_date",
    header: "Start Date",
    cell: ({ row }: any) => row.original.start_date_label,
    onCellClick: true,
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "end_date",
    header: "End Date",
    cell: ({ row }: any) => row.original.end_date_label,
    onCellClick: true,
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "weeks",
    header: "Weeks",
    cell: ({ row }: any) => row.original.weeks,
    onCellClick: true,
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "period_qty",
    header: "Period Qty",
    cell: ({ row }: any) => row.original.period_qty,
    onCellClick: true,
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "knockout",
    header: "Knockout",
    cell: ({ row }: any) => row.original.knockout,
    onCellClick: true,
    enableSorting: false,
    enableHiding: false,
  },
  {
    id: "step_one",
    header: "Step 1",
    cell: ({ row }: any) => row.original.step_one,
    onCellClick: true,
  },
  {
    id: "step_two",
    header: "Step 2",
    cell: ({ row }: any) => row.original.step_two,
    onCellClick: true,
  },
  {
    id: "step_three",
    header: "Step 3",
    cell: ({ row }: any) => row.original.step_three,
    onCellClick: true,
  },
  {
    id: "option_premium",
    header: "Option Premium",
    cell: ({ row }: any) => row.original.option_premium,
    onCellClick: true,
  },
  {
    id: "contract_fee",
    header: "Initial Premium + Fee",
    cell: ({ row }: any) => row.original.contract_fee,
    onCellClick: true,
  },
  {
    id: "margin",
    header: "Lighthouse Fee",
      cell: ({ row }: any) => formatNumber(row.original.margin),
    onCellClick: true,
  },
  {
    id: "margin_amount",
    header: "Margin Amount",
    cell: ({ row }: any) => formatNumber(row.original.margin_amount),
    onCellClick: true,
  },
  {
    accessorKey: "trade_reference",
    header: "Trade Reference",
    cell: ({ row }: any) => row.original.trade_reference,
    onCellClick: true,
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "futures_price",
    header: "Futures Price",
    cell: ({ row }: any) => formatNumber(row.original.futures_price),
    onCellClick: true,
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "otc_futures",
    header: "OTC Futures",
      cell: ({ row }: any) => formatNumber(row.original.otc_futures),
    onCellClick: true,
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "notes",
    header: "Notes",
    cell: ({ row }: any) => row.original.notes,
    onCellClick: true,
    enableSorting: false,
    enableHiding: false,
  },
];

const posPairOpenContractColumns: any[] = [
    {
        id: "pair",
        header: "Pair",
        cell: ({ row }: any) => (
            <Button className="" variant="outline">
                Pair
            </Button>
        ),
        enableSorting: true,
        enableHiding: false,
        onCellClick: true,
    },
    ...nonActionContractColumns,
];

const posUnpairOpenContractColumns: any[] = [
  {
    id: "unpair",
    header: "Pair",
    cell: ({ row }: any) => (
      <Button className="w-24" variant="outline">
        Un-Pair
      </Button>
    ),
    enableSorting: true,
    enableHiding: false,
    onCellClick: true,
  },
  ...nonActionContractColumns,
];

interface NewContractInfo {
  contract_type: string;
  contract_no: string;

  date: Date;
  start_date?: Date;
  end_date?: Date;

  account_no_a: string;
  account_no_b?: string;

  weeks: string;
  period_qty: string;
  knockout: string;

  trade_reference: string;
  customer_id: string;

  quantity: number;
  commodity: string;

  option_month: string;
  step_one: string;
  step_two: string;
  step_three: string;

  option_premium: string;
  contract_fee: string;
  margin: string;
  margin_amount: string;
}

interface PositionListOptionParams {
  filters: {
    account_ids?: string[];
    strike_price?: string;
    commodity?: string;
  };
}

interface UpdateContractInfo extends Partial<NewContractInfo> {
  id: string;
}

interface PairingData {
  position?: TradingAccountOpenPosition;
  contract?: TradingContract;
}

interface BearState {
  position_filters: PositionListOptionParams;
  editing_position_filters: PositionListOptionParams;
  contract_filters: ContractListOptionParams;
  editing_contract_filters: ContractListOptionParams;
  new_contract?: NewContractInfo;
  editing_contract?: UpdateContractInfo;
  hasAttemptedSave: boolean;
  contracts_filter_modal_status: boolean;
  positions_filter_modal_status: boolean;
  open_contracts?: TradingContract[];
  open_positions?: TradingAccountOpenPosition[];
  pairing_data: PairingData;

  set_open_contracts: (data: TradingContract[]) => void;
  set_open_positions: (data: TradingAccountOpenPosition[]) => void;
  update_contract_filters_field: (
    key: string,
    value?: string | string[] | number | Date | undefined,
  ) => void;
  update_position_filters_field: (
    key: string,
    value?: string | string[] | number | Date | undefined,
  ) => void;
  set_position_filters: (key: string, value: string | undefined) => void;
  set_new_contract: (new_contract: {} | undefined) => void;
  set_edit_contract: (new_contract: {} | undefined) => void;
  set_pair_position: (pair_position: PairingData) => void;
  update_new_contract_field: (
    key: string,
    value?: string | number | Date | undefined | null,
  ) => void;
  update_edit_contract_field: (
    key: string,
    value?: string | number | Date | undefined | null,
  ) => void;
  updateAttemptedSave: (status: boolean) => void;
  set_contract_filter_modal_status: (status: boolean) => void;
  set_position_filter_modal_status: (status: boolean) => void;
  persistEditingContractFilters: () => void;
  persistPositionFilters: () => void;
    clear_filters: () => void;
}

const useBearStore = create<BearState>((set) => ({
  open_positions: undefined,
  open_contracts: undefined,
  position_filters: { filters: {} },
  editing_position_filters: { filters: {} },
  contract_filters: { filters: {} },
  editing_contract_filters: { filters: {} },
  new_contract: undefined,
  editing_contract: undefined,
  pairing_data: {},
  hasAttemptedSave: false,
  contracts_filter_modal_status: false,
  positions_filter_modal_status: false,

  set_open_contracts: (open_contracts: TradingContract[]) =>
    set((state) => ({
      open_contracts,
    })),
  set_open_positions: (open_positions: TradingAccountOpenPosition[]) =>
    set((state) => ({
      open_positions,
    })),
  set_position_filters: (key: string, value: string | undefined | null) =>
    set((state) => ({
      [key]: value,
    })),
  persistPositionFilters: () =>
    set((state) => ({
      positions_filter_modal_status: false,
      position_filters: {
        filters: { ...state.editing_position_filters.filters },
      },
    })),
  persistEditingContractFilters: () =>
    set((state) => ({
      contracts_filter_modal_status: false,
      contract_filters: {
        filters: { ...state.editing_contract_filters.filters },
      },
    })),
    clear_filters: () => set((state) => ({
        contract_filters: { filters: {} },
        position_filters: { filters: {} },
        editing_contract_filters: { filters: {} },
        editing_position_filters: { filters: {} },
    })),
    update_contract_filters_field: (
        key: string,
        value?: string | string[] | number | Date | undefined,
    ) =>
        set((state) => ({
            editing_contract_filters: {
        filters: {
          ...state.editing_contract_filters.filters,
          [key]: value,
        },
      },
    })),
  update_position_filters_field: (
    key: string,
    value?: string | string[] | number | Date | undefined,
  ) =>
    set((state) => ({
      editing_position_filters: {
        filters: {
          ...state.editing_position_filters.filters,
          [key]: value,
        },
      },
    })),
  set_contract_filter_modal_status: (status: boolean) =>
    set((state) => ({
      contracts_filter_modal_status: status,
    })),
  set_position_filter_modal_status: (status: boolean) =>
    set((state) => ({
      positions_filter_modal_status: status,
    })),
  updateAttemptedSave: (hasAttemptedSave: boolean) =>
    set((state) => ({
      hasAttemptedSave,
    })),
  set_new_contract: (new_contract: {} | undefined) =>
    set((state) => ({
      new_contract: new_contract as any,
      hasAttemptedSave: !!new_contract ? state.hasAttemptedSave : false,
    })),
  set_edit_contract: (editing_contract: {} | undefined) =>
    set((state) => ({
      editing_contract: editing_contract as any,
      hasAttemptedSave: !!editing_contract ? state.hasAttemptedSave : false,
    })),
  set_pair_position: (data: PairingData) =>
    set((state) => ({
      pairing_data: data,
    })),
  update_edit_contract_field: (
    key: string,
    value?: string | number | Date | undefined | null,
  ) => {
    return set((state) => {
      if (!state.editing_contract) {
        return { editing_contract: undefined };
      }

      return {
        editing_contract: {
          ...state.editing_contract,
          ...clearFieldsOnContractTypeChange(
            (key === "contract_type"
              ? value
              : state.editing_contract?.contract_type) as any,
          ),
          [key]: typeof value === "string" ? value?.trim() : value,
        },
      };
    });
  },
  update_new_contract_field: (
    key: string,
    value?: string | number | Date | undefined | null,
  ) => {
    return set((state) => {
      if (!state.new_contract) {
        return { new_contract: undefined };
      }

      return {
        new_contract: {
          ...state.new_contract,
          ...clearFieldsOnContractTypeChange(
            (key === "contract_type"
              ? value
              : state.new_contract?.contract_type) as any,
          ),
          [key]: typeof value === "string" ? value?.trim() : value,
        },
      };
    });
  },
}));

function should_refresh_data({
  current_state,
  new_state,
}: {
  current_state?: any;
  new_state?: any[];
}) {
  if (!current_state && !!new_state) {
    return true;
  }
  if (!!current_state && !new_state) {
    return false;
  }

  if (!current_state && !new_state) {
    return false;
  }

  return current_state! !== hashObject(new_state!);
}

export function PositionsDashboard() {
  const position_filters = useBearStore(
    (state) => state.position_filters.filters,
  );
  const contract_filters = useBearStore(
    (state) => state.contract_filters.filters,
  );
  const openPositionsRes = useListOpenPositions({ filters: position_filters });
  const openContractsRes = useListOpenContracts({ filters: contract_filters });
  const accountsRes = useListAccounts(false);
  const customersResult = useListCustomers();
  const recentArchivedPositionsRes = useListRecentArchivedPositions();

    const isMissingData = (!openPositionsRes?.data?.data) || (!openContractsRes?.data?.data);

    if (
        [recentArchivedPositionsRes, customersResult, accountsRes].some((result) => result.isLoading) ||isMissingData
    ) {
        return (
            <div className="flex flex-col space-y-2">
                <Skeleton className="h-4 w-[250px]" />
                <Skeleton className="h-4 w-[200px]" />
            </div>
        );
  }

  if (
      [recentArchivedPositionsRes, customersResult, accountsRes].some(
          (result) => result.error || !result?.data?.success,
      )
  ) {
    return (
      <div className="flex flex-col space-y-2">
        Failed to communicate with servers. Please refresh and try again
      </div>
    );
  }

  const openPositionsData = openPositionsRes?.data?.data || {};
  const openContractsData = openContractsRes?.data?.data || {};

  return (
      <DashboardView
          customers={customersResult?.data?.data || []}
          recentArchivedPositions={recentArchivedPositionsRes?.data?.data || []}
          openContractsPaired={openContractsData.pairedContracts || []}
          openContractsUnpaired={openContractsData.unpairedContracts || []}
          openPositionsPaired={openPositionsData.pairedPositions || []}
          openPositionsUnpaired={openPositionsData.unpairedPositions || []}
          refetchPositions={openPositionsRes.fetchData}
          refetchContracts={openContractsRes.fetchData}
          pairs={openPositionsData.pairs || []}
          accounts={accountsRes?.data?.data || []}
      />
  );
}

export function DashboardView({
    recentArchivedPositions,
    openContractsPaired,
    openContractsUnpaired,
    openPositionsPaired,
    openPositionsUnpaired,
    pairs,
    accounts,
    customers,
    refetchPositions,
    refetchContracts,
}: {
    recentArchivedPositions: TradingAccountOpenPositionAudit[];
    openContractsPaired: TradingContract[];
    openContractsUnpaired: TradingContract[];
  openPositionsPaired: TradingAccountOpenPosition[];
  openPositionsUnpaired: TradingAccountOpenPosition[];
  pairs: PositionContractPair[];
  accounts: TradingAccount[];
  customers: Customer[];
    refetchPositions: any;
    refetchContracts: any;
}) {
  return (
      <>
          <div className="flex flex-col mb-2">
              <h1 className="scroll-m-20 text-2xl font-extrabold tracking-tight lg:text-3xl">
                  Positions Dashboard
              </h1>
          </div>
          <div className="flex items-start flex-col h-full">
            <OpenPositionsSection
                accounts={accounts}
                customers={customers}
                openContractsUnpaired={openContractsUnpaired}
                openContractsPaired={openContractsPaired}
                openPositionsPaired={openPositionsPaired}
                openPositionsUnpaired={openPositionsUnpaired}
                refetchPositions={refetchPositions}
                refetchContracts={refetchContracts}
                pairs={pairs}
            />
          </div>
      </>
  );
}

function OpenPositionsSection({
  openPositionsPaired,
  openPositionsUnpaired,
  openContractsPaired,
  openContractsUnpaired,
  pairs,
  accounts,
  customers,
    refetchPositions,
    refetchContracts,
}: {
  openContractsPaired: TradingContract[];
  openContractsUnpaired: TradingContract[];
  openPositionsPaired: TradingAccountOpenPosition[];
  openPositionsUnpaired: TradingAccountOpenPosition[];
  pairs: PositionContractPair[];
  accounts: TradingAccount[];
  customers: Customer[];
    refetchPositions: any;
    refetchContracts: any;
}) {
  const set_pair_position = useBearStore((state) => state.set_pair_position);
  const pairing_data = useBearStore((state) => state.pairing_data);

    const pairedOpenPositionColumns: any[] = [
    {
        id: "mark_unpaired",
        header: "Manual Un pair",
        cell: ({ row }: any) => (
            row.original.is_manually_fully_paired ?
        <MarkPositionFullyPairedContractButton
            id={row.original.id}
            refetchPositions={refetchPositions}
            message="Marked Un-Paired"
            buttonLabel="Un-Pair"
            buttonClassName="w-28"
            markStatus={false}
        /> : null
        ),
        enableSorting: true,
        enableHiding: false,
    },
    {
        id: "contract_no",
        header: "Contract #",
        cell: ({ row }: any) => row.original.contract_no || "",
        onCellClick: true,
    },
    {
        id: "customer_name",
        header: "Customer",
        cell: ({ row }: any) => row.original.customer?.long_name || "",
        onCellClick: true,
    },

    ...baseOpenPositionColumns,
    ];

    const unpairedOpenPositionColumns: any[] = [
    {
        id: "mark_paired",
        header: "Manual Mark Paired",
        cell: ({ row }: any) => (
        <MarkPositionFullyPairedContractButton
            id={row.original.id}
            refetchPositions={refetchPositions}
            message="Marked Paired"
            buttonLabel="Mark Paired"
            markStatus={true}
        />
        ),
        enableSorting: true,
        enableHiding: false,
    },
    {
        id: "contract_no",
        header: "Contract #",
        cell: ({ row }: any) => row.original.contract_no || "",
        onCellClick: true,
    },
    ...baseOpenPositionColumns,
    ];



  return (
      <>
          <div className="flex flex-col w-full">
              <DataTable
                  height="550px"
                  onCellClick={(row) => set_pair_position({ position: row as any })}
                  columns={baseOpenPositionColumns}
                  data={[...openPositionsUnpaired,...openPositionsPaired]}
              />
          </div>
      </>
  );
}

function PairFormModal({
  setPairData,
  pairingData,
  customers,
  accounts,
  openContracts,
  pairs,
    refetchPositions,
    refetchContracts,
}: {
  openContracts: TradingContract[];
  setPairData: (data: PairingData) => void;
  pairingData: PairingData;
  accounts: TradingAccount[];
  customers: Customer[];
  pairs: PositionContractPair[];
    refetchPositions: any;
    refetchContracts: any;
}) {
  const { member } = useStytchMember();
  const { toast } = useToast();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const pairContract = usePairContract();
  const unpairContract = useUnPairContract();

  const AccountTypeOptions = accounts.map((account: TradingAccount) => ({
    value: account.id,
    label: account.account_id,
  }));

  const onUpdateStatus = (status: boolean) => {
    if (!status) {
      setPairData({});
    }
  };

  const onPairClick = async (contract: TradingContract, cell_id?: string) => {
    if (isSubmitting) return;
    setIsSubmitting(true);

    const res =
      cell_id === "pair"
        ? await pairContract({
            position_id: pairingData.position?.id || "",
            contract_id: contract.id || "",
          })
        : await unpairContract({
            position_id: pairingData.position?.id || "",
            contract_id: contract.id || "",
          });

    if (!res.success) {
      toast({
        title: "Failed to pair",
        description: "",
      });
    } else {
      refetchPositions();
        refetchContracts();
        toast({
            title: "Paired",
            description: "",
        });
    }

    setIsSubmitting(false);
  };

  const pairedContractIds = pairingData.position?.id
    ? pairs
        .filter((pair) => {
          return pair.trading_position_id === pairingData.position?.id;
        })
        .map((pair) => pair.trading_contract_id)
    : [];
  const pairedContracts = openContracts.filter((contract) =>
    pairedContractIds.includes(contract.id),
  );

  const isOtcAdmin = doesUserHavePackages({
    requiredPackages: [PossiblePackage.OTCAdmin],
    member,
  });

  return (
    <Dialog
      onOpenChange={onUpdateStatus}
      open={!!pairingData.position}
      defaultOpen={false}
    >
      <DialogContent className="max-w-screen-2xl md:min-w-sm lg:min-w-md xl:mg-w-lg">
        <DialogHeader>
          <DialogTitle>{"Pair Position"}</DialogTitle>
        </DialogHeader>
        {!isSubmitting && (
          <>
            {pairedContracts?.length > 0 && (
              <>
                <h3 className="text-lg font-bold tracking-tight">
                  Currently Matched
                </h3>
                <DataTable
                  height="250px"
                  onCellClick={onPairClick}
                  className="max-h-[16rem] md:max-h-[24rem] lg:max-h-[30rem] grid overflow-y-auto overflow-x-auto"
                  columns={
                    isOtcAdmin
                      ? posUnpairOpenContractColumns.map((item, index) => {
                          if (!index) return item;
                          return {
                            ...item,
                            onCellClick: false,
                          };
                        })
                      : nonActionContractColumns
                  }
                  data={pairedContracts?.map(
                    mapToTableContract({ customers, AccountTypeOptions }),
                  )}
                />
                <h3 className="text-lg font-bold tracking-tight my-4">
                  Un-Matched
                </h3>
              </>
            )}
            <DataTable
              height="450px"
              onCellClick={onPairClick}
              className="max-h-[16rem] md:max-h-[24rem] lg:max-h-[30rem] grid overflow-y-auto overflow-x-auto"
              columns={posPairOpenContractColumns.map((item, index) => {
                if (!index) return item;
                return {
                  ...item,
                  onCellClick: false,
                };
              })}
              data={openContracts?.map(
                mapToTableContract({ customers, AccountTypeOptions }),
              ).sort((a,b) => {
                    if (!a.contract_no && !b.contract_no) return 0;
                    if (!a.contract_no && !!b.contract_no) return 1;
                    if (!!a.contract_no && !b.contract_no) return -1;
                    if (a.contract_no === b.contract_no) return 0;
                    if (a.contract_no! < b.contract_no!) return -1;
                    if (a.contract_no! > b.contract_no!) return 1;
                    return 0;
                })}
            />
          </>
        )}
        {!!isSubmitting && (
          <div className="flex flex-col space-y-2 justify-center items-center my-2">
            <div className="font-semibold">Pairing</div>
            <Loader />
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
}

function PositionFiltersModal({ accounts }: { accounts: TradingAccount[] }) {
  const isModalOpen = useBearStore(
    (state) => state.positions_filter_modal_status,
  );
  const onUpdateStatus = useBearStore(
    (state) => state.set_position_filter_modal_status,
  );
  const position_filters = useBearStore(
    (state) => state.editing_position_filters.filters,
  );
  const update_position_filters_field = useBearStore(
    (state) => state.update_position_filters_field,
  );
  const persistEditingPositionFilters = useBearStore(
    (state) => state.persistPositionFilters,
  );

  const onSubmit = persistEditingPositionFilters;
  return (
    <Dialog
      onOpenChange={onUpdateStatus}
      open={isModalOpen}
      defaultOpen={false}
    >
      <DialogTrigger asChild>
      <span className="flex flex-col justify-center items-center">
          <Label
              htmlFor="positions_filter_trigger"
              className="flex flex-row justify-between text-base items-center"
          >
              Position Filters
          </Label>
        <Button
          id="positions_filter_trigger"
          variant="ghost"
          type="button"
          className="ml-4"
          disabled={isModalOpen}
          onClick={() => onUpdateStatus(true)}
        >
          <ListFilterIcon />
        </Button>
      </span>
      </DialogTrigger>
      <DialogContent className="max-w-screen-2xl md:min-w-sm lg:min-w-md xl:mg-w-lg">
        <DialogHeader>
          <DialogTitle>{"Position Filters"}</DialogTitle>
        </DialogHeader>

        <form
          onSubmit={(e) => {
            if (e?.preventDefault) e.preventDefault();
            return onSubmit();
          }}
        >
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 items-start gap-4 content-center">
            <BearSelectMultiInput
              name="position_account_ids"
              label="Accounts"
              values={position_filters.account_ids || []}
              onValueChange={(prev: any) =>
                update_position_filters_field(
                  "account_ids",
                  prev(position_filters.account_ids || []),
                )
              }
              options={accounts.map((account) => ({
                value: account.id,
                label: account.account_id,
              }))}
            />
            <BearSelectInput
              name="positions_commodity"
              label="Commodity"
              value={position_filters.commodity || ""}
              onValueChange={(value) =>
                update_position_filters_field(
                  "commodity",
                  findMatchOption(CommodityOptions, value)?.value,
                )
              }
              options={CommodityOptions}
            />
            <BearFloatInput
              name="positions_strike_price"
              label="Strike Price"
              value={position_filters.strike_price || ""}
              onChange={(value) =>
                update_position_filters_field("strike_price", value as any)
              }
            />
            <div className="grid grid-cols-1 place-content-between gap-2 col-span-4">
              <div className="flex flex-row justify-between">
                <DialogClose asChild>
                  <Button
                    type="button"
                    variant="secondary"
                    className="col-start-1"
                  >
                    Close
                  </Button>
                </DialogClose>

                <Button type="submit" size="sm" className="px-3 col-start-4">
                  {"Apply"}
                </Button>
              </div>
            </div>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
}

function BearSelectMultiInput({
  values,
  onValueChange,
  options,
  name,
  required,
  label,
}: {
  values: string[];
  onValueChange: any;
  options: { value: string; label: string }[];
  name: string;
  required?: boolean;
  label?: string;
}) {
  return (
    <div className="grid items-center gap-1.5">
      {label && (
        <Label
          htmlFor={name}
          className="flex flex-row justify-between text-base items-center"
        >
          <span className="font-semibold">{label}</span>
          {required ? (
            <span
              className="text-xs font-light"
              style={{ fontStyle: "italic" }}
            >
              Required
            </span>
          ) : (
            <span></span>
          )}
        </Label>
      )}
      <MultiSelect
        values={values}
        setValues={onValueChange}
        options={options}
        listClassName="overflow-y-scroll max-h-44 min-h-36"
      />
    </div>
  );
}

function BearSelectInput({
  value,
  onValueChange,
  options,
  name,
  required,
  label,
  tabIndex,
  schema,
  hasAttemptedSave,
}: {
  value: string;
  onValueChange: (item: string) => void;
  options: { value: string; label: string }[];
  name: string;
  required?: boolean;
  label?: string;
  tabIndex?: number;
  schema?: any;
  hasAttemptedSave?: boolean;
}) {
  const data = JSON.parse(
    JSON.stringify(transformToApiContract({ [name]: value })),
  );
  const hasErrors = schema
    ? !(schema as any).pick({ [name]: true }).safeParse(data).success
    : false;

  return (
    <div className="grid items-center gap-1.5">
      {label && (
        <Label
          htmlFor={name}
          className="flex flex-row justify-between text-base items-center"
        >
          <span className="font-semibold">{label}</span>
          {required ? (
            <span
              className="text-xs font-light"
              style={{ fontStyle: "italic" }}
            >
              Required
            </span>
          ) : (
            <span></span>
          )}
        </Label>
      )}

      <Combobox
        tabIndex={tabIndex}
        isModal
        className="w-full"
        value={value}
        onValueChange={onValueChange}
        options={options}
      />
    </div>
  );
}

function BearDatePicker({
  value,
  onValueChange,
  name,
  required,
  label,
  tabIndex,
  schema,
  hasAttemptedSave,
}: {
  value: Date | undefined;
  onValueChange: (date: Date | undefined) => void;
  name: string;
  required?: boolean;
  label?: string;
  tabIndex?: number;
  schema?: any;
  hasAttemptedSave?: boolean;
}) {
  const data = JSON.parse(
    JSON.stringify(transformToApiContract({ [name]: value })),
  );
  const hasErrors = schema
    ? !(schema as any).pick({ [name]: true }).safeParse(data).success
    : false;

  return (
    <div className="grid items-center gap-1.5">
      {label && (
        <Label
          htmlFor={name}
          className="flex flex-row justify-between text-base items-center"
        >
          <span className="font-semibold">{label}</span>
          {required ? (
            <span
              className="text-xs font-light"
              style={{ fontStyle: "italic" }}
            >
              Required
            </span>
          ) : (
            <span></span>
          )}
        </Label>
      )}
      <DatePicker
        tabIndex={tabIndex}
        value={value}
        onChange={(date) => {
          return onValueChange(date);
        }}
        placeholder={moment.tz(CommonDestinationTimezone).format("MM/DD/YYYY")}
        className={cn(
          "rounded-md border-0 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6 w-full",
          hasAttemptedSave && hasErrors
            ? "ring-red-500 focus:ring-red-500"
            : "",
        )}
      />
    </div>
  );
}

function BearFloatInput({
  disabled,
  value,
  onChange,
  name,
  min,
  max,
  required,
  step,
  label,
  tabIndex,
  schema,
  hasAttemptedSave,
}: {
  value: string;
  onChange: (item: string | number) => void;
  name: string;
  min?: string;
  max?: string;
  required?: boolean;
  step?: string;
  label?: string;
  disabled?: boolean;
  tabIndex?: number;
  schema?: any;
  hasAttemptedSave?: boolean;
}) {
  const data = JSON.parse(
    JSON.stringify(transformToApiContract({ [name]: value })),
  );
  const passesPattern = value
    ? /^-?\d*\.?\d+$/.test(value ? `${value}` : "")
    : true;
  const hasErrors = schema
    ? !(schema as any).pick({ [name]: true }).safeParse(data).success ||
      !passesPattern
    : false;

  return (
    <div className="grid items-center gap-1.5">
      {label && (
        <Label
          htmlFor={name}
          className="flex flex-row justify-between text-base items-center"
        >
          <span className="font-semibold">{label}</span>
          {required ? (
            <span
              className="text-xs font-light"
              style={{ fontStyle: "italic" }}
            >
              Required
            </span>
          ) : (
            <span></span>
          )}
        </Label>
      )}
      <Input
        tabIndex={tabIndex}
        disabled={disabled}
        value={value ? `${value}` : ""}
        id={name}
        type="text"
        onChange={(event: any) =>
          onChange(event.target.value?.replace(/[^\-0-9\.\$\(\) ]+/gim, ""))
        }
        className={cn(
          "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6",
          hasAttemptedSave && hasErrors
            ? "ring-red-500 focus:ring-red-500"
            : "",
        )}
        onFocus={handleFocus}
      />
    </div>
  );
}

function BearIntInput({
  value,
  onChange,
  name,
  min,
  max,
  required,
  step,
  label,
  tabIndex,
  schema,
  hasAttemptedSave,
}: {
  value: number;
  onChange: (item: number) => void;
  name: string;
  min?: string;
  max?: string;
  required?: boolean;
  step?: string;
  label?: string;
  tabIndex?: number;
  schema: any;
  hasAttemptedSave: boolean;
}) {
  const data = JSON.parse(
    JSON.stringify(transformToApiContract({ [name]: value })),
  );
  const hasErrors = !(schema as any).pick({ [name]: true }).safeParse(data)
    .success;

  return (
    <div className="grid items-center gap-1.5">
      {label && (
        <Label
          htmlFor={name}
          className="flex flex-row justify-between text-base items-center"
        >
          <span className="font-semibold">{label}</span>
          {required ? (
            <span
              className="text-xs font-light"
              style={{ fontStyle: "italic" }}
            >
              Required
            </span>
          ) : (
            <span></span>
          )}
        </Label>
      )}
      <Input
        value={value}
        id={name}
        type="number"
        required={required}
        min={min}
        max={max}
        step={step}
        onChange={parseIntEvent(onChange)}
        className={cn(
          "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6",
          hasAttemptedSave && hasErrors
            ? "ring-red-500 focus:ring-red-500"
            : "",
        )}
        onFocus={handleFocus}
        tabIndex={tabIndex}
      />
    </div>
  );
}

function BearInput({
  value,
  onChange,
  name,
  required,
  label,
  tabIndex,
  className,
  schema,
  hasAttemptedSave,
}: {
  value: string;
  onChange: (event: any) => void;
  name: string;
  required?: boolean;
  label?: string;
  tabIndex?: number;
  className?: string;
  schema?: any;
  hasAttemptedSave?: boolean;
}) {
  const data = JSON.parse(
    JSON.stringify(transformToApiContract({ [name]: value })),
  );
  const hasErrors = schema
    ? !(schema as any).pick({ [name]: true }).safeParse(data).success
    : false;

  return (
    <div className="grid items-center gap-1.5">
      {label && (
        <Label
          htmlFor={name}
          className="flex flex-row justify-between text-base items-center"
        >
          <span className="font-semibold">{label}</span>
          {required ? (
            <span
              className="text-xs font-light"
              style={{ fontStyle: "italic" }}
            >
              Required
            </span>
          ) : (
            <span></span>
          )}
        </Label>
      )}
      <Input
        value={value}
        id={name}
        type="text"
        required={required}
        onChange={onChange}
        className={cn(
          "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6",
          hasAttemptedSave && hasErrors
            ? "ring-red-500 focus:ring-red-500"
            : "",
        )}
        onFocus={handleFocus}
        tabIndex={tabIndex}
      />
    </div>
  );
}

function parseIntEvent(onChange: (item: number) => void) {
  return function parseIntEventHelper(event: any) {
    try {
      return onChange(parseInt(event.target.value));
    } catch (e) {
      return onChange(0);
    }
  };
}

function parseFloatNumber(onChange: (item: string | number) => void) {
  return function parseFloatEventHelper(event: any) {
    try {
      return onChange(parseFloat(event.target.value || ""));
    } catch (e) {
      return onChange(event.target.value || "");
    }
  };
}

function transformToApiContract(item: Record<string, any>): any {
  if (!!item["commodity"]) {
    item["commodity"] = item["commodity"]?.toLocaleUpperCase();
  }

  return Object.entries(item).reduce(
    (acc, [key, value]) => {
      if (value === "") {
        acc[key] = undefined;
      } else if (
        typeof value === "number" ||
        contractNumberKeys.includes(key)
      ) {
        try {
          acc[key] = new Decimal(value);
        } catch (error: any) {
          acc[key] = undefined;
        }
      } else if (!!value && key?.toLocaleLowerCase()?.includes("date")) {
          if (typeof value === "object") {
              acc[key] = formatDateWithoutTimezoneToDate(value);
          } else {
              acc[key] = formatDateWithoutTimezoneToDate(new Date(value));
          }
      }else if (value === null) {
        acc[key] = undefined;
      } else {
        acc[key] = value;
      }
      return acc;
    },
    {} as Record<string, any>,
  );
}

function ignoreSafeParseDecimal(item: string | number): Decimal {
  try {
    return new Decimal(item);
  } catch (error: any) {
    return new Decimal(0);
  }
}

function downloadCSV(filename: string, csvContent: string, clearData?: any) {
  return function downloadCSVHelper() {
    // Create a Blob
    const blob = new Blob([csvContent], { type: "text/csv" });

    // Create a link element
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute("download", filename);

    // Append the link to the body
    document.body.appendChild(link);

    // Programmatically click the link to trigger the download
    link.click();

    // Remove the link from the body
    document.body.removeChild(link);
  };
}

function MarkPositionFullyPairedContractButton({ buttonClassName, id, refetchPositions, markStatus, message, buttonLabel }: { id: string; refetchPositions: any; markStatus: boolean; message: string; buttonLabel: string; buttonClassName?: string }) {
    const { toast } = useToast();
  const [isArchiving, setIsArchiving] = useState(false);
  const markFullyPaired = useMarkPositionManualFullyPaired();

  const onArchive = async (id: string) => {
    if (isArchiving) return;
    setIsArchiving(true);

    const result = await markFullyPaired({ position_id: id, is_fully_paired: markStatus });
    setIsArchiving(false);

    if (!result.success) {
      toast({
        title: "Failed to mark",
        description: "",
      });
    } else {
        refetchPositions()
        toast({
            title: message,
            description: "",
        });
    }
  };

  return (
    <Button
      className={buttonClassName}
      variant="outline"
      onClick={() => onArchive(id)}
      disabled={isArchiving}
    >
      <span className="sr-only">{buttonLabel}</span>
      {isArchiving ? <Loader /> : <span>{buttonLabel}</span>}
    </Button>
  );
}

function MarkContractFullyPairedContractButton({ id, refetchContracts }: { id: string; refetchContracts: any }) {
    const { toast } = useToast();
  const [isArchiving, setIsArchiving] = useState(false);
  const markFullyPaired = useMarkContractManualFullyPaired();

  const onArchive = async (id: string) => {
    if (isArchiving) return;
    setIsArchiving(true);

    const result = await markFullyPaired({ contract_id: id, is_fully_paired: true });
    setIsArchiving(false);

    if (!result.success) {
      toast({
        title: "Failed to mark paired",
        description: "",
      });
    } else {
        refetchContracts()
        toast({
            title: "Marked Paired",
            description: "",
        });
    }
  };

  return (
    <Button
      variant="outline"
      onClick={() => onArchive(id)}
      disabled={isArchiving}
    >
      <span className="sr-only">Mark Paired</span>
      {isArchiving ? <Loader /> : <span>Mark Paired</span>}
    </Button>
  );

}

function ArchiveContractButton({ id, refetchContracts }: { id: string; refetchContracts: any }) {
    const { toast } = useToast();
  const [isArchiving, setIsArchiving] = useState(false);
  const archiveContract = useArchiveContract();

  const onArchive = async (id: string) => {
    if (isArchiving) return;
    setIsArchiving(true);

    const result = await archiveContract(id);
    setIsArchiving(false);

    if (!result.success) {
      toast({
        title: "Failed to archive contract",
        description: "",
      });
    } else {
        refetchContracts();
        toast({
            title: "Archived",
            description: "",
        });
    }
  };

  return (
    <Button
      variant="ghost"
      className="h-8 w-8 p-0"
      onClick={() => onArchive(id)}
      disabled={isArchiving}
    >
      <span className="sr-only">Archive</span>
      {isArchiving ? <Loader /> : <ArchiveXIcon className="h-4 w-4" />}
    </Button>
  );
}

function generateTotalMargin(openContracts: TradingContract[]) {
  const amount = openContracts.reduce((acc, contract) => {
    return acc.plus(contract.margin_amount);
  }, new Decimal(0));
  const currencyAmount = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  })
    .format(amount.abs().toNumber())
    .replace("$", "");
  return amount.isNegative() ? `$ (${currencyAmount})` : `$ ${currencyAmount}`;
}

function getSchemaForType(isUpdateForm: boolean, contract_type: string) {
  switch (contract_type) {
    case ContractType.Range:
      return isUpdateForm
        ? transformSchemaToOptionalNullish(RangeValidation)
        : RangeValidation;
    case ContractType.MinMax:
      return isUpdateForm
        ? transformSchemaToOptionalNullish(MinMaxValidation)
        : MinMaxValidation;
    case ContractType.PremiumOfferWDU:
      return isUpdateForm
        ? transformSchemaToOptionalNullish(PremiumValidation)
        : PremiumValidation;
    case ContractType.MinPriceCallSpread:
      return isUpdateForm
        ? transformSchemaToOptionalNullish(MinPriceValidation)
        : MinPriceValidation;
    case ContractType.MinimumPrice:
      return isUpdateForm
        ? transformSchemaToOptionalNullish(MinimumPriceValidation)
        : MinimumPriceValidation;
    case ContractType.AccWeeklyDU:
      return isUpdateForm
        ? transformSchemaToOptionalNullish(AccumulatorValidation)
        : AccumulatorValidation;
    default:
      return RangeValidation;
  }
}

function transformSchemaToOptionalNullish(schema: any) {
  return schema;
}

function clearFieldsOnContractTypeChange(contract_type?: string) {
  if (!contract_type) return {};
  const type_mapping: Record<string, string[]> = {
    [ContractType.Range]: ["step_three", "step_two", "step_one"],
    [ContractType.MinMax]: ["step_three", "step_one"],
    [ContractType.PremiumOfferWDU]: [
      "step_three",
      "otc_futures",
      "futures_price",
      "account_no_b",
    ],
    [ContractType.MinPriceCallSpread]: ["step_one", "step_three"],
    [ContractType.MinimumPrice]: ["step_three"],
    [ContractType.AccWeeklyDU]: [
      "start_date",
      "weeks",
      "period_qty",
      "knockout",
      "step_one",
    ],
  };

  if (!type_mapping[contract_type]) return {};

  const clear_values = Object.entries(type_mapping).reduce((acc, entry) => {
    const [key, values] = entry;
    if (key === contract_type) return acc;

    values.forEach((valKey: string) => {
      acc = {
        ...acc,
        [valKey]: undefined,
      } as any;
    });

    return acc;
  }, {});

  type_mapping[contract_type].forEach((key: string) => {
    delete (clear_values as any)[key];
  });

  return clear_values;
}

function mapToTableContract({
  AccountTypeOptions,
  customers,
}: {
  AccountTypeOptions: SelectOptionItem<string>[];
  customers: Customer[];
}) {
  return function mapToTableContractHelper(contract: TradingContract) {
    return {
      ...contract,
      options_account: {
        no: AccountTypeOptions.find(
          ({ value, label }: SelectOptionItem<string>) => {
            return (
              value?.toLocaleLowerCase() ===
              contract.account_no_a?.toLocaleLowerCase()
            );
          },
        )?.label,
      },
      futures_account: {
        no: AccountTypeOptions.find(
          ({ value, label }: SelectOptionItem<string>) => {
            return (
              value?.toLocaleLowerCase() ===
              contract.account_no_b?.toLocaleLowerCase()
            );
          },
        )?.label,
      },
      date_label: moment
        .tz(contract.date, CommonDestinationTimezone)
        .format("MM/DD/YYYY"),
      start_date_label: contract.start_date
        ? moment
            .tz(contract.start_date, CommonDestinationTimezone)
            .format("MM/DD/YYYY")
        : "",
      end_date_label: contract.end_date
        ? moment
            .tz(contract.end_date, CommonDestinationTimezone)
            .format("MM/DD/YYYY")
        : "",
      commodity_label:
        CommodityOptions.find((option: SelectOptionItem<string>) => {
          return (
            option.value?.toLocaleLowerCase() ===
            contract.commodity?.toLocaleLowerCase()
          );
        })?.label || contract.commodity,
      contract: {
        type: ContractTypeOptions.find(
          ({ value, label }: SelectOptionItem<string>) => {
            return (
              value?.toLocaleLowerCase() ===
              contract.contract_type?.toLocaleLowerCase()
            );
          },
        )?.label,
      },
      customer: customers.find(
        (customer) => customer.id === contract.customer_id,
      ),
    };
  };
}
