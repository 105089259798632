export enum PossibleRole {
  Admin = "admin",
  User = "user",
}

export enum PossiblePackage {
  AtsBacktest = "ats_backtest",
  Tickets = "tickets",
  HedgeBeacon = "hedge_beacon",
  HedgeBeaconMarketThoughts = "hedge_beacon_market_thoughts",
  Communications = "communications",
  Developer = "developer",
  AtsRead = "ats_read",
  AtsAction = "ats_action",
  ListTraders = "list_traders",
  TransactionalReports = "transactional_reports",
  SignatureDocuments = 'signature_documents',
  Freight = "freight",
  ManagerCustomer = "manage_customers",
  OTC = "otc",
  OTCAdmin = "otc_admin",
  GeneralContract = "general_contract",
  General = "general",
  ClientNotification = "client_notification",
  Employee = "employee",
  Destination = "destination",
}

export function doesUserHavePackages({
  requiredPackages,
  member,
}: {
  requiredPackages?: PossiblePackage[];
  member: any;
}) {
  if (!requiredPackages) return true;
  if (!requiredPackages.length) return true;

  const basePackages = getUserPackages(member);
  const role = getUserRole(member);

  const packages = basePackages.map((aPackage: string) => aPackage.split(":")[0]);
  if (packages.includes(PossiblePackage.Developer)) {
    return true;
  }

  if (
    requiredPackages.includes(PossiblePackage.TransactionalReports) &&
    !packages.includes(PossiblePackage.TransactionalReports)
  ) {
    return false;
  }

  if (role === PossibleRole.Admin) return true;

  if (!packages.length) return false;

  for (const requiredPackage of requiredPackages) {
    if (!packages.includes(requiredPackage)) return false;
  }

  return true;
}

export function getUserPackages(member: any): PossiblePackage[] {
  return member?.trusted_metadata["packages"] || [];
}

export function getUserRole(member: any): PossibleRole {
  return member?.trusted_metadata["role"] || PossibleRole.User;
}

export function getUserDbId(member: any): string {
  return member?.trusted_metadata["db_user_id"]!;
}

export function getUserStytchName(member: any): string {
  return member?.name! || "";
}
