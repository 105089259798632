import { parse } from "csv-parse/browser/esm/sync";

export function parseFromString(csvText: string) {
  return parse(csvText, {
    columns: true,
    skip_empty_lines: true,
    relax_column_count: true,
    trim: true,
  });
}

export interface UnsettledArCanRow {
    "Purchase/Sale": string,
    "As Of Date": string,
    "Location Id": string,
    "Location Name": string,
    "Commodity Id": string,
    "Commodity Name": string,
    "Pricing UOM": string,
    "Currency": string,
    "Customer/Vendor Id": string,
    "Customer/Vendor Name": string,
    "City": string,
    "State/Province": string,
    "Contract No": string,
    "Contract Type": string,
    "Ticket No": string,
    "Ticket Date": string,
    "Quantity": string,
    "Futures": string,
    "Basis": string,
    "Option Month": string,
    "Price": string,
    "Gross": string,
    "Discount": string,
    "Charges": string,
    "Settlement": string,
    "Advance": string,
    "Net": string,
    "Payment Indicator": string,
}


export interface UnsettledArUsRow extends UnsettledArCanRow {
}

export interface TrialArRow {
    "Aging As Of": string,
    "Currency": string,
    "Trader/Salesrep": string,
    "Aging Balances": string,
    "Business Type": string,
    "Credit Administrator": string,
    "Customer Id": string,
    "Customer": string,
    "Location": string,
    "Invoice Number": string,
    "Reference": string,
    "Transaction Date": string,
    "Due Date": string,
    "Transaction Type": string,
    "Total AR": string,
    "Current": string,
    "1 - 15": string,
    "16 - 30": string,
    "31 - 45": string,
    "46 - 9999": string,
    "Credit Limit": string,
    "Credit Limit Currency": string,
    "Next Credit Review Date": string,
    // "Business Type": string,
    "Avg  Days To Pay": string,
}
