import { create } from "zustand";
import { useState } from "react";
import moment from "moment-timezone";
import { GenerateLoadingOrError, genFlatOptionList, genIdOptionList, CommonDestinationTimezone, cn } from "../../lib/utils";
import {
    ListFilter as ListFilterIcon,
} from "lucide-react";
import {
    InputRequiredStatus,
    InputFormType,
    InputType,
    ImportType,
    ExportType,
    InputFilter,
    InputRequiredStatusType,
} from "../../lib/form";
import { Customer } from "../../lib/api/reports";
import * as FormHelper from "../../lib/form";
import { CommodityOptions, Commodities } from "../../lib/api/commodity";
import * as FormLayout from "../../lib/form/layout";
import {
    PrimitiveGridLayout,
    PrimitiveManagedTable,
    PrimitiveDialogForm,
} from "../../lib/form/layout";
import { Button } from "../../components/ui/button";
import { toast, useToast } from "../../components/ui/use-toast";
import {
    useListBaseData,
    useListStagedData,
    useCreateUploadJob,
    FilterOptions,
    StagedRow,
    BaseInfo,
    useUpdateEnabledStatus,
    useSendMessage,
    useListHistoricalData,
    HistoricalData,
    HistoricalMessageReceiver,
} from "../../lib/api/destination";
import {
    Tabs,
    TabsContent,
    TabsList,
    TabsTrigger,
} from "../../components/ui/tabs";
import { FormGridHelper } from "../reports/helper";
import { SuccessResult, ValidationError } from "../../lib/responseResults";
import { useGetCreateJobStatus } from "../../lib/api/dorman";
import { UploadPresignedFile } from "@/lib/file";
import { Loader } from "@/components/ui/loader";
import { Textarea } from "@/components/ui/textarea";
import { DatePicker } from "@/components/ui/typeable_date_picker";
import { Label } from "@/components/ui/label";

const useSortFilterState = create((set) => ({
    filters: {
        shipment_end: moment.tz(CommonDestinationTimezone).endOf("month").format("YYYY-MM-DD")
    } as FilterOptions,
    setFilterState: (newState: FilterOptions) =>
        set((state: any) => ({ filters: newState })),
    clearAll: () => set((state: any) => ({ filters: {} })),
}));

const useHistoricalState = create((set) => ({
    selectedDestinationId: undefined,
    selectedCommodity: undefined,
    sent_date: new Date(),
    setSentDate: (newState: string | undefined) =>
        set((state: any) => ({ sent_date: newState })),
    setSelectedDestinationId: (newState: string | undefined) =>
        set((state: any) => ({ selectedDestinationId: newState })),
    setSelectedCommodity: (newState: string[]) =>
        set((state: any) => ({ selectedCommodity: newState })),
    clearFilterKey: (messageId: string) =>
        set((state: any) => ({ messages: {
          ...state.messages,
          [messageId]: undefined
        } })),
    setFilterState: (messageId: string, newState: string) =>
        set((state: any) => ({ messages: {
          ...state.messages,
          [messageId]: newState
        } })),
}));

const useMessagesState = create((set) => ({
    messages: {} as Record<string, string>,
    selectedDestinationId: undefined,
    selectedCommodities: [],
    shipment_start: undefined,
    shipment_end: moment.tz(CommonDestinationTimezone).endOf("month").toDate(),
    scheduledDate: undefined,
    isCommunicating: false,
    setScheduledDate: (newState: string | undefined) =>
        set((state: any) => ({ scheduledDate: newState })),
    setIsCommunicating: (newState: boolean) =>
        set((state: any) => ({ isCommunicating: newState })),
    setSelectedDestinationId: (newState: string | undefined) =>
        set((state: any) => ({ selectedDestinationId: newState })),
    setSelectedCommodities: (newState: string[]) =>
        set((state: any) => ({ selectedCommodities: newState })),
    clearFilterKey: (messageId: string) =>
        set((state: any) => ({ messages: {
          ...state.messages,
          [messageId]: undefined
        } })),
    setFilterState: (messageId: string, newState: string) =>
        set((state: any) => ({ messages: {
          ...state.messages,
          [messageId]: newState
        } })),
    setShipmentStart: (newState: string | undefined) =>
        set((state: any) => ({ shipment_start: newState })),
    setShipmentEnd: (newState: string | undefined) =>
        set((state: any) => ({ shipment_end: newState })),
    clearAll: () => set((state: any) => ({ messages: {} })),
}));

export const HistoricalFilterFormSchema = {
    message_date: { label: "Message Date", input_type: InputType.Date, input_type_validation: InputFormType.DateString, required_status: InputRequiredStatus.Required },

    destination_id: { label: "Destination", options_reference_id: "destinationOptions", input_type: InputType.Uuid, input_type_validation: InputFormType.Uuid, required_status: InputRequiredStatus.Optional },
    commodity: { label: "Commodity", options_reference_id: "commodityOptions", input_type: InputType.Enum, input_type_validation: InputFormType.Enum, required_status: InputRequiredStatus.Optional },
}

export const CreateJobFormSchema = {
    contract_release_file: { label: "Contract Release Excel", fileExtensions: [".xlsx", ".xls"], input_type: InputType.File, input_type_validation: InputFormType.File, required_status: InputRequiredStatus.Required },
    freight_orders_file: { label: "Freight Orders Csv", fileExtensions: [".csv"], input_type: InputType.File, input_type_validation: InputFormType.File, required_status: InputRequiredStatus.Required },
};

const createJobState = FormHelper.generateBaseState({
    schemas: [CreateJobFormSchema],
    baseSchema: CreateJobFormSchema,
});

export function DestinationMessagingDashboard() {
    const baseDataRes = useListBaseData();
    const loadingOrErrorUi = GenerateLoadingOrError([baseDataRes])
    const [tabValue, setTabValue] = useState("upload");

    if (loadingOrErrorUi) {
        return (
            <section className="flex min-h-full flex-1 flex-col justify-start px-6 py-6 lg:px-8">
                <h1 className="scroll-m-20 text-2xl font-extrabold tracking-tight lg:text-3xl">
                    Destination Messaging
                </h1>
                {loadingOrErrorUi}
            </section>
        );
    }

    const baseData = baseDataRes.data?.data || {};

    return (
      <section className="flex min-h-full flex-1 flex-col justify-start px-6 py-6 lg:px-8">
        <h1 className="scroll-m-20 text-2xl font-extrabold tracking-tight lg:text-3xl">
          Destination Messaging
        </h1>
        <div className="flex justify-end my-4">
          <Tabs defaultValue="upload" className="w-full">
            <TabsList>
              <TabsTrigger value="upload">Upload</TabsTrigger>
              <TabsTrigger value="historical">Historical</TabsTrigger>
              <TabsTrigger value="staged">Staged</TabsTrigger>
            </TabsList>
            <TabsContent value="upload">
              <UploadSection onTabValueChange={setTabValue} />
            </TabsContent>
            <TabsContent value="historical">
              <HistoricalSection customers={baseData.customers} marketZones={baseData.market_zones} />
            </TabsContent>
            <TabsContent value="staged">
              <StagedSection customers={baseData.customers} marketZones={baseData.market_zones} />
            </TabsContent>
          </Tabs>
        </div>
      </section>
    );
}

function UploadSection({ onTabValueChange }: { onTabValueChange: any }) {
    const [jobId, setJobId] = useState<string | undefined>(undefined);
    const jobStatusRes = useGetCreateJobStatus(jobId);
    const inputState: any = createJobState.useInputsState((state: any) => state);
    const submissionState: any = createJobState.useSubmissionState((state: any) => state);
    const onCreateJob = useCreateUploadJob();
    const { toast } = useToast();

    return (
        <div className="mt-4 flex flex-col gap-4">
            {FormLayout.RenderFullPrimitiveGridLayout({
                hideSkipButton: true,
                id: "CreateDestinationMessagingUploadJob",
                activeSchema: CreateJobFormSchema,
                totalFields: inputState.totalFields,
                formValues: inputState.formValues,
                updateFormValue: inputState.updateFormValue,
                formRelationships: inputState.formRelationships,
                updateFormRelationship: inputState.updateFormRelationship,
                isSubmittingDisabled: !!jobId,
                submissionState,
                externalReferences: {},
                onSubmitTo: async (item: any) => {
                    if (!!jobId) { return new SuccessResult("", {}); }

                    if (![".xlsx", ".xls"].some((extension: string) => item.agtech_file?.name?.toLocaleLowerCase()?.endsWith(extension))) {
                        toast({
                            title: "Invalid Agtech File",
                            description: "Must be an Excel file",
                        });
                        return new ValidationError("", {});
                    }

                    const createResult = await onCreateJob({
                        contract_release_filename: item.contract_release_file.name || "",
                        freight_orders_filename: item.freight_orders_file.name || "",
                    });

                    if (!createResult.success) {
                        return createResult;
                    }

                    const resultData = createResult.data;
                    setJobId(resultData.queuedJobId);

                    await UploadPresignedFile(resultData.freightUploadUrl, item.freight_orders_file);
                    await UploadPresignedFile(resultData.contractUploadUrl, item.contract_release_file);

                    return createResult;
                },
                handleSubmitResponse: (result) => {
                    if (result.statusCode === 400) {
                        return;
                    } else if (!result.success) {
                        toast({
                            title: "Failed to upload files",
                            description: "",
                        });
                    } else {
                        toast({
                            title: "Queued - Please wait.",
                            description: "",
                        });
                    }
                }
            })}

            {jobId && <JobStatus jobStatus={jobStatusRes.data?.data?.status} setJobId={setJobId} onTabValueChange={onTabValueChange} />}

        </div>
    );
}

function JobStatus({ jobStatus, setJobId, onTabValueChange }: { jobStatus: string; setJobId: any; onTabValueChange: any }) {
    const className = "text-lg font-bold tracking-tight mb-4";

    if (!jobStatus) { return (<div className={className}><Loader /></div>) }
    if (jobStatus === "queued") { return (<div className={className}>Processing</div>) }
    if (jobStatus === "doesnt_exist") { return (<div className={className}>Failed to find files</div>) }
    if (jobStatus === "failed") { return (<div className={className}>Failed to process</div>) }
    if (jobStatus === "halt") { return (<div className={className}>Failed to process</div>) }
    if (jobStatus !== "completed") { return (<div className={className}>Unknown</div>); }

    onTabValueChange("staged");
    setJobId(undefined);
    return (<div className={className}>Completed</div>);
}

function HistoricalSection({
    customers,
    marketZones,
  }:{
    customers: BaseInfo["customers"];
    marketZones: BaseInfo["market_zones"];
  }) {
      const messagesState = useHistoricalState((state: any) => state);
      const historicalDataRes = useListHistoricalData(moment(messagesState.sent_date).format("YYYY-MM-DD"));
      const loadingOrErrorUi = GenerateLoadingOrError([historicalDataRes])
  
      if (loadingOrErrorUi) {
          return loadingOrErrorUi;
      }

    const historicalRows = historicalDataRes.data?.data || [];

      const stageRowCollectionMap = historicalRows.reduce((acc: any, row: HistoricalData) => {
        if (!acc[row.destination_id]) {
            acc[row.destination_id] = { commodities: [], rows: [] };
        }

        row.DestinationMessageReceivers.forEach((receiver: HistoricalMessageReceiver) => {
            if (!acc[row.destination_id].commodities.includes(receiver.commodity)) {
                acc[row.destination_id].commodities.push(receiver.commodity);
            }

            acc[row.destination_id].rows.push(receiver);
        });

        return acc;
    }, {});

    const selectedDestinationFullCommoditiesList = stageRowCollectionMap[messagesState.selectedDestinationId]?.commodities || [];
    const orderedDestinations = Object.keys(stageRowCollectionMap).map((destinationId: string) => customers.find((customer: any) => customer.id === destinationId)).sort((a: any, b: any) => a.long_name.localeCompare(b.long_name));

    if (!messagesState.sent_date) {
        return (<div className="mt-4 flex flex-col gap-4">
            <div className="flex flex-row justify-end items-center">
                <span className="flex flex-col gap-2">
                    <Label>Select a date to view historical messages</Label>
                </span>
            </div>
        </div>);
    }

    const message = stageRowCollectionMap[messagesState.selectedDestinationId]?.rows?.find((row: HistoricalMessageReceiver) => row.commodity === messagesState.selectedCommodity)?.message || "";


    return (<div className="mt-4 flex flex-col gap-4">
        <div className="flex flex-row justify-end items-center">
            <span className="flex flex-col gap-2">
            <Label>Message Date</Label>
            <DatePicker
                value={messagesState.sent_date}
                onChange={(date) => {
                    messagesState.setSentDate(date)
                }}
                placeholder=""
                className="w-[200px]"
            />
            </span>
        </div>
        {historicalRows.length ? (
        <>
            <div className="flex flex-row gap-4 items-end">
                <Textarea 
                    className="flex-1"
                    placeholder="Select a Destination and Commodity to view message"
                    disabled
                    value={message}
                />
            </div>
            <div className="mt-4 grid grid-cols-4 gap-4 h-full bg-gray-100 divide-x divide-gray-200 p-2">
                <div className="flex flex-col h-full px-2">
                    <div className="mb-4">
                        <h3 className="font-semibold px-3">Destination</h3>
                        <div className="h-[1px] bg-gray-200 mt-2 mb-3 mx-[-8px]"></div>
                    </div>
                    {orderedDestinations?.map((row: any) => (
                        <Button
                            key={row.id}
                            variant="ghost"
                            className={cn(
                                "justify-start h-auto py-2 px-3",
                                messagesState.selectedDestinationId === row.id ? "bg-blue-500 hover:bg-blue-600 text-white" : ""
                            )}
                            onClick={() => {
                                if (messagesState.selectedDestinationId === row.id) {
                                    messagesState.setSelectedDestinationId(undefined);
                                    messagesState.setSelectedCommodity(undefined);
                                } else {
                                    messagesState.setSelectedDestinationId(row.id);
                                    messagesState.setSelectedCommodity(undefined);
                                }
                            }}
                        >
                            {row?.long_name}
                        </Button>
                    ))}
                </div>
                <div className="flex flex-col h-full px-2">
                    <div className="mb-4">
                        <h3 className="font-semibold px-2">Commodity</h3>
                        <div className="h-[1px] bg-gray-200 mt-2 mb-3 mx-[-8px]"></div>
                    </div>
                    {selectedDestinationFullCommoditiesList.map((commodity: string) => (
                        <div 
                            key={commodity}
                            className="flex items-center gap-2 py-1 px-2"
                        >
                            <Button
                                key={`History:${commodity}`}
                                variant="ghost"
                                className={cn(
                                    "justify-start h-auto py-2 px-3",
                                    messagesState.selectedCommodity === commodity ? "bg-blue-500 hover:bg-blue-600 text-white" : ""
                                )}
                                onClick={() => {
                                    if (messagesState.selectedCommodity === commodity) {
                                        messagesState.setSelectedCommodity(undefined);
                                    } else {
                                        messagesState.setSelectedCommodity(commodity);
                                    }
                                }}
                            >
                            {CommodityOptions.find((option: any) => option.value === commodity)?.label}
                            </Button>
                        </div>
                    ))}
                </div>
                <div className="flex flex-col h-full px-2">
                    <div className="mb-4">
                        <h3 className="font-semibold">Producer/Freight</h3>
                        <div className="h-[1px] bg-gray-200 mt-2 mb-3 mx-[-8px]"></div>
                    </div>
                    {stageRowCollectionMap[messagesState.selectedDestinationId]?.rows?.filter((row: HistoricalMessageReceiver) => messagesState.selectedCommodity === row.commodity).map((row: HistoricalMessageReceiver) => (
                        <div 
                            key={row.receiver_id}
                            className="flex items-center gap-2 py-1"
                        >
                            <span className={cn(
                                "flex-1",
                            )}>
                                {customers.find((customer: any) => customer.id === row.receiver_id)?.long_name}
                            </span>
                        </div>
                    ))}
                </div>
            </div>
        </>
        ) :
          (
            <div className="mt-4 grid grid-cols-3 gap-4 h-full">
              Found no messages for Date
            </div>
          )
        }
    </div>);
}

function StagedSection({
  customers,
  marketZones,
}:{
  customers: BaseInfo["customers"];
  marketZones: BaseInfo["market_zones"];
}) {
    const messagesState = useMessagesState((state: any) => state);
    const stagedDataRes = useListStagedData({
        shipment_start: messagesState.shipment_start ? moment(messagesState.shipment_start).format("YYYY-MM-DD") : undefined,
        shipment_end: messagesState.shipment_end ? moment(messagesState.shipment_end).format("YYYY-MM-DD") : "",
    });
    const loadingOrErrorUi = GenerateLoadingOrError([stagedDataRes])
    const updateEnabledStatus = useUpdateEnabledStatus();
    const sendMessage = useSendMessage();

    if (loadingOrErrorUi) {
        return loadingOrErrorUi;
    }

    const { data: stagedRows, exceptions } = stagedDataRes.data?.data || { exceptions: [], data: [] };
    const stageRowCollectionMap = stagedRows.reduce((acc: any, row: StagedRow) => {
        if (!acc[row.data.destination_id]) {
            acc[row.data.destination_id] = { commodities: [], rows: [] };
        }

        if (!acc[row.data.destination_id].commodities.includes(row.data.commodity)) {
            acc[row.data.destination_id].commodities.push(row.data.commodity);
        }

        acc[row.data.destination_id].rows.push(row);

        return acc;
    }, {});

    const selectedDestinationFullCommoditiesList = stageRowCollectionMap[messagesState.selectedDestinationId]?.commodities || [];
    const orderedDestinations = Object.keys(stageRowCollectionMap).map((destinationId: string) => customers.find((customer: any) => customer.id === destinationId)).sort((a: any, b: any) => a.long_name.localeCompare(b.long_name));

    return (<div className="mt-4 flex flex-col gap-4">
        <div className="flex flex-row justify-end items-center gap-2">
            <span className="flex flex-col gap-2">
            <Label>Start Date (Optional)</Label>
            <DatePicker
                value={messagesState.shipment_start}
                onChange={(date) => {
                    messagesState.setShipmentStart(date)
                }}
                placeholder=""
                className="w-[200px]"
            />
            </span>
            <span className="flex flex-col gap-2">
            <Label>End Date (Required)</Label>
            <DatePicker
                value={messagesState.shipment_end}
                onChange={(date) => {
                    messagesState.setShipmentEnd(date)
                }}
                placeholder=""
                className="w-[200px]"
            />
            </span>
        </div>
        {stagedRows.length ? (
        <>
            <div className="flex flex-row gap-4 items-end">
                <Textarea 
                    className="flex-1"
                    placeholder="Enter freight/producer message here..."
                    disabled={!messagesState.selectedDestinationId || messagesState.selectedCommodities.length === 0}
                    value={messagesState.messages[messagesState.selectedDestinationId] || ''}
                    onChange={(e) => messagesState.setFilterState(messagesState.selectedDestinationId, e.target.value)}
                />
                <div className="flex flex-col gap-2">
                    <Label>Schedule Future Send</Label>
                    <DatePicker
                        value={messagesState.scheduledDate}
                        onChange={(date) => {
                            messagesState.setScheduledDate(date)
                        }}
                        placeholder=""
                        className="w-[200px]"
                        minDate={new Date()} // Prevents selecting past dates
                    />
                    <Button
                        disabled={
                            !messagesState.selectedDestinationId || 
                            !(messagesState.selectedCommodities?.length > 0) ||
                            (messagesState.messages[messagesState.selectedDestinationId]?.length || 0) < 5 ||
                            messagesState.isCommunicating
                        }
                        onClick={async () => {
                            if (messagesState.isCommunicating) { return; }
                            if (!!messagesState.scheduledDate && moment(messagesState.scheduledDate).isSameOrBefore(moment().endOf("day"))) {
                                toast({
                                    title: "Invalid date",
                                    description: "Date must be in the future",
                                });
                                return;
                            }
                            messagesState.setIsCommunicating(true);
                            messagesState.clearFilterKey(messagesState.selectedDestinationId);

                            const formattedDate = messagesState.scheduledDate ? moment(messagesState.scheduledDate).format("YYYY-MM-DD") : undefined;   
                            const sendResult = await sendMessage({
                                destination_id: messagesState.selectedDestinationId,
                                commodities: messagesState.selectedCommodities,
                                scheduledDate: formattedDate,
                                message: messagesState.messages[messagesState.selectedDestinationId] || "",
                            });
                            if (sendResult.success) {
                                toast({
                                    title: "Message sent",
                                    description: messagesState.scheduledDate ? 
                                        `Scheduled for ${formattedDate}` : 
                                        "Sending now",
                                });
                            } else {
                                toast({
                                    title: "Failed to send message",
                                    description: "",
                                });
                            }
                            
                            messagesState.setIsCommunicating(false);
                            messagesState.setScheduledDate(undefined); // Reset date after sending
                        }}
                    >
                        {messagesState.scheduledDate ? 'Schedule' : 'Send'}
                    </Button>
                </div>
            </div>
            <div className="mt-4 grid grid-cols-4 gap-4 h-full bg-gray-100 divide-x divide-gray-200 p-2">
                <div className="flex flex-col h-full px-2">
                    <div className="mb-4">
                        <h3 className="font-semibold px-3">Destination</h3>
                        <div className="h-[1px] bg-gray-200 mt-2 mb-3 mx-[-8px]"></div>
                    </div>
                    {orderedDestinations?.map((row: any) => (
                        <Button
                            key={row.id}
                            variant="ghost"
                            className={cn(
                                "justify-start h-auto py-2 px-3",
                                messagesState.selectedDestinationId === row.id ? "bg-blue-500 hover:bg-blue-600 text-white" : ""
                            )}
                            onClick={() => {
                                if (messagesState.selectedDestinationId === row.id) {
                                    messagesState.setSelectedDestinationId(undefined);
                                    messagesState.setSelectedCommodities([]);
                                } else {
                                    messagesState.setSelectedDestinationId(row.id);
                                    if (stageRowCollectionMap[row.id]?.commodities.length === 1) {
                                        messagesState.setSelectedCommodities(stageRowCollectionMap[row.id].commodities);
                                    }
                                }
                            }}
                        >
                            {row?.long_name}
                        </Button>
                    ))}
                </div>
                <div className="flex flex-col h-full px-2">
                    <div className="mb-4">
                        <h3 className="font-semibold px-2">Commodity</h3>
                        <div className="h-[1px] bg-gray-200 mt-2 mb-3 mx-[-8px]"></div>
                    </div>
                    {selectedDestinationFullCommoditiesList.map((commodity: string) => (
                        <div 
                            key={commodity}
                            className="flex items-center gap-2 py-1 px-2"
                        >
                            <input
                                type="checkbox"
                                checked={messagesState.selectedCommodities.includes(commodity)}
                                onChange={(e) => {
                                    const newSelectedCommodities = e.target.checked
                                        ? [...messagesState.selectedCommodities, commodity]
                                        : messagesState.selectedCommodities.filter((c: string) => c !== commodity);
                                    messagesState.setSelectedCommodities(newSelectedCommodities);
                                }}
                                className="h-4 w-4 rounded border-gray-300"
                            />
                            <span className="flex-1">
                                {CommodityOptions.find((option: any) => option.value === commodity)?.label}
                            </span>
                        </div>
                    ))}
                </div>
                <div className="flex flex-col h-full px-2">
                    <div className="mb-4">
                        <h3 className="font-semibold">Producer/Freight</h3>
                        <div className="h-[1px] bg-gray-200 mt-2 mb-3 mx-[-8px]"></div>
                    </div>
                    {stageRowCollectionMap[messagesState.selectedDestinationId]?.rows?.filter((row: StagedRow) => messagesState.selectedCommodities.includes(row.data.commodity)).map((row: StagedRow) => (
                        <div 
                            key={row.data.producer_or_freight_id}
                            className="flex items-center gap-2 py-1"
                        >
                            <input
                                type="checkbox"
                                checked={row.data.is_enabled}
                                onChange={async (e) => {
                                    if (messagesState.isCommunicating) { return; }
                                    messagesState.setIsCommunicating(true);

                                    const updateResult = await updateEnabledStatus(row.id, e.target.checked);
                                    if (!updateResult.success) {
                                        toast({
                                            title: "Failed to update status",
                                            description: "",
                                        });
                                    } else {
                                        toast({
                                            title: "Updated",
                                            description: "",
                                        });
                                    }   

                                    messagesState.setIsCommunicating(false);
                                }}
                                className="h-4 w-4 rounded border-gray-300"
                            />
                            <span className={cn(
                                "flex-1",
                                !row.data.is_enabled && "text-red-500 line-through opacity-70"
                            )}>
                                {customers.find((customer: any) => customer.id === row.data.producer_or_freight_id)?.long_name}
                            </span>
                        </div>
                    ))}
                </div>
            </div>
        </>
        ) :
          (
            <div className="mt-4 grid grid-cols-3 gap-4 h-full">
              Found no remaining groups to message
            </div>
          )
        }
    </div>);
}
