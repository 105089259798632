import dayjs from "moment-timezone";
import { ChevronRight, ChevronLeft } from "lucide-react";
import { useState } from "react";

interface Props {
  value: Date;
  onChange: (date: Date) => void;
  topRightCornerIcon: (date: Date) => React.ReactNode;
  bottomRightCornerIcon: (date: Date) => React.ReactNode;
}

export function AnalystCalendar({
  value,
  onChange,
  topRightCornerIcon,
  bottomRightCornerIcon,
}: Props) {
  const [currentMonth, setCurrentMonth] = useState(dayjs().month());
  const [currentYear, setCurrentYear] = useState(dayjs().year());

  const handleNextMonth = () => {
    if (currentMonth === 11) {
      setCurrentMonth(0);
      setCurrentYear(currentYear + 1);
    } else {
      setCurrentMonth(currentMonth + 1);
    }
  };

  const handlePrevMonth = () => {
    if (currentMonth === 0) {
      setCurrentMonth(11);
      setCurrentYear(currentYear - 1);
    } else {
      setCurrentMonth(currentMonth - 1);
    }
  };

  const handleDateSelect = (date: dayjs.Moment) => {
    onChange(date.toDate());
  };

  const generateDate = (month = dayjs().month(), year = dayjs().year()) => {
    const firstDateOfMonth = dayjs().year(year).month(month).startOf("month");
    const lastDateOfMonth = dayjs().year(year).month(month).endOf("month");

    const arrayOfDate: {
      currentMonth: boolean;
      date: dayjs.Moment;
      today?: boolean;
    }[] = [];

    // prefix date
    for (let i = 0; i < firstDateOfMonth.day(); i++) {
      arrayOfDate.push({
        currentMonth: false,
        date: dayjs(firstDateOfMonth).day(i),
      });
    }

    // generate current day
    for (let i = firstDateOfMonth.date(); i <= lastDateOfMonth.date(); i++) {
      arrayOfDate.push({
        currentMonth: true,
        date: dayjs(firstDateOfMonth).date(i),
        today:
          dayjs(firstDateOfMonth).date(i).toDate().toDateString() ===
          dayjs().toDate().toDateString(),
      });
    }
    const remaining: number = 42 - arrayOfDate.length;

    // get the remaining dates and push them into an array
    for (
      let i = lastDateOfMonth.date() + 1;
      i <= lastDateOfMonth.date() + remaining;
      i++
    ) {
      arrayOfDate.push({
        currentMonth: false,
        date: dayjs(lastDateOfMonth).date(i),
      });
    }
    return { arrayOfDate };
  };

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  // Generate the dates with background color
  const arrayOfDatesWithBg = generateDate(
    currentMonth,
    currentYear,
  ).arrayOfDate.map(({ date, currentMonth, today }, index) => {
    let bgColorClass = "bg-neutral-200";
    const todayDayjs = dayjs();

    // If the day is in the past
    if (date.isBefore(todayDayjs, "day")) bgColorClass = "bg-neutral-300";

    // If the day is a weekend (Sunday or Saturday)
    if (date.day() === 0 || date.day() === 6) bgColorClass = "bg-neutral-100";

    return {
      date,
      currentMonth,
      today,
      bgColorClass,
      isTopBorder: index < 7,
      isLeftBorder: index % 7 === 0,
    };
  });

  return (
    <div className="flex items-start justify-start min-h-screen max-w-[32rem]">
      <div className="w-full h-full overflow-hidden border-2 border-neutral-300">
        <div className="bg-white">
          <div className="flex justify-between items-center p-1 border-b bg-neutral-200">
            <span className="text-xl font-semibold">
              {months[currentMonth]} {currentYear}
            </span>
            <div>
              <button onClick={handlePrevMonth}>
                <ChevronLeft size={32} className="text-red-800" />
              </button>
              <button onClick={handleNextMonth}>
                <ChevronRight size={32} className="text-red-800" />
              </button>
            </div>
          </div>
          <div className="grid grid-cols-7 text-sm py-0.5">
            {days.map((day, index) => (
              <div
                key={index}
                className={`
                flex items-center justify-start h-8 bg-neutral-500 text-white pl-1 text-xs
                ${index !== 0 && "border-l-1 border-white"}
                ${index !== 6 && "border-r border-white"}
            `}
              >
                <h1 className="text-base">{day}</h1>
              </div>
            ))}
          </div>
        </div>
        <div className="grid grid-cols-7">
          {arrayOfDatesWithBg.map(
            ({ date, bgColorClass, isTopBorder, isLeftBorder }, index) => (
              <div
                key={index}
                onClick={() => handleDateSelect(date)}
                className={`relative flex flex-col justify-between items-start h-[4.2rem] text-neutral-500 cursor-pointer
                  ${
                    date.isSame(value, "day")
                      ? "border-red-800 bg-neutral-50 border" // Selected Date
                      : date.isSame(dayjs(), "day")
                        ? "bg-red-800 text-white border border-white" // Current Date
                        : `border-l border-t border-white ${bgColorClass}`
                  }`} // Other Dates
                style={{
                  borderTop:
                    isTopBorder && !date.isSame(value, "day") ? "none" : "",
                  borderLeft:
                    isLeftBorder && !date.isSame(value, "day") ? "none" : "",
                }}
              >
                <div className="flex w-full justify-between">
                  <h1
                    className={`text-xl p-1 ${
                      date.isSame(value, "day")
                        ? "text-neutral-500"
                        : date.isSame(dayjs(), "day")
                          ? "text-white"
                          : "text-neutral-500"
                    }`}
                  >
                    {date.date()}
                  </h1>
                  <div
                    className="pt-2 pr-1"
                    style={{
                      color: date.isSame(value, "day")
                        ? "var(--neutral-300)"
                        : date.isSame(dayjs(), "day")
                          ? "white"
                          : "var(--neutral-200)",
                    }}
                  >
                    {topRightCornerIcon(date.toDate())}
                  </div>
                </div>
                <div className="flex w-full justify-end pb-1 pr-1">
                  {bottomRightCornerIcon(date.toDate())}
                </div>
              </div>
            ),
          )}
        </div>
      </div>
    </div>
  );
}
