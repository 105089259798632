import moment from "moment-timezone";
import { v4 as uuidV4 } from "uuid";
import { useRef, useState, useEffect } from "react";
import {
  CheckIcon,
  PencilIcon,
  AlertCircleIcon,
  CheckSquare as CheckSquareIcon,
} from "lucide-react";
import { useToast } from "../../components/ui/use-toast";
import { AnalystCalendar } from "../../components/ui/analyst_calendar";
import { SelectInput } from "../../components/ui/SelectInput";
import { Skeleton } from "../../components/ui/skeleton";
import { Button } from "../../components/ui/button";
import { Textarea } from "../../components/ui/textarea";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../components/ui/tooltip";
import {
  AnalystRecommendation,
  AnalystAnalysis,
  AnalystCommentary,
  AnalysisCommodityOptions,
  AnalysisSignalOptions,
  AnalystRecommendationCropTypeOptions,
  AnalysisCommodity,
} from "../../lib/models/analystRecommendation";
import {
  useUpsertRecommendation,
  useLastDayAnalysis,
  useAnalystCalendarSummary,
  usePublishRecommendations,
  useAnalystRecommendations,
  ProjectFullName,
} from "../../lib/api/analystRecommendations";
import { ApiAutoSaver } from "../../lib/autosave";
import { AnalystRecommendationType } from "../../lib/models/analystRecommendation";

moment.tz.setDefault("America/Chicago");
type Analysis = AnalystCommentary | AnalystAnalysis;

function filterAndSortAnalysis(details: Analysis[]) {
  const data = [
    details.find(
      (detail) => detail.type === AnalystRecommendationType.Commentary,
    ),
    details.find(
      (detail) => (detail as any).commodity === AnalysisCommodity.Corn,
    ),
    details.find(
      (detail) => (detail as any).commodity === AnalysisCommodity.Soybean,
    ),
    details.find(
      (detail) => (detail as any).commodity === AnalysisCommodity.MinnieWheat,
    ),
    details.find(
      (detail) => (detail as any).commodity === AnalysisCommodity.Canola,
    ),
  ];

  return data?.filter((item) => !!item) as Analysis[];
}

export function RecommendationAnalysis() {
  return (
    <>
      <div className="border-b border-b-2 flex flex-row justify-between items-center">
        <h1 className="scroll-m-20 text-2xl font-bold tracking-tight lg:text-3xl">
          HedgeBeacon Analysis
        </h1>
        <span className="flex flex-row items-center">
          {moment.tz("America/Chicago").format("dddd M/D/YY")}
        </span>
      </div>
      <BaseView />
    </>
  );
}

function LoadingPage() {
  return (
    <div className="flex flex-col space-y-2 mt-8">
      <Skeleton className="h-4 w-[250px]" />
      <Skeleton className="h-4 w-[200px]" />
    </div>
  );
}

function calculateFilters(
  date: Date,
  recommendationType: AnalystRecommendationType,
) {
  const from = moment.tz(date, "America/Chicago").startOf("day").toDate();
  const to = moment.tz(date, "America/Chicago").endOf("day").toDate();
  return { from, to, recommendationType };
}

function BaseView() {
  const { toast } = useToast();
  const [shouldUpdateRows, setShouldUpdateRows] = useState<boolean>(true);
  const [selectedDate, setSelectedDate] = useState<Date>(
    moment.tz("America/Chicago").startOf("day").toDate(),
  );
  const defaultAnalysisResult = useLastDayAnalysis(selectedDate);
  const recommendationsResult = useAnalystRecommendations(
    calculateFilters(selectedDate, AnalystRecommendationType.Analysis),
  );
  const commentaryResult = useAnalystRecommendations(
    calculateFilters(selectedDate, AnalystRecommendationType.Commentary),
  );
  const [analysisRows, setAnalysisRows] = useState<Analysis[]>([]);
  const upsertRecommendationAnalysis = useUpsertRecommendation();
  const publishAnalysis = usePublishRecommendations();
  const [autoSavers, setAutoSavers] = useState<
    ApiAutoSaver<Analysis, Analysis>[]
  >([
    new ApiAutoSaver<Analysis, Analysis>([], upsertRecommendationAnalysis),
    new ApiAutoSaver<Analysis, Analysis>([], upsertRecommendationAnalysis),
    new ApiAutoSaver<Analysis, Analysis>([], upsertRecommendationAnalysis),
    new ApiAutoSaver<Analysis, Analysis>([], upsertRecommendationAnalysis),
    new ApiAutoSaver<Analysis, Analysis>([], upsertRecommendationAnalysis),
  ]);
  const intervalRef = useRef<NodeJS.Timer>();

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      analysisRows.forEach((row, index) => {
        autoSavers[index].enqueueData(row);
      });
    }, 2000);
    return () => clearInterval(intervalRef.current);
  }, []);

  useEffect(() => {
    if (!shouldUpdateRows) return;
    if (
      recommendationsResult.isLoading ||
      defaultAnalysisResult.isLoading ||
      commentaryResult.isLoading
    ) {
      return;
    }

    if (recommendationsResult?.data?.data?.length) {
      setAnalysisRows(
        filterAndSortAnalysis([
          ...(commentaryResult?.data?.data || []),
          ...(recommendationsResult?.data?.data || []),
        ]),
      );
      setShouldUpdateRows(false);
    } else if (defaultAnalysisResult?.data?.data?.length) {
      setAnalysisRows(
        filterAndSortAnalysis(defaultAnalysisResult?.data?.data || []),
      );
      setShouldUpdateRows(false);
    } else {
      setAnalysisRows([]);
      setShouldUpdateRows(false);
    }
  }, [
    recommendationsResult,
    commentaryResult,
    defaultAnalysisResult,
    selectedDate,
    shouldUpdateRows,
  ]);

  const updateRow =
    (aIndex: number) =>
    ({ key, value }: { key: string; value: string }) => {
      setAnalysisRows(
        analysisRows.map((row, index) => {
          if (aIndex !== index) return row;

          const updatedRecommendation = { ...row, [key]: value };
          autoSavers[aIndex].enqueueData(updatedRecommendation);

          return updatedRecommendation;
        }),
      );
    };

  const onPublish = async () => {
    const publishResult = await publishAnalysis({
      recommendationIds: analysisRows.map((analysis) => analysis.id),
      published: true,
      projectName: ProjectFullName.HedgeBeacon,
    });

    if (!!publishResult.success) {
      toast({
        title: "Published analysis",
        description: "",
      });
    } else {
      toast({
        title: "Failed to publish",
        description: "Please try again",
      });
    }
  };

  const onDateChange = (aDate: Date) => {
    if (!aDate || aDate === selectedDate) return;

    const simpleDate = moment(aDate).format("YYYY/MM/DD");
    setSelectedDate(
      moment.tz(simpleDate, "YYYY/MM/DD", "America/Chicago").toDate(),
    );
    setShouldUpdateRows(true);
  };

  const isLoading =
    defaultAnalysisResult.isLoading || recommendationsResult.isLoading;

  return (
    <div className="flex items-start flex-row h-full gap-4">
      <div className="flex flex-col items-start justify-start">
        <FilterCalendar
          selectedDate={selectedDate}
          setSelectedDate={onDateChange}
        />
      </div>
      <div className="flex flex-col w-full">
        {isLoading && <LoadingPage />}
        {!isLoading && (
          <AnalysisListPreview
            analysisRows={analysisRows}
            updateRow={updateRow}
            onPublish={onPublish}
            selectedDate={selectedDate}
          />
        )}
      </div>
    </div>
  );
}

function FilterCalendar({
  selectedDate,
  setSelectedDate,
}: {
  selectedDate: Date;
  setSelectedDate: (newDate: Date) => void;
}) {
  const summaryResult = useAnalystCalendarSummary(selectedDate);

  return (
    <div className="min-w-[28rem] lg:min-w-[30rem] lg:max-w-[38rem] max-w-2xl mt-4">
      <AnalystCalendar
        value={moment(
          moment.tz(selectedDate, "America/Chicago").format("YYYY/MM/DD"),
        ).toDate()}
        onChange={setSelectedDate as any}
        topRightCornerIcon={(date) => {
          const dateMoment = moment.tz(date, "America/Chicago");
          const found = summaryResult?.data?.data?.calendarSummary?.find(
            (item: any) =>
              item.type === "analysis" &&
              moment.tz(item.date, "America/Chicago").isSame(dateMoment, "day"),
          );
          if (!found) return null;
          if (found.published) return <CheckIcon size={16} />;
          if (!found.published) return <PencilIcon size={16} />;

          return null;
        }}
        bottomRightCornerIcon={(date) => {
          const dateMoment = moment.tz(date, "America/Chicago");
          const found = summaryResult?.data?.data?.calendarSummary?.find(
            (item: any) =>
              item.type === "alert" &&
              moment.tz(item.date, "America/Chicago").isSame(dateMoment, "day"),
          );
          if (!found) return null;
          if (found.active)
            return <AlertCircleIcon size={16} className="text-red-800" />;

          return null;
        }}
      />
    </div>
  );
}

function AnalysisListPreview({
  analysisRows,
  updateRow,
  onPublish,
  selectedDate,
}: {
  selectedDate: Date;
  onPublish: () => Promise<void>;
  analysisRows: Analysis[];
  updateRow: (
    aIndex: number,
  ) => ({ key, value }: { key: string; value: string }) => void;
}) {
  return (
    <div className="flex flex-col mt-4 border">
      <div className="flex flex-row justify-between items-center bg-[rgb(230,230,230)] p-2 px-4">
        <span>
          Analysis for{" "}
          {moment.tz(selectedDate, "America/Chicago").format("M/D/YY")}
        </span>
        <span className="flex flex-row items-center gap-2">
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                <Button
                  disabled
                  variant="outline"
                  className=""
                  style={{ pointerEvents: "none" }}
                >
                  Preview
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                <p>Coming Soon</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>

          <Button className="" onClick={onPublish}>
            <CheckSquareIcon className="h-4 w-4 p-0 mr-2" /> Publish
          </Button>
        </span>
      </div>
      <div className="flex flex-col gap-y-2">
        {analysisRows?.length > 0 &&
          analysisRows.map((row, index) => (
            <AnalysisRow
              key={row.id}
              recommendation={row}
              updateRow={updateRow(index)}
            />
          ))}
        {!analysisRows?.length && (
          <div className="flex flex-col">
            <div className="flex bg-[rgb(242,242,242)] px-4">
              No data for date
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

function AnalysisRow({
  recommendation,
  updateRow,
}: {
  recommendation: Analysis;
  updateRow: any;
}) {
  if (recommendation.type === AnalystRecommendationType.Commentary) {
    return (
      <div className="flex flex-col">
        <div className="flex p-2">
          <Textarea
            placeholder="Commentary"
            className="text-sm ring-0 border-none focus:ring-0 focus-visible:ring-0"
            onChange={(event) =>
              updateRow({ key: "details", value: event.target.value })
            }
            rows={5}
            value={recommendation.details}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col">
      <div className="flex bg-[rgb(242,242,242)] px-4">
        <SelectInput
          onValueChange={(value) => updateRow({ key: "crop_type", value })}
          value={recommendation.crop_type}
          className="w-[3rem] bg-transparent font-bold border-none focus:ring-0 px-0"
          options={AnalystRecommendationCropTypeOptions}
        />
        <SelectInput
          disabled
          onValueChange={(value) => updateRow({ key: "commodity", value })}
          value={recommendation.commodity}
          className="w-[10rem] bg-transparent font-bold border-none focus:ring-0"
          options={AnalysisCommodityOptions}
        />
        <SelectInput
          onValueChange={(value) => updateRow({ key: "action", value })}
          value={recommendation.action}
          className="w-[5rem] bg-transparent font-bold border-none focus:ring-0"
          options={AnalysisSignalOptions}
        />
      </div>
      <div className="flex p-2">
        <Textarea
          className="text-sm ring-0 border-none focus:ring-0 focus-visible:ring-0"
          onChange={(event) =>
            updateRow({ key: "details", value: event.target.value })
          }
          rows={5}
          value={recommendation.details}
        />
      </div>
    </div>
  );
}
