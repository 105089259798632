import moment from "moment-timezone";
import { useState } from "react";
import {
  CheckIcon,
  CheckSquare as CheckSquareIcon,
  Plus as PlusIcon,
  Send as SendIcon,
  Trash as TrashIcon,
  FlaskConical,
} from "lucide-react";
import { useToast } from "../../components/ui/use-toast";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../components/ui/popover";
import { Skeleton } from "../../components/ui/skeleton";
import { Button } from "../../components/ui/button";
import { Textarea } from "../../components/ui/textarea";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../components/ui/tooltip";
import {
  AnalystRecommendation,
  AnalystAlert,
  AnalystRecommendationType,
  AnalysisAction,
  AnalysisCommodityOptions,
  AnalysisSignalOptions,
  AnalystRecommendationCropTypeOptions,
  AlertCommodities,
} from "../../lib/models/analystRecommendation";
import {
  ProjectFullName,
  usePublishMarketThoughts,
} from "../../lib/api/analystRecommendations";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogClose,
} from "../../components/ui/dialog";

moment.tz.setDefault("America/Chicago");

const handleFocus = (event: any) => event.target.select();

export function HedgeBeaconMarketThoughts() {
  return (
    <>
      <div className="border-b border-b-2 flex flex-row justify-between items-center">
        <h1 className="scroll-m-20 text-2xl font-bold tracking-tight lg:text-3xl">
          HedgeBeacon Market Thoughts
        </h1>
        <span className="flex flex-row items-center">
          {moment.tz("America/Chicago").format("dddd M/D/YY")}
        </span>
      </div>
      <BaseView />
    </>
  );
}

function LoadingPage() {
  return (
    <div className="flex flex-col space-y-2 mt-8">
      <Skeleton className="h-4 w-[250px]" />
      <Skeleton className="h-4 w-[200px]" />
    </div>
  );
}

function BaseView() {
  return (
    <div className="flex items-start flex-row h-full gap-4">
      <div className="flex flex-col items-start justify-start">
        <MarketThoughts />
      </div>
    </div>
  );
}

function MarketThoughts() {
  const [details, updateDetails] = useState("");
  const [published, onPublishItem] = useState(false);

  const dashboardColumns = [
    {
      accessorKey: "details",
      header: "Details",
      cell: ({
        row,
      }: {
        row: { original: { details: string; published: boolean } };
      }) => (
        <ThoughtsDetails
          disabled={row.original.published}
          details={row.original.details || ""}
          onChange={(value: string) => {
            return updateDetails(value || "");
          }}
        />
      ),
    },
    {
      accessorKey: "published",
      header: "Published",
      cell: ({
        row,
      }: {
        row: { original: { details: string; published: boolean } };
      }) => (
        <ThoughtsPublishedAction
          details={row.original.details || ""}
          onChange={() => {
            return onPublishItem(true);
          }}
          requiresConfirmation
          projectName={ProjectFullName.MarketThoughts}
        />
      ),
    },
  ];

  return (
    <div className="flex flex-col m-4 min-w-[56rem]">
      <div className="text-sm border min-h-[30rem] max-h-[40rem] lg:max-h-[42]  overflow-auto">
        <div className="grid grid-cols-[5fr_1fr_1fr] justify-center items-center text-center py-1 px-2">
          {dashboardColumns.map((col) => (
            <span key={col.header} className="font-semibold">
              {col.header as any}
            </span>
          ))}
        </div>
        <div className="flex flex-col [&_div:last-child]:border-0 px-1 pb-1">
          {[{ details, published }].map((item) => (
            <div
              key={`MarketThoughtsNewItem`}
              className="grid grid-cols-[5fr_1fr_1fr] border-b transition-colors hover:bg-muted/50"
            >
              {dashboardColumns.map((col) => (
                <span
                  key={`${col.header}`}
                  className="flex justify-center items-center "
                >
                  {col?.cell({ row: { original: item } })}
                </span>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

function ThoughtsPublishedAction({
  details,
  onChange,
  requiresConfirmation,
  projectName,
}: {
  details: string;
  onChange: () => void;
  requiresConfirmation?: boolean;
  projectName: ProjectFullName;
}) {
  const { toast } = useToast();
  const [isPublished, setIsPublished] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const publishMarketThoughts = usePublishMarketThoughts();

  const isTest = projectName.includes("test");

  const onPublish = async () => {
    if (!details) {
      toast({
        title: "Failed",
        description: "May not send empty market thoughts.",
      });
      return;
    }

    if (isLoading) return;
    setIsLoading(true);

    const publishResult = await publishMarketThoughts({
      details,
      projectName,
    });
    setIsLoading(false);

    if (!!publishResult.success) {
      setIsPublished(true);
      onChange();
      toast({
        title: "Sent",
        description: "",
      });
    } else {
      toast({
        title: "Failed to send",
        description: "Please try again",
      });
    }
  };

  if (isPublished) {
    return <CheckIcon size={16} />;
  }

  if (requiresConfirmation) {
    return (
      <Dialog>
        <DialogTrigger>
          <span className="sr-only">confirm</span>
          {isTest ? (
            <FlaskConical className="h-4 w-4" />
          ) : (
            <SendIcon className="h-4 w-4" />
          )}
        </DialogTrigger>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Send</DialogTitle>
            <DialogDescription>
              {isTest
                ? "Send test SMS to preview what you will see"
                : "Are you sure you want to send Market thoughts to everyone?"}
            </DialogDescription>
          </DialogHeader>
          <span className="flex flex-row justify-end gap-4">
            <DialogClose>
              <Button variant="ghost" className="">
                Cancel
              </Button>
            </DialogClose>
            <DialogClose>
              <Button className="" onClick={onPublish} disabled={isLoading}>
                {isTest ? "Send Preview" : "Send"}
              </Button>
            </DialogClose>
          </span>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Button
      variant="ghost"
      className="h-8 w-8 p-0"
      onClick={onPublish}
      disabled={isLoading}
    >
      <span className="sr-only">Send</span>
      {isTest ? (
        <FlaskConical className="h-4 w-4" />
      ) : (
        <SendIcon className="h-4 w-4" />
      )}
    </Button>
  );
}

function ThoughtsDetails({
  details,
  onChange,
  disabled,
}: {
  disabled?: boolean;
  details: string;
  onChange: (data: string) => void;
}) {
  return (
    <Textarea
      disabled={disabled}
      className="flex text-sm ring-0 border-none focus:ring-0 focus-visible:ring-0"
      onChange={(event) => onChange(event.target.value)}
      placeholder="New Market Thoughts"
      rows={6}
      value={details}
    />
  );
}
