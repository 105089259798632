import * as React from "react";
import { Check, ChevronsUpDown } from "lucide-react";
import { SelectOptionItem } from "../../lib/models/ui";
import { cn } from "../../lib/utils";
import { Button } from "./button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "./command";
import { Popover, PopoverContent, PopoverTrigger } from "./popover";
import { ScrollArea } from "./scroll-area";

export function Combobox<T extends string>({
  className,
  options,
  value,
  onValueChange,
  disabled,
  isModal,
  tabIndex,
}: {
  disabled?: boolean;
  options: SelectOptionItem<T>[];
  value: string;
  onValueChange: (value: string) => void;
  className?: string;
  isModal?: boolean;
  tabIndex?: number;
}) {
  const [open, setOpen] = React.useState(false);
  const [search, setSearch] = React.useState("");

  return (
    <Popover open={open} onOpenChange={setOpen} modal={isModal}>
      <PopoverTrigger asChild>
        <Button
          tabIndex={tabIndex}
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className={cn("w-[200px] justify-between", className)}
        >
          {value
            ? options.find((item) =>
                [
                  item.value?.toLocaleLowerCase(),
                  item.label?.toLocaleLowerCase(),
                ].includes(value?.toLocaleLowerCase()),
              )?.label
            : ""}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[200px] max-h-52 p-0 overflow-y-scroll">
        <Command shouldFilter={false}>
          <CommandInput
            placeholder=""
            value={search}
            onValueChange={setSearch}
          />
          <CommandGroup>
            {options
              .filter((option) => {
                if (!search?.trim()) return true;
                return (
                  option.label
                    ?.toLocaleLowerCase()
                    ?.trim()
                    ?.includes(search?.toLocaleLowerCase()?.trim()) || false
                );
              })
              .map((option) => (
                <CommandItem
                  key={option.value}
                  value={option.value}
                  onSelect={(currentValue) => {
                    onValueChange(currentValue === value ? "" : currentValue);
                    setOpen(false);
                  }}
                >
                  <Check
                    className={cn(
                      "mr-2 h-4 w-4",
                      value === option.value ? "opacity-100" : "opacity-0",
                    )}
                  />
                  {option.label}
                </CommandItem>
              ))}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
