import { create } from "zustand";
import { useState } from "react";
import { AudioRecorder } from 'react-audio-voice-recorder';
import moment from "moment-timezone";
import { GenerateLoadingOrError, genFlatOptionList, genIdOptionList, CommonDestinationTimezone } from "../../lib/utils";
import {
  CommodityOptions,
} from "../../lib/api/commodity";
import { useListCustomers } from "../../lib/api/customer";
import { Customer } from "../../lib/api/reports";
import {
    useListLocations,
    useListMarketZones,
    Location, MarketZone,
    purchaseSaleOptions,
    uomOptions,
    currencyOptions,
    generateContractTypesWith,
} from "../../lib/api/generalContract";
import { reduceArrayTo, formulateOptionMonths } from "../../lib/api/common";
import * as FormHelper from "../../lib/form";
import * as FormLayout from "../../lib/form/layout";
import {
    PrimitiveGridLayout
} from "../../lib/form/layout";

export function DeveloperTestPage() {
    const onRecordingComplete = () => {};
    return (
        <div>
            <div>
                <h1>Test Page</h1>
   <AudioRecorder
       onRecordingComplete={() => {console.warn("record complete")}}
      audioTrackConstraints={{
        noiseSuppression: true,
        echoCancellation: true,
      }}
      downloadOnSavePress={true}
      downloadFileExtension="wav"
    />
            </div>
        </div>
    );
}

