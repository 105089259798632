import { parse } from "csv-parse/browser/esm/sync";

export interface Row {
  "Contract No": string;
  Name: string;
  "Market Zone": string;
  "Start Date": string;
  "End Date": string;
  UOM: string;
  Quantity: string;
  Balance: string;
  // Freight Terms
  // Notes
  Rel: string;
  Commodity: string;
  "Cont  Ref": string;
  "Release Reference": string;
  "Freight Terms": string;
}

// Each `SheetDefinition` represents a tab/sheet in the final Excel Book
export interface SheetDefinition {
  commodities: string[];
  includeMarketZones?: string[];
  excludeMarketZones?: string[];
}

// NW, NE, SE MB
export const sheetMap: { [key: string]: SheetDefinition } = {
  "Barley Hulls": {
    commodities: ["BH"],
  },
  Barley: {
    commodities: ["B"],
  },
  "Corn Screenings": {
    commodities: ["CS"],
  },
  Corn: {
    commodities: ["C"],
  },
  "Canadian Corn": {
    commodities: ["C"],
  },
  Canola: {
    commodities: ["CNL"],
  },
  "Wheat Midds": {
    commodities: ["MIDD"],
  },
  Wheat: {
    commodities: ["MW", "WW"],
  },
  Soybeans: {
    commodities: ["S"],
  },
  // 'NW Corn': {
  //     commodities: ['C'],
  // },
  // 'SE MB Corn': {
  //     commodities: ['C'],
  // },
  // 'NE Corn': {
  //     commodities: ['C'],
  // },
  // 'SD Corn': {
  // 'OH Corn': {
  // 'CAD Corn': {
  // 'ND Corn': {

  // 'ND Corn': {
  //     commodities: ['C'],
  // },
  // 'SD Corn': {
  //     commodities: ['C'],
  // },
  // 'OH Corn': {
  //     commodities: ['C'],
  // },
};

export function addMissingCommoditiesToSheetMap(allCommodities: string[]) {
  const commoditiesInSheetMap = Object.values(sheetMap).flatMap(
    (x) => x.commodities,
  );
  const missingCommodities = allCommodities.filter(
    (x) => !commoditiesInSheetMap.includes(x),
  );

  for (let commodity of missingCommodities) {
    sheetMap[commodity] = {
      commodities: [commodity],
    };
  }
}

export function parseFromString(csvText: string) {
  return parse(csvText, {
    columns: true,
    skip_empty_lines: true,
    relax_column_count: true,
    trim: true,
  });
}

export function unique<T>(...list: T[]) {
  return [...new Set(list)];
}

export function padArrayWithNulls<T>(
  array: (T | null)[],
  desiredArrayLength: number,
) {
  const delta = desiredArrayLength - array.length;
  if (delta > 0) {
    array.push(...new Array(delta).fill(null, 0, delta));
  }
}
