import {
  CheckIcon,
  PencilIcon,
  AlertCircleIcon,
  CheckSquare as CheckSquareIcon,
  Plus as PlusIcon,
  Send as SendIcon,
  Trash as TrashIcon,
} from "lucide-react";
import { Button } from "../../components/ui/button";
import { useToast } from "../../components/ui/use-toast";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../components/ui/popover";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../components/ui/tooltip";
import {
  AnalystRecommendation,
  AnalystAlert,
  AnalystRecommendationType,
  AnalysisAction,
  AnalysisCommodityOptions,
  AnalysisSignalOptions,
  AnalystRecommendationCropTypeOptions,
  AlertCommodities,
  ProjectRouteName,
} from "../../lib/models/analystRecommendation";
import {
  useManualCHS,
  useCreateZohoSegment,
  useRefreshZohoMailingList,
  useForceSendTodaysAnalysisComms,
  useUpdateTicketProviderDynamicMappings,
  useRunDataMigrationUpdateCropYears,
  useAtsReplayEvents,
} from "../../lib/api/developer";

export function DeveloperDashboardPage() {
  const { toast } = useToast();
  const replayAtsEvents = useAtsReplayEvents();
  const runManualCHS = useManualCHS();
  const runMappings = useUpdateTicketProviderDynamicMappings();

  const items = [
    {
      title: "Replay ATS Events",
      action: replayAtsEvents,
      description:
        "Wipe the global session data PL and replay the events for all time",
      toast: { success: "Done" },
      background: "bg-purple-500",
    },
    {
      title: "CHS run",
      action: runManualCHS,
      description: "Run a manual unified CHS run on the EB backend",
      toast: { success: "Started" },
      background: "bg-purple-500",
    },
    {
      title: "Refresh Mappings",
      action: runMappings,
      description: "Refresh to latest mappings",
      toast: { success: "Started" },
      background: "bg-purple-500",
    },

  ];

  const handleAction = async (item: any) => {
    const result = await item.action();

    if (!!result.success) {
      toast({
        title: item.toast.success,
        description: "",
      });
    } else {
      toast({
        title: "Failed",
        description: "Please try again",
      });
    }
  };

  return (
    <div>
      <div className="container flex flex-col mb-2">
        <h2 className="scroll-m-20 text-2xl font-extrabold tracking-tight lg:text-3xl">
          Developer Dashboard
        </h2>
        <ul
          role="list"
          className="mt-6 grid grid-cols-1 gap-6 border-b border-t border-gray-200 py-6 sm:grid-cols-2"
        >
          {items.map((item, itemIdx) => (
            <li key={itemIdx} className="flow-root">
              <div className="relative -m-2 flex items-center space-x-4 rounded-xl p-2 focus-within:ring-2 focus-within:ring-indigo-500 hover:bg-gray-50">
                <div>
                  <h3 className="text-sm font-medium text-gray-900">
                    <Button
                      onClick={() => handleAction(item)}
                      disabled={!item.action}
                      className="focus:outline-none"
                    >
                      <span className="absolute inset-0" aria-hidden="true" />
                      <span>{item.title}</span>
                      <span aria-hidden="true"> &rarr;</span>
                    </Button>
                  </h3>
                  <p className="mt-1 text-sm text-gray-500">
                    {item.description}
                  </p>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
