import { useStytchMember } from "@stytch/react/b2b";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useState } from "react";
import { DownloadCloud, Trash2, Plus, MoreHorizontal } from "lucide-react";
import moment from "moment-timezone";
import { Link } from "react-router-dom";
import { Textarea } from "../../components/ui/textarea";
import { Label } from "../../components/ui/label";
import { Loader } from "../../components/ui/loader";
import { Input } from "../../components/ui/input";
import { useTicketUploadGeneratePresignPutUrl } from "../../lib/api/tickets";
import { useListProviders } from "../../lib/api/providers";
import { DormanPreliminarySection } from "../dorman/UploadForm";
import {
  TicketRunSummary,
  TicketsSummary,
  Provider,
} from "../../lib/models/index";
import {
  TicketProviderSummary,
  TicketProviderRecentRunPartial,
  TicketProviderBatchDownloadPartial,
} from "../../lib/models/tickets";
import { ProviderType } from "../../lib/models/provider";
import { Button } from "../../components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../components/ui/dropdown-menu";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../components/ui/tabs";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/ui/table";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../components/ui/accordion";
import { Badge } from "../../components/ui/badge";
import { Checkbox } from "../../components/ui/checkbox";
import { SelectInput } from "../../components/ui/SelectInput";
import { Skeleton } from "../../components/ui/skeleton";
import { useToast } from "../../components/ui/use-toast";
import { DataTable, DataTableProps } from "../../components/ui/dataTable";
import { downloadCsvTextAsFile } from "../../lib/browser/download";

const date = new Date();

export function TicketUpload() {
  const providerListRes = useListProviders({ isUploadable: true });

  if ([providerListRes].some((result) => result.isLoading)) {
    return (
      <div className="flex flex-col space-y-2">
        <Skeleton className="h-4 w-[250px]" />
        <Skeleton className="h-4 w-[200px]" />
      </div>
    );
  }

  if (
    [providerListRes].some((result) => result.error || !result?.data?.success)
  ) {
    return (
      <div className="flex flex-col space-y-2">
        Failed to find providers info. Please refresh and try again
      </div>
    );
  }

  const filteredProviderList = (providerListRes?.data?.data?.records || []);

  return (
    <>
      <TicketUploadView
        providersList={filteredProviderList}
      />
      <div className="mt-8" />
      <div className="container">
        <DormanPreliminarySection />
      </div>
    </>
  );
}

export function TicketUploadView({
  providersList,
}: {
  providersList: Provider[];
}) {
  const { toast } = useToast();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [providerId, setProviderId] = useState<string | undefined>(
    providersList?.[0]?.id,
  );
  const [newFile, setNewFile] = useState<File | undefined>(undefined);
  const generateUploadLink = useTicketUploadGeneratePresignPutUrl();

  const onUploadFile = async () => {
    if (!providerId || !newFile) {
      return;
    }

    if (isSubmitting) return;
    setIsSubmitting(true);

    const result = await generateUploadLink({
      providerId,
      originalFileName: newFile.name,
      mimeType: newFile.type,
    });

    if (!result.success) {
      setIsSubmitting(false);
      toast({
        title: "Failed to reach our servers",
        description: "Please try again or contact your administrator",
      });
      return;
    }

    const uploadFileResult = await fetch(result.data.url, {
      method: "PUT",
      body: newFile,
      headers: {
        "Content-Type": newFile.type,
      },
    })
      .then((res) => {
        if ([202, 200, 201].includes(res.status))
          return { success: true, data: { status: res.status } };
        return { success: false, data: { status: res.status } };
      })
      .catch((error: any) => {
        return { success: false, data: { error } };
      });

    setIsSubmitting(false);
    if (!!uploadFileResult.success) {
      toast({
        title: "Uploaded",
        description: "",
      });
    } else {
      toast({
        title: "Failed to upload",
        description: "Please try again",
      });
    }
  };

  const onFileChange = (...params: any[]) => {
    const item = params[0];

    setNewFile(item.target.files[0]);
  };

  return (
    <>
      <div className="container flex flex-col mb-2">
        <h1 className="scroll-m-20 text-2xl font-extrabold tracking-tight lg:text-3xl">
          Manually Upload Ticket
        </h1>
      </div>
      <div className="flex items-start flex-col h-full mt-4">
        <div className="container flex flex-col gap-4">
          <SelectInput
            disabled={isSubmitting}
            placeHolder="Select a provider"
            onValueChange={setProviderId}
            value={providerId}
            className="w-[300px] px-2"
            options={providersList.map((provider) => ({
              value: provider.id,
              label: provider.name,
            }))}
          />
          <div className="grid w-full max-w-sm items-center gap-1.5">
            <Label htmlFor="uploadTicketFile">Ticket File</Label>
            <Input
              id="uploadTicketFile"
              type="file"
              accept=".pdf, .csv, .txt"
              onChange={onFileChange}
            />
          </div>
          <div className="grid w-full max-w-sm items-center gap-1.5 mt-2">
            <Button
              disabled={isSubmitting}
              id="uploadButton"
              onClick={onUploadFile}
            >
              {isSubmitting ? <Loader /> : "Upload"}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

