import { useQuery, useQueryClient } from "@tanstack/react-query";
import { S3ObjectItem } from "../models/trading";
import { ProjectRouteName } from "../models/analystRecommendation";
import {
  fetchApiDelete,
  fetchApiGet,
  fetchApiPost,
  fetchApiPut,
} from "./utils";

const ENTITY_KEY = "trading";

export function useCreateZohoSegment(projectName: ProjectRouteName) {
  const queryClient = useQueryClient();
  const queryParams = new URLSearchParams();

  return async () => {
    const result = await fetchApiPost<{ id: string }>(
      `developer/communications/zoho/${projectName}/segment`,
      {},
      queryParams,
    );
    return result;
  };
}

export function useManualCHS() {
  const queryClient = useQueryClient();
  const queryParams = new URLSearchParams();

  // TODO convert to post
  return async () => {
    const result = await fetchApiGet<{}>(`/tickets/manual/chs`, queryParams);
    return result;
  };
}

export function useRefreshZohoMailingList(projectName: ProjectRouteName) {
  const queryClient = useQueryClient();
  const queryParams = new URLSearchParams();

  return async () => {
    const result = await fetchApiPost<{ id: string }>(
      `developer/communications/zoho/${projectName}/refreshMailingList`,
      {},
      queryParams,
    );
    return result;
  };
}

export function useForceSendTodaysAnalysisComms() {
  const queryClient = useQueryClient();
  const queryParams = new URLSearchParams();

  return async () => {
    const result = await fetchApiPost<{ id: string }>(
      "developer/communications/zoho/sendTodaysAnalysisComms",
      {},
      queryParams,
    );
    return result;
  };
}

export function useUpdateTicketProviderDynamicMappings() {
  const queryClient = useQueryClient();
  const queryParams = new URLSearchParams();

  return async () => {
    const result = await fetchApiPost<{ id: string }>(
      "developer/ticket/providers/dynamicMappings/create",
      {},
      queryParams,
    );
    return result;
  };
}

export function useRunDataMigrationUpdateCropYears() {
  const queryClient = useQueryClient();
  const queryParams = new URLSearchParams();

  return async () => {
    const result = await fetchApiPost<{}>(
      "data-migrations/hedgebeacon/updateCropYear",
      {},
      queryParams,
    );
    return result;
  };
}

export function useAtsReplayEvents() {
  const queryClient = useQueryClient();
  const queryParams = new URLSearchParams();

  return async () => {
    const result = await fetchApiPost<{}>(
      "developer/ats/events/replay",
      {},
      queryParams,
    );
    return result;
  };
}
