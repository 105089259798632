import Decimal from "decimal.js";

export function formatNumber(item: string | number | Decimal) {
    try {
        const amount = new Decimal(item);
        if (amount.isNaN()) return "0";
        return amount.toNumber()?.toLocaleString('en-US') || "0";
    } catch(error) {
        return "0";
    }
}

export function formatDecimalAsPrice() {}
