export type KnownStatusCodes = 200 | 400 | 429 | 500 | 401;

export class ResponseResult<T> {
  statusCode: KnownStatusCodes;
  success: boolean;
  message: string;
  data: T;

  constructor(
    statusCode: KnownStatusCodes,
    success: boolean,
    message: string,
    data: T,
  ) {
    this.statusCode = statusCode;
    this.success = success;
    this.message = message;
    this.data = data;
  }
}

export type PossibleReturnType<T> =
  | SuccessResult<T>
  | ValidationError<any>
  | NotAuthorizedError<any>
  | ServerError<any>
  | ThrottledError<any>;

export class NotAuthorizedError<T> extends ResponseResult<T> {
  constructor(message: string, data: T) {
    super(401, false, message, data);
  }
}

export class ValidationError<T> extends ResponseResult<T> {
  constructor(message: string, data: T) {
    super(400, false, message, data);
  }
}

export class ThrottledError<T> extends ResponseResult<T> {
  constructor(message: string) {
    super(429, false, message, {} as any);
  }
}

export class ServerError<T> extends ResponseResult<T> {
  constructor(message: string, data: T) {
    super(500, false, message, data);
  }
}

export class SuccessResult<T> extends ResponseResult<T> {
  constructor(message: string, data: T) {
    super(200, true, message, data);
  }
}

export function isRetryable(response: any): boolean {
  if (!response) return false;
  return (
    response instanceof ServerError ||
    response instanceof SuccessResult ||
    false
  );
}
