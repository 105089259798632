import { Row } from "./frieghtReportUtils";

export const SALES_LENGTH = 9;
export const PURCHASE_LENGTH = 11;

export function createArrayOfSize(size: number): string[] {
  return new Array(size).fill("", 0, size);
}

export function createEmptyRow() {
  return createArrayOfSize(21);
}

export function createHeaderRows() {
  const titleRow = [
    "Sales",
    ...createArrayOfSize(SALES_LENGTH - 1),
    "",
    "Purchase",
    ...createArrayOfSize(PURCHASE_LENGTH - 1),
  ];
  const headersRow = [
    "Contract #",
    "Destination",
    "Start Date",
    "End Date",
    "UOM",
    "Quantity",
    "Balance",
    "Freight Terms",
    "Notes",
    "",
    "Contract #",
    "Name",
    "Destination",
    "Start Date",
    "End Date",
    "UOM",
    "Quantity",
    "Balance",
    "Freight Terms",
    "Release",
    "Notes",
  ];

  return [titleRow, headersRow];
}

export function createOutputLine(sale: Row | null, purcahse: Row | null) {
  const salesPart =
    sale != null
      ? [
          sale["Contract No"],
          sale["Name"],
          sale["Start Date"],
          sale["End Date"],
          sale["UOM"],
          sale["Quantity"],
          sale["Balance"],
          "",
          "",
        ]
      : createArrayOfSize(SALES_LENGTH);

  const purcahsePart =
    purcahse != null
      ? [
          purcahse["Contract No"],
          purcahse["Name"],
          purcahse["Market Zone"],
          purcahse["Start Date"],
          purcahse["End Date"],
          purcahse["UOM"],
          purcahse["Quantity"],
          purcahse["Balance"],
          "",
          purcahse["Rel"],
          "",
        ]
      : createArrayOfSize(PURCHASE_LENGTH);

  return [...salesPart, ...purcahsePart];
}

export function createSalesOutputLine(sale: Row | null) {
  const salesPart =
    sale != null
      ? [
          sale["Contract No"],
          sale["Name"],
          sale["Start Date"],
          sale["End Date"],
          sale["UOM"],
          sale["Quantity"],
          sale["Balance"],
          sale["Freight Terms"],
          "",
        ]
      : createArrayOfSize(SALES_LENGTH);

  return salesPart;
}

export function createPurchaseOutputLine(purcahse: Row | null) {
  const purcahsePart =
    purcahse != null
      ? [
          purcahse["Contract No"],
          purcahse["Name"],
          purcahse["Market Zone"],
          purcahse["Start Date"],
          purcahse["End Date"],
          purcahse["UOM"],
          purcahse["Quantity"],
          purcahse["Balance"],
          purcahse["Freight Terms"],
          purcahse["Rel"],
          "",
        ]
      : createArrayOfSize(PURCHASE_LENGTH);

  return purcahsePart;
}
