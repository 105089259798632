import { create } from "zustand";
import { z } from "zod";
import hashObject from "object-hash";
import { useState, useEffect } from "react";
import type { SetStateAction, Dispatch, ChangeEvent } from "react";
import Decimal from "decimal.js";
import { useStytchMember } from "@stytch/react/b2b";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import {
  Dot as DotIcon,
  RotateCcwIcon,
  DownloadCloud,
  Trash2,
  Plus,
  MoreHorizontal,
  Link as LinkIcon,
  Archive as ArchiveXIcon,
  ListFilter as ListFilterIcon,
  Eye as EyeIcon,
  SquarePen as SquarePenIcon,
  CircleHelp as CircleHelpIcon,
} from "lucide-react";
import moment from "moment-timezone";
import { Link } from "react-router-dom";
import { CommonDestinationTimezone } from "../../lib/utils";
import {
  ReOwnWDUValidation,
  MinimumPriceValidation,
  MinMaxDoubleUpValidation,
  useDownloadMarginReportData,
  useArchivePosition,
  UpdatePositionValidation,
  useEditManualPosition,
  formulateOptionMonths,
  OptionMonthOptions,
  generateAccountLabel,
  generateCurrentAndFutureMonthOptions,
  DownloadRecentContractFilterValidation,
  DownloadFilterValidation,
  useDownloadOpenData,
  useCreatePosition,
  PutOrCallOptions,
  CreatePositionValidation,
  ContractListOptionParams,
  useListOpenPositions,
  useDownloadOpenPositions,
  useDownloadOpenContracts,
  useListAccounts,
  TradingAccountOpenPosition,
  TradingAccount,
  TradingContract,
  ContractType,
  ContractTypeOptions,
  CommodityOptions,
  useListOpenContracts,
  useListOpenContractFilters,
  useCreateContract,
  useUpdateContract,
  useArchiveContract,
  useUnPairContract,
  usePairContract,
  UpdateContractValidation,
  CreateContractValidation,
  AccumulatorValidation,
  MinPriceValidation,
  PremiumValidation,
  MinMaxValidation,
  RangeValidation,
  createContractsExcelBuffer,
  contractNumberKeys,
  posNumberKeys,
  findMatchOption,
  PositionContractPair,
  useListRecentArchivedPositions,
  TradingAccountOpenPositionAudit,
  useMarkContractManualFullyPaired,
  useMarkPositionManualFullyPaired,
  useDownloadOpenRecentContractData,
  useDownloadSummaryData,
} from "../../lib/api/otc";
import { useListTraders } from "../../lib/api/trader";
import { Trader } from "../../lib/api/reports";
import {
    formatDateWithoutTimezone,
    formatDateWithoutTimezoneToDate
} from "../../lib/api/date";
import { useListCustomers } from "../../lib/api/customer";
import { Customer } from "../../lib/api/reports";
import {
  TicketRunSummary,
  TicketsSummary,
  ProviderTicketCount,
  Provider,
} from "../../lib/models/index";
import { ProviderType } from "../../lib/models/provider";
import {
  TicketProviderSummary,
  TicketProviderRecentRunPartial,
  TicketProviderBatchDownloadPartial,
} from "../../lib/models/tickets";
import { Button } from "../../components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../components/ui/dropdown-menu";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../components/ui/tabs";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/ui/table";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../components/ui/accordion";
import { Badge } from "../../components/ui/badge";
import { Checkbox } from "../../components/ui/checkbox";
import { MultiSelect } from "../../components/ui/selectMulti";
import { SelectInput } from "../../components/ui/SelectInput";
import { Input } from "../../components/ui/input";
import { Skeleton } from "../../components/ui/skeleton";
import { useToast } from "../../components/ui/use-toast";
import {
  DataTable,
  DataTableProps,
} from "../../components/ui/virtualizedTable";
import { downloadCsvTextAsFile } from "../../lib/browser/download";
import { Loader } from "../../components/ui/loader";
import { DatePicker } from "../../components/ui/typeable_date_picker";
import { handleFocus } from "../../lib/utils";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogClose,
  DialogFooter,
} from "../../components/ui/dialog";
import { Label } from "../../components/ui/label";
import { ValidationError, PossibleReturnType } from "../../lib/responseResults";
import { Combobox } from "../../components/ui/combobox";
import { cn, createDateLabel } from "../../lib/utils";
import { downloadBinaryData } from "../../lib/browser/download";
import { SelectOptionItem } from "../../lib/models/ui";
import { PossiblePackage, doesUserHavePackages } from "../../lib/models/auth";
import useDebounce from "./useDebounce";
import { formatNumber } from "./common";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "../../components/ui/hover-card"

const baseOpenPositionColumns: any[] = [
  {
    id: "account",
    header: "Account",
    cell: ({ row }: any) => (
      <span className="">{generateAccountLabel(row.original.TradingAccount.account_id) || row.original.TradingAccount.account_id}</span>
    ),
    enableSorting: true,
    enableHiding: false,
    onCellClick: true,
  },
  {
      id: "customer_name",
      header: "Customer",
      cell: ({ row }: any) => row.original.customer?.long_name || "",
      onCellClick: true,
  },
  {
    accessorKey: "date",
    header: "Date",
    cell: ({ row }: any) =>
      moment
        .tz(row.original.date, CommonDestinationTimezone)
        .format("MM/DD/YYYY"),
    enableSorting: false,
    enableHiding: false,
    onCellClick: true,
  },
  {
    accessorKey: "long",
    header: "Long",
    cell: ({ row }: any) => row.original.long_qty,
    enableSorting: false,
    enableHiding: false,
    onCellClick: true,
  },
  {
    accessorKey: "short",
    header: "Short",
    cell: ({ row }: any) => row.original.short_qty,
    enableSorting: false,
    enableHiding: false,
    onCellClick: true,
  },
  {
    accessorKey: "put_call",
    header: "Put/Call",
    cell: ({ row }: any) => row.original.put_or_call,
    enableSorting: false,
    enableHiding: false,
    onCellClick: true,
  },
  {
    id: "commodity",
    header: "Commodity",
    cell: ({ row }: any) => row.original.commodity,
    onCellClick: true,
  },
  {
    id: "optionMonth",
    header: "Reference Month",
    cell: ({ row }: any) => row.original.option_month || "",
    onCellClick: true,
  },
  {
    id: "strike_price",
    header: "Strike Price",
    cell: ({ row }: any) => row.original.strike_price,
    onCellClick: true,
  },
  {
    id: "price",
    header: "Price",
    cell: ({ row }: any) => row.original.price,
    onCellClick: true,
  },
  {
    id: "premium",
    header: "Option Premium",
    cell: ({ row }: any) => row.original.premium || 0,
    onCellClick: true,
  },
  {
    id: "bushels",
    header: "Bushels",
    cell: ({ row }: any) => formatNumber(row.original.bushel),
    onCellClick: true,
  },
];

const posPairOpenPositionColumns: any[] = [
    {
        id: "pair",
        header: "Pair",
        cell: ({ row }: any) => (
            <Button className="" variant="outline">
                Pair
            </Button>
        ),
        enableSorting: true,
        enableHiding: false,
        onCellClick: true,
    },
    ...baseOpenPositionColumns,
];

const posUnpairOpenPositionColumns: any[] = [
  {
    id: "unpair",
    header: "Pair",
    cell: ({ row }: any) => (
      <Button className="w-24" variant="outline">
        Un-Pair
      </Button>
    ),
    enableSorting: true,
    enableHiding: false,
    onCellClick: true,
  },
  ...baseOpenPositionColumns,
];

const nonActionContractColumns: any[] = [
  {
    accessorKey: "date",
    header: "Date",
    cell: ({ row }: any) => row.original.date_label,
    onCellClick: true,
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "account_no_a",
    header: "Brokerage OTC",
    cell: ({ row }: any) => row.original.options_account.no || "",
    onCellClick: true,
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "account_no_b",
    header: "Brokerage HTA",
    cell: ({ row }: any) => row.original.futures_account?.no || "",
    onCellClick: true,
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "contract_no",
    header: "Contract #",
    cell: ({ row }: any) => row.original.contract_no,
    onCellClick: true,
    enableSorting: false,
    enableHiding: false,
  },
  {
    id: "customer_id",
    header: "Customer",
    cell: ({ row }: any) => row.original.customer?.long_name || "", // customers.find(customer => customer.id === row.original.customer_id)?.long_name ||
    onCellClick: true,
  },
  {
    id: "trader_id",
    header: "Trader",
    cell: ({ row }: any) => row.original.Trader?.name || "",
    onCellClick: true,
  },
  {
    id: "contract_type",
    header: "Contract Type",
    cell: ({ row }: any) => (
      <span className="">{row.original.contract.type}</span>
    ),
    onCellClick: true,
    enableSorting: true,
    enableHiding: false,
  },
  {
    id: "quantity",
    header: "Quantity",
    cell: ({ row }: any) => formatNumber(row.original.quantity),
    onCellClick: true,
  },
  {
    id: "commodity",
    header: "Commodity",
    cell: ({ row }: any) => row.original.commodity_label,
    onCellClick: true,
  },
  {
    id: "crop_year",
    header: "Crop Year",
    cell: ({ row }: any) => row.original.crop_year,
    onCellClick: true,
  },
  {
    id: "option_month",
    header: "OTC Opt. Month",
    cell: ({ row }: any) => row.original.option_month,
    onCellClick: true,
  },
  {
    id: "option_month_etc_one",
    header: "HTA DU Opt. Month",
    cell: ({ row }: any) => row.original.option_month_etc_one || "",
    onCellClick: true,
  },
  {
    accessorKey: "notes",
    header: "Notes",
    cell: ({ row }: any) => row.original.notes || "",
    onCellClick: true,
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "start_date",
    header: "Start Date",
    cell: ({ row }: any) => row.original.start_date_label,
    onCellClick: true,
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "end_date",
    header: "Expiration Date",
    cell: ({ row }: any) => row.original.end_date_label,
    onCellClick: true,
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "shipment_start_date",
    header: "Shipment Start Date",
    cell: ({ row }: any) => row.original.shipment_start_date_label,
    onCellClick: true,
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "shipment_end_date",
    header: "Shipment End Date",
    cell: ({ row }: any) => row.original.shipment_end_date_label,
    onCellClick: true,
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "weeks",
    header: "Weeks",
    cell: ({ row }: any) => row.original.weeks,
    onCellClick: true,
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "period_qty",
    header: "Period Qty",
    cell: ({ row }: any) => row.original.period_qty,
    onCellClick: true,
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "knockout",
    header: "Knockout",
    cell: ({ row }: any) => row.original.knockout,
    onCellClick: true,
    enableSorting: false,
    enableHiding: false,
  },
  {
    id: "step_one",
    header: "Step 1",
    cell: ({ row }: any) => row.original.step_one,
    onCellClick: true,
  },
  {
    id: "step_two",
    header: "Step 2",
    cell: ({ row }: any) => row.original.step_two,
    onCellClick: true,
  },
  {
    id: "step_three",
    header: "Step 3",
    cell: ({ row }: any) => row.original.step_three,
    onCellClick: true,
  },
  {
    id: "option_premium",
    header: "Option Premium",
    cell: ({ row }: any) => row.original.option_premium,
    onCellClick: true,
  },
  {
    id: "contract_fee",
    header: "Initial Premium + Fee",
    cell: ({ row }: any) => row.original.contract_fee,
    onCellClick: true,
  },
  {
    id: "margin",
    header: "Lighthouse Fee",
    cell: ({ row }: any) => formatNumber(row.original.margin),
    onCellClick: true,
  },
  {
    id: "margin_amount",
    header: "Margin Amount",
    cell: ({ row }: any) => formatNumber(row.original.margin_amount),
    onCellClick: true,
  },
  {
    accessorKey: "trade_reference",
    header: "Trade Reference",
    cell: ({ row }: any) => row.original.trade_reference,
    onCellClick: true,
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "futures_price",
    header: "Futures Price",
      cell: ({ row }: any) => formatNumber(row.original.futures_price),
    onCellClick: true,
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "otc_futures",
    header: "OTC Futures",
      cell: ({ row }: any) => formatNumber(row.original.otc_futures),
    onCellClick: true,
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "rolled_option_month",
    header: "Futures Rolled Month",
    cell: ({ row }: any) => row.original.rolled_option_month,
    onCellClick: true,
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "rolled_basis_month",
    header: "Basis Rolled Month",
    cell: ({ row }: any) => row.original.rolled_basis_month,
    onCellClick: true,
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "carry",
    header: "Futures Carry",
    cell: ({ row }: any) => ignoreSafeParseDecimal(row.original.carry)?.toFixed(2),
    onCellClick: true,
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "basis_carry",
    header: "Basis Carry",
    cell: ({ row }: any) => ignoreSafeParseDecimal(row.original.basis_carry)?.toFixed(2),
    onCellClick: true,
    enableSorting: false,
    enableHiding: false,
  },
];

const posPairOpenContractColumns: any[] = [
    {
        id: "pair",
        header: "Pair",
        cell: ({ row }: any) => (
            <Button className="" variant="outline">
                Pair
            </Button>
        ),
        enableSorting: true,
        enableHiding: false,
        onCellClick: true,
    },
    ...nonActionContractColumns,
];

const posUnpairOpenContractColumns: any[] = [
  {
    id: "unpair",
    header: "Pair",
    cell: ({ row }: any) => (
      <Button className="w-24" variant="outline">
        Un-Pair
      </Button>
    ),
    enableSorting: true,
    enableHiding: false,
    onCellClick: true,
  },
  ...nonActionContractColumns,
];

interface NewContractInfo {
  contract_type: string;
  contract_no: string;

  date: Date;
  start_date?: Date;
  end_date?: Date;

  shipment_start_date?: Date;
  shipment_end_date?: Date;

  account_no_a: string;
  account_no_b?: string;

  weeks: string;
  period_qty: string;
  knockout: string;

  trade_reference: string;
  customer_id: string;
  trader_id: string;

  quantity: number;
  commodity: string;

  option_month: string;
  step_one: string;
  step_two: string;
  step_three: string;

  option_premium: string;
  contract_fee: string;
  margin: string;
  margin_amount: string;

  crop_year: number;
}

interface PositionListOptionParams {
  filters: {
    account_ids?: string[];
    strike_price?: string;
    commodity?: string;
    option_month?: string;
  };
}

interface UpdateContractInfo extends Partial<NewContractInfo> {
  id: string;
}

interface PairingData {
    position?: TradingAccountOpenPosition;
  contract?: TradingContract;
}

interface MarginReportOptionParams {
  after_date?: Date;
  before_date?: Date;
}

interface BearState {
  margin_report_filters?: MarginReportOptionParams;
  position_filters: PositionListOptionParams;
  editing_position_filters: PositionListOptionParams;
  contract_filters: ContractListOptionParams;
  editing_contract_filters: ContractListOptionParams;
  new_contract?: NewContractInfo;
  editing_contract?: UpdateContractInfo;
  hasAttemptedSave: boolean;
  contracts_filter_modal_status: boolean;
  positions_filter_modal_status: boolean;
  summaryFilters: undefined | {};
  open_contracts?: TradingContract[];
  open_positions?: TradingAccountOpenPosition[];
    downloadFilters?: typeof DownloadFilterValidation._type,
    downloadRecentContractFilters?: typeof DownloadRecentContractFilterValidation._type,
  edit_manual_position_modal_id?: string;

  setOpenContractModalDownloadStatus: (item: undefined | {}) => void;
  openContractModalDownloadStatus: undefined | {};

  hasDownloadsAttemptedSave: boolean,

  pairing_data: PairingData;
  contracts_position_modal_contract_id?: string;
  set_contracts_positions_modal_id: (id?: string | undefined) => void;

  new_position?: typeof CreatePositionValidation._type;
  edit_manual_position?: typeof UpdatePositionValidation._type
  hasPositionAttemptedSave: boolean;

  set_edit_manual_position_modal_id: (id?: string) => void;
  set_new_position: (new_position: {} | undefined) => void;
  set_edit_manual_position: (edit_position: {} | undefined) => void;
  set_margin_report_modal: (filters: {} | undefined) => void;

  update_margin_report_field: (
    key: string,
    value?: string | number | Date | undefined | null,
  ) => void;
  update_edit_position_field: (
    key: string,
    value?: string | number | Date | undefined | null,
  ) => void;
  updatePositionAttemptedSave: (status: boolean) => void;
    update_new_position_field: (
        key: string,
        value?: string | number | Date | undefined | null,
    ) => void;

  editDownloadRecentFilterKey: (
    key: string,
    value?: string | number | Date | undefined | null,
  ) => void;
  editDownloadFilterKey: (
    key: string,
    value?: string | number | Date | undefined | null,
  ) => void;

  setSummaryFilters: (new_filters: {} | undefined) => void;
  setDownloadFilters: (new_filters: {} | undefined) => void;
  setDownloadRecentContractsFilters: (new_filters: { created_date: Date } | undefined) => void;

    set_open_contracts: (data: TradingContract[]) => void;
    set_open_positions: (data: TradingAccountOpenPosition[]) => void;
  update_contract_filters_field: (
    key: string,
    value?: string | string[] | number | Date | undefined,
  ) => void;
  update_position_filters_field: (
    key: string,
    value?: string | string[] | number | Date | undefined,
  ) => void;
  set_position_filters: (key: string, value: string | undefined) => void;
  set_new_contract: (new_contract: {} | undefined) => void;
  set_edit_contract: (new_contract: {} | undefined) => void;
  set_pair_position: (pair_position: PairingData) => void;
  update_new_contract_field: (
    key: string,
    value?: string | number | Date | undefined | null,
  ) => void;
  update_edit_contract_field: (
    key: string,
    value?: string | number | Date | undefined | null,
  ) => void;
  updateAttemptedSave: (status: boolean) => void;
  set_contract_filter_modal_status: (status: boolean) => void;
  set_position_filter_modal_status: (status: boolean) => void;
  persistEditingContractFilters: () => void;
  persistPositionFilters: () => void;
    clear_filters: () => void;
}

const useBearStore = create<BearState>((set) => ({
  open_positions: undefined,
  open_contracts: undefined,
  position_filters: { filters: {} },
  editing_position_filters: { filters: {} },
  contract_filters: { filters: {} },
  editing_contract_filters: { filters: {} },
  new_contract: undefined,
  editing_contract: undefined,
  pairing_data: {},
  hasAttemptedSave: false,
  contracts_filter_modal_status: false,
  positions_filter_modal_status: false,
  contracts_position_modal_contract_id: undefined,
  downloadFilters: undefined,
  hasDownloadsAttemptedSave: false,
  downloadRecentContractFilters: undefined,
  summaryFilters: undefined,
  edit_manual_position_modal_id: undefined,
  margin_report_filters: undefined,

  openContractModalDownloadStatus: undefined,

  edit_manual_position: undefined,
  new_position: undefined,
  hasPositionAttemptedSave: false,

  editDownloadRecentFilterKey: (
    key: string,
    value?: string | number | Date | undefined | null,
  ) => { return set((state) => {
    if (!state.downloadRecentContractFilters) {
        return { downloadRecentContractFilters: undefined };
      }

      return {
        downloadRecentContractFilters: {
          ...state.downloadRecentContractFilters,
          [key]: typeof value === "string" ? value?.trim() : value,
        },
      };
    });
  },
    editDownloadFilterKey: (
        key: string,
        value?: string | number | Date | undefined | null,
    ) => { return set((state) => {
      if (!state.downloadFilters) {
        return { downloadFilters: undefined };
      }

      return {
        downloadFilters: {
          ...state.downloadFilters,
          [key]: typeof value === "string" ? value?.trim() : value,
        },
      };
    });
  },


    set_margin_report_modal: (new_filters: {} | undefined) => set((state) => ({
        margin_report_filters: new_filters as any,
    })),
    set_edit_manual_position_modal_id: (id: string | undefined) => set((state) => ({
        edit_manual_position_modal_id: id as any,
    })),
    setOpenContractModalDownloadStatus: (new_filters: {} | undefined) => set((state) => ({
        openContractModalDownloadStatus: new_filters as any,
    })),
    setSummaryFilters: (new_filters: {} | undefined) => set((state) => ({
        summaryFilters: new_filters as any,
    })),

    setDownloadFilters: (new_filters: {} | undefined) => set((state) => ({
        downloadFilters: new_filters as any,
        hasDownloadsAttemptedSave: !!new_filters ? state.hasDownloadsAttemptedSave : false,
    })),
    setDownloadRecentContractsFilters: (new_filters?: { created_date: Date } | undefined) => set((state) => ({
        downloadRecentContractFilters: new_filters as any,
    })),
    set_new_position: (new_position: {} | undefined) => set((state) => ({
        new_position: new_position as any,
        hasPositionAttemptedSave: !!new_position ? state.hasPositionAttemptedSave : false,
    })),

    set_edit_manual_position: (edit_position: {} | undefined) => set((state) => ({
        edit_manual_position: edit_position as any,
    })),
    updatePositionAttemptedSave: (status: boolean) => set((state) => ({
      hasPositionAttemptedSave: status,
    })),
  update_margin_report_field: (
    key: string,
    value?: string | number | Date | undefined | null,
  ) => { return set((state) => {
    if (!state.margin_report_filters) {
        return { margin_report_filters: undefined };
      }

      return {
        margin_report_filters: {
          ...state.margin_report_filters,
          [key]: typeof value === "string" && key !== "contract" ? value?.trim() : value,
        },
      };
    });
  },
  update_edit_position_field: (
    key: string,
    value?: string | number | Date | undefined | null,
  ) => { return set((state) => {
    if (!state.edit_manual_position) {
        return { edit_manual_position: undefined };
      }

      return {
        edit_manual_position: {
          ...state.edit_manual_position,
          [key]: typeof value === "string" && key !== "contract" ? value?.trim() : value,
        },
      };
    });
  },
  update_new_position_field: (
    key: string,
    value?: string | number | Date | undefined | null,
  ) => { return set((state) => {
    if (!state.new_position) {
        return { new_position: undefined };
      }

      return {
        new_position: {
          ...state.new_position,
          [key]: typeof value === "string" && key !== "contract" ? value?.trim() : value,
        },
      };
    });
  },

    set_contracts_positions_modal_id: (id?: string | undefined) =>
    set((state) => ({
      contracts_position_modal_contract_id: id,
    })),

  set_open_contracts: (open_contracts: TradingContract[]) =>
    set((state) => ({
      open_contracts,
    })),
  set_open_positions: (open_positions: TradingAccountOpenPosition[]) =>
    set((state) => ({
      open_positions,
    })),
  set_position_filters: (key: string, value: string | undefined | null) =>
    set((state) => ({
      [key]: value,
    })),
  persistPositionFilters: () =>
    set((state) => ({
      positions_filter_modal_status: false,
      position_filters: {
        filters: { ...state.editing_position_filters.filters },
      },
    })),
  persistEditingContractFilters: () =>
    set((state) => ({
      contracts_filter_modal_status: false,
      contract_filters: {
        filters: { ...state.editing_contract_filters.filters },
      },
    })),
    clear_filters: () => set((state) => ({
        contract_filters: { filters: {} },
        position_filters: { filters: {} },
        editing_contract_filters: { filters: {} },
        editing_position_filters: { filters: {} },
    })),
    update_contract_filters_field: (
        key: string,
        value?: string | string[] | number | Date | undefined,
    ) =>
        set((state) => ({
            editing_contract_filters: {
        filters: {
          ...state.editing_contract_filters.filters,
          [key]: value,
        },
      },
    })),
  update_position_filters_field: (
    key: string,
    value?: string | string[] | number | Date | undefined,
  ) =>
    set((state) => ({
      editing_position_filters: {
        filters: {
          ...state.editing_position_filters.filters,
          [key]: value,
        },
      },
    })),
  set_contract_filter_modal_status: (status: boolean) =>
    set((state) => ({
      contracts_filter_modal_status: status,
    })),
  set_position_filter_modal_status: (status: boolean) =>
    set((state) => ({
      positions_filter_modal_status: status,
    })),
  updateAttemptedSave: (hasAttemptedSave: boolean) =>
    set((state) => ({
      hasAttemptedSave,
    })),
  set_new_contract: (new_contract: {} | undefined) =>
    set((state) => ({
      new_contract: new_contract as any,
      hasAttemptedSave: !!new_contract ? state.hasAttemptedSave : false,
    })),
  set_edit_contract: (editing_contract: {} | undefined) =>
    set((state) => ({
      editing_contract: editing_contract as any,
      hasAttemptedSave: !!editing_contract ? state.hasAttemptedSave : false,
    })),
  set_pair_position: (data: PairingData) =>
    set((state) => ({
      pairing_data: data,
    })),
  update_edit_contract_field: (
    key: string,
    value?: string | number | Date | undefined | null,
  ) => {
    return set((state) => {
      if (!state.editing_contract) {
        return { editing_contract: undefined };
      }

      return {
        editing_contract: {
          ...state.editing_contract,
          ...clearFieldsOnContractTypeChange(
            (key === "contract_type"
              ? value
              : state.editing_contract?.contract_type) as any,
          ),
          [key]: key === 'notes' ? value : typeof value === "string" ? value?.trim() : value,
        },
      };
    });
  },
  update_new_contract_field: (
    key: string,
    value?: string | number | Date | undefined | null,
  ) => {
    return set((state) => {
      if (!state.new_contract) {
        return { new_contract: undefined };
      }

      return {
        new_contract: {
          ...state.new_contract,
          ...clearFieldsOnContractTypeChange(
            (key === "contract_type"
              ? value
              : state.new_contract?.contract_type) as any,
          ),
          [key]: key === 'notes' ? value : typeof value === "string" ? value?.trim() : value,
        },
      };
    });
  },
}));

function should_refresh_data({
  current_state,
  new_state,
}: {
  current_state?: any;
  new_state?: any[];
}) {
  if (!current_state && !!new_state) {
    return true;
  }
  if (!!current_state && !new_state) {
    return false;
  }

  if (!current_state && !new_state) {
    return false;
  }

  return current_state! !== hashObject(new_state!);
}

export function OtcDashboard() {
  const position_filters = useBearStore(
    (state) => state.position_filters.filters,
  );
  const contract_filters = useBearStore(
    (state) => state.contract_filters.filters,
  );
  const listTradersRes = useListTraders();
  const openPositionsRes = useListOpenPositions({ filters: position_filters, listOtcAccountsOnly: true });
  const openContractsRes = useListOpenContracts({ filters: contract_filters });
  const accountsRes = useListAccounts(true);
  const customersResult = useListCustomers();
  const recentArchivedPositionsRes = useListRecentArchivedPositions();

    const isMissingData = (!openPositionsRes?.data?.data) || (!openContractsRes?.data?.data);

    if (
        [listTradersRes, recentArchivedPositionsRes, customersResult, accountsRes].some((result) => result.isLoading) ||isMissingData
    ) {
        return (
            <div className="flex flex-col space-y-2">
                <Skeleton className="h-4 w-[250px]" />
                <Skeleton className="h-4 w-[200px]" />
            </div>
        );
  }

  if (
      [listTradersRes, recentArchivedPositionsRes, customersResult, accountsRes].some(
          (result) => result.error || !result?.data?.success,
      )
  ) {
    return (
      <div className="flex flex-col space-y-2">
        Failed to communicate with servers. Please refresh and try again
      </div>
    );
  }

  const openPositionsData = openPositionsRes?.data?.data || {};
  const openContractsData = openContractsRes?.data?.data || {};

  return (
      <DashboardView
          traders={listTradersRes?.data?.data || []}
          customers={customersResult?.data?.data || []}
          recentArchivedPositions={recentArchivedPositionsRes?.data?.data || []}
          openContractsPaired={openContractsData.pairedContracts || []}
          openContractsUnpaired={openContractsData.unpairedContracts || []}
          openPositionsPaired={openPositionsData.pairedPositions || []}
          openPositionsUnpaired={openPositionsData.unpairedPositions || []}
          refetchPositions={openPositionsRes.fetchData}
          refetchContracts={openContractsRes.fetchData}
          pairs={openPositionsData.pairs || []}
          accounts={accountsRes?.data?.data || []}
      />
  );
}

export function DashboardView({
    recentArchivedPositions,
    openContractsPaired,
    openContractsUnpaired,
    openPositionsPaired,
    openPositionsUnpaired,
    pairs,
    accounts,
    customers,
  traders,
    refetchPositions,
    refetchContracts,
}: {
    recentArchivedPositions: TradingAccountOpenPositionAudit[];
    openContractsPaired: TradingContract[];
    openContractsUnpaired: TradingContract[];
  openPositionsPaired: TradingAccountOpenPosition[];
  openPositionsUnpaired: TradingAccountOpenPosition[];
  pairs: PositionContractPair[];
  accounts: TradingAccount[];
  customers: Customer[];
  traders: Trader[];
    refetchPositions: any;
    refetchContracts: any;
}) {

  return (
      <>
          <div className="flex flex-col mb-2">
              <h1 className="scroll-m-20 text-2xl font-extrabold tracking-tight lg:text-3xl">
                  OTC Dashboard
              </h1>
          </div>
          <div className="flex items-start flex-col h-full">
              <Tabs defaultValue="open_positions" className="w-full">
                  <TabsList>
                      <TabsTrigger value="open_positions">Open Positions</TabsTrigger>
                      <TabsTrigger value="open_contracts">Open Contracts</TabsTrigger>
                      <TabsTrigger value="recent_archived_positions">Recently Closed Positions</TabsTrigger>
                  </TabsList>
                  <TabsContent value="open_positions">
                      <OpenPositionsSection
                        traders={traders}
                          accounts={accounts}
                          customers={customers}
                          openContractsUnpaired={openContractsUnpaired}
                          openContractsPaired={openContractsPaired}
                          openPositionsPaired={openPositionsPaired}
                          openPositionsUnpaired={openPositionsUnpaired}
                          refetchPositions={refetchPositions}
                          refetchContracts={refetchContracts}
                          pairs={pairs}
                      />
                  </TabsContent>
                  <TabsContent value="open_contracts">
                      <OpenContractsSection
                        traders={traders}
                          accounts={accounts}
                          customers={customers}
                          openContractsUnpaired={openContractsUnpaired}
                          openContractsPaired={openContractsPaired}
                          refetchContracts={refetchContracts}
                          refetchPositions={refetchPositions}
                          openPositionsPaired={openPositionsPaired}
                          openPositionsUnpaired={openPositionsUnpaired}
                          pairs={pairs}
                      />
                  </TabsContent>
                  <TabsContent value="recent_archived_positions">
                      <RecentArchivedPositionsSection recentArchivedPositions={recentArchivedPositions}/>
                  </TabsContent>
              </Tabs>
          </div>
      </>
  );
}

function RecentArchivedPositionsSection({
    recentArchivedPositions,
}: {
    recentArchivedPositions: TradingAccountOpenPositionAudit[];
}) {
    return (
        <DataTable
          height="550px"
          columns={baseOpenPositionColumns}
          data={recentArchivedPositions}
          formId="RecentArchivedPos"
        />
    )
}

function OpenPositionsSection({
  openPositionsPaired,
  openPositionsUnpaired,
  openContractsPaired,
  openContractsUnpaired,
  pairs,
  traders,
  accounts,
  customers,
    refetchPositions,
    refetchContracts,
}: {
  openContractsPaired: TradingContract[];
  openContractsUnpaired: TradingContract[];
  openPositionsPaired: TradingAccountOpenPosition[];
  openPositionsUnpaired: TradingAccountOpenPosition[];
  pairs: PositionContractPair[];
  traders: Trader[];
  accounts: TradingAccount[];
  customers: Customer[];
    refetchPositions: any;
    refetchContracts: any;
}) {
  const set_pair_position = useBearStore((state) => state.set_pair_position);
  const pairing_data = useBearStore((state) => state.pairing_data);
  const set_edit_manual_position = useBearStore((state) => state.set_edit_manual_position);

    const pairedOpenPositionColumns: any[] = [
    {
        id: "mark_unpaired",
        header: "Manual Un pair",
        cell: ({ row }: any) => (
            row.original.is_manually_fully_paired ?
        <MarkPositionFullyPairedContractButton
            id={row.original.id}
            refetchPositions={refetchPositions}
            message="Marked Un-Paired"
            buttonLabel="Un-Pair"
            buttonClassName="w-28"
            markStatus={false}
        /> : null
        ),
        enableSorting: true,
        enableHiding: false,
    },
    {
        id: "contract_no",
        header: "Contract #",
        cell: ({ row }: any) => row.original.contract_no || "",
        onCellClick: true,
    },
    {
        id: "customer_name",
        header: "Customer",
        cell: ({ row }: any) => row.original.customer?.long_name || "",
        onCellClick: true,
    },

    ...baseOpenPositionColumns,
 {
        id: "edit_manual_posa_positions",
        header: "Edit Position",
        cell: ({ row }: any) => row.original.is_manually_inserted ? (
            <Button
          id="edit_manual_position_trigger_a"
          variant="ghost"
          type="button"
          className=""
          onClick={() => set_edit_manual_position(row.original)}
        >
          <SquarePenIcon />
        </Button>
        ) : null,
        enableSorting: true,
        enableHiding: false,
    },
    {
        id: "close_pos",
        header: "Close",
      cell: ({ row }: any) => row.original.is_manually_inserted ? (<ArchivePositionButton id={row.original.id} refetchPositions={refetchPositions} />): null,
        enableSorting: true,
        enableHiding: false,
    },
    ];

    const unpairedOpenPositionColumns: any[] = [
    {
        id: "mark_paired",
        header: "Manual Mark Paired",
        cell: ({ row }: any) => (
        <MarkPositionFullyPairedContractButton
            id={row.original.id}
            refetchPositions={refetchPositions}
            message="Marked Paired"
            buttonLabel="Mark Paired"
            markStatus={true}
        />
        ),
        enableSorting: true,
        enableHiding: false,
    },
    {
        id: "contract_no",
        header: "Contract #",
        cell: ({ row }: any) => row.original.contract_no || "",
        onCellClick: true,
    },
    ...baseOpenPositionColumns,
 {
        id: "edit_manual_posb_positions",
        header: "Edit Position",
        cell: ({ row }: any) => row.original.is_manually_inserted ? (
            <Button
          id="edit_manual_position_trigger_b"
          variant="ghost"
          type="button"
          className=""
          onClick={() => set_edit_manual_position(row.original)}
        >
          <SquarePenIcon />
        </Button>
        ) : null,
        enableSorting: true,
        enableHiding: false,
    },
    {
        id: "close_pos",
        header: "Close",
      cell: ({ row }: any) => row.original.is_manually_inserted ? (<ArchivePositionButton id={row.original.id} refetchPositions={refetchPositions} />): null,
        enableSorting: true,
        enableHiding: false,
    },
    ];

  return (
    <>
      <div className="flex flex-col w-full">
        <ManualPositionFormModal
          refetchPositions={refetchPositions}
          customers={customers}
          accounts={accounts}
        />
        <OpenPositions
          traders={traders}
      refetchPositions={refetchPositions}
          accounts={accounts}
          areEmptyPositions={
            !openPositionsPaired.length && !openPositionsUnpaired.length
          }
          customers={customers}
        />
        <PairFormModal
          openContracts={[...openContractsPaired, ...openContractsUnpaired]}
          customers={customers}
          accounts={accounts}
          pairingData={pairing_data}
          setPairData={set_pair_position}
          pairs={pairs}
            refetchPositions={refetchPositions}
            refetchContracts={refetchContracts}
        />
        <h2 className="text-lg font-bold tracking-tight">Un-Paired</h2>
        <DataTable
          height="450px"
          onCellClick={(row) => set_pair_position({ position: row as any })}
          columns={unpairedOpenPositionColumns}
          data={openPositionsUnpaired}
          formId="UnpairedPosition"
        />
        <h3 className="text-lg font-bold tracking-tight mt-4">Paired</h3>
        <DataTable
          height="250px"
          onCellClick={(row) => set_pair_position({ position: row as any })}
          columns={pairedOpenPositionColumns}
          data={openPositionsPaired}
          formId="PairedPosition"
        />
      </div>
    </>
  );
}

function OpenContractsSection({
    customers,
  traders,
    openContractsPaired,
    openContractsUnpaired,
    accounts,
    refetchContracts,
    refetchPositions,
  pairs,
    openPositionsPaired,
    openPositionsUnpaired,
}: {
    customers: Customer[];
  traders: Trader[];
  openPositionsPaired: TradingAccountOpenPosition[];
  openPositionsUnpaired: TradingAccountOpenPosition[];
  pairs: PositionContractPair[];
  openContractsPaired: TradingContract[];
  openContractsUnpaired: TradingContract[];
  accounts: TradingAccount[];
    refetchContracts: any;
    refetchPositions: any
}) {
    const { toast } = useToast();
  const set_contracts_positions_modal_id = useBearStore(state => state.set_contracts_positions_modal_id)
  const [isDownloading, setIsDownloading] = useState(false);
  const [downloadData, setDownloadData] = useState<string>("");

  const set_edit_contract = useBearStore((state) => state.set_edit_contract);
  const AccountTypeOptions = createAccountListOptions(undefined)(accounts);

    const unpairedOpenContractColumns: any[] = [
    {
        id: "close",
        header: "Close",
        cell: ({ row }: any) => <ArchiveContractButton id={row.original.id} refetchContracts={refetchContracts} />,
        enableSorting: true,
        enableHiding: false,
    },
    {
        id: "mark_paired",
        header: "Manual Mark Paired",
        cell: ({ row }: any) => <MarkContractFullyPairedContractButton show id={row.original.id} refetchContracts={refetchContracts} markStatus={true} />,
        enableSorting: true,
        enableHiding: false,
    },
    {
        id: "view_cona_positions",
        header: "View Positions",
        cell: ({ row }: any) => (
            <Button
              id="contracts_view_positions_trigger_b"
              variant="ghost"
              type="button"
              className=""
              onClick={() => set_contracts_positions_modal_id(row.original.id)}
            >
              <EyeIcon />
            </Button>
        ),
        enableSorting: true,
        enableHiding: false,
    },
        ...nonActionContractColumns,
    ];


    const pairedOpenContractColumns: any[] = [
        {
        id: "close",
        header: "Close",
        cell: ({ row }: any) => <ArchiveContractButton id={row.original.id} refetchContracts={refetchContracts} />,
        enableSorting: true,
        enableHiding: false,
    },
    {
        id: "mark_unpaired",
        header: "Manual Mark Un-Paired",
        cell: ({ row }: any) => <MarkContractFullyPairedContractButton show={row.original.is_manually_fully_paired || false} id={row.original.id} refetchContracts={refetchContracts} markStatus={false}/>,
        enableSorting: true,
        enableHiding: false,
    },
    {
        id: "view_conb_positions",
        header: "View Positions",
        cell: ({ row }: any) => (
            <Button
              id="contracts_view_positions_trigger_a"
              variant="ghost"
              type="button"
              className=""
              onClick={() => set_contracts_positions_modal_id(row.original.id)}
            >
              <EyeIcon />
            </Button>
        ),
        enableSorting: true,
        enableHiding: false,
    },
    ...nonActionContractColumns,
    ];

    const onDownload = async () => {
    if (isDownloading) return;
    setIsDownloading(true);

    const result = {
      success: true,
      data: await createContractsExcelBuffer({ contracts: openContracts }),
    };

    setIsDownloading(false);

    if (!result.success) {
      toast({
        title: "Failed to download",
        description: "",
      });
    } else {
      downloadBinaryData(
        result.data,
        `openContracts-${moment.tz(CommonDestinationTimezone).format("YYYYMMDD")}.xlsx`,
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      );
    }
  };

  const openContracts = [...openContractsPaired, ...openContractsUnpaired];

  return (
    <>
      <div className="flex flex-col w-full">
        <div className="flex flex-row justify-between w-full items-center my-2">
          <div className="flex flex-row justify-start w-full items-center">
            <DownloadContractPositionData />
            <DownloadRecentContractData accounts={accounts} customers={customers} />
            <DownloadSummaryReportData />
            <DownloadOpenContractReportData />
            <DownloadMarginReportReportModal />

            <ContractFormModal
              refetchContracts={refetchContracts}
              isNew={true}
              customers={customers}
              accounts={accounts}
            />
            <ContractFormModal
              refetchContracts={refetchContracts}
              isNew={false}
              customers={customers}
              accounts={accounts}
            />
            <ViewContractsOpenPositionsModal
              refetchContracts={refetchContracts} refetchPositions={refetchPositions}
              pairs={pairs}
              openPositionsPaired={openPositionsPaired}
              openPositionsUnpaired={openPositionsUnpaired}
            />
            <ContractFiltersModal customers={customers} accounts={accounts} traders={traders} />
          </div>

          <span className="flex flex-row justify-end items-center">
            {openContracts?.length > 0 && (
              <div className="h-6 px-4 align-middle text-sm text-muted-foreground flex flex-row justify-end items-center">
                <span>Total Margin: </span>
                <span> {generateTotalMargin(openContracts)}</span>
              </div>
            )}
          </span>
        </div>
        <h2 className="text-lg font-bold tracking-tight mt-4">Un-Paired</h2>
        <DataTable
          height="450px"
          onCellClick={(row) => set_edit_contract(row)}
          columns={unpairedOpenContractColumns}
          data={openContractsUnpaired?.map(
            mapToTableContract({ customers, AccountTypeOptions }),
          )}
          formId="UnPairedContracts"
        />

        <h3 className="text-lg font-bold tracking-tight mt-4">Paired</h3>
        <DataTable
          height="250px"
          onCellClick={(row) => set_edit_contract(row)}
          columns={pairedOpenContractColumns}
          data={openContractsPaired?.map(
            mapToTableContract({ customers, AccountTypeOptions }),
          )}
          formId="PairedContracts"
        />
      </div>
    </>
  );
}

function isPositionFormValid(formData: any) {
  const data = JSON.parse(JSON.stringify(transformToApiPosition(formData)));

    if (!CreatePositionValidation.safeParse(data).success) {
        return false;
  }

    if ((new Decimal(data.long_qty)).isZero() && (new Decimal(data.short_qty)).isZero()) {
        return false;
    }

  return true;
}

function isFormValid(isUpdateForm: boolean, formData: any) {
    const data = JSON.parse(JSON.stringify(transformToApiContract(formData)));
  const schema = getSchemaForType(isUpdateForm, data.contract_type);


  if (!isUpdateForm && !schema.safeParse(data).success) {
    return false;
  }

  if (isUpdateForm && !schema.safeParse(data).success) {
    return false;
  }

  return true;
}

async function onSubmitPositionForm(
    {
      isEdit,
        refetchPositions,
        onCloseModal,
        createPosition,
        isSaving,
        setIsSaving,
        updateAttemptedSave,
        toast,
    }: {
      isEdit: boolean;
        refetchPositions: any;
        toast: (data: { title: string; description?: string }) => void;
        createPosition: (formData: any) => PossibleReturnType<any>;
    onCloseModal: () => void;
    isSaving: boolean;
    setIsSaving: (status: boolean) => void;
    updateAttemptedSave: (status: boolean) => void;
  },
  formData: typeof CreatePositionValidation._type,
) {
  updateAttemptedSave(true);
  // NOTE: we convert it json so it convert to how the api expects it.
  // i.e. decimals become strings
  if (!isPositionFormValid(formData)) {
    return false;
  }

  if (isSaving) return false;

  setIsSaving(true);

  const data = JSON.parse(JSON.stringify(transformToApiPosition(formData)));
    const saveResult = await (createPosition(data as any));

  updateAttemptedSave(false);
  setIsSaving(false);

    if (!saveResult.success) {
        toast({
            title: isEdit ? "Failed to update position" : "Failed to create position",
            description: "",
        });
    } else {
        onCloseModal();
        refetchPositions();
        toast({
            title: isEdit ? "Updated" : "Created",
            description: "",
        });
    }

    return true;
}

async function onSubmitForm(
    {
        refetchContracts,
        onCloseModal,
        updateContract,
        createContract,
        isUpdateForm,
        isSaving,
        setIsSaving,
        updateAttemptedSave,
        toast,
    }: {
        refetchContracts: any;
        toast: (data: { title: string; description?: string }) => void;
        updateContract: (formData: any) => PossibleReturnType<any>;
    createContract: (formData: any) => PossibleReturnType<any>;
    onCloseModal: () => void;
    isUpdateForm: boolean;
    isSaving: boolean;
    setIsSaving: (status: boolean) => void;
    updateAttemptedSave: (status: boolean) => void;
  },
  formData: NewContractInfo | UpdateContractInfo,
) {
  updateAttemptedSave(true);
  // NOTE: we convert it json so it convert to how the api expects it.
  // i.e. decimals become strings
  if (!isFormValid(isUpdateForm, formData)) {
    return false;
  }

  if (isSaving) return false;

  setIsSaving(true);

  const data = JSON.parse(JSON.stringify(transformToApiContract(formData)));
    const saveResult = await (!!isUpdateForm
                            ? updateContract(data as any)
                            : createContract(data as any));

  updateAttemptedSave(false);
  setIsSaving(false);

    if (!saveResult.success) {
        toast({
            title: isUpdateForm
                 ? "Failed to update contract"
                 : "Failed to create contract",
            description: "",
        });
    } else {
        onCloseModal();
        refetchContracts();
        toast({
            title: isUpdateForm ? "Updated" : "Created",
            description: "",
        });
    }

    return true;
}

function ViewContractsOpenPositionsModal({
    refetchPositions,
    refetchContracts,
    pairs,
    openPositionsPaired,
    openPositionsUnpaired,
}: {
    refetchPositions: any
    refetchContracts: any
  openPositionsPaired: TradingAccountOpenPosition[];
  openPositionsUnpaired: TradingAccountOpenPosition[];
  pairs: PositionContractPair[];
}) {
  const { member } = useStytchMember();
  const { toast } = useToast();
  const contract_id = useBearStore(state => state.contracts_position_modal_contract_id)
  const set_contracts_positions_modal_id = useBearStore(state => state.set_contracts_positions_modal_id)
  const [isSubmitting, setIsSubmitting] = useState(false);
  const pairContract = usePairContract();
  const unpairContract = useUnPairContract();

    const setIsOpen = (status: boolean) => {
        if (!status) {
            set_contracts_positions_modal_id(undefined);
        }
    };

  const onPairClick = async (position: any, cell_id?: string) => {
    if (isSubmitting) return;
    setIsSubmitting(true);

    const res =
      cell_id === "pair"
        ? await pairContract({
            position_id: position?.id || "",
            contract_id: contract_id || "",
          })
        : await unpairContract({
            position_id: position?.id || "",
            contract_id: contract_id || "",
          });

    if (!res.success) {
      toast({
        title: cell_id === "pair" ? "Failed to pair" : "Failed to unpair",
        description: "",
      });
    } else {
      refetchPositions();
        refetchContracts();
        toast({
            title: cell_id === "pair" ? "Paired" : "Unpaired",
            description: "",
        });
    }

    setIsSubmitting(false);
  };

  const openPositions = [...openPositionsPaired, ...openPositionsUnpaired];
  const pairedPositionIds = contract_id
    ? pairs
        .filter((pair) => {
          return pair.trading_contract_id === contract_id;
        })
        .map((pair) => pair.trading_position_id)
    : [];

  const pairedPositions = openPositions.filter((pos) => {
    return pairedPositionIds.includes(pos.id);
  });

  const isOtcAdmin = doesUserHavePackages({
    requiredPackages: [PossiblePackage.OTCAdmin],
    member,
  });


    return (
        <Dialog
            onOpenChange={setIsOpen}
            open={!!contract_id}
            defaultOpen={false}
        >
            <DialogContent className="max-w-screen-2xl md:min-w-sm lg:min-w-md xl:mg-w-lg">
                <DialogHeader>
                    <DialogTitle>{"Pair Position"}</DialogTitle>
                </DialogHeader>
                <>
                {!isSubmitting && (
                          <>
                            {pairedPositions?.length > 0 && (
                              <>
                                <h3 className="text-lg font-bold tracking-tight">
                                  Currently Matched
                                </h3>
                                <DataTable
                                  height="250px"
                                  onCellClick={onPairClick}
                                  className="max-h-[16rem] md:max-h-[24rem] lg:max-h-[30rem] grid overflow-y-auto overflow-x-auto"
                                  columns={
                                    isOtcAdmin
                                      ? posUnpairOpenPositionColumns.map((item, index) => {
                                          if (!index) return item;
                                          return {
                                            ...item,
                                            onCellClick: false,
                                          };
                                        })
                                      : nonActionContractColumns.map((item, index) => {
                                          return {
                                            ...item,
                                            onCellClick: false,
                                          };
                                        })
                                  }
                                  data={pairedPositions}
                                  formId="PosContractPaired"
                                />
                                <h3 className="text-lg font-bold tracking-tight my-4">
                                  Un-Matched
                                </h3>
                              </>
                            )}
                            <DataTable
                              height="450px"
                              onCellClick={onPairClick}
                              className="max-h-[16rem] md:max-h-[24rem] lg:max-h-[30rem] grid overflow-y-auto overflow-x-auto"
                              columns={posPairOpenPositionColumns.map((item, index) => {
                                if (!index) return item;
                                return {
                                  ...item,
                                  onCellClick: false,
                                };
                              })}
                              data={openPositions}
                              formId="PosContractOpenPos"
                          />
                        </>
                      )}
                      {!!isSubmitting && (
                        <div className="flex flex-col space-y-2 justify-center items-center my-2">
                          <div className="font-semibold">Pairing</div>
                          <Loader />
                        </div>
                      )}
                </>
            </DialogContent>
        </Dialog>
    );
}


function PairFormModal({
  setPairData,
  pairingData,
  customers,
  accounts,
  openContracts,
  pairs,
    refetchPositions,
    refetchContracts,
}: {
  openContracts: TradingContract[];
  setPairData: (data: PairingData) => void;
  pairingData: PairingData;
  accounts: TradingAccount[];
  customers: Customer[];
  pairs: PositionContractPair[];
    refetchPositions: any;
    refetchContracts: any;
}) {
  const { member } = useStytchMember();
  const { toast } = useToast();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const pairContract = usePairContract();
  const unpairContract = useUnPairContract();
  const [contractFilter, setContractNumberFilter] = useState('');

  const AccountTypeOptions = createAccountListOptions(undefined)(accounts);

  const onUpdateStatus = (status: boolean) => {
    if (!status) {
      setPairData({});
    }
  };

  const onPairClick = async (contract: TradingContract, cell_id?: string) => {
    if (isSubmitting) return;
    setIsSubmitting(true);

    const res =
      cell_id === "pair"
        ? await pairContract({
            position_id: pairingData.position?.id || "",
            contract_id: contract.id || "",
          })
        : await unpairContract({
            position_id: pairingData.position?.id || "",
            contract_id: contract.id || "",
          });

    if (!res.success) {
      toast({
        title: "Failed to pair",
        description: "",
      });
    } else {
      refetchPositions();
        refetchContracts();
        toast({
            title: "Paired",
            description: "",
        });
    }

    setIsSubmitting(false);
  };

  const pairedContractIds = pairingData.position?.id
    ? pairs
        .filter((pair) => {
          return pair.trading_position_id === pairingData.position?.id;
        })
        .map((pair) => pair.trading_contract_id)
    : [];
  const pairedContracts = openContracts.filter((contract) =>
    pairedContractIds.includes(contract.id),
  );

  const isOtcAdmin = doesUserHavePackages({
    requiredPackages: [PossiblePackage.OTCAdmin],
    member,
  });

  return (
    <Dialog
      onOpenChange={onUpdateStatus}
      open={!!pairingData.position}
      defaultOpen={false}
    >
      <DialogContent className="max-w-screen-2xl md:min-w-sm lg:min-w-md xl:mg-w-lg">
        <DialogHeader>
          <DialogTitle>{"Pair Position"}</DialogTitle>
        </DialogHeader>
        {!isSubmitting && (
          <>
            {pairedContracts?.length > 0 && (
              <>
                <h3 className="text-lg font-bold tracking-tight">
                  Currently Matched
                </h3>
                <DataTable
                  height="250px"
                  onCellClick={onPairClick}
                  className="max-h-[16rem] md:max-h-[24rem] lg:max-h-[30rem] grid overflow-y-auto overflow-x-auto"
                  columns={
                    isOtcAdmin
                      ? posUnpairOpenContractColumns.map((item, index) => {
                          if (!index) return item;
                          return {
                            ...item,
                            onCellClick: false,
                          };
                        })
                      : nonActionContractColumns
                  }
                  data={pairedContracts?.map(
                    mapToTableContract({ customers, AccountTypeOptions }),
                  )}
                  formId="PairFormPaired"
                />
                <span className="flex flex-row">
                <h3 className="text-lg font-bold tracking-tight my-4 w-full">
                  Un-Matched
                </h3>
      <Input
        value={contractFilter}
        id={"PairFormModalContractNoFilter"}
        type="text"
        required={false}
              onChange={(event) => setContractNumberFilter(event.target.value || "")}
        className={cn(
          "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6",
        )}
          placeholder={"Contract No Filter"}
      />
                </span>
              </>
            )}
            <DataTable
              height="450px"
              onCellClick={onPairClick}
              className="max-h-[16rem] md:max-h-[24rem] lg:max-h-[30rem] grid overflow-y-auto overflow-x-auto"
              columns={posPairOpenContractColumns.map((item, index) => {
                if (!index) return item;
                return {
                  ...item,
                  onCellClick: false,
                };
              })}
              data={openContracts?.filter(contract => {
                if (contractFilter !== "" && contract.contract_no) {
                  return contract.contract_no.includes(contractFilter);
                }
                if (!!pairingData.position?.put_or_call) { return !(contract.rolled_option_month || contract.rolled_basis_month); }
                return !!(contract.rolled_option_month || contract.rolled_basis_month)
              }).map(
                mapToTableContract({ customers, AccountTypeOptions }),
              ).sort((a,b) => {
                    if (!a.contract_no && !b.contract_no) return 0;
                    if (!a.contract_no && !!b.contract_no) return 1;
                    if (!!a.contract_no && !b.contract_no) return -1;
                    if (a.contract_no === b.contract_no) return 0;
                    if (a.contract_no! < b.contract_no!) return -1;
                    if (a.contract_no! > b.contract_no!) return 1;
                    return 0;
                })}
              formId="PairFormOpen"
            />
          </>
        )}
        {!!isSubmitting && (
          <div className="flex flex-col space-y-2 justify-center items-center my-2">
            <div className="font-semibold">Pairing</div>
            <Loader />
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
}

function PositionFiltersModal({ accounts }: { accounts: TradingAccount[] }) {
  const isModalOpen = useBearStore(
    (state) => state.positions_filter_modal_status,
  );
  const onUpdateStatus = useBearStore(
    (state) => state.set_position_filter_modal_status,
  );
  const position_filters = useBearStore(
    (state) => state.editing_position_filters.filters,
  );
  const update_position_filters_field = useBearStore(
    (state) => state.update_position_filters_field,
  );
  const persistEditingPositionFilters = useBearStore(
    (state) => state.persistPositionFilters,
  );

  const onSubmit = persistEditingPositionFilters;
  return (
    <Dialog
      onOpenChange={onUpdateStatus}
      open={isModalOpen}
      defaultOpen={false}
    >
      <DialogTrigger asChild>
      <span className="flex flex-col justify-center items-center">
          <Label
              htmlFor="positions_filter_trigger"
              className="flex flex-row justify-between text-base items-center"
          >
              Position Filters
          </Label>
        <Button
          id="positions_filter_trigger"
          variant="ghost"
          type="button"
          className="ml-4"
          disabled={isModalOpen}
          onClick={() => onUpdateStatus(true)}
        >
          <ListFilterIcon />
        </Button>
      </span>
      </DialogTrigger>
      <DialogContent className="max-w-screen-2xl md:min-w-sm lg:min-w-md xl:mg-w-lg">
        <DialogHeader>
          <DialogTitle>{"Position Filters"}</DialogTitle>
        </DialogHeader>

        <form
          onSubmit={(e) => {
            if (e?.preventDefault) e.preventDefault();
            return onSubmit();
          }}
        >
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 items-start gap-4 content-center">
            <BearSelectMultiInput
              name="position_account_ids"
              label="Accounts"
              values={position_filters.account_ids || []}
              onValueChange={(prev: any) =>
                update_position_filters_field(
                  "account_ids",
                  prev(position_filters.account_ids || []),
                )
              }
              options={createAccountListOptions(undefined)(accounts)}
            />
            <BearSelectInput
              name="positions_commodity"
              label="Commodity"
              value={position_filters.commodity || ""}
              onValueChange={(value) =>
                update_position_filters_field(
                  "commodity",
                  findMatchOption(CommodityOptions, value)?.value,
                )
              }
              options={CommodityOptions}
            />
            <BearFloatInput
              name="positions_strike_price"
              label="Strike Price"
              value={position_filters.strike_price || ""}
              onChange={(value) =>
                update_position_filters_field("strike_price", value as any)
              }
            />
            <BearSelectInput
              name="position_filters_option_month"
              label="Reference Month"
              value={position_filters.option_month || ""}
              onValueChange={(value) =>
                update_position_filters_field(
                  "option_month",
                  findMatchOption(formulateOptionMonths(position_filters.option_month), value)?.value,
                )
              }
              options={formulateOptionMonths(position_filters.option_month)}
            />
            <div className="grid grid-cols-1 place-content-between gap-2 col-span-4">
              <div className="flex flex-row justify-between">
                <DialogClose asChild>
                  <Button
                    type="button"
                    variant="secondary"
                    className="col-start-1"
                  >
                    Close
                  </Button>
                </DialogClose>

                <Button type="submit" size="sm" className="px-3 col-start-4">
                  {"Apply"}
                </Button>
              </div>
            </div>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
}

function ContractFiltersModal({
  customers,
  accounts,
  traders,
}: {
    accounts: TradingAccount[];
  traders: Trader[];
    customers: Customer[];
}) {
  const isModalOpen = useBearStore(
    (state) => state.contracts_filter_modal_status,
  );
  const onUpdateStatus = useBearStore(
    (state) => state.set_contract_filter_modal_status,
  );
  const contract_filters = useBearStore(
    (state) => state.editing_contract_filters.filters,
  );
  const update_contract_filters_field = useBearStore(
    (state) => state.update_contract_filters_field,
  );
  const persistEditingContractFilters = useBearStore(
    (state) => state.persistEditingContractFilters,
  );

  const onSubmit = persistEditingContractFilters;

  return (
    <Dialog
      onOpenChange={onUpdateStatus}
      open={isModalOpen}
      defaultOpen={false}
    >
      <DialogTrigger asChild>
        <span className="flex flex-col justify-center items-center">
          <Label
            htmlFor="contract_filter_trigger"
            className="flex flex-row justify-between text-base items-center"
          >
            Contract Filters
          </Label>
          <Button
            id="contract_filter_trigger"
            variant="ghost"
            type="button"
            className="ml-4"
            disabled={isModalOpen}
            onClick={() => onUpdateStatus(true)}
          >
            <ListFilterIcon />
          </Button>
        </span>
      </DialogTrigger>
      <DialogContent className="max-w-screen-2xl md:min-w-sm lg:min-w-md xl:mg-w-lg">
        <DialogHeader>
          <DialogTitle>{"Contract Filters"}</DialogTitle>
        </DialogHeader>

        <form
          onSubmit={(e) => {
            if (e?.preventDefault) e.preventDefault();
            return onSubmit();
          }}
        >
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 items-start gap-4 content-center">
            <BearSelectMultiInput
              name="account_no_a_ids"
              label="Brokerage OTC"
              values={contract_filters.account_no_a_ids || []}
              onValueChange={(prev: any) =>
                update_contract_filters_field(
                  "account_no_a_ids",
                  prev(contract_filters.account_no_a_ids || []),
                )
              }
              options={createAccountListOptions(undefined)(accounts)}
            />
            <BearSelectMultiInput
              name="account_no_b_ids"
              label="Brokerage HTA"
              values={contract_filters.account_no_b_ids || []}
              onValueChange={(prev: any) =>
                update_contract_filters_field(
                  "account_no_b_ids",
                  prev(contract_filters.account_no_b_ids || []),
                )
              }
              options={createAccountListOptions(undefined)(accounts)}
            />
            <BearSelectMultiInput
              name="filters_customer_ids"
              label="Customer"
              values={contract_filters.customer_ids || []}
              onValueChange={(prev: any) =>
                update_contract_filters_field(
                  "customer_ids",
                  prev(contract_filters.customer_ids || []),
                )
              }
              options={customers.map((customer: Customer) => ({
                value: customer.id,
                label: customer.long_name,
              }))}
            />
            <BearSelectMultiInput
              name="filters_trader_ids"
              label="Trader"
              values={contract_filters.trader_ids || []}
              onValueChange={(prev: any) =>
                update_contract_filters_field(
                  "trader_ids",
                  prev(contract_filters.trader_ids || []),
                )
              }
              options={traders.map((trader: Trader) => ({
                value: trader.id,
                label: trader.name,
              }))}
            />
            <BearDatePicker
              name="filter_date"
              label="Date"
              value={contract_filters.date}
              onValueChange={(value) =>
                update_contract_filters_field("date", value)
              }
            />
            <BearDatePicker
              name="contract_filters_expiration_date"
              label="Expiration Date"
              value={contract_filters.end_date}
              onValueChange={(value) =>
                update_contract_filters_field("end_date", value)
              }
            />
            <BearSelectInput
              name="filters_contract_type"
              label="Contract Type"
              value={contract_filters.contract_type || ""}
              onValueChange={(value) =>
                update_contract_filters_field("contract_type", value)
              }
              options={ContractTypeOptions}
            />
            <BearSelectInput
              name="filters_commodity"
              label="Commodity"
              value={contract_filters.commodity || ""}
              onValueChange={(value) =>
                update_contract_filters_field(
                  "commodity",
                  findMatchOption(CommodityOptions, value)?.value,
                )
              }
              options={CommodityOptions}
            />
            <BearInput
              name="filters_trade_reference"
              label="Trade Reference"
              value={contract_filters.trade_reference || ""}
              onChange={(event) =>
                update_contract_filters_field(
                  "trade_reference",
                  event.target.value,
                )
              }
            />
            <BearInput
              name="filters_contract_no"
              label="Contract #"
              value={contract_filters.contract_no || ""}
              onChange={(event) =>
                update_contract_filters_field(
                  "contract_no",
                  event.target.value,
                )
              }
            />
            <BearSelectInput
              name="filter_option_month"
              label="OTC Opt. Month"
              value={contract_filters.option_month || ""}
              onValueChange={(value) =>
                update_contract_filters_field(
                  "option_month",
                  findMatchOption(formulateOptionMonths(contract_filters.option_month), value)?.value,
                )
              }
              options={formulateOptionMonths(contract_filters.option_month)}
            />
            <BearFloatInput
              name="filter_step_one"
              label="Step 1"
              value={contract_filters.step_one || ""}
              onChange={(value) =>
                update_contract_filters_field("step_one", value)
              }
            />
            <BearFloatInput
              name="filter_step_two"
              label="Step 2"
              value={contract_filters.step_two || ""}
              onChange={(value) =>
                update_contract_filters_field("step_two", value)
              }
            />
            <BearFloatInput
              name="filter_step_three"
              label="Step 3"
              value={contract_filters.step_three || ""}
              onChange={(value) =>
                update_contract_filters_field("step_three", value)
              }
            />
            <div className="grid grid-cols-1 place-content-between gap-2 col-span-4">
              <div className="flex flex-row justify-between">
                <DialogClose asChild>
                  <Button
                    type="button"
                    variant="secondary"
                    className="col-start-1"
                  >
                    Close
                  </Button>
                </DialogClose>

                <Button type="submit" size="sm" className="px-3 col-start-4">
                  {"Apply"}
                </Button>
              </div>
            </div>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
}

function DownloadRecentContractsFiltersForm() {
  const { toast } = useToast();
  const fields = useBearStore(state => state.downloadRecentContractFilters);
  const onEditField = useBearStore(state => state.editDownloadRecentFilterKey);
  const downloadDataLinkFunc = useDownloadOpenRecentContractData();
  const [links, setLinks] = useState<undefined | {
    link: string;
    import_new_contracts_link: string;
    import_update_contracts_link: string;
  }>(undefined);
  const [isDownloading, setIsDownloading] = useState(false);

  if (!fields) return null;


  const onDownload = async (downloadFilters: typeof DownloadRecentContractFilterValidation._type) => {
    if (!downloadFilters.created_date) return;
    if (isDownloading) return;
    setIsDownloading(true);

    const result = await downloadDataLinkFunc({
      created_date: moment.tz(transformGenericApiData(downloadFilters).created_date, CommonDestinationTimezone).format("YYYY/MM/DD")
    });
    setIsDownloading(false);

    if (!result.success) {
      toast({
        title: "Failed to generate",
        description: "",
      });
    } else {
        setLinks({
          link: result.data.link,
          import_new_contracts_link: result.data.import_new_contracts_link,
          import_update_contracts_link: result.data.import_update_contracts_link,
        });
    }
  };

    return (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 items-start gap-4 content-center">
              <div className="grid grid-cols-2 gap-3 col-span-2 content-center">
                  <BearDatePicker
                      tabIndex={0}
                      name="recent_download_filters_contract_end_date"
                      label="Created Date"
                      value={fields.created_date as any}
                      onValueChange={(date) => onEditField("created_date", date)}
                  />
            </div>
            <div className="grid grid-cols-1 place-content-between gap-2 col-span-4">
                <div className="flex flex-row justify-between">
                    {!!isDownloading && (<Loader />)}
                    {!links && !isDownloading && (
                        <Button variant="outline" type="button" size="sm" className="px-3 col-start-4" onClick={() => onDownload(fields)} disabled={!fields.created_date}>
                            {"Generate"}
                        </Button>
                    )}
                    {!!links && !isDownloading && (
                      <>
                        <a
                          href={links.link}
                          target="_blank"
                          className="underline underline-offset-1 decoration-sky-500"
                        >
                            Download Report
                        </a>
                        <a
                          href={links.import_new_contracts_link}
                          target="_blank"
                          className="underline underline-offset-1 decoration-sky-500"
                        >
                            Download Import
                        </a>
                        <a
                          href={links.import_update_contracts_link}
                          target="_blank"
                          className="underline underline-offset-1 decoration-sky-500"
                        >
                            Download Import Updated
                        </a>
                      </>
                    )}
                </div>
            </div>
            </div>
    );
}

function MarginReportForm() {
  const { toast } = useToast();
  const downloadDataLinkFunc = useDownloadMarginReportData();

  const onEditField = useBearStore(state => state.update_margin_report_field);
  const fields = useBearStore(
    (state) => state.margin_report_filters,
  );
  const [link, setLink] = useState<undefined | string>(undefined);
  const [isDownloading, setIsDownloading] = useState(false);

  if (!fields) return null;

  const onDownload = async (fields: any) => {
    if (isDownloading) return;
    setIsDownloading(true);

    const result = await downloadDataLinkFunc(fields);
    setIsDownloading(false);

    if (!result.success) {
      toast({
        title: "Failed to generate",
        description: "",
      });
    } else {
        setLink(result.data.link)
    }
  };

    return (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 items-start gap-4 content-center">
              <div className="grid grid-cols-2 gap-3 col-span-2 content-center">
                  <BearDatePicker
                      tabIndex={0}
                      name="margin_report_after_date"
                      label="After Date"
                      value={fields.after_date as any}
                      onValueChange={(date) => onEditField("after_date", date)}
                  />
                  <BearDatePicker
                      tabIndex={1}
                      name="margin_report_before_date"
                      label="Before Date"
                      value={fields.before_date as any}
                      onValueChange={(date) => onEditField("before_date", date)}
                  />
            </div>
            <div className="grid grid-cols-1 place-content-between gap-2 col-span-4">
                <div className="flex flex-row justify-between">
                    {!!isDownloading && (<Loader />)}
                    {!link && !isDownloading && (
                        <Button variant="outline" type="button" size="sm" className="px-3 col-start-4" onClick={() => onDownload(fields)}>
                            {"Generate"}
                        </Button>
                    )}
                    {!!link && !isDownloading && (
                            <a
                            href={link}
                            target="_blank"
                            className="underline underline-offset-1 decoration-sky-500"
                            >
                                Download Report
                            </a>
                    )}
                </div>
            </div>
            </div>
    );
}

function OpenContractsForm() {
  const { toast } = useToast();
  const downloadDataLinkFunc = useDownloadOpenContracts();
  const contract_filters = useBearStore(
    (state) => state.contract_filters.filters,
  );
  const [link, setLink] = useState<undefined | string>(undefined);
  const [isDownloading, setIsDownloading] = useState(false);

  const fields = useBearStore(state => state.openContractModalDownloadStatus);
  if (!fields) return null;


  const onDownload = async () => {
    if (isDownloading) return;
    setIsDownloading(true);

    const result = await downloadDataLinkFunc({ filters: contract_filters });
    setIsDownloading(false);

    if (!result.success) {
      toast({
        title: "Failed to generate",
        description: "",
      });
    } else {
        setLink(result.data.link)
    }
  };

    return (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 items-start gap-4 content-center">
          <div className="grid grid-cols-1 place-content-between gap-2 col-span-4">
              <div className="flex flex-row justify-between">
                  {!!isDownloading && (<Loader />)}
                  {!link && !isDownloading && (
                      <Button variant="outline" type="button" size="sm" className="px-3 col-start-4" onClick={() => onDownload()}>
                          {"Generate"}
                      </Button>
                  )}
                  {!!link && !isDownloading && (
                      <a
                        href={link}
                        target="_blank"
                        className="underline underline-offset-1 decoration-sky-500"
                      >
                          Download Report
                      </a>
                  )}
              </div>
          </div>
      </div>
    );
}

function SummaryFiltersForm() {
  const { toast } = useToast();
    const fields = useBearStore(state => state.summaryFilters);
    const downloadDataLinkFunc = useDownloadSummaryData();
  const [link, setLink] = useState<undefined | string>(undefined);
    const [isDownloading, setIsDownloading] = useState(false);

    if (!fields) return null;


  const onDownload = async () => {
            if (isDownloading) return;
    setIsDownloading(true);

    const result = await downloadDataLinkFunc();
    setIsDownloading(false);

    if (!result.success) {
      toast({
        title: "Failed to generate",
        description: "",
      });
    } else {
        setLink(result.data.link)
    }
  };

    return (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 items-start gap-4 content-center">
            <div className="grid grid-cols-1 place-content-between gap-2 col-span-4">
                <div className="flex flex-row justify-between">
                    {!!isDownloading && (<Loader />)}
                    {!link && !isDownloading && (
                        <Button variant="outline" type="button" size="sm" className="px-3 col-start-4" onClick={() => onDownload()}>
                            {"Generate"}
                        </Button>
                    )}
                    {!!link && !isDownloading && (
                            <a
                            href={link}
                            target="_blank"
                            className="underline underline-offset-1 decoration-sky-500"
                            >
                                Download Report
                            </a>
                    )}
                </div>
            </div>
          </div>
    );
}

function DownloadFiltersForm() {
  const { toast } = useToast();
    const fields = useBearStore(state => state.downloadFilters);
    const onEditField = useBearStore(state => state.editDownloadFilterKey);
    const downloadDataLinkFunc = useDownloadOpenData();
  const [link, setLink] = useState<undefined | string>(undefined);
    const [isDownloading, setIsDownloading] = useState(false);

    if (!fields) return null;

        const onDownload = async (downloadFilters: typeof DownloadFilterValidation._type) => {
            if (isDownloading) return;
    setIsDownloading(true);

    const result = await downloadDataLinkFunc(transformGenericApiData(downloadFilters));
    setIsDownloading(false);

    if (!result.success) {
      toast({
        title: "Failed to generate",
        description: "",
      });
    } else {
        setLink(result.data.link)
    }
  };

    return (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 items-start gap-4 content-center">
              <div className="grid grid-cols-2 gap-3 col-span-2 content-center">
                  <BearInput
                      tabIndex={0}
                      name="download_filters_reference_month"
                      label="Reference Month"
                      value={fields.reference_month || ""}
                      onChange={(event) => onEditField("reference_month", event.target.value)}
        />
                  <BearDatePicker
                      tabIndex={1}
                      name="download_filters_contract_end_date"
                      label="End Date"
                      value={fields.contract_end_date as any}
                      onValueChange={(date) => onEditField("contract_end_date", date)}
                  />
                <BearSelectInput
                      tabIndex={2}
                  name="download_filters_commodity"
                  label="Commodity"
                  value={fields.commodity || ""}
                  onValueChange={(value) =>
                    onEditField(
                      "commodity",
                      findMatchOption(CommodityOptions, value)?.value,
                    )
                  }
                  options={CommodityOptions}
                />
                <BearFloatInput
                  tabIndex={3}
                  name="download_filters_strike_price"
                  label="Strike Price"
                  step="5000"
                  value={fields.strike_price || ""}
                  onChange={(value) => onEditField("strike_price", value)}
                />
            </div>
            <div className="grid grid-cols-1 place-content-between gap-2 col-span-4">
                <div className="flex flex-row justify-between">
                    {!!isDownloading && (<Loader />)}
                    {!link && !isDownloading && (
                        <Button variant="outline" type="button" size="sm" className="px-3 col-start-4" onClick={() => onDownload(fields)}>
                            {"Generate"}
                        </Button>
                    )}
                    {!!link && !isDownloading && (
                            <a
                            href={link}
                            target="_blank"
                            className="underline underline-offset-1 decoration-sky-500"
                            >
                                Download Report
                            </a>
                    )}
                </div>
            </div>
            </div>
    );
}

function ManualPositionFormModal({
  customers,
  accounts,
  refetchPositions,
}: {
  accounts: TradingAccount[];
  customers: Customer[];
    refetchPositions: any;
}) {
  const { toast } = useToast();
  const edit_manual_position = useBearStore((state) => state.edit_manual_position);
  const set_edit_position = useBearStore((state) => state.set_edit_manual_position);
  const update_edit_position_field = useBearStore(
    (state) => state.update_edit_position_field,
  );
  const editManualPosition = useEditManualPosition();
  const [isSaving, setIsSaving] = useState(false);

  const onCloseModal = () => {
      set_edit_position(undefined);
  };

  return (
      <Dialog
          onOpenChange={(status) => {
              if (!status) { onCloseModal(); }
          }}
          open={!!edit_manual_position}
          defaultOpen={false}
      >
          <DialogContent className="max-w-screen-2xl md:min-w-sm lg:min-w-md xl:mg-w-lg">
              <DialogHeader>
                  <DialogTitle>
                      Edit Position
                  </DialogTitle>
              </DialogHeader>
              <PositionForm
                isEdit
                  accounts={accounts}
                  customers={customers}
                  onEditField={update_edit_position_field}
                  onSubmit={(formData) =>
                      onSubmitPositionForm(
                          {
                            isEdit: true,
                              refetchPositions,
                              toast,
                              createPosition: ((formData: any) => {
                                  return editManualPosition(formData, edit_manual_position?.id || "");
                              }) as any,
                              onCloseModal,
                            updateAttemptedSave: () => {},
                              isSaving,
                              setIsSaving,
                          },
                          formData,
                      )
                  }
              />
              <DialogFooter className="sm:justify-start"></DialogFooter>
          </DialogContent>
      </Dialog>
  );

}

function PositionFormModal({
  customers,
  accounts,
  refetchPositions,
}: {
    accounts: TradingAccount[];
  customers: Customer[];
    refetchPositions: any;
}) {
    const { toast } = useToast();
  const isModalOpen = useBearStore((state) => {
    return !!state.new_position;
  });
  const new_position = useBearStore((state) => state.new_position);
  const set_new_position = useBearStore((state) => state.set_new_position);
  const update_new_position_field = useBearStore(
    (state) => state.update_new_position_field,
  );
  const updateAttemptedSave = useBearStore(
    (state) => state.updatePositionAttemptedSave,
  );
  const createPosition = useCreatePosition();
  const [isSaving, setIsSaving] = useState(false);

  const onCloseModal = () => {
      set_new_position(undefined);
  };

  return (
      <Dialog
          onOpenChange={(status) => {
              if (!status) { onCloseModal(); }
          }}
          open={isModalOpen}
          defaultOpen={false}
      >
          <DialogTrigger asChild>
              <Button
                  type="button"
                  className="mr-2"
                  disabled={isModalOpen}
                  onClick={() => set_new_position({ short_qty: "0", long_qty: "0" })}
              >
                  New Position
              </Button>
          </DialogTrigger>
          <DialogContent className="max-w-screen-2xl md:min-w-sm lg:min-w-md xl:mg-w-lg">
              <DialogHeader>
                  <DialogTitle>
                      New Position
                  </DialogTitle>
              </DialogHeader>
              <PositionForm
                isEdit={false}
                  accounts={accounts}
                  customers={customers}
                  onEditField={update_new_position_field}
                  onSubmit={(formData) =>
                      onSubmitPositionForm(
                          {
                            isEdit: false,
                              refetchPositions,
                              toast,
                              createPosition: ((formData: any) => {
                                  return createPosition(formData);
                              }) as any,
                              onCloseModal,
                              updateAttemptedSave,
                              isSaving,
                              setIsSaving,
                          },
                          formData,
                      )
                  }
              />
              <DialogFooter className="sm:justify-start"></DialogFooter>
          </DialogContent>
      </Dialog>
  );
}

function ContractFormModal({
  isNew,
  customers,
  accounts,
    refetchContracts
}: {
    refetchContracts: any;
  accounts: TradingAccount[];
  customers: Customer[];
  isNew: boolean;
}) {
  const { toast } = useToast();
  const isModalOpen = useBearStore((state) => {
    return isNew ? !!state.new_contract : !!state.editing_contract?.id;
  });
  const set_new_contract = useBearStore((state) => state.set_new_contract);
  const set_edit_contract = useBearStore((state) => state.set_edit_contract);
  const update_new_contract_field = useBearStore(
    (state) => state.update_new_contract_field,
  );
  const update_edit_contract_field = useBearStore(
    (state) => state.update_edit_contract_field,
  );
  const updateAttemptedSave = useBearStore(
    (state) => state.updateAttemptedSave,
  );
  const [isSaving, setIsSaving] = useState(false);
  const createContract = useCreateContract();
  const updateContract = useUpdateContract();

  const onUpdateStatus = (status?: boolean) => {
    if (isNew) {
      set_new_contract(
        status === true ? { contract_type: ContractType.Range } : undefined,
      );
    } else {
      set_edit_contract(status === true ? {} : undefined);
    }
  };

  const onCloseModal = () => {
    if (isNew) {
      set_new_contract(undefined);
    } else {
      set_edit_contract(undefined);
    }
  };

  const isUpdateForm = !isNew;

  return (
      <Dialog
          onOpenChange={onUpdateStatus}
          open={isModalOpen}
          defaultOpen={false}
      >
          {isNew && (
              <DialogTrigger asChild>
                  <Button
                      type="button"
                      className="mr-4"
                      disabled={isModalOpen}
                      onClick={() => onUpdateStatus(true)}
                  >
                      New Contract
                  </Button>
              </DialogTrigger>
          )}
          <DialogContent className="max-w-screen-2xl md:min-w-sm lg:min-w-md xl:mg-w-lg">
              <DialogHeader>
                  <DialogTitle>
                      {isUpdateForm ? "Update Contract" : "New Contract"}
                  </DialogTitle>
              </DialogHeader>
              <ContractForm
                  accounts={accounts}
                  customers={customers}
                  isUpdateForm={!isNew}
                  onEditField={
                  isNew ? update_new_contract_field : update_edit_contract_field
                  }
                  onSubmit={(formData) =>
                      onSubmitForm(
                          {
                              refetchContracts,
                              toast,
                              updateContract: ((formData: any) => {
                                  return updateContract(formData);
                              }) as any,
                              createContract: ((formData: any) => {
                                  return createContract(formData);
                              }) as any,
                              onCloseModal,
                              isUpdateForm,
                              updateAttemptedSave,
                              isSaving,
                              setIsSaving,
                          },
                          formData,
                      )
                  }
              />
              <DialogFooter className="sm:justify-start"></DialogFooter>
          </DialogContent>
      </Dialog>
  );
}

function PositionForm({
  accounts,
  customers,
  onEditField,
  onSubmit,
  isEdit,
}: {
  isEdit: boolean;
  accounts: TradingAccount[];
  customers: Customer[];
  onEditField: (
    key: string,
    value?: string | number | Date | undefined | null,
  ) => void;
  onSubmit: (formData: typeof CreatePositionValidation._type) => Promise<boolean>;
}) {
  const fields = useBearStore((state) => isEdit ? state.edit_manual_position : state.new_position);
  const hasAttemptedSave = useBearStore((state) => state.hasPositionAttemptedSave);
  if (!fields) return null;

  const AccountTypeOptions = createAccountListOptions(undefined)(accounts);

  const isSubmittingDisabled = !isPositionFormValid(fields);
  const schema = CreatePositionValidation;

  return (
      <form
          onSubmit={(e) => {
              if (e?.preventDefault) e.preventDefault();
              return onSubmit(fields as any);
          }}
      >
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 items-start gap-4 content-center">
              <div className="grid grid-cols-5 gap-3 col-span-4 content-center">
                  <BearSelectInput
                      tabIndex={0}
                      required
                      name="create_position_trading_account_id"
                      label="Account"
                      value={fields["trading_account_id"] || ""}
                      onValueChange={(id) =>
                          onEditField(
                              "trading_account_id",
                              findMatchOption(AccountTypeOptions, id)?.value,
                          )
                      }
                      options={AccountTypeOptions}
                  />

                  <BearDatePicker
                      tabIndex={1}
                      name="create_position_date"
                      label="Date"
                      required
                      value={fields.date as any}
                      onValueChange={(date) => onEditField("date", date)}
                      schema={schema}
                      hasAttemptedSave={hasAttemptedSave}
                  />

                  <BearSelectInput
                      tabIndex={2}
                      required
                      name="create_position_option_month"
                      label="Reference Month"
                      value={fields.option_month || ""}
                      onValueChange={(value) => onEditField("option_month", findMatchOption(formulateOptionMonths(fields.option_month), value)?.value)}
                      options={formulateOptionMonths(fields.option_month)}
                  />

                  <BearFloatInput
                      tabIndex={3}
                      name="create_position_long_qty"
                      label="Long QTY"
                      step="1"
                      min="0"
                      value={fields.long_qty || "0"}
                      onChange={(value) => onEditField("long_qty", value || "0")}
                      schema={schema}
                      hasAttemptedSave={hasAttemptedSave}
                  />

                  <BearFloatInput
                      tabIndex={4}
                      name="create_position_short_qty"
                      label="Short QTY"
                      step="1"
                      min="0"
                      value={fields.short_qty || "0"}
                      onChange={(value) => onEditField("short_qty", value || "0")}
                      schema={schema}
                      hasAttemptedSave={hasAttemptedSave}
                  />

                  <BearSelectInput
                      tabIndex={5}
                      name="create_positions_put_or_call"
                      label="Put or Call"
                      value={fields.put_or_call || ""}
                      onValueChange={(value) =>
                          onEditField(
                              "put_or_call",
                              findMatchOption(PutOrCallOptions, value)?.value,
                          )
                      }
                      options={PutOrCallOptions}
                  />

            <BearSelectInput
                      tabIndex={7}
              name="create_positions_commodity"
              label="Commodity"
              value={fields.commodity || ""}
              onValueChange={(id) =>
                onEditField(
                  "commodity",
                  findMatchOption(CommodityOptions, id)?.value,
                )
              }
              options={CommodityOptions}
              schema={schema}
              hasAttemptedSave={hasAttemptedSave}
            />

          <BearFloatInput
                      tabIndex={8}
            name="create_position_bushel"
            label="Bushel"
            step="5000"
            value={fields.bushel || ""}
            onChange={(value) => onEditField("bushel", value)}
            schema={schema}
            hasAttemptedSave={hasAttemptedSave}
          />

          <BearFloatInput
                      tabIndex={9}
            name="create_position_strike_price"
            label="Strike Price"
            step="5000"
            value={fields.strike_price || ""}
            onChange={(value) => onEditField("strike_price", value)}
            schema={schema}
            hasAttemptedSave={hasAttemptedSave}
          />




              </div>

              <div className="grid grid-cols-1 place-content-between gap-2 col-span-4">
                  <div className="flex flex-row justify-between">
                      <DialogClose asChild>
                          <Button type="button" variant="secondary" className="col-start-1">
                              Close
                          </Button>
                      </DialogClose>

                      <Button
                          type="submit"
                          size="sm"
                          className="px-3 col-start-4"
                          disabled={isSubmittingDisabled}
                      >
                          {isEdit ? "Update" : "Create"}
                      </Button>
                  </div>
              </div>
          </div>
      </form>
  );

}

function ContractForm({
  accounts,
  customers,
  isUpdateForm,
  onEditField,
  onSubmit,
}: {
  accounts: TradingAccount[];
  customers: Customer[];
  isUpdateForm: boolean;
  onEditField: (
    key: string,
    value?: string | number | Date | undefined | null,
  ) => void;
  onSubmit: (formData: UpdateContractInfo) => Promise<boolean>;
}) {
  const new_contract_data = useBearStore((state) => state.new_contract);
  const editing_contract_data = useBearStore((state) => state.editing_contract);
  const hasAttemptedSave = useBearStore((state) => state.hasAttemptedSave);
  const listTradersRes = useListTraders();

  const fields = (
    isUpdateForm ? editing_contract_data : new_contract_data
  ) as any;
  if (!fields) return null;

    if ([listTradersRes].some((result) => result.isLoading)) {
        return (
            <div className="flex flex-col space-y-2">
                <Skeleton className="h-4 w-[250px]" />
                <Skeleton className="h-4 w-[200px]" />
            </div>
        );
  }

  const traders = listTradersRes?.data?.data || [];

  const margin = ignoreSafeParseDecimal(fields.contract_fee || 0).sub(
    ignoreSafeParseDecimal(fields.option_premium || 0),
  );
  const margin_amount = margin.times(
    ignoreSafeParseDecimal(fields.quantity || 0),
  );

  const isSubmittingDisabled = !isFormValid(isUpdateForm, fields);

  const OTCAccountTypeOptions = createAccountListOptions(undefined)(accounts);
  const HTAAccountTypeOptions = createAccountListOptions(fields.contract_type || "")(accounts);

  const schema = getSchemaForType(isUpdateForm, fields.contract_type);
  const TraderOptions = traders.map((trader: Trader) => ({
    value: trader.id,
    label: trader.name || "",
  }));

  return (
    <form
      onSubmit={(e) => {
        if (e?.preventDefault) e.preventDefault();
        return onSubmit(fields as any);
      }}
    >
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 items-start gap-4 content-center">
        <div className="grid grid-cols-5 gap-3 col-span-4 content-center">
          <div className="grid items-center gap-1.5 content-center">
            <BearDatePicker
              tabIndex={0}
              name="date"
              label="Date"
              required
              value={fields.date}
              onValueChange={(date) => onEditField("date", date)}
              schema={schema}
              hasAttemptedSave={hasAttemptedSave}
            />
          </div>
          <div className="grid items-center gap-1.5 content-center">
            <BearDatePicker
              required
              tabIndex={1}
              name="end_date"
              label="Expiration Date"
              value={fields.end_date}
              onValueChange={(date) => onEditField("end_date", date)}
              schema={schema}
              hasAttemptedSave={hasAttemptedSave}
            />
          </div>
          <div className="grid items-center gap-1.5 content-center">
            <BearSelectInput
              tabIndex={1}
              name="contract_type"
              label="Contract Type"
              required
              value={fields.contract_type || ""}
              onValueChange={(id) => {
                return onEditField("contract_type", id);
              }}
              options={ContractTypeOptions}
              schema={schema}
              hasAttemptedSave={hasAttemptedSave}
            />
          </div>

          <div className="grid items-center gap-1.5">
            <BearSelectInput
              tabIndex={2}
              name="customer_id"
              label="Customer"
              required
              value={fields.customer_id || ""}
              onValueChange={(id) => onEditField("customer_id", id)}
              options={customers.map((customer: Customer) => ({
                value: customer.id,
                label: customer.long_name,
              }))}
              schema={schema}
              hasAttemptedSave={hasAttemptedSave}
            />
          </div>
          <div className="grid items-center gap-1.5 content-center">
            <BearSelectInput
              required
              tabIndex={3}
              name="commodity"
              label="Commodity"
              value={fields.commodity || ""}
              onValueChange={(id) =>
                onEditField(
                  "commodity",
                  findMatchOption(CommodityOptions, id)?.value,
                )
              }
              options={CommodityOptions}
              schema={schema}
              hasAttemptedSave={hasAttemptedSave}
            />
          </div>
        </div>
        <div className="grid grid-cols-4 gap-2 col-span-4 content-center">
          <div className="grid items-center gap-1.5">
            <BearSelectInput
              tabIndex={5}
              name="option_month"
              label="OTC Opt. Month"
              required
              value={fields.option_month || ""}
              onValueChange={(value) =>
                onEditField("option_month", findMatchOption(formulateOptionMonths(fields.option_month), value)?.value,)
              }
              options={formulateOptionMonths(fields.option_month)}
              schema={schema}
              hasAttemptedSave={hasAttemptedSave}
            />
          </div>
          <BearIntInput
            tabIndex={6}
            name="quantity"
            label="Quantity"
            required
            step="5000"
            value={fields.quantity}
            onChange={(value) => onEditField("quantity", value)}
            schema={schema}
            hasAttemptedSave={hasAttemptedSave}
          />
          <div className="grid items-center gap-1.5 content-center">
            <BearInput
              tabIndex={7}
              name="trade_reference"
              label="Trade Reference"
              value={fields.trade_reference || ""}
              onChange={(event) =>
                onEditField("trade_reference", event.target.value)
              }
              schema={schema}
              hasAttemptedSave={hasAttemptedSave}
            />
          </div>
          <div className="grid items-center gap-1.5 content-center">
            <BearSelectInput
              tabIndex={8}
              name="account_no_a"
              label="Brokerage OTC"
              required
              value={fields.account_no_a || ""}
              onValueChange={(id) => {
                return onEditField("account_no_a", id);
              }}
              options={OTCAccountTypeOptions}
              schema={schema}
              hasAttemptedSave={hasAttemptedSave}
            />
          </div>
        </div>

        <div
          className={cn(
            "grid items-center gap-1.5 grid-cols-subgrid gap-4 content-center",
            "grid-cols-4 sm:col-span-2 md:col-span-4",
            fields.contract_type === ContractType.PremiumOfferWDU
            ? "grid-cols-5"
            : "",
          )}
        >
          {[
            ContractType.MinMax,
            ContractType.Range,
            ContractType.MinPriceCallSpread,
            ContractType.AccWeeklyDU,
            ContractType.MinMaxWDU,
            ContractType.ReOwnWDU,
          ].includes(fields.contract_type as any) && (
            <div className="grid items-center gap-1.5 content-center">
              <BearFloatInput
                required={!isUpdateForm}
                tabIndex={9}
                name="step_one"
                label="Step 1"
                value={fields.step_one}
                onChange={(value) => onEditField("step_one", value)}
                schema={schema}
                hasAttemptedSave={hasAttemptedSave}
              />
            </div>
          )}
          {[ContractType.Range, ContractType.MinMaxWDU, ContractType.ReOwnWDU].includes(fields.contract_type as any) && (
            <div className="grid items-center gap-1.5 content-center">
              <BearFloatInput
                required={!isUpdateForm}
                tabIndex={10}
                name="step_two"
                label="Step 2"
                value={fields.step_two}
                onChange={(value) => onEditField("step_two", value)}
                schema={schema}
                hasAttemptedSave={hasAttemptedSave}
              />
            </div>
          )}
          {[
            ContractType.MinMax,
            ContractType.Range,
            ContractType.MinPriceCallSpread,
            ContractType.MinimumPrice,
            ContractType.PremiumOfferWDU,
            ContractType.MinMaxWDU,
            ContractType.ReOwnWDU,
          ].includes(fields.contract_type as any) && (
            <div className="grid items-center gap-1.5 content-center">
              <BearFloatInput
                required={!isUpdateForm}
                tabIndex={11}
                name="step_three"
                label="Step 3"
                value={fields.step_three}
                onChange={(value) => onEditField("step_three", value)}
                schema={schema}
                hasAttemptedSave={hasAttemptedSave}
              />
            </div>
          )}
          <div className="grid items-center gap-1.5 content-center">
            <BearInput
              name="contract_no"
              label="Contract #"
              tabIndex={11}
              value={fields.contract_no || ""}
              onChange={(event) =>
                onEditField("contract_no", event.target.value)
              }
              schema={schema}
              hasAttemptedSave={hasAttemptedSave}
            />
          </div>
          {[ContractType.PremiumOfferWDU].includes(
            fields.contract_type as any,
          ) && (
            <>
              <div className="grid items-center gap-1.5 content-center">
                <BearSelectInput
                  tabIndex={11}
                  name="account_no_b"
                  label="Brokerage HTA"
                  required
                  value={fields.account_no_b || ""}
                  onValueChange={(id) => {
                    return onEditField("account_no_b", id);
                  }}
                  options={HTAAccountTypeOptions}
                  schema={schema}
                  hasAttemptedSave={hasAttemptedSave}
                />
              </div>
              <div className="grid items-center gap-1.5 content-center">
                <BearFloatInput
                  tabIndex={11}
                  required
                  name="futures_price"
                  label="Futures Price"
                  value={fields.futures_price}
                  onChange={(value) => onEditField("futures_price", value)}
                  schema={schema}
                  hasAttemptedSave={hasAttemptedSave}
                />
              </div>
              <div className="grid items-center gap-1.5 content-center">
                <BearFloatInput
                  tabIndex={11}
                  required
                  name="otc_futures"
                  label="OTC Futures"
                  value={fields.otc_futures}
                  onChange={(value) => onEditField("otc_futures", value)}
                  schema={schema}
                  hasAttemptedSave={hasAttemptedSave}
                />
              </div>
            </>
          )}
        </div>

        <div className="grid grid-cols-2 gap-2 col-span-2 content-center">
          <div className="grid items-center gap-1.5 content-center">
            <span className="flex flex-row">
              <BearFloatInput
                className="grow"
                tabIndex={12}
                required
                name="option_premium"
                label="Option Premium"
                value={fields.option_premium}
                onChange={(value) => onEditField("option_premium", value)}
                schema={schema}
                hasAttemptedSave={hasAttemptedSave}
              />
              <HoverCard>
                <HoverCardTrigger asChild>
                  <CircleHelpIcon className="ml-4 w-4"/>
                </HoverCardTrigger>
                <HoverCardContent className="w-80">
                  <div className="flex justify-between space-x-4">
                    <div className="space-y-1">
                      <p className="text-sm flex flex-row">
                        <DotIcon />{" "}Option premium is (+) if you paid funds for option
                      </p>
                      <div className="flex items-center pt-2">
                        <span className="text-sm flex flex-row">
                          <DotIcon />{" "}Option Premium is (-) If you collected funds for option
                        </span>
                      </div>
                    </div>
                  </div>
                </HoverCardContent>
              </HoverCard>
            </span>
          </div>
          <div className="grid items-center gap-1.5 content-center">
            <BearFloatInput
              tabIndex={13}
              required
              name="contract_fee"
              label="Initial Premium + Fee"
              value={fields.contract_fee}
              onChange={(value) => onEditField("contract_fee", value)}
              schema={schema}
              hasAttemptedSave={hasAttemptedSave}
            />
          </div>
        </div>
        <div className="grid grid-cols-2 gap-2 col-span-2 content-center">
          <div className="grid items-center gap-1.5 content-center">
            <BearFloatInput
              required
              name="margin"
              label="Lighthouse Fee"
              disabled
              value={margin.toString()}
              onChange={(value) => onEditField("margin", value)}
              schema={schema}
              hasAttemptedSave={hasAttemptedSave}
            />
          </div>
          <div className="grid items-center gap-1.5 content-center">
            <BearFloatInput
              required
              name="margin_amount"
              label="Margin Amount"
              disabled
              value={margin_amount.toString()}
              onChange={(value) => onEditField("margin_amount", value)}
              schema={schema}
              hasAttemptedSave={hasAttemptedSave}
            />
          </div>
        </div>
        {[ContractType.AccWeeklyDU].includes(fields.contract_type as any) && (
          <div className="grid grid-cols-5 gap-2 col-span-4 content-center">
            <div className="grid items-center gap-1.5 content-center">
              <BearDatePicker
                required
                tabIndex={14}
                name="start_date"
                label="Start Date"
                value={fields.start_date}
                onValueChange={(date) => onEditField("start_date", date)}
                schema={schema}
                hasAttemptedSave={hasAttemptedSave}
              />
            </div>
            <div className="grid items-center gap-1.5 content-center">
              <BearFloatInput
                tabIndex={16}
                required
                name="weeks"
                label="Weeks"
                value={fields.weeks}
                onChange={(value) => onEditField("weeks", value)}
                schema={schema}
                hasAttemptedSave={hasAttemptedSave}
              />
            </div>
            <div className="grid items-center gap-1.5 content-center">
              <BearFloatInput
                tabIndex={17}
                required
                name="period_qty"
                label="Period Qty"
                value={fields.period_qty}
                onChange={(value) => onEditField("period_qty", value)}
                schema={schema}
                hasAttemptedSave={hasAttemptedSave}
              />
            </div>
            <div className="grid items-center gap-1.5 content-center">
              <BearFloatInput
                tabIndex={18}
                required
                name="knock_out"
                label="Knockout"
                value={fields.knockout}
                onChange={(value) => onEditField("knockout", value)}
                schema={schema}
                hasAttemptedSave={hasAttemptedSave}
              />
            </div>
          </div>
        )}
        <div className="grid grid-cols-2 gap-2 col-span-2 content-center">
          <BearDatePicker
            required
            tabIndex={19}
            name="shipment_start_date"
            label="Shipment Start Date"
            value={fields.shipment_start_date}
            onValueChange={(date) => onEditField("shipment_start_date", date)}
            schema={schema}
            hasAttemptedSave={hasAttemptedSave}
          />
          <BearDatePicker
            required
            tabIndex={20}
            name="shipment_end_date"
            label="Shipment End Date"
            value={fields.shipment_end_date}
            onValueChange={(date) => onEditField("shipment_end_date", date)}
            schema={schema}
            hasAttemptedSave={hasAttemptedSave}
          />
        </div>
        <div className="grid grid-cols-1 gap-2 col-span-1 content-center">
          <BearSelectInput
            required
            tabIndex={21}
            name="contract_trader_options"
            label="Trader"
            value={fields.trader_id || ""}
            onValueChange={(value) =>
              onEditField(
                "trader_id",
                findMatchOption(TraderOptions, value)?.value,
              )
            }
            options={TraderOptions}
          />
        </div>
        <BearIntInput
          tabIndex={22}
          name="crop_year"
          label="Crop Year"
          required
          step="1"
          value={fields.crop_year}
          onChange={(value) => onEditField("crop_year", value)}
          schema={schema}
          hasAttemptedSave={hasAttemptedSave}
        />
        <BearSelectInput
          tabIndex={23}
          name="rolled_option_month"
          label="Futures Rolled Month"
          value={fields.rolled_option_month || ""}
          onValueChange={(value) =>
            onEditField("rolled_option_month", findMatchOption(formulateOptionMonths(fields.rolled_option_month), value)?.value,)
          }
          options={formulateOptionMonths(fields.rolled_option_month)}
          schema={schema}
          hasAttemptedSave={hasAttemptedSave}
        />
        <BearFloatInput
          tabIndex={23}
          name="carry"
          label="Futures Carry"
          value={fields.carry}
          onChange={(value) => onEditField("carry", value)}
          schema={schema}
          hasAttemptedSave={hasAttemptedSave}
        />
        <BearSelectInput
          tabIndex={24}
          name="rolled_basis_month"
          label="Basis Rolled Month"
          value={fields.rolled_basis_month || ""}
          onValueChange={(value) =>
            onEditField("rolled_basis_month", findMatchOption(formulateOptionMonths(fields.rolled_basis_month), value)?.value,)
          }
          options={formulateOptionMonths(fields.rolled_basis_month)}
          schema={schema}
          hasAttemptedSave={hasAttemptedSave}
        />
        <BearFloatInput
          tabIndex={25}
          name="basis_carry"
          label="Basis Carry"
          value={fields.basis_carry}
          onChange={(value) => onEditField("basis_carry", value)}
          schema={schema}
          hasAttemptedSave={hasAttemptedSave}
        />
            <BearInput
              name="notes"
              label="Notes"
              tabIndex={26}
              value={fields.notes || ""}
              onChange={(event) =>
                onEditField("notes", event.target.value)
              }
              schema={schema}
              hasAttemptedSave={hasAttemptedSave}
            />
            {[
            ContractType.PremiumOfferWDU,
            ContractType.AccWeeklyDU,
            ContractType.MinMaxWDU,
            ContractType.ReOwnWDU,
          ].includes(fields.contract_type as any) && (
            <div className="grid items-center gap-1.5 content-center">
            <BearSelectInput
              tabIndex={27}
              name="option_month_etc_one"
              label="HTA DU Opt. Month"
              required
              value={fields.option_month_etc_one || ""}
              onValueChange={(value) =>
                onEditField("option_month_etc_one", findMatchOption(formulateOptionMonths(fields.option_month), value)?.value,)
              }
              options={formulateOptionMonths(fields.option_month_etc_one)}
              schema={schema}
              hasAttemptedSave={hasAttemptedSave}
            />
            </div>
          )}
        <div className="grid grid-cols-1 place-content-between gap-2 col-span-4">
          <div className="flex flex-row justify-between">
            <DialogClose asChild>
              <Button type="button" variant="secondary" className="col-start-1">
                Close
              </Button>
            </DialogClose>

            <Button
              type="submit"
              size="sm"
              className="px-3 col-start-4"
              disabled={isSubmittingDisabled}
            >
              {isUpdateForm ? "Update" : "Create"}
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
}

function DownloadContractPositionData() {
  const { toast } = useToast();
    const [isDownloading, setIsDownloading] = useState(false);
    const isModalOpen = useBearStore(state => !!state.downloadFilters);
    const setDownloadFilters = useBearStore(state => state.setDownloadFilters);

    const onUpdateStatus = (status: boolean) => {
        if (!status) {
            setDownloadFilters(undefined);
        }
    }

    return (
        <Dialog
            onOpenChange={onUpdateStatus}
            open={isModalOpen}
            defaultOpen={false}
        >
            <DialogTrigger asChild>
                <span className="flex flex-col justify-center items-center">
                    <Button
                        id="download_data"
                        variant="outline"
                        type="button"
                        className="mr-2"
                        disabled={isModalOpen}
                        onClick={() => setDownloadFilters({})}
                    >
                        Position Report
                    </Button>
                </span>
            </DialogTrigger>
            <DialogContent className="max-w-screen-lg md:min-w-sm lg:min-w-md xl:mg-w-lg">
                <DialogHeader>
                    <DialogTitle>{"Download Filters"}</DialogTitle>
                </DialogHeader>
                <>
                    <DownloadFiltersForm />
                </>
            </DialogContent>
        </Dialog>
    );
}

function DownloadMarginReportReportModal() {
   const { toast } = useToast();
    const [isDownloading, setIsDownloading] = useState(false);
    const isModalOpen = useBearStore(state => !!state.margin_report_filters);
    const setModalStatus = useBearStore(state => state.set_margin_report_modal);

    const onUpdateStatus = (status: boolean) => {
        if (!status) {
            setModalStatus(undefined);
        }
    };

    return (
        <Dialog
            onOpenChange={onUpdateStatus}
            open={isModalOpen}
            defaultOpen={false}
        >
            <DialogTrigger asChild>
                <span className="flex flex-col justify-center items-center">
                    <Button
                        id="open_contract_report_summary_data"
                        variant="outline"
                        type="button"
                        className="mr-2"
                        disabled={isModalOpen}
                        onClick={() => setModalStatus({})}
                    >
                        Download Margin Report
                    </Button>
                </span>
            </DialogTrigger>
            <DialogContent className="max-w-screen-lg md:min-w-sm lg:min-w-md xl:mg-w-lg">
                <DialogHeader>
                    <DialogTitle>{"Margin Report Download"}</DialogTitle>
                </DialogHeader>
                <>
                    <MarginReportForm />
                </>
            </DialogContent>
        </Dialog>
    );
}

function DownloadOpenContractReportData() {
  const { toast } = useToast();
    const [isDownloading, setIsDownloading] = useState(false);
    const isModalOpen = useBearStore(state => !!state.openContractModalDownloadStatus);
    const setModalStatus = useBearStore(state => state.setOpenContractModalDownloadStatus);

    const onUpdateStatus = (status: boolean) => {
        if (!status) {
            setModalStatus(undefined);
        }
    }

    return (
        <Dialog
            onOpenChange={onUpdateStatus}
            open={isModalOpen}
            defaultOpen={false}
        >
            <DialogTrigger asChild>
                <span className="flex flex-col justify-center items-center">
                    <Button
                        id="open_contract_report_summary_data"
                        variant="outline"
                        type="button"
                        className="mr-2"
                        disabled={isModalOpen}
                        onClick={() => setModalStatus({})}
                    >
                        Open Contract Report
                    </Button>
                </span>
            </DialogTrigger>
            <DialogContent className="max-w-screen-lg md:min-w-sm lg:min-w-md xl:mg-w-lg">
                <DialogHeader>
                    <DialogTitle>{"Open Contracts Download"}</DialogTitle>
                </DialogHeader>
                <>
                    <OpenContractsForm />
                </>
            </DialogContent>
        </Dialog>
    );
}

function DownloadSummaryReportData() {
  const { toast } = useToast();
    const [isDownloading, setIsDownloading] = useState(false);
    const isModalOpen = useBearStore(state => !!state.summaryFilters);
    const setSummaryFilters = useBearStore(state => state.setSummaryFilters);

    const onUpdateStatus = (status: boolean) => {
        if (!status) {
            setSummaryFilters(undefined);
        }
    }

    return (
        <Dialog
            onOpenChange={onUpdateStatus}
            open={isModalOpen}
            defaultOpen={false}
        >
            <DialogTrigger asChild>
                <span className="flex flex-col justify-center items-center">
                    <Button
                        id="summary_data"
                        variant="outline"
                        type="button"
                        className="mr-2"
                        disabled={isModalOpen}
                        onClick={() => setSummaryFilters({})}
                    >
                        Summary Report
                    </Button>
                </span>
            </DialogTrigger>
            <DialogContent className="max-w-screen-lg md:min-w-sm lg:min-w-md xl:mg-w-lg">
                <DialogHeader>
                    <DialogTitle>{"Summary"}</DialogTitle>
                </DialogHeader>
                <>
                    <SummaryFiltersForm />
                </>
            </DialogContent>
        </Dialog>
    );
}



function DownloadRecentContractData({
    accounts,
    customers,
}: {
    accounts: TradingAccount[];
    customers: Customer[];
}) {
  const { toast } = useToast();
    const [isDownloading, setIsDownloading] = useState(false);
    const isModalOpen = useBearStore(state => !!state.downloadRecentContractFilters);
    const setDownloadFilters = useBearStore(state => state.setDownloadRecentContractsFilters);

    const onUpdateStatus = (status: boolean) => {
        if (!status) {
            setDownloadFilters(undefined);
        }
    }

    return (
        <Dialog
            onOpenChange={onUpdateStatus}
            open={isModalOpen}
            defaultOpen={false}
        >
            <DialogTrigger asChild>
                <span className="flex flex-col justify-center items-center">
                    <Button
                        id="contract_filters_download_data"
                        variant="outline"
                        type="button"
                        className="mr-2"
                        disabled={isModalOpen}
                        onClick={() => setDownloadFilters({ created_date: new Date() })}
                    >
                        Download Recent
                    </Button>
                </span>
            </DialogTrigger>
            <DialogContent className="max-w-screen-lg md:min-w-sm lg:min-w-md xl:mg-w-lg">
                <DialogHeader>
                    <DialogTitle>{"Download Recent Filters"}</DialogTitle>
                </DialogHeader>
                <>
                    <DownloadRecentContractsFiltersForm />
                </>
            </DialogContent>
        </Dialog>
    );
}


function OpenPositions({
    refetchPositions,
    accounts,
  traders,
    areEmptyPositions,
    customers,
}: {
    refetchPositions: any;
    accounts: TradingAccount[];
  traders: Trader[];
    customers: Customer[];
    areEmptyPositions: boolean;
}) {
  return (
    <div className="flex flex-row justify-between w-full items-center">
      <OpenPositionsFilters traders={traders} accounts={accounts} customers={customers} refetchPositions={refetchPositions} />
    </div>
  );
}

function OpenPositionsFilters({
    customers,
    accounts,
  traders,
    refetchPositions,
}: {
    refetchPositions: any;
    accounts: TradingAccount[];
  traders: Trader[];
    customers: Customer[];
}) {
    const clear_filters = useBearStore(state => state.clear_filters);

    return (
        <div className="flex flex-row items-center gap-4">
            <DownloadContractPositionData />
            <PositionFormModal customers={customers} accounts={accounts} refetchPositions={refetchPositions}/>
            <Button
                variant="outline"
                className="mr-2"
                onClick={() => clear_filters()}
            >
                Clear Filters
            </Button>
            <PositionFiltersModal accounts={accounts} />
            <ContractFiltersModal customers={customers} accounts={accounts} traders={traders} />
        </div>
    );
}

function BearSelectMultiInput({
  values,
  onValueChange,
  options,
  name,
  required,
  label,
}: {
  values: string[];
  onValueChange: any;
  options: { value: string; label: string }[];
  name: string;
  required?: boolean;
  label?: string;
}) {
  return (
    <div className="grid items-center gap-1.5">
      {label && (
        <Label
          htmlFor={name}
          className="flex flex-row justify-between text-base items-center"
        >
          <span className="font-semibold">{label}</span>
          {required ? (
            <span
              className="text-xs font-light"
              style={{ fontStyle: "italic" }}
            >
              Required
            </span>
          ) : (
            <span></span>
          )}
        </Label>
      )}
      <MultiSelect
        values={values}
        setValues={onValueChange}
        options={options}
        listClassName="overflow-y-scroll max-h-44 min-h-36"
      />
    </div>
  );
}

function BearSelectInput({
  value,
  onValueChange,
  options,
  name,
  required,
  label,
  tabIndex,
  schema,
  hasAttemptedSave,
}: {
  value: string;
  onValueChange: (item: string) => void;
  options: { value: string; label: string }[];
  name: string;
  required?: boolean;
  label?: string;
  tabIndex?: number;
  schema?: any;
  hasAttemptedSave?: boolean;
}) {
  const data = JSON.parse(
    JSON.stringify(transformToApiContract({ [name]: value })),
  );
  const hasErrors = schema
    ? !(schema as any).pick({ [name]: true }).safeParse(data).success
    : false;

  return (
    <div className="grid items-center gap-1.5">
      {label && (
        <Label
          htmlFor={name}
          className="flex flex-row justify-between text-base items-center"
        >
          <span className="font-semibold">{label}</span>
          {required ? (
            <span
              className="text-xs font-light"
              style={{ fontStyle: "italic" }}
            >
              Required
            </span>
          ) : (
            <span></span>
          )}
        </Label>
      )}

      <Combobox
        tabIndex={tabIndex}
        isModal
        className="w-full"
        value={value}
        onValueChange={onValueChange}
        options={options}
      />
    </div>
  );
}

function BearDatePicker({
  value,
  onValueChange,
  name,
  required,
  label,
  tabIndex,
  schema,
  hasAttemptedSave,
}: {
  value: Date | undefined;
  onValueChange: (date: Date | undefined) => void;
  name: string;
  required?: boolean;
  label?: string;
  tabIndex?: number;
  schema?: any;
  hasAttemptedSave?: boolean;
}) {
  const data = JSON.parse(
    JSON.stringify(transformToApiContract({ [name]: value })),
  );
  const hasErrors = schema
    ? !(schema as any).pick({ [name]: true }).safeParse(data).success
    : false;

  return (
    <div className="grid items-center gap-1.5">
      {label && (
        <Label
          htmlFor={name}
          className="flex flex-row justify-between text-base items-center"
        >
          <span className="font-semibold">{label}</span>
          {required ? (
            <span
              className="text-xs font-light"
              style={{ fontStyle: "italic" }}
            >
              Required
            </span>
          ) : (
            <span></span>
          )}
        </Label>
      )}
      <DatePicker
        tabIndex={tabIndex}
        value={value}
        onChange={(date) => {
          return onValueChange(date);
        }}
        placeholder={"MM/DD/YYYY"}
        className={cn(
          "rounded-md border-0 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6 w-full",
          hasAttemptedSave && hasErrors
            ? "ring-red-500 focus:ring-red-500"
            : "",
        )}
      />
    </div>
  );
}

function BearFloatInput({
  className,
  disabled,
  value,
  onChange,
  name,
  min,
  max,
  required,
  step,
  label,
  tabIndex,
  schema,
  hasAttemptedSave,
}: {
  className?: string;
  value: string;
  onChange: (item: string | number) => void;
  name: string;
  min?: string;
  max?: string;
  required?: boolean;
  step?: string;
  label?: string;
  disabled?: boolean;
  tabIndex?: number;
  schema?: any;
  hasAttemptedSave?: boolean;
}) {
  const data = JSON.parse(
    JSON.stringify(transformToApiContract({ [name]: value })),
  );

  const passesPattern = value
    ? /^-?\d*\.?\d+$/.test(value ? `${value}` : "")
    : true;
  const hasErrors = schema
    ? !(schema as any).pick({ [name]: true }).safeParse(data).success ||
      !passesPattern
    : false;

  return (
    <div className={cn("grid items-center gap-1.5", className)}>
      {label && (
        <Label
          htmlFor={name}
          className="flex flex-row justify-between text-base items-center"
        >
          <span className="font-semibold">{label}</span>
          {required ? (
            <span
              className="text-xs font-light"
              style={{ fontStyle: "italic" }}
            >
              Required
            </span>
          ) : (
            <span></span>
          )}
        </Label>
      )}
      <Input
        tabIndex={tabIndex}
        disabled={disabled}
        value={value ? `${value}` : ""}
        id={name}
        type="text"
        onChange={(event: any) =>
          onChange(event.target.value?.replace(/[^\-0-9\.\$\(\) ]+/gim, ""))
        }
        className={cn(
          "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6",
          hasAttemptedSave && hasErrors
          ? "ring-red-500 focus:ring-red-500"
          : "",
        )}
        onFocus={handleFocus}
      />
    </div>
  );
}

function BearIntInput({
    placeholder,
  value,
  onChange,
  name,
  min,
  max,
  required,
  step,
  label,
  tabIndex,
  schema,
  hasAttemptedSave,
}: {
    placeholder?: string;
  value: number;
  onChange: (item: number) => void;
  name: string;
  min?: string;
  max?: string;
  required?: boolean;
  step?: string;
  label?: string;
  tabIndex?: number;
  schema: any;
  hasAttemptedSave: boolean;
}) {
  const data = JSON.parse(
    JSON.stringify(transformToApiContract({ [name]: value })),
  );
  const hasErrors = !(schema as any).pick({ [name]: true }).safeParse(data)
    .success;

  return (
    <div className="grid items-center gap-1.5">
      {label && (
        <Label
          htmlFor={name}
          className="flex flex-row justify-between text-base items-center"
        >
          <span className="font-semibold">{label}</span>
          {required ? (
            <span
              className="text-xs font-light"
              style={{ fontStyle: "italic" }}
            >
              Required
            </span>
          ) : (
            <span></span>
          )}
        </Label>
      )}
      <Input
        value={value}
        id={name}
        type="number"
        required={required}
        min={min}
        max={max}
        step={step}
        onChange={parseIntEvent(onChange)}
        className={cn(
          "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6",
          hasAttemptedSave && hasErrors
            ? "ring-red-500 focus:ring-red-500"
            : "",
        )}
        onFocus={handleFocus}
        tabIndex={tabIndex}
          placeholder={placeholder}
      />
    </div>
  );
}

function BearInput({
    placeholder,
  value,
  onChange,
  name,
  required,
  label,
  tabIndex,
  className,
  schema,
  hasAttemptedSave,
}: {
placeholder?: string;
  value: string;
  onChange: (event: any) => void;
  name: string;
  required?: boolean;
  label?: string;
  tabIndex?: number;
  className?: string;
  schema?: any;
  hasAttemptedSave?: boolean;
}) {
  const data = JSON.parse(
    JSON.stringify(transformToApiContract({ [name]: value })),
  );
  const hasErrors = schema
    ? !(schema as any).pick({ [name]: true }).safeParse(data).success
    : false;

  return (
    <div className="grid items-center gap-1.5">
      {label && (
        <Label
          htmlFor={name}
          className="flex flex-row justify-between text-base items-center"
        >
          <span className="font-semibold">{label}</span>
          {required ? (
            <span
              className="text-xs font-light"
              style={{ fontStyle: "italic" }}
            >
              Required
            </span>
          ) : (
            <span></span>
          )}
        </Label>
      )}
      <Input
        value={value}
        id={name}
        type="text"
        required={required}
        onChange={onChange}
        className={cn(
          "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6",
          hasAttemptedSave && hasErrors
            ? "ring-red-500 focus:ring-red-500"
            : "",
        )}
        onFocus={handleFocus}
        tabIndex={tabIndex}
          placeholder={placeholder}
      />
    </div>
  );
}

function parseIntEvent(onChange: (item: number) => void) {
  return function parseIntEventHelper(event: any) {
    try {
      return onChange(parseInt(event.target.value));
    } catch (e) {
      return onChange(0);
    }
  };
}

function parseFloatNumber(onChange: (item: string | number) => void) {
  return function parseFloatEventHelper(event: any) {
    try {
      return onChange(parseFloat(event.target.value || ""));
    } catch (e) {
      return onChange(event.target.value || "");
    }
  };
}

function transformToApiPosition(item: Record<string, any>): any {
  if (!!item["commodity"]) {
    item["commodity"] = item["commodity"]?.toLocaleUpperCase();
  }

  const result =  Object.entries(item).reduce(
    (acc, [key, value]) => {
      if (value === "") {
        acc[key] = undefined;
      } else if (
        typeof value === "number" ||
        posNumberKeys.includes(key)
      ) {
        try {
          acc[key] = new Decimal(value);
        } catch (error: any) {
          acc[key] = undefined;
        }
      } else if (!!value && key?.toLocaleLowerCase()?.includes("date")) {
          if (typeof value === "object") {
              acc[key] = formatDateWithoutTimezoneToDate(value);
          } else {
              acc[key] = formatDateWithoutTimezoneToDate(new Date(value));
          }
      }else if (value === null) {
        acc[key] = undefined;
      } else {
        acc[key] = value;
      }
      return acc;
    },
    {} as Record<string, any>,
  );

    return {
        ...result,
        contract: result.commodity,
        price: new Decimal(0),
        premium: new Decimal(0),
    }
}

function transformGenericApiData(item: Record<string, any>): any {
  return Object.entries(item).reduce(
    (acc, [key, value]) => {
      if (value === "") {
        acc[key] = undefined;
      } else if (!!value && key?.toLocaleLowerCase()?.includes("date")) {
          if (typeof value === "object") {
              acc[key] = formatDateWithoutTimezoneToDate(value);
          } else {
              acc[key] = formatDateWithoutTimezoneToDate(new Date(value));
          }
      } else if (value === null) {
        acc[key] = undefined;
      } else {
        acc[key] = value;
      }
      return acc;
    },
    {} as Record<string, any>,
  );
}

function transformToApiContract(item: Record<string, any>): any {
    if (!!item["commodity"]) {
    item["commodity"] = item["commodity"]?.toLocaleUpperCase();
  }

  return Object.entries(item).reduce(
    (acc, [key, value]) => {
      if (value === "") {
        acc[key] = undefined;
      } else if (typeof value === "number"  && key === "crop_year") {
        acc[key] = value;
      } else if (
        typeof value === "number" ||
        contractNumberKeys.includes(key)
      ) {
        try {
          acc[key] = new Decimal(value);
        } catch (error: any) {
          acc[key] = undefined;
        }
      } else if (!!value && key?.toLocaleLowerCase()?.includes("date")) {
          if (typeof value === "object") {
              acc[key] = formatDateWithoutTimezoneToDate(value);
          } else {
              acc[key] = formatDateWithoutTimezoneToDate(new Date(value));
          }
      }else if (value === null) {
        acc[key] = undefined;
      } else {
        acc[key] = value;
      }
      return acc;
    },
    {} as Record<string, any>,
  );
}

function ignoreSafeParseDecimal(item: string | number): Decimal {
  try {
    return new Decimal(item);
  } catch (error: any) {
    return new Decimal(0);
  }
}

function downloadCSV(filename: string, csvContent: string, clearData?: any) {
  return function downloadCSVHelper() {
    // Create a Blob
    const blob = new Blob([csvContent], { type: "text/csv" });

    // Create a link element
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute("download", filename);

    // Append the link to the body
    document.body.appendChild(link);

    // Programmatically click the link to trigger the download
    link.click();

    // Remove the link from the body
    document.body.removeChild(link);
  };
}

function MarkPositionFullyPairedContractButton({ buttonClassName, id, refetchPositions, markStatus, message, buttonLabel }: { id: string; refetchPositions: any; markStatus: boolean; message: string; buttonLabel: string; buttonClassName?: string }) {
    const { toast } = useToast();
  const [isArchiving, setIsArchiving] = useState(false);
  const markFullyPaired = useMarkPositionManualFullyPaired();

  const onArchive = async (id: string) => {
    if (isArchiving) return;
    setIsArchiving(true);

    const result = await markFullyPaired({ position_id: id, is_fully_paired: markStatus });
    setIsArchiving(false);

    if (!result.success) {
      toast({
        title: "Failed to mark",
        description: "",
      });
    } else {
        refetchPositions()
        toast({
            title: message,
            description: "",
        });
    }
  };

  return (
    <Button
      className={buttonClassName}
      variant="outline"
      onClick={() => onArchive(id)}
      disabled={isArchiving}
    >
      <span className="sr-only">{buttonLabel}</span>
      {isArchiving ? <Loader /> : <span>{buttonLabel}</span>}
    </Button>
  );
}

function MarkContractFullyPairedContractButton({ id, refetchContracts, markStatus, show }: { id: string; refetchContracts: any; markStatus: boolean; show: boolean }) {
  const { toast } = useToast();
  const [isArchiving, setIsArchiving] = useState(false);
  const markFullyPaired = useMarkContractManualFullyPaired();

  if (!show) return null;

  const onArchive = async (id: string) => {
    if (isArchiving) return;
    setIsArchiving(true);

    const result = await markFullyPaired({ contract_id: id, is_fully_paired: markStatus });
    setIsArchiving(false);

    if (!result.success) {
      toast({
        title: "Failed to mark paired",
        description: "",
      });
    } else {
        refetchContracts()
        toast({
            title: "Marked Paired",
            description: "",
        });
    }
  };

  return (
    <Button
      variant="outline"
      onClick={() => onArchive(id)}
      disabled={isArchiving}
    >
      <span className="sr-only">{markStatus ? "Mark Paired" : "Mark Un-Paired"}</span>
      {isArchiving ? <Loader /> : <span>{
        markStatus ? "Mark Paired" : "Mark Un-Paired"
      }</span>}
    </Button>
  );

}

function ArchivePositionButton({ id, refetchPositions }: { id: string; refetchPositions: any }) {
    const { toast } = useToast();
  const [isArchiving, setIsArchiving] = useState(false);
  const archivePosition = useArchivePosition();

  const onArchive = async (id: string) => {
    if (isArchiving) return;
    setIsArchiving(true);

    const result = await archivePosition(id);
    setIsArchiving(false);

    if (!result.success) {
      toast({
        title: "Failed to archive position",
        description: "",
      });
    } else {
        refetchPositions();
        toast({
            title: "Archived",
            description: "",
        });
    }
  };

  return (
    <Button
      variant="ghost"
      className="h-8 w-8 p-0"
      onClick={() => onArchive(id)}
      disabled={isArchiving}
    >
      <span className="sr-only">Archive</span>
      {isArchiving ? <Loader /> : <ArchiveXIcon className="h-4 w-4" />}
    </Button>
  );
}

function ArchiveContractButton({ id, refetchContracts }: { id: string; refetchContracts: any }) {
    const { toast } = useToast();
  const [isArchiving, setIsArchiving] = useState(false);
  const archiveContract = useArchiveContract();

  const onArchive = async (id: string) => {
    if (isArchiving) return;
    setIsArchiving(true);

    const result = await archiveContract(id);
    setIsArchiving(false);

    if (!result.success) {
      toast({
        title: "Failed to archive contract",
        description: "",
      });
    } else {
        refetchContracts();
        toast({
            title: "Archived",
            description: "",
        });
    }
  };

  return (
    <Button
      variant="ghost"
      className="h-8 w-8 p-0"
      onClick={() => onArchive(id)}
      disabled={isArchiving}
    >
      <span className="sr-only">Archive</span>
      {isArchiving ? <Loader /> : <ArchiveXIcon className="h-4 w-4" />}
    </Button>
  );
}

function generateTotalMargin(openContracts: TradingContract[]) {
  const amount = openContracts.reduce((acc, contract) => {
    return acc.plus(contract.margin_amount);
  }, new Decimal(0));
  const currencyAmount = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  })
    .format(amount.abs().toNumber())
    .replace("$", "");
  return amount.isNegative() ? `$ (${currencyAmount})` : `$ ${currencyAmount}`;
}

function getSchemaForType(isUpdateForm: boolean, contract_type: string) {
  switch (contract_type) {
    case ContractType.Range:
      return isUpdateForm
        ? transformSchemaToOptionalNullish(RangeValidation)
        : RangeValidation;
    case ContractType.MinMaxWDU:
      return isUpdateForm
        ? transformSchemaToOptionalNullish(MinMaxDoubleUpValidation)
        : MinMaxDoubleUpValidation;
    case ContractType.MinMax:
      return isUpdateForm
        ? transformSchemaToOptionalNullish(MinMaxValidation)
        : MinMaxValidation;
    case ContractType.PremiumOfferWDU:
      return isUpdateForm
        ? transformSchemaToOptionalNullish(PremiumValidation)
        : PremiumValidation;
    case ContractType.MinPriceCallSpread:
      return isUpdateForm
        ? transformSchemaToOptionalNullish(MinPriceValidation)
        : MinPriceValidation;
    case ContractType.MinimumPrice:
      return isUpdateForm
        ? transformSchemaToOptionalNullish(MinimumPriceValidation)
        : MinimumPriceValidation;
    case ContractType.AccWeeklyDU:
      return isUpdateForm
        ? transformSchemaToOptionalNullish(AccumulatorValidation)
        : AccumulatorValidation;
    case ContractType.ReOwnWDU:
      return isUpdateForm
        ? transformSchemaToOptionalNullish(ReOwnWDUValidation)
        : ReOwnWDUValidation;
    default:
      return RangeValidation;
  }
}

function transformSchemaToOptionalNullish(schema: any) {
  return schema.merge(z.object({
    step_one: z.string().min(1).optional(),
    step_two: z.string().min(1).optional(),
    step_three: z.string().min(1).optional(),
  }));
}

function clearFieldsOnContractTypeChange(contract_type?: string) {
  if (!contract_type) return {};
  const type_mapping: Record<string, string[]> = {
    [ContractType.Range]: ["step_three", "step_two", "step_one"],
    [ContractType.ReOwnWDU]: ["step_three", "step_two", "step_one", "option_month_etc_one"],
    [ContractType.MinMax]: ["step_three", "step_one"],
    [ContractType.MinMaxWDU]: ["step_three", "step_two", "step_one", "option_month_etc_one"],
    [ContractType.PremiumOfferWDU]: [
      "step_three",
      "otc_futures",
      "futures_price",
      "account_no_b",
      "option_month_etc_one",
    ],
    [ContractType.MinPriceCallSpread]: ["step_one", "step_three"],
    [ContractType.MinimumPrice]: ["step_three"],
    [ContractType.AccWeeklyDU]: [
      "start_date",
      "weeks",
      "period_qty",
      "knockout",
      "step_one",
      "option_month_etc_one",
    ],
  };

  if (!type_mapping[contract_type]) return {};

  const clear_values = Object.entries(type_mapping).reduce((acc, entry) => {
    const [key, values] = entry;
    if (key === contract_type) return acc;

    values.forEach((valKey: string) => {
      acc = {
        ...acc,
        [valKey]: undefined,
      } as any;
    });

    return acc;
  }, {});

  type_mapping[contract_type].forEach((key: string) => {
    delete (clear_values as any)[key];
  });

  return clear_values;
}

function mapToTableContract({
  AccountTypeOptions,
  customers,
}: {
  AccountTypeOptions: SelectOptionItem<string>[];
  customers: Customer[];
}) {
  return function mapToTableContractHelper(contract: TradingContract) {
    return {
      ...contract,
      options_account: {
        no: AccountTypeOptions.find(
          ({ value, label }: SelectOptionItem<string>) => {
            return (
              value?.toLocaleLowerCase() ===
              contract.account_no_a?.toLocaleLowerCase()
            );
          },
        )?.label,
      },
      futures_account: {
        no: AccountTypeOptions.find(
          ({ value, label }: SelectOptionItem<string>) => {
            return (
              value?.toLocaleLowerCase() ===
              contract.account_no_b?.toLocaleLowerCase()
            );
          },
        )?.label,
      },
      date_label: moment
        .tz(contract.date, CommonDestinationTimezone)
        .format("MM/DD/YYYY"),
      start_date_label: createDateLabel(contract.start_date),
      end_date_label: createDateLabel(contract.end_date),

      shipment_start_date_label: createDateLabel(contract.shipment_start_date),
      shipment_end_date_label: createDateLabel(contract.shipment_end_date),

      commodity_label:
        CommodityOptions.find((option: SelectOptionItem<string>) => {
          return (
            option.value?.toLocaleLowerCase() ===
            contract.commodity?.toLocaleLowerCase()
          );
        })?.label || contract.commodity,
      contract: {
        type: ContractTypeOptions.find(
          ({ value, label }: SelectOptionItem<string>) => {
            return (
              value?.toLocaleLowerCase() ===
              contract.contract_type?.toLocaleLowerCase()
            );
          },
        )?.label,
      },
      customer: customers.find(
        (customer) => customer.id === contract.customer_id,
      ),
    };
  };
}

function createAccountListOptions(contract_type: ContractType | undefined) {
 return function createAccountListHelper(accountsList: TradingAccount[]) {
   return accountsList.filter((account: TradingAccount) => {
     switch(contract_type || "") {
       case ContractType.PremiumOfferWDU:
         return ["AA052","AA055","AA058","AA060"].includes(account.account_id);
       default:
         return !["AA052","AA055","AA058","AA060"].includes(account.account_id);
     }

   }).map((account: TradingAccount) => ({
      value: account.id,
      label: generateAccountLabel(account.account_id) || account.account_id,
    }));
 };
}
