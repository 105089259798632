import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGetCreateJobStatus, useCreatePreliminaryUpload } from "../../lib/api/dorman";
import { FormGridHelper } from "../reports/helper";
import {
    InputRequiredStatus,
    InputFormType,
    InputType,
    ImportType,
    ExportType,
    InputFilter,
    InputRequiredStatusType,
} from "../../lib/form";
import * as FormHelper from "../../lib/form";
import { UploadPresignedFile } from "../../lib/file";
import * as FormLayout from "../../lib/form/layout";
import {
    PrimitiveGridLayout,
    PrimitiveManagedTable,
    PrimitiveDialogForm,
} from "../../lib/form/layout";
import { SuccessResult, ServerError, ValidationError } from "../../lib/responseResults";
import { useToast } from "../../components/ui/use-toast";
import { Loader } from "../../components/ui/loader";

export const CreateJobFormSchema = {
    dorman_text_file: { label: "Dorman Preliminary Txt", fileExtensions: ["text/plain"], input_type: InputType.File, input_type_validation: InputFormType.File, required_status: InputRequiredStatus.Required },
    tt_excel_file: { label: "TT Preliminary Excel", fileExtensions: [".xlsx", ".xls"], input_type: InputType.File, input_type_validation: InputFormType.File, required_status: InputRequiredStatus.Optional },
};

export function DormanPreliminarySection() {
    const [jobId, setJobId] = useState<string | undefined>(undefined);
    const jobStatusRes = useGetCreateJobStatus(jobId);

    const hasFetchedJob = !!jobStatusRes?.data;
    const jobStatus = jobStatusRes?.data?.data?.job_status;

    return (
        <div className="flex flex-col">
            <h1 className="scroll-m-20 text-2xl font-extrabold tracking-tight lg:text-3xl mb-4">
                Dorman Preliminary Upload
            </h1>
            <CreateJobForm setJobId={setJobId} />
            <div className="mt-8 flex flex-col">
              { !!hasFetchedJob && (
                  <JobStatus jobStatus={jobStatus} navTo="/tickets/dorman/staged"/>
              )}
            </div>
        </div>
    );
}

function JobStatus({ jobStatus, navTo }: { jobStatus: string; navTo: string }) {
  const navigate = useNavigate();

    const className = "text-lg font-bold tracking-tight mb-4";

    if (!jobStatus) { return (<div className={className}><Loader /></div>) }
    if (jobStatus === "queued") { return (<div className={className}>Processing</div>) }
    if (jobStatus === "doesnt_exist") { return (<div className={className}>Failed to find files</div>) }
    if (jobStatus === "failed") { return (<div className={className}>Failed to process</div>) }
    if (jobStatus === "halt") { return (<div className={className}>Failed to process</div>) }
    if (jobStatus !== "completed") { return (<div className={className}>Unknown</div>); }

    navigate(navTo);
    return (<div className={className}>Redirecting</div>);
}

const createJobState = FormHelper.generateBaseState({
    schemas: [CreateJobFormSchema],
    baseSchema: CreateJobFormSchema,
});


function CreateJobForm({ setJobId }: { setJobId: any }) {
    const inputState: any = createJobState.useInputsState((state: any) => state);
    const submissionState: any = createJobState.useSubmissionState((state: any) => state);
    const onCreateJob = useCreatePreliminaryUpload();
    const { toast } = useToast();

    return FormLayout.RenderFullPrimitiveGridLayout({
        hideSkipButton: true,
        id: "CreateDormanPreliminaryJob",
        activeSchema: CreateJobFormSchema,
        totalFields: inputState.totalFields,
        formValues: inputState.formValues,
        updateFormValue: inputState.updateFormValue,
        formRelationships: inputState.formRelationships,
        updateFormRelationship: inputState.updateFormRelationship,
        submissionState,
        externalReferences: {},
        onSubmitTo: async (item: any) => {
            if (!item.dorman_text_file?.name?.toLocaleLowerCase()?.endsWith(".txt")) {
                toast({
                    title: "Invalid Dorman File",
                    description: "Must be a TXT file",
                });
                return new ValidationError("", {});
            }
            if (item.tt_excel_file && ![".xlsx", ".xls"].some((extension: string) => item.tt_excel_file?.name?.toLocaleLowerCase()?.endsWith(extension))) {
                toast({
                    title: "Invalid TT File",
                    description: "Must be an Excel file",
                });
                return new ValidationError("", {});
            }

            const createResult = await onCreateJob({
                txtFilename: item.dorman_text_file?.name || "",
                ttFilename: item.tt_excel_file?.name,
            });

            if (!createResult.success) {
                return createResult;
            }

            const resultData = createResult.data;
            setJobId(resultData.queuedJobId);

            await UploadPresignedFile(resultData.txtPresignUrl, item.dorman_text_file);
            if (item.tt_excel_file) {
                await UploadPresignedFile(resultData.excelPresignUrl, item.tt_excel_file);
            }

            return createResult;
        },
        handleSubmitResponse: (result) => {
            if (result.statusCode === 400) {
                return;
            } else if (!result.success) {
                toast({
                    title: "Failed to upload files",
                    description: "",
                });
            } else {
                toast({
                    title: "Queued - Please wait.",
                    description: "You will be redirected after processing.",
                });
            }
        }
    });
}
