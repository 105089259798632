import { useQuery, useQueryClient, QueryKey } from "@tanstack/react-query";
import Decimal from "decimal.js";
import { BaseDbObject } from "../models/db";
import { Customer } from "./reports";
import { z } from "zod";
import moment, { Moment } from "moment-timezone";
import {
  fetchApiDelete,
  fetchApiGet,
  fetchApiPost,
  fetchApiPut,
} from "./utils";
import {
  PossibleReturnType,
} from "../responseResults";
import { CommonDestinationTimezone } from "../utils";
import { formatDateWithoutTimezone } from "./date";
import { DbUser } from "../models/index";

export enum ContactType {
    Lighthouse = 'lhc'
}

export enum StaffType {
    ClientExperienceManager = 'client_experience_manager',
    AccountingStaff = 'accounting_staff',
    PaymentsAccountsPayable = 'payments_accounts_payable',
    FreightStaff = 'freight_staff',
    TradingStaff = 'trading_staff',
}

export enum StaffSubType {
    Tickets = 'tickets',
    Settlements = 'settlements',
}

export interface Contact {
    name: string;
    email?: string;
    phone_number?: string;

    contact_type: ContactType;
    staff_type?: StaffType;
    staff_sub_type?: StaffSubType;
}

export function useListContacts() {
  const queryParams = new URLSearchParams();

  return useQuery({
    queryKey: ["contact", queryParams.toString()],
    queryFn: () =>
      fetchApiGet<Contact[]>("contact", queryParams),
    retry: 1,
  });
}


export function useCreateContact() {
  const queryClient = useQueryClient();

  return async (params: any) => {
    const queryParams = new URLSearchParams();

    const result = await fetchApiPost<Contact>(
      `contact`,
      params,
      queryParams,
    );
    queryClient.invalidateQueries({ queryKey: ["contact"] });
    return result;
  };
}

export function useUpdateContact() {
  const queryClient = useQueryClient();

  return async (params: any) => {
    const queryParams = new URLSearchParams();

    const result = await fetchApiPut<Contact>(
      `contact/${params.id}`,
      params,
      queryParams,
    );
    queryClient.invalidateQueries({ queryKey: ["contact"] });
    return result;
  };
}

export function useDeleteContact() {
  const queryClient = useQueryClient();

  return async (params: any) => {
    const queryParams = new URLSearchParams();

    const result = await fetchApiDelete<boolean>(
      `contact/${params.id}`,
      queryParams,
    );

    queryClient.invalidateQueries({ queryKey: ["contact"] });

    return result;
  };
}

export const contactTypeOptions = [
  { value: ContactType.Lighthouse, label: "Lighthouse" },
];

export const staffTypeOptions = [
  { value: StaffType.ClientExperienceManager, label: "Client Experience Manager" },
  { value: StaffType.AccountingStaff, label: "Accounting Staff" },
  { value: StaffType.PaymentsAccountsPayable, label: "Payments Accounts Payable" },
  { value: StaffType.FreightStaff, label: "Freight Staff" },
  { value: StaffType.TradingStaff, label: "Merchandising Staff" },
];

export const staffSubTypeOptions = [
  { value: StaffSubType.Tickets, label: "Tickets" },
  { value: StaffSubType.Settlements, label: "Settlements" },
];
