export function downloadUsingATag(url: string, fileName: string) {
  const a = document.createElement("a");
  a.href = url;
  a.download = fileName;

  document.body.appendChild(a);

  a.click();

  document.body.removeChild(a);
}

export function downloadCsvTextAsFile(filename: string, text: string) {
  var element = document.createElement("a");

  element.setAttribute("id", filename);
  element.setAttribute(
    "href",
    "data:text/csv;charset=utf-8," + encodeURIComponent(text),
  );
  element.setAttribute("download", filename);

  element.style.display = "none";
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

export function downloadBinaryData(
  data: any,
  fileName: string,
  mimeType: string,
) {
  const blob = new Blob([data], { type: mimeType });
  const url = URL.createObjectURL(blob);

  const a = document.createElement("a");
  a.href = url;
  a.download = fileName;
  a.style.display = "none";
  document.body.appendChild(a);
  a.click();

  // Cleanup
  window.addEventListener(
    "focus",
    () => {
      URL.revokeObjectURL(url);
      document.body.removeChild(a);
    },
    { once: true },
  );
}
