import Fuse from "fuse.js";
import { Customer, Trader, TraderCustomerBulk } from "./api/reports";
import { CommonDestinationTimezone } from "./utils";

export function findCustomerFrom(
  source: string,
  description: string,
  customers: Customer[],
) {
  const item = description.toLocaleLowerCase()?.trim().replace("total", "");

  const base = customers.find((customer) => {
    if (
      /^\d{1}[A-Z0-9]{3,10} .*$/gim.test(item) &&
      item.split(" ")[0] === customer.client_id?.toLocaleLowerCase()?.trim()
    ) {
      return true;
    }

    return (
      item === customer.client_id?.toLocaleLowerCase()?.trim() ||
      item === customer.short_name.toLocaleLowerCase()?.trim() ||
      item === customer.long_name.toLocaleLowerCase()?.trim() ||
      item ===
        customer.long_name.toLocaleLowerCase()?.trim()?.replace("and", "&") ||
      item ===
        `${customer.long_name.toLocaleLowerCase()?.trim()} llc` ||
      item ===
        `${customer.long_name.toLocaleLowerCase()?.trim()} inc`
    );
  });

  if (!base) {
    const fuzzyResult = searchFuzzyCustomers(description, customers);
    if (fuzzyResult) {
      return fuzzyResult;
    }
  }

  return base;
}


export function findTraderForCustomer(
  customer: Customer,
  traderInfo: TraderCustomerBulk,
) {
  const traderCustomer = traderInfo.traderCustomers.find(
    (pair) => pair.customer_id === customer.id,
  );
  return traderInfo.traders.find(
    (trader) => trader.id === traderCustomer?.trader_id,
  );
}

export function searchFuzzyCustomers(description: string, customers: Customer[]) {
  const commonOptions = {
    shouldSort: true,
    includeMatches: false,
    threshold: 0.05,
    location: 0,
    distance: 5,
    maxPatternLength: 32,
    minMatchCharLength: 1,
    findAllMatches: false,
    includeScore: true,
    isCaseSensitive: false,
    keys: ["long_name", "short_name"],
  };

  const foundCustomer = customers.find((customer: any) => customer.client_id === description || customer.long_name === description);
  if (foundCustomer) {
    return foundCustomer;
  }

  const a = attemptDifferentDescriptionBases(
    description,
    customers,
    commonOptions,
  );
  if (a) return a;

  const b = attemptDifferentDescriptionBases(description, customers, {
    ...commonOptions,
    distance: 15,
  });
  if (b) return b;

  const c = attemptDifferentDescriptionBases(description, customers, {
    ...commonOptions,
    distance: 15,
    threshold: 0.1,
  });
  if (c) return c;

  const d = attemptDifferentDescriptionBases(description, customers, {
    ...commonOptions,
    distance: 20,
    threshold: 0.2,
  });
  if (d) return d;
}

function attemptDifferentDescriptionBases(
  description: string,
  customers: Customer[],
  options: any,
) {
  let a = new Fuse(customers, options).search(description);
  if (a.length === 1 && a[0].score! < 0.15) {
    return a[0].item;
  }

  let b = new Fuse(customers, options).search(description?.replace("and", "&"));
  if (b.length === 1 && b[0].score! < 0.15) {
    return b[0].item;
  }

  let c = new Fuse(customers, options).search(description?.replace("&", "and"));
  if (c.length === 1 && c[0].score! < 0.15) {
    return c[0].item;
  }

  if (a.length === 1 && a[0].score! < 0.39) {
    return a[0].item;
  }
}
