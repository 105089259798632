import { useStytchMember } from "@stytch/react/b2b";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useState } from "react";
import {
  Link as LinkIcon,
  DownloadCloud,
  Trash2,
  Plus,
  MoreHorizontal,
} from "lucide-react";
import moment from "moment-timezone";
import { Link } from "react-router-dom";
import { Textarea } from "../../../components/ui/textarea";
import {
  useDownloadAtsArchive,
  useListRecentPeriods,
  useDownloadAtsPeriods,
  AtsSession,
  CommonDestinationTimezone,
  AtsAppEnvironmentType,
  AtsSessionAppType,
} from "../../../lib/api/trading";
import {
  TicketRunSummary,
  TicketsSummary,
  Provider,
} from "../../../lib/models/index";
import {
  TicketProviderSummary,
  TicketProviderRecentRunPartial,
  TicketProviderBatchDownloadPartial,
} from "../../../lib/models/tickets";
import { S3ObjectItem } from "../../../lib/models/trading";
import { ProviderType } from "../../../lib/models/provider";
import { Button } from "../../../components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../../components/ui/dropdown-menu";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../../components/ui/tabs";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../components/ui/table";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../../components/ui/accordion";
import { Badge } from "../../../components/ui/badge";
import { Checkbox } from "../../../components/ui/checkbox";
import { SelectInput } from "../../../components/ui/SelectInput";
import { Skeleton } from "../../../components/ui/skeleton";
import { useToast } from "../../../components/ui/use-toast";
import { DataTable, DataTableProps } from "../../../components/ui/dataTable";
import { downloadUsingATag } from "../../../lib/browser/download";
import { Loader } from "../../../components/ui/loader";

export function AtsDashboardPage() {
  const recentPeriodsResult = useListRecentPeriods();

  if ([recentPeriodsResult].some((result) => result.isLoading)) {
    return (
      <div className="flex flex-col space-y-2">
        <Skeleton className="h-4 w-[250px]" />
        <Skeleton className="h-4 w-[200px]" />
      </div>
    );
  }

  if (
    [recentPeriodsResult].some(
      (result) => result.error || !result?.data?.success,
    )
  ) {
    return (
      <div className="flex flex-col space-y-2">
        Failed to recent ATS price bars. Please refresh and try again
      </div>
    );
  }

  return (
    <AtsDashboardView
      recentSessions={[
        ...(recentPeriodsResult?.data?.data || []),
        {
          id: "bcac8cb2-f3cf-4c53-9e98-b43b1e8813bb:@LEM4",
          app_type: AtsSessionAppType.NonLive,
          app_environment: AtsAppEnvironmentType.Simulation,
          GlobalState: {
            algo_start_date: "2024-04-09T17:00:00",
          },
        } as any,
        {
          id: "bcac8cb2-f3cf-4c53-9e98-b43b1e8813bb:@SBK4",
          app_type: AtsSessionAppType.NonLive,
          app_environment: AtsAppEnvironmentType.Simulation,
          GlobalState: {
            algo_start_date: "2024-04-09T17:00:00",
          },
        } as any,
        {
          id: "bcac8cb2-f3cf-4c53-9e98-b43b1e8813bb:@CK4",
          app_type: AtsSessionAppType.NonLive,
          app_environment: AtsAppEnvironmentType.Simulation,
          GlobalState: {
            algo_start_date: "2024-04-09T17:00:00",
          },
        } as any,
      ]}
    />
  );
}

export function AtsDashboardView({
  recentSessions,
}: {
  recentSessions: AtsSession[];
}) {
  const dashboardColumns: ColumnDef<any>[] = [
    {
      id: "archiveDate",
      header: "Date",
      cell: ({ row }) => <span className="">{row.original.label}</span>,
      enableSorting: false,
      enableHiding: false,
    },
    {
      id: "actions",
      header: "Download",
      cell: ({ row }) => (
        <div className="flex flex-row">
          <DownloadArchive archive={row.original} />
        </div>
      ),
    },
  ];

  const sessionPeriodsColumns: ColumnDef<any>[] = [
    {
      id: "product",
      header: "Product",
      cell: ({ row }) => (
        <span className="">{generateProductName(row.original.id)}</span>
      ),
      enableSorting: false,
      enableHiding: false,
    },
    {
      id: "appType",
      header: "App Type",
      cell: ({ row }) => (
        <span className="">{generateAppTypeName(row.original.app_type)}</span>
      ),
      enableSorting: false,
      enableHiding: false,
    },
    {
      id: "environment",
      header: "Environment",
      cell: ({ row }) => (
        <span className="">
          {generateEnvName(row.original.app_environment)}
        </span>
      ),
      enableSorting: false,
      enableHiding: false,
    },
    {
      id: "sessionStartDate",
      header: "Start Date",
      cell: ({ row }) => (
        <span className="">
          {row.original.GlobalState?.algo_start_date
            ? moment
                .tz(
                  row.original.GlobalState?.algo_start_date,
                  CommonDestinationTimezone,
                )
                .format("MMM DD, YY HH:mm a")
            : ""}
        </span>
      ),
      enableSorting: false,
      enableHiding: false,
    },
    {
      id: "actions",
      header: "Download",
      cell: ({ row }) => (
        <div className="flex flex-row">
          <DownloadPeriodArchive session={row.original} />
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="container flex flex-col mb-2">
        <h1 className="scroll-m-20 text-2xl font-extrabold tracking-tight lg:text-3xl">
          ATS Periods Dashboard
        </h1>
      </div>
      <div className="flex items-start flex-col h-full gap-4">
        <div className="container flex flex-col">
          <DataTable columns={sessionPeriodsColumns} data={recentSessions} />
        </div>
      </div>
    </>
  );
}

function DownloadPeriodArchive({ session }: { session: AtsSession }) {
  const { toast } = useToast();
  const [isDownloading, setIsDownloading] = useState(false);
  const [downloadData, setDownloadData] = useState<string[]>([]);
  const downloadPeriods = useDownloadAtsPeriods();

  const onDownload = async (sessionId: string, app_type: AtsSessionAppType) => {
    if (isDownloading) return;
    setIsDownloading(true);

    const result = await downloadPeriods(sessionId, app_type);
    setIsDownloading(false);

    if (!result.success) {
      toast({
        title: "Failed to download periods",
        description: "",
      });
    } else {
      setDownloadData(result.data);
    }
  };

  function downloadCSV(
    sessionId: string,
    filename: string,
    csvContent: string,
  ) {
    return function downloadCSVHelper() {
      // Create a Blob
      const blob = new Blob([csvContent], { type: "text/csv" });

      // Create a link element
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.setAttribute("download", filename);

      // Append the link to the body
      document.body.appendChild(link);

      // Programmatically click the link to trigger the download
      link.click();

      // Remove the link from the body
      document.body.removeChild(link);
    };
  }

  const fetchDate = moment.tz(CommonDestinationTimezone).format("MM/DD/YY");
  const productName = generateProductName(session.id);
  const filename = `${productName}_${fetchDate}.csv`;

  return (
    <Button
      variant="ghost"
      className="h-8 w-8 p-0"
      onClick={() => onDownload(session.id, session.app_type)}
      disabled={isDownloading}
    >
      <span className="sr-only">Download</span>
      {isDownloading ? (
        <Loader />
      ) : !downloadData?.length ? (
        <DownloadCloud className="h-4 w-4" />
      ) : (
        <Button
          variant="ghost"
          type="button"
          onClick={downloadCSV(session.id, filename, downloadData.join("\n"))}
        >
          <LinkIcon className="h-6 w-6" aria-hidden="true" />
        </Button>
      )}
    </Button>
  );
}

function DownloadArchive({ archive }: { archive: S3ObjectItem }) {
  const { toast } = useToast();
  const [isDownloading, setIsDownloading] = useState(false);
  const [downloadData, setDownloadData] = useState<
    { url: string; filename: string } | undefined
  >(undefined);
  const downloadArchive = useDownloadAtsArchive();

  const onDownload = async (key: string, filename: string) => {
    if (isDownloading) return;
    setIsDownloading(true);

    const result = await downloadArchive(key);
    setIsDownloading(false);
    if (!result.success) {
      toast({
        title: "Failed to download price bars",
        description: "",
      });
    } else {
      setDownloadData({ url: result.data.url, filename });
    }
  };

  const fetchDate = moment().format("MM/DD/YY");

  return (
    <Button
      variant="ghost"
      className="h-8 w-8 p-0"
      onClick={() =>
        onDownload(archive.key, archive.key.split("/").slice(-1)[0])
      }
      disabled={isDownloading}
    >
      <span className="sr-only">Download</span>
      {isDownloading ? (
        <Loader />
      ) : !downloadData ? (
        <DownloadCloud className="h-4 w-4" />
      ) : (
        <a
          href={downloadData.url}
          download={downloadData.filename}
          target="_blank"
        >
          <LinkIcon className="h-6 w-6" aria-hidden="true" />
        </a>
      )}
    </Button>
  );
}

function generateEnvName(app_environment: string) {
  switch (app_environment) {
    case AtsAppEnvironmentType.Live:
      return "Live";
    case AtsAppEnvironmentType.Simulation:
      return "Simulation";
    default:
      return "Unknown";
  }
}

function generateAppTypeName(app_type: string) {
  switch (app_type) {
    case AtsSessionAppType.Live:
      return "TT";
    case AtsSessionAppType.NonLive:
      return "Email";
    default:
      return "Unknown";
  }
}

function generateProductName(id: string) {
  if (id.includes(":") && id.includes("@")) {
    return id.split(":")[1];
  }

  return "ES";
}
