import ExcelJS from "exceljs";
import * as XLSX from "xlsx";
import Decimal from "decimal.js";
import moment from "moment-timezone";
import { CommonDestinationTimezone } from "../api/trading";
import { downloadBinaryData } from "../browser/download";
import { lightGreenFill, boldCell, setColumnInfo, workbookToBuffer, safeParseDecimal, parseFromBrowserFile, CellFormat } from "../excel";
import { parseFromFile as parseXlsxFromFile } from "../file";
import { SuccessResult, ServerError } from "../responseResults";
import {
  parseFromString,
    UnsettledArCanRow,
    UnsettledArUsRow,
    TrialArRow,
} from "./utils";
import { optionLetterToMonthNumber, monthNumberToOptionLetter } from "../api/otc";
import { Commodities, CommodityOptions } from "../api/commodity";
import { Customer } from "../api/reports";
import { searchFuzzyCustomers } from "../customer";
import { getPath } from "../prelude";
import {
    addImage, applyBorderToRange,
    setFontColor, setFontSize, setCellFill, setCellBorders, setCellAlignments,
    getCells, getCell, makePaperLikeSpreadsheet,
} from "../excel";

export enum ReportVariation {
  MonthEnd = "month_end",
  Standard = "standard"
}

interface UnsettledReleaseResult {
    errors: {
        missingCustomer: Record<string, any>,
        missingCurrency: Record<string, any>,

        unknownCustomer: Record<string, any>,
        unknownCurrency: Record<string, any>,
    },
    found: {
        currency: Record<string, Record<string, any>>,
        rows: any[]
    }
}

export async function processFromFiles({
    files,
}: {
    files: {
        file_trial_balance: File;
        file_unsettled_ar_us: File;
        file_unsettled_ar_can: File;
    }
},
externalReferences: { customers: Customer[]; },
options: { manualFxRate?: number; aggNonCommLimit: number; aggCommLimit: number; variation: ReportVariation; },
) {
    const parsedFiles: Record<string, any[]> = {};
    for (const pair of Object.entries(files)) {
        const [key, value] = pair;

        if (value.name.endsWith(".csv")) {
            parsedFiles[key] = parseFromString((await parseFromBrowserFile(value)).data);
        } else {
            const workbook = await parseXlsxFromFile(value);
            let sheetName = workbook.SheetNames[0]!;
            let sheet = workbook.Sheets[sheetName];
            let sheetData = XLSX.utils.sheet_to_json(sheet);

            sheetData = sheetData.map((row: any) => {
                return Object.entries(row).reduce((acc: any, [key, value]) => {
                    (acc as any)[key.trim()] = value;
                    return acc;
                }, {} as any);
            });

            parsedFiles[key] = sheetData;
        }
    }

  return await processCSVs(parsedFiles as any, externalReferences, options);
}

async function processCSVs(files: {
    file_trial_balance: TrialArRow[];
    file_unsettled_ar_us: UnsettledArCanRow[];
    file_unsettled_ar_can: UnsettledArUsRow[];
},
externalReferences: { customers: Customer[]; },
options: { manualFxRate?: number; aggNonCommLimit: number; aggCommLimit: number; variation: ReportVariation; },
) {
    const startDate = new Date();

    const unsettledUsAggregation = aggregateUnsettledAr(files.file_unsettled_ar_us, startDate, externalReferences);

    const unsettledCanAggregation = aggregateUnsettledAr(files.file_unsettled_ar_can, startDate, externalReferences);

    const trialAggregation = aggregateTrial(files.file_trial_balance, startDate, externalReferences);

    return await aggregateAndAssembleMasterReport({
        unsettledUsAggregation,
        unsettledCanAggregation,
        trialAggregation,
        externalReferences,
        options,
    });
}

function aggregateUnsettledAr(
    rows: (UnsettledArCanRow | UnsettledArUsRow)[],
    startDate: Date,
    externalReferences: { customers: Customer[]; }
) {
    return rows.reduce((acc: UnsettledReleaseResult, row: (UnsettledArCanRow | UnsettledArUsRow)) => {
        const customerDescription = row["Customer/Vendor Id"]?.trim();
        const currency = row["Currency"]?.trim();
        const net = row["Net"];

        if (!customerDescription) {
            acc.errors.missingCustomer[`ContractNo: ${row["Contract No"]}. TicketNo: ${row["Ticket No"]}`] = true;
            return acc;
        }


        if (!currency) {
            acc.errors.missingCurrency[`Customer: ${customerDescription}. ContractNo: ${row["Contract No"]}. TicketNo: ${row["Ticket No"]}}`] = true;
            return acc;
        }

        const foundCustomer = searchFuzzyCustomers(customerDescription, externalReferences.customers);
        if (!foundCustomer) {
            acc.errors.unknownCustomer[customerDescription] = true;
            return acc;
        }
        if (!["US$", "CAN$"].includes(currency)) {
            acc.errors.unknownCurrency[currency] = true;
            return acc;
        }

        const convertedCurrency: string = currency?.toLocaleUpperCase() as any;

        if (!acc.found.currency[convertedCurrency]) {
            acc.found.currency[convertedCurrency] = {};
        }

        if (!acc.found.currency[convertedCurrency][foundCustomer.long_name]) {
            acc.found.currency[convertedCurrency][foundCustomer.long_name] = { amount: new Decimal(0), isCommercial: foundCustomer.is_commercial || false };
        }

        acc.found.currency[convertedCurrency][foundCustomer.long_name].amount = acc.found.currency[convertedCurrency][foundCustomer.long_name].amount.plus(safeParseDecimal(net));

        return acc;
    }, {
        errors: {
            missingCustomer: {},
            missingCurrency: {},

            unknownCustomer: {},
            unknownCurrency: {},
        },
        found: {
            currency: {},
            rows: []
        }
    });
}

function aggregateTrial(
    rows: TrialArRow[],
    startDate: Date,
    externalReferences: { customers: Customer[]; }
) {
    return rows.reduce((acc: UnsettledReleaseResult, row: TrialArRow) => {
        const customerDescription = row["Customer Id"]?.trim();
        const currency = row["Currency"]?.trim();
        const net = row["Total AR"];

        if (!customerDescription) {
            acc.errors.missingCustomer[`Invoice: ${row["Invoice Number"]}. TransactionDate: ${row["Transaction Date"]}`] = true;
            return acc;
        }

        if (!currency) {
            acc.errors.missingCurrency[`Customer: ${customerDescription}. Invoice: ${row["Invoice Number"]}`] = true;
            return acc;
        }

        const foundCustomer = searchFuzzyCustomers(customerDescription, externalReferences.customers);
        if (!foundCustomer) {
            acc.errors.unknownCustomer[customerDescription] = true;
            return acc;
        }
        if (!["US$", "CAN$"].includes(currency)) {
            acc.errors.unknownCurrency[currency] = true;
            return acc;
        }

        const convertedCurrency: string = currency?.toLocaleUpperCase() as any;

        if (!acc.found.currency[convertedCurrency]) {
            acc.found.currency[convertedCurrency] = {};
        }

        if (!acc.found.currency[convertedCurrency][foundCustomer.long_name]) {
            acc.found.currency[convertedCurrency][foundCustomer.long_name] = { amount: new Decimal(0), isCommercial: foundCustomer.is_commercial || false };
        }

        acc.found.currency[convertedCurrency][foundCustomer.long_name].amount = acc.found.currency[convertedCurrency][foundCustomer.long_name].amount.plus(safeParseDecimal(net));

        return acc;
    }, {
        errors: {
            missingCustomer: {},
            missingCurrency: {},

            unknownCustomer: {},
            unknownCurrency: {},
        },
        found: {
            currency: {},
            rows: []
        }
    });
}

async function aggregateAndAssembleMasterReport({
    unsettledUsAggregation,
    unsettledCanAggregation,
    trialAggregation,
    externalReferences,
    options,
}: {
    unsettledUsAggregation: any,
    unsettledCanAggregation: any,
    trialAggregation: any,
    externalReferences: { customers: Customer[]; }
    options: { manualFxRate?: number; aggNonCommLimit: number; aggCommLimit: number; variation: ReportVariation; };
}) {
    const testWorkbook = new ExcelJS.Workbook();

    const sheets = {
        Summary: testWorkbook.addWorksheet("Summary"),
        Commercial: testWorkbook.addWorksheet("Commercial"),
        NonCommercial: testWorkbook.addWorksheet("Non Commercial"),
        Loads: testWorkbook.addWorksheet("Loads"),
    }

    const commResult = aggregateMasterSheet(
        sheets.Commercial,
        {
            unsettledUsAggregation: unsettledUsAggregation,
            unsettledCanAggregation: unsettledCanAggregation,
            trialAggregation,
            isCommercial: true,
        },
        (data: any) => data.isCommercial === true,
        externalReferences,
        { manualFxRate: options.manualFxRate, commExceedLimit: 1000000, nonCommExceedLimit: 75000 },
    );

    const nonComResult = aggregateMasterSheet(
        sheets.NonCommercial,
        {
            unsettledUsAggregation: unsettledUsAggregation,
            unsettledCanAggregation: unsettledCanAggregation,
            trialAggregation,
            isCommercial: false,
        },
        (data: any) => data.isCommercial === false,
        externalReferences,
        { manualFxRate: options.manualFxRate, commExceedLimit: 1000000, nonCommExceedLimit: 75000 },
    );

    generateSummaryBox({ sheet: sheets.Commercial, commResult, nonComResult });

    const fxRate = options.manualFxRate ||  1;
    sheets.Commercial.getRow(11).getCell("I").value = "FX Rate";
    sheets.Commercial.getRow(11).getCell("J").value = fxRate;
    boldCell(sheets.Commercial, 11, "I");

    generateSummaryPageContent({ sheets, commResult, nonComResult, aggNonCommLimit: options.aggNonCommLimit, aggCommLimit: options.aggCommLimit });

    const date = moment().format("YYYY_MM_DDTHH:mm");
    downloadBinaryData(
        await workbookToBuffer(testWorkbook),
        `ar_report_${date}.xlsx`,
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    );

    return new SuccessResult("", {
        errors: {
            unsettledUs: unsettledUsAggregation.errors,
            unsettledCan: unsettledCanAggregation.errors,
            trial: trialAggregation.errors,
        }
    });
}

function aggregateMasterSheet(
    sheet: any,
    {
        unsettledUsAggregation,
        unsettledCanAggregation,
        trialAggregation,
        isCommercial,
    }:{
        unsettledUsAggregation: any;
        unsettledCanAggregation: any;
        trialAggregation: any;
        isCommercial: boolean;
    },
    shouldSkip: (item: any) => boolean,
    externalReferences: { customers: Customer[]; },
    options: { manualFxRate?: number, commExceedLimit: number; nonCommExceedLimit: number; },
) {
    const fxRate = options.manualFxRate ||  1;
    setColumnInfo(sheet, "J", CellFormat.Float3, 14);

    const exceededAmount = isCommercial ? options.commExceedLimit : options.nonCommExceedLimit;

    const usSection = computeSection({
        sheet,
        aggData: unsettledUsAggregation,
        trialAggregation,
        fxRate,
        shouldSkip,
        convertCurrency: "",
        currency: "US$",
        exceededAmount,
    });


    sheet.addRow(["", "", "", "", "", "", ""])
    sheet.addRow(["", "", "", "", "", "", ""])

    const canSection = computeSection({
        sheet,
        aggData: unsettledCanAggregation,
        trialAggregation,
        fxRate,
        shouldSkip,
        convertCurrency: "US$",
        currency: "CAN$",
        exceededAmount,
    });

    sheet.addRow(["", "", "", "", "", "", ""])

    setColumnInfo(sheet, "C", CellFormat.ACCOUNTING, 17);
    setColumnInfo(sheet, "D", CellFormat.ACCOUNTING, 17);
    setColumnInfo(sheet, "E", CellFormat.ACCOUNTING, 17);
    setColumnInfo(sheet, "F", CellFormat.ACCOUNTING, 17);
    setColumnInfo(sheet, "G", CellFormat.ACCOUNTING, 17);

    return {
        usSection,
        canSection,
    }
}

function computeSection({
    currency,
    convertCurrency,
    aggData,
    trialAggregation,
    sheet,
    fxRate,
    shouldSkip,
    exceededAmount,
}:{
    currency: string;
    convertCurrency: string;
    aggData: any;
    trialAggregation: any;
    sheet: any;
    fxRate: number;
    shouldSkip: (item: any) => boolean;
    exceededAmount: number;
}) {
    let firstRow: undefined | number = undefined;
    let lastRow: undefined | number = undefined;

    const summaries = {
        totalTrialArBalance: safeParseDecimal(0),
        totalUnsettledAr: safeParseDecimal(0),
        totalOpenLoads: safeParseDecimal(0),
        totalAr: safeParseDecimal(0),
        totalConvertAr: safeParseDecimal(0),
    }

    sheet.getColumn("B").width = 28;

    const headerRowValues = ["", currency, "AR Trial Balance", "Total Unsettled AR", "Open Loads", `Total AR ${currency}`, convertCurrency ? `Total AR ${convertCurrency}`: "", "Notes"];
    const headerRow = sheet.addRow(headerRowValues);
    boldCell(sheet, headerRow.number, "B");
    new Array(headerRowValues.length).fill(0).forEach((_item, index) => {
        headerRow.getCell(index + 1).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
        };
    });


    const currencyData = aggData.found.currency[currency] || {};
    const trialData = trialAggregation.found.currency[currency] || {};

    const unsettledKeys = Object.keys(currencyData);
    const newTrialKeys = findDifferences(unsettledKeys, Object.keys(trialData))

    unsettledKeys.sort().forEach((customerName: string) => {
        const value = currencyData[customerName];
        if (!shouldSkip(value)) return;

        const trialAmount = safeParseDecimal(getPath(trialAggregation, ["found", "currency", currency, customerName, "amount"]) as any);
        const unsettledAmount = safeParseDecimal(value.amount);
        const loadAmount = safeParseDecimal(0);
        const totalArAmount = unsettledAmount.plus(trialAmount).plus(loadAmount);
        const toalConvertAmount = !!convertCurrency ? safeParseDecimal(totalArAmount).times(fxRate) : safeParseDecimal(0);

        summaries.totalTrialArBalance = summaries.totalTrialArBalance.plus(trialAmount);
        summaries.totalUnsettledAr = summaries.totalUnsettledAr.plus(unsettledAmount);
        summaries.totalOpenLoads = summaries.totalOpenLoads.plus(loadAmount);
        summaries.totalAr = summaries.totalAr.plus(totalArAmount);
        summaries.totalConvertAr = summaries.totalConvertAr.plus(toalConvertAmount);


        const recentRow = sheet.addRow(["", customerName, trialAmount.toNumber(), unsettledAmount.toNumber(), loadAmount.toNumber(), totalArAmount.toNumber(), toalConvertAmount.toNumber()]);
        recentRow.getCell("F").value = {
            formula: `=SUM(C${recentRow.number}:E${recentRow.number})`,
            result: totalArAmount.toNumber()
        };

        if (!!convertCurrency) {
            recentRow.getCell("G").value = {
                formula: `=F${recentRow.number}*Commercial!$J$11`,
                result: (toalConvertAmount).toNumber()
            };
        }


        if (!!convertCurrency && toalConvertAmount.gte(exceededAmount)) {
            boldCell(sheet, recentRow.number, "G");
        }  else if (!convertCurrency && totalArAmount.gte(exceededAmount)) {
            boldCell(sheet, recentRow.number, "F");
        }

        if (!firstRow) { firstRow = recentRow.number; }
        lastRow = recentRow.number;
    })

    newTrialKeys.forEach((customerName: string) => {
        const value = trialData[customerName];
        if (!shouldSkip(value)) return;

        const trialAmount = safeParseDecimal(value.amount as any);
        const unsettledAmount = safeParseDecimal(getPath(aggData, ["found", "currency", currency, customerName, "amount"]) as any);
        const loadAmount = safeParseDecimal(0);
        const totalArAmount = unsettledAmount.plus(trialAmount).plus(loadAmount);
        const toalConvertAmount = !!convertCurrency ? safeParseDecimal(totalArAmount).times(fxRate) : safeParseDecimal(0);

        summaries.totalTrialArBalance = summaries.totalTrialArBalance.plus(trialAmount);
        summaries.totalUnsettledAr = summaries.totalUnsettledAr.plus(unsettledAmount);
        summaries.totalOpenLoads = summaries.totalOpenLoads.plus(loadAmount);
        summaries.totalAr = summaries.totalAr.plus(totalArAmount);
        summaries.totalConvertAr = summaries.totalConvertAr.plus(toalConvertAmount);


        const recentRow = sheet.addRow(["", customerName, trialAmount.toNumber(), unsettledAmount.toNumber(), loadAmount.toNumber(), totalArAmount.toNumber(), toalConvertAmount.toNumber()]);
        recentRow.getCell("F").value = {
            formula: `SUM(C${recentRow.number}:E${recentRow.number})`,
            result: totalArAmount.toNumber()
        };
        if (!!convertCurrency) {
            recentRow.getCell("G").value = {
                formula: `F${recentRow.number}*Commercial!$J$11`,
                result: (toalConvertAmount).toNumber()
            };
        }

        if (!!convertCurrency && toalConvertAmount.gte(exceededAmount)) {
            boldCell(sheet, recentRow.number, "G");
        }  else if (!convertCurrency && totalArAmount.gte(exceededAmount)) {
            boldCell(sheet, recentRow.number, "F");
        }

        if (!firstRow) { firstRow = recentRow.number; }
        lastRow = recentRow.number;
    });

    const totalRowValues = [
        "",
        `Total ${currency}`,
        summaries.totalTrialArBalance.toNumber(),
        summaries.totalUnsettledAr.toNumber(),
        0, summaries.totalAr.toNumber(), summaries.totalConvertAr.toNumber()];
    const totalRow = sheet.addRow(totalRowValues)
    new Array(totalRowValues.length).fill(0).forEach((_item, index) => {
        totalRow.getCell(index + 1).border = {
            top: {style:'thin', color: {argb:'00000000'}},
        };
    });

    totalRow.getCell("C").value = {
        formula: `SUM(C${firstRow}:C${lastRow})`,
        result: summaries.totalTrialArBalance.toNumber()
    };
    totalRow.getCell("D").value = {
        formula: `SUM(D${firstRow}:D${lastRow})`,
        result: summaries.totalUnsettledAr.toNumber()
    };
    totalRow.getCell("E").value = {
        formula: `SUM(E${firstRow}:E${lastRow})`,
        result: summaries.totalOpenLoads.toNumber()
    };
    totalRow.getCell("F").value = {
        formula: `SUM(F${firstRow}:F${lastRow})`,
        result: summaries.totalAr.toNumber()
    };

    if (!!convertCurrency) {
        totalRow.getCell("G").value = {
            formula: `SUM(G${firstRow}:G${lastRow})`,
            result: summaries.totalConvertAr.toNumber()
        };
    }

    boldCell(sheet, totalRow.number, "B");

    return {
        hasRows: true,
        firstRow,
        lastRow,
        totalRowValues,
        summaryRow: totalRow.number,
        summaries,
    };
}

function findDifferences(array1: string[], array2: string[]) {
  const set1 = new Set(array1);
  return array2.filter(item => !set1.has(item));
}

function generateSummaryBox({ sheet, commResult, nonComResult }: any) {
    // Define the range
    const startCell = sheet.getCell('J1');
    const rowOneEndCell = sheet.getCell('M1');
    const endCell = sheet.getCell('M9');

    applyBorderToRange(sheet, startCell, rowOneEndCell, ['bottom']);

    // Apply borders
    applyBorderToRange(sheet, { col: startCell.col, row: startCell.row }, { col: endCell.col, row: startCell.row }, ['top']);
    applyBorderToRange(sheet, { col: startCell.col, row: startCell.row }, { col: startCell.col, row: endCell.row }, ['left']);
    applyBorderToRange(sheet, { col: startCell.col, row: endCell.row }, endCell, ['bottom']);
    applyBorderToRange(sheet, { col: endCell.col, row: startCell.row }, endCell, ['right']);

    sheet.getCell("K1").value = "Total";
    sheet.getCell("L1").value = "Balance Report";
    sheet.getCell("M1").value = "Variance";
    boldCell(sheet, 1, "K");
    boldCell(sheet, 1, "L");

    sheet.getCell("J2").value = "Total Unsettled AR US$";
    sheet.getCell("J3").value = "Total Unsettled AR US$";

    sheet.getCell("J7").value = "US$ AR";
    sheet.getCell("J8").value = "CAN$ AR";

    sheet.getColumn("J").width = 24;

    sheet.getCell("M2").value = 0;
    sheet.getCell("M3").value = 0;
    sheet.getCell("M7").value = 0;
    sheet.getCell("M8").value = 0;

    sheet.getCell("L2").value = 0;
    sheet.getCell("L3").value = 0;

    sheet.getCell("L7").value = 0;
    sheet.getCell("L8").value = 0;

    sheet.getCell("L2").fill = lightGreenFill;
    sheet.getCell("L3").fill = lightGreenFill;
    sheet.getCell("L7").fill = lightGreenFill;
    sheet.getCell("L8").fill = lightGreenFill;

    sheet.getCell("K2").value = {
        formula: `=D${commResult.usSection.summaryRow}+'Non Commercial'!D${nonComResult?.usSection?.summaryRow}`,
    };
    sheet.getCell("K3").value = {
        formula: `=D${commResult.canSection.summaryRow}+'Non Commercial'!D${nonComResult?.canSection?.summaryRow}`,
    };

    sheet.getCell("K7").value = {
        formula: `=C${commResult.usSection.summaryRow}+'Non Commercial'!C${nonComResult?.usSection?.summaryRow}`,
    };
    sheet.getCell("K8").value = {
        formula: `=C${commResult.canSection.summaryRow}+'Non Commercial'!C${nonComResult?.canSection?.summaryRow}`,
    };

    sheet.getCell("M2").value = {
        formula: `=L2-K2`,
    };
    sheet.getCell("M3").value = {
        formula: `=L3-K3`,
    };

    sheet.getCell("M7").value = {
        formula: `=L7-K7`,
    };
    sheet.getCell("M8").value = {
        formula: `=L8-K8`,
    };

    sheet.getCell("K4").value = {
        formula: `=SUM(K2:K3)`,
    };
    sheet.getCell("L4").value = {
        formula: `=SUM(L2:L3)`,
    };
    sheet.getCell("M4").value = {
        formula: `=SUM(M2:M3)`,
    };

    sheet.getCell("K9").value = {
        formula: `=SUM(K7:K8)`,
    };
    sheet.getCell("L9").value = {
        formula: `=SUM(L7:L8)`,
    };
    sheet.getCell("M9").value = {
        formula: `=SUM(M7:M8)`,
    };

    setColumnInfo(sheet, "K", CellFormat.ACCOUNTING, 15);
    setColumnInfo(sheet, "L", CellFormat.ACCOUNTING, 15);
    setColumnInfo(sheet, "M", CellFormat.ACCOUNTING, 15);
}

function generateSummaryPageContent({ sheets, commResult, nonComResult, aggNonCommLimit, aggCommLimit }: any) {
    makePaperLikeSpreadsheet(sheets.Summary);
    sheets.Summary.addRow(["", "", "", "", ""])
    sheets.Summary.addRow(["", "", "", "", ""])
    sheets.Summary.addRow(["", "", "", "", ""])
    sheets.Summary.addRow(["", "Weekly Credit Limit Report", "", "", ""])
    sheets.Summary.addRow(["", "Lighthouse Commodities", "", "", ""])
    sheets.Summary.addRow(["", "", "", "", ""])
    sheets.Summary.addRow(["", "", "", "", ""])
    sheets.Summary.addRow(["", "", "", "", ""])
    sheets.Summary.addRow(["", "Type", "Total AR", "Limit", "Limit Available"])
    sheets.Summary.addRow(["", "", "", "", ""])


    const aggCommRow = sheets.Summary.addRow(["", "Aggregate Commmercial", 0, 0, 0])
    aggCommRow.getCell("C").value = {
        formula: `=Commercial!F${commResult?.usSection?.summaryRow}+Commercial!G${commResult?.canSection?.summaryRow}`,
    };
    aggCommRow.getCell("D").value = aggCommLimit;
    aggCommRow.getCell("E").value = {
        formula: `=D${aggCommRow.number}-C${aggCommRow.number}`,
    };

    const aggNonCommRow = sheets.Summary.addRow(["", "Aggregate Non-Commercial", 0, 0, 0])
    aggNonCommRow.getCell("C").value = {
        formula: `='Non Commercial'!F${nonComResult?.usSection?.summaryRow}+'Non Commercial'!G${nonComResult?.canSection?.summaryRow}`,
    };
    aggNonCommRow.getCell("D").value = aggNonCommLimit;
    aggNonCommRow.getCell("E").value = {
        formula: `=D${aggNonCommRow.number}-C${aggNonCommRow.number}`,
    };

    const totalRow = sheets.Summary.addRow(["","", 0, 0, 0])
    totalRow.getCell("C").value = {
        formula: `=SUM(C11:C12)`,
    };
    totalRow.getCell("D").value = {
        formula: `=SUM(D11:D12)`,
    };
    totalRow.getCell("E").value = {
        formula: `=SUM(E11:E12)`,
    };

    boldCell(sheets.Summary, totalRow.number, "C");
    boldCell(sheets.Summary, totalRow.number, "D");
    boldCell(sheets.Summary, totalRow.number, "E");

    setColumnInfo(sheets.Summary, "C", CellFormat.CurrencyRed, 17);
    setColumnInfo(sheets.Summary, "D", CellFormat.CurrencyRed, 17);
    setColumnInfo(sheets.Summary, "E", CellFormat.CurrencyRed, 17);
    sheets.Summary.getColumn("B").width = 30;

    sheets.Summary.addRow(["", "", "", "", ""])
    sheets.Summary.addRow(["", "", "", "", ""])

    sheets.Summary.addRow(["", "RBC"])
    sheets.Summary.addRow(["", "RBC US$"])
    sheets.Summary.addRow(["", "RJO  Current Net Liquidating Value"])

    sheets.Summary.addRow(["", "StoneX Net Liquidating Value"])
    sheets.Summary.addRow(["", "Dorman Net Liquidating Value"])
    sheets.Summary.addRow(["", "Convera Holdings"])

    sheets.Summary.addRow(["", "", "", "", ""])
    sheets.Summary.addRow(["", "Total"])
    sheets.Summary.getCell("E23").value = {
        formula: `=SUM(E16:E22)`,
    };

    sheets.Summary.mergeCells("B4:E4");
    sheets.Summary.mergeCells("B5:E5");

    boldCell(sheets.Summary, 4, "B");
    boldCell(sheets.Summary, 5, "B");

    boldCell(sheets.Summary, 9, "B");
    boldCell(sheets.Summary, 9, "C");
    boldCell(sheets.Summary, 9, "D");
    boldCell(sheets.Summary, 9, "E");

    boldCell(sheets.Summary, 23, "B");
    boldCell(sheets.Summary, 23, "C");
    boldCell(sheets.Summary, 23, "D");
    boldCell(sheets.Summary, 23, "E");

    setCellFill(sheets.Summary, ["B4"], "FF861810");
    setFontColor(sheets.Summary, ["B4"], "FFFFFFFF");
    setFontColor(sheets.Summary, ["B5"], "FF3A3A3A");
    setFontSize(sheets.Summary, ["B4"], 26);
    setFontSize(sheets.Summary, ["B5"], 14);

    setCellAlignments(sheets.Summary, ["B23"], { vertical: "center", horizontal: "right" });
    setCellAlignments(sheets.Summary, ["B4", "B5"]);
    setCellAlignments(sheets.Summary, ["B9", "C9", "D9", "E9"]);
    setCellFill(sheets.Summary, ["B9", "C9", "D9", "E9"], "FF3A3A3A");
    setFontColor(sheets.Summary, ["B9", "C9", "D9", "E9"], "FFFFFFFF");

    applyBorderToRange(sheets.Summary, getCell(sheets.Summary, "B9"), getCell(sheets.Summary, "E13"), ['top', 'left', 'bottom', 'right']);
    applyBorderToRange(sheets.Summary, getCell(sheets.Summary, "B16"), getCell(sheets.Summary, "E21"), ['top', 'left', 'bottom', 'right']);

    applyBorderToRange(sheets.Summary, getCell(sheets.Summary, "B16"), getCell(sheets.Summary, "E21"), ['top', 'left', 'bottom', 'right']);
    applyBorderToRange(sheets.Summary, getCell(sheets.Summary, "B23"), getCell(sheets.Summary, "E23"), ['top', 'left', 'bottom', 'right']);

    setCellFill(sheets.Summary, ["B13", "C13", "D13", "E13"], "FFD9D9D9");
    setCellFill(sheets.Summary, ["E16", "E17", "E18", "E19", "E20", "E21"], lightGreenFill.fgColor.argb);
}
